import React from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import { IoMdClose } from "react-icons/io";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";

function RateModal({ setOpenRate = () => {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const rateArr = [
    {
      title: "Hermes bags",
      point: [
        "<IDR 50,000,000 = 20%",
        "IDR 51,000,000 - 100,000,000 =15%",
        "IDR 101,000,000 - 150,000,000 = 12%",
        "IDR 151,000,000 - 250,000,000 = 10%",
        "IDR 251,000,000 - 350,000,000 = 9%",
        "IDR 351,000,000 - 850,000,000 = 8%",
        ">IDR 850,000,000 = 6%",
      ],
    },
    {
      title: "All other brands (and Hermes accessories)",
      point: [
        "IDR 5,000,000 - 15,000,000 = 25%",
        "IDR 15,000,001 - 70,000,000 =20%",
        "IDR 70,000,001 - 100,000,000 = 15%",
        "IDR 100,000,001 - 350,000,000 = 10%",
        ">IDR 350,000,000 = 5%",
      ],
    },
  ];
  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <LatoBold
          style={{
            fontSize: 20,
            lineHeight: 28,
          }}
        >
          Our fee rates
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenRate(false)}>
          <IoMdClose size={24} />
        </TouchableOpacity>
      </View>
      {rateArr.map(({ title, point }, i) => (
        <View key={i} style={{ marginBottom: 16 }}>
          <LatoRegular
            style={{
              fontSize: 18,
              lineHeight: 26,
              marginBottom: 8,
            }}
          >
            {title}
          </LatoRegular>
          {point.map((itm, j) => (
            <View
              key={j}
              style={{
                marginTop: 8,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <View
                style={{
                  backgroundColor: colors.grey33,
                  borderRadius: "100%",
                  width: 5,
                  height: 5,
                }}
              />
              <LatoRegular
                style={{
                  fontSize: isTablet ? 16 : 18,
                  lineHeight: isTablet ? 24 : 26,
                  marginLeft: 10,
                }}
              >
                {itm}
              </LatoRegular>
            </View>
          ))}
        </View>
      ))}
    </View>
  );
}

export default RateModal;
