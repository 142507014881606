import React from "react";
import { View } from "react-native";
import colors from "../assets/colors/colors";
import { LatoRegular } from "./Text";

export default function Line({
  title = "",
  color = colors.greyBB,
  width = "100%",
}) {
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        width,
        marginVertical: 16,
      }}
    >
      <View style={{ flex: 1, height: 1, backgroundColor: color }} />
      <View>
        <LatoRegular
          style={{ textAlign: "center", color, paddingHorizontal: 8 }}
        >
          {title}
        </LatoRegular>
      </View>
      <View style={{ flex: 1, height: 1, backgroundColor: color }} />
    </View>
  );
}
