import React, { useContext, useEffect, useState } from "react";
import {
  View,
  TextInput,
  Platform,
  useWindowDimensions,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "./Text";
import ShowPass from "../assets/images/show-password.png";
import HidePass from "../assets/images/hide-password.png";
import { IoMdArrowDropdown } from "react-icons/io";
import {
  relationDecider,
  selectionDataChecker,
  selectionURLDecider,
} from "../tools/decider";
import { api } from "../utils/api";
import { formatCurrency } from "../tools/formatter";
import { upperCase } from "lodash";
import { GrSquare } from "react-icons/gr";
import { BsSquare } from "react-icons/bs";
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from "react-icons/md";
import ToasterContext from "../contexts/ToastContext";
import { asyncGetCurrency, asyncSetCurrency } from "../tools/asyncStorages";

export const MyInput = ({
  handleBlur = () => {},
  handleChange = () => {},
  error = "",
  name = "",
  placeholder = "",
  initialValue = "",
  label = "",
  customWidth = false,
  hide = false,
  required = false,
  disabled = false,
  noLabel = false,
  color = false,
  bordered = false,
  backgroundColor = false,
  checkByName = true,
  prefix = false,
  multiline = false,
  additional = {},
  textStyle = {},
  prefixStyle = {},
  length,
  value,
  leftIcon = false,
  inline = false,
  helper = "",
  autoClear = false,
  handlingEnter = () => {},
}) => {
  const [show, setShow] = useState(hide);
  const [showLabel, setShowLabel] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  return (
    <View
      style={{
        marginBottom: 16,
      }}
    >
      {Boolean(!noLabel && (showLabel || label)) && (
        <LatoRegular style={{ marginBottom: multiline ? 12 : 6 }}>
          {label || placeholder}
          {Boolean(required) && (
            <LatoRegular style={{ color: colors.redB6 }}>*</LatoRegular>
          )}
        </LatoRegular>
      )}
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {Boolean(leftIcon) && leftIcon}
        {Boolean(prefix) && (
          <LatoBold
            style={{
              color: colors.grey33,
              marginBottom: 6,
              fontSize: 14,
              lineHeight: 20,
              ...prefixStyle,
            }}
          >
            {prefix}
          </LatoBold>
        )}
        <TextInput
          editable={!disabled}
          placeholder={required ? `${placeholder}*` : placeholder}
          maxLength={length}
          placeholderTextColor={colors.grey6C}
          onKeyPress={handlingEnter}
          secureTextEntry={show}
          defaultValue={initialValue}
          clearTextOnFocus={autoClear}
          onChangeText={checkByName ? handleChange(name) : handleChange}
          onBlur={
            checkByName
              ? () => {
                  handleBlur(name);
                  setShowLabel(label);
                }
              : handleBlur
          }
          onFocus={() => setShowLabel(true)}
          multiline={multiline}
          style={
            Platform.OS === "web"
              ? {
                  borderColor: bordered ? color : "transparent",
                  borderBottomColor: color
                    ? color
                    : initialValue || label
                    ? colors.grey33
                    : colors.greyE6,
                  borderWidth: bordered ? 1 : 0,
                  borderBottomWidth: 1,
                  height: multiline ? 120 : 40,
                  width: customWidth
                    ? customWidth
                    : isPhone
                    ? "80vw"
                    : isTablet
                    ? "40vw"
                    : "32.5vw",
                  fontFamily: "Lato-Regular",
                  fontSize: 14,
                  lineHeight: 20,
                  paddingHorizontal: leftIcon
                    ? 20
                    : prefix
                    ? prefix.length * 10
                    : inline
                    ? 12
                    : 0,
                  paddingVertical: multiline ? 6 : 0,
                  marginLeft: leftIcon ? -20 : prefix ? prefix?.length * -8 : 0,
                  marginBottom: 6,
                  outlineWidth: 0,
                  color: color || colors.black00,
                  backgroundColor: backgroundColor || "transparent",
                  ...textStyle,
                }
              : {
                  borderColor: bordered ? color : "transparent",
                  borderBottomColor: color
                    ? color
                    : initialValue || label
                    ? colors.grey33
                    : colors.greyE6,
                  borderWidth: bordered ? 1 : 0,
                  borderBottomWidth: 1,
                  height: multiline ? 120 : 40,
                  fontSize: 14,
                  lineHeight: 20,
                  width: customWidth
                    ? customWidth
                    : isPhone
                    ? "80vw"
                    : isTablet
                    ? "40vw"
                    : "32.5vw",
                  fontFamily: "Lato-Regular",
                  paddingHorizontal: leftIcon
                    ? 20
                    : prefix
                    ? prefix.length * 10
                    : inline
                    ? 12
                    : 0,
                  paddingVertical: multiline ? 6 : 0,
                  marginLeft: leftIcon ? -20 : prefix ? prefix?.length * -8 : 0,
                  marginBottom: 6,
                  color: color || colors.black00,
                  backgroundColor: backgroundColor || "transparent",
                  ...textStyle,
                }
          }
          {...additional}
        />
        {Boolean(hide) && (
          <TouchableOpacity
            onPress={() => {
              setShow(!show);
            }}
          >
            <img
              src={show ? ShowPass : HidePass}
              alt="React Logo"
              width={20}
              height={20}
              style={{ alignSelf: "center", marginBottom: 16, marginLeft: -24 }}
            />
          </TouchableOpacity>
        )}
      </View>
      {Boolean(error) && (
        <LatoRegular
          style={{
            fontSize: 12,
            lineHeight: 16,
            color: colors.redAD,
            // paddingHorizontal: 6,
          }}
        >
          {error}
        </LatoRegular>
      )}
      {Boolean(helper) && (
        <LatoRegular
          style={{
            fontSize: 12,
            lineHeight: 16,
            color: colors.grey6C,
            // paddingHorizontal: 6,
          }}
        >
          {helper}
        </LatoRegular>
      )}
      {Boolean(length) && (
        <View style={{ position: "absolute", right: 10, bottom: 12 }}>
          <LatoRegular
            style={{
              fontSize: 12,
              lineHeight: 16,
              color: colors.black00,
              // paddingHorizontal: 6,
            }}
          >
            {`${value?.length}/${length}`}
          </LatoRegular>
        </View>
      )}
    </View>
  );
};

export const RadioSelection = ({
  selection = [],
  selected = false,
  setSelected = () => {},
  bordered = false,
  circle = false,
  installment = {},
  setInstallment = () => {},
}) => {
  const Selected = circle ? MdRadioButtonChecked : GrSquare;
  const Deselected = circle ? MdRadioButtonUnchecked : BsSquare;

  return selection?.map(
    (
      {
        label,
        value,
        rightIcon,
        disabled,
        installments = [],
        additional,
        hide,
      },
      idx
    ) => {
      const checker =
        typeof selected === "string"
          ? selected === value
          : JSON.stringify(selected) === JSON.stringify(value);
      return hide ? null : (
        <View key={idx}>
          <TouchableOpacity
            key={idx}
            style={{
              flexDirection: "row",
              alignItems: additional ? "flex-start" : "center",
              marginBottom: 16,
              marginRight: 24,
              borderWidth: bordered ? 1 : 0,
              borderColor: disabled
                ? colors.greyBB
                : checker
                ? colors.grey33
                : colors.grey90,
              borderRadius: 6,
              paddingVertical: bordered ? 10 : 0,
              paddingHorizontal: bordered ? 24 : 0,
            }}
            onPress={() => setSelected(value)}
            disabled={disabled}
          >
            {checker ? (
              <Selected color={colors.black00} size={20} />
            ) : (
              <Deselected
                color={disabled ? colors.greyBB : colors.grey90}
                size={20}
              />
            )}
            <View>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  marginHorizontal: 8,
                  color: disabled ? colors.greyBB : colors.grey33,
                  fontFamily: additional ? "lato-bold" : "lato-regular",
                  marginBottom: additional ? 8 : 0,
                }}
              >
                {label || ""}
              </LatoBold>
              {Boolean(additional) &&
                additional.map((item, i) => (
                  <LatoRegular
                    key={i}
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      marginHorizontal: 8,
                      color: disabled ? colors.greyBB : colors.grey90,
                    }}
                  >
                    {item || ""}
                  </LatoRegular>
                ))}
            </View>
            {Boolean(rightIcon) && rightIcon}
          </TouchableOpacity>
          {Boolean(installments?.length && checker) && (
            <SelectionDropDown
              name={"installment"}
              required
              values={installment}
              setValue={setInstallment}
              label={"Installment"}
              noLabel
              initialID={installment}
              datas={installments}
            />
          )}
        </View>
      );
    }
  );
};

export const SelectionDropDown = ({
  label = "",
  placeholder = "",
  required = false,
  initialValue = "",
  initialID = false,
  name = "",
  values = {},
  setValue = () => {},
  noLabel = false,
  error = false,
  datas = [],
  filterString = "",
  disabled = false,
  radioArr = [],
  setRadioArr = () => {},
  hide = false,
  setMainLoading = () => {},
  labelStyle = {},
  color,
}) => {
  const [initialData, setInitialData] = useState([]);
  const [selectionData, setSelectionData] = useState([]);
  const [disable, setDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [relationID, setRelationID] = useState();
  const { showToast } = useContext(ToasterContext);

  const getSelectionData = async () => {
    try {
      setMainLoading(true);
      setLoading(true);
      const relation_id = relationDecider(name, values);
      selectionDataChecker(name, values, setValue);
      const url = selectionURLDecider(name, relation_id);

      if (relation_id) {
        const res = relation_id && url && (await api.get(url));
        const data = res?.data?.data;

        const currency = await asyncGetCurrency();

        const initialValue =
          name === "currency"
            ? currency
            : name === "courier" && !values?.logistic
            ? data?.find(({ rate }) => initialID === rate?.id)
            : !values[name]?.id && data?.find(({ id }) => initialID === id);

        radioArr.forEach(({ value }, i) => {
          const options = data.filter(({ rate }) => value.includes(rate?.id));

          radioArr[i].hide = !Boolean(options?.length);
        });

        const value = radioArr.find(({ value }) =>
          value.includes(initialValue?.rate?.id)
        )?.value;

        setRadioArr(radioArr.filter(({ hide }) => !hide));

        setDisable(!relation_id);

        if (radioArr.length && value) {
          setSelectionData(data.filter(({ rate }) => value.includes(rate?.id)));
        } else if (!radioArr.length) {
          setSelectionData(data);
        }

        setInitialData(data);
        setRelationID(relation_id);
        name === "currency"
          ? setValue(initialValue)
          : initialValue && name === "courier"
          ? setValue(initialValue)
          : initialValue && setValue(name, initialValue);
      }
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
      setMainLoading(false);
    }
  };

  useEffect(() => {
    if (!datas.length) {
      getSelectionData();
    } else {
      setSelectionData(datas);
    }
  }, [values[name], filterString]);

  useEffect(() => {
    if (name === "courier") {
      setShow(false);
      if (filterString !== "other") {
        const newData = initialData.filter(({ rate }) =>
          filterString.includes(rate?.id)
        );

        setSelectionData(newData);
        if (newData.length === 1) {
          setDisable(true);
          setValue(newData[0]);
        } else {
          setDisable(false);
          const checker = newData.find(
            ({ logistic }) => logistic?.id === values?.logistic?.id
          );

          if (!checker) {
            setValue({});
          }
        }
      } else {
        const newData = initialData.filter(({ rate }) =>
          [4, 5].includes(rate?.id)
        );

        setSelectionData(newData);

        if (newData.length === 1) {
          setDisable(true);
          setValue(newData[0]);
        } else {
          setDisable(false);
          const checker = newData.find(
            ({ logistic }) => logistic?.id === values?.logistic?.id
          );

          if (!checker) {
            setValue({});
          }
        }
      }
    }
  }, [filterString]);

  return (
    <View
      style={{
        marginBottom: 32,
        opacity: hide ? 0 : 1,
      }}
    >
      {Boolean(!noLabel) && (
        <LatoRegular style={{ marginBottom: 12, ...labelStyle }}>
          {label}
          {Boolean(required) && (
            <LatoRegular style={{ color: colors.redB6 }}>*</LatoRegular>
          )}
        </LatoRegular>
      )}
      <TouchableOpacity
        style={{
          borderWidth: 1,
          borderColor:
            disable || show || values?.logistic || disabled
              ? colors.greyE6
              : color || colors.grey33,

          paddingHorizontal: 8,
          paddingVertical: 4,
        }}
        disabled={disable || !selectionData?.length || disabled}
        onPress={() => setShow(!show)}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <LatoRegular
            style={{
              color:
                initialValue || values[name] || values?.logistic?.name
                  ? colors.grey33
                  : color || colors.greyBB,
              fontFamily: values?.logistic?.name ? "Lato-Bold" : "Lato-Regular",
            }}
          >
            {name === "currency"
              ? values || initialValue || label
              : name === "courier" && values?.logistic
              ? `${upperCase(values?.logistic?.name)} - ${upperCase(
                  values?.rate?.type
                )} (IDR ${formatCurrency(values?.total_price)})` || label
              : values[name]?.name || initialValue || placeholder || label}
            {Boolean(
              required &&
                !initialValue &&
                !values[name] &&
                !values?.logistic?.name
            ) && <LatoRegular style={{ color: colors.greyBB }}>*</LatoRegular>}
          </LatoRegular>
          {loading ? (
            <ActivityIndicator color={colors.black00} size={"small"} />
          ) : (
            <IoMdArrowDropdown
              size={24}
              color={
                disable || disabled ? colors.greyBB : color || colors.grey33
              }
            />
          )}
        </View>

        {Boolean(name === "courier" && values?.logistic) && (
          <LatoRegular
            style={{ color: colors.greyBB, marginTop: 12 }}
          >{`Estimated Time: ${
            values?.duration_min === values?.duration_max ||
            values?.duration_unit === "hours"
              ? values?.duration_min === 0 || values?.duration_unit === "hours"
                ? "today"
                : values?.duration_min === 1
                ? "tomorrow"
                : values?.duration_min + " days"
              : values?.duration_min + "-" + values?.duration_max + " days"
          }`}</LatoRegular>
        )}
      </TouchableOpacity>
      {Boolean(error && !show) && (
        <LatoRegular
          style={{
            fontSize: 12,
            lineHeight: 16,
            color: colors.redAD,
            paddingTop: 6,
          }}
        >
          {error}
        </LatoRegular>
      )}
      {Boolean(show) && (
        <View style={{ borderWidth: 1, borderColor: colors.greyE6 }}>
          <ScrollView style={{ height: "12vh" }}>
            {selectionData.map((item, index) => {
              const {
                name: selection,
                id,
                logistic = {},
                rate = {},
                duration_min,
                duration_max,
                duration_unit,
                total_price,
              } = item;
              const { name: courierName } = logistic;
              const { type } = rate;

              return (
                <TouchableOpacity
                  style={{
                    paddingHorizontal: 8,
                    paddingVertical: 6,
                    borderBottomWidth: 1,
                    borderBottomColor: colors.greyE6,
                    backgroundColor:
                      (name === "currency" && values === selection) ||
                      (name !== "courier" &&
                        name !== "installment" &&
                        values[name]?.id === id) ||
                      (name === "courier" &&
                        values?.rate?.id === rate?.id &&
                        values?.logistic?.name === courierName) ||
                      (name === "installment" &&
                        values[name]?.name === selection)
                        ? colors.whiteE8
                        : colors.whiteFF,
                  }}
                  key={index}
                  onPress={async () => {
                    if (name === "currency") {
                      asyncSetCurrency(item?.name);
                      setValue(item?.name);
                    } else if (name === "courier" || name === "currency") {
                      setValue(item);
                    } else if (name === "installment") {
                      setValue({ installment: item });
                    } else {
                      setValue(name, item);
                    }
                    setShow(false);
                  }}
                >
                  {name === "courier" ? (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <View>
                        <LatoBold
                          style={{
                            textTransform: "uppercase",
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey33,
                          }}
                        >{`${courierName} - ${type.replace(
                          "_",
                          "-"
                        )}`}</LatoBold>
                        <LatoRegular
                          style={{
                            fontSize: 12,
                            lineHeight: 16,
                            color: colors.grey90,
                            marginTop: 8,
                          }}
                        >{`Estimated Time: ${
                          duration_min === duration_max ||
                          duration_unit === "hours"
                            ? duration_min === 0 || duration_unit === "hours"
                              ? "today"
                              : duration_min === 1
                              ? "tomorrow"
                              : duration_min + " days"
                            : duration_min + "-" + duration_max + " days"
                        }`}</LatoRegular>
                      </View>
                      <LatoBold
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: colors.grey33,
                        }}
                      >{`IDR ${formatCurrency(total_price)}`}</LatoBold>
                    </View>
                  ) : (
                    <LatoRegular>{selection}</LatoRegular>
                  )}
                </TouchableOpacity>
              );
            })}
          </ScrollView>
        </View>
      )}
    </View>
  );
};
