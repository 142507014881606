import React from "react";
import { View, useWindowDimensions } from "react-native";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { format } from "date-fns";
import { formatCurrency } from "../tools/formatter";
import RewardTracker from "../components/Account/RewardTracker";
import RewardSummary from "../components/Account/RewardSummary";
import { getDeviceType } from "../tools/Interface";
import BenefitRewardTable from "../components/BenefitRewardTable";

function RewardsScreen({ user = {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const { created_at, reward = {} } = user;
  const {
    current_spending,
    tier = {},
    expiration_date,
    next_tier_spending,
  } = reward;
  const { name } = tier;

  return (
    <View
      style={{
        width: isPhone ? width - 32 : isTablet ? width - 300 : width - 600,
      }}
    >
      <LatoBold style={{ fontSize: 24, lineHeight: 32 }}>My Rewards</LatoBold>
      <LatoRegular style={{ fontSize: 16, lineHeight: 24, marginVertical: 24 }}>
        {`Total spend from ${
          created_at ? format(new Date(created_at), "do MMM yyyy") : ""
        }: IDR ${formatCurrency(current_spending, true)}`}
      </LatoRegular>
      <RewardTracker position={name} current={Number(current_spending)} />
      <RewardSummary
        current={current_spending}
        expiry={expiration_date}
        tier={name}
        next={next_tier_spending}
      />
      <View
        style={{
          marginTop: 32,
          paddingBottom: 48,
          marginBottom: 48,
          borderBottomWidth: 1,
          borderBottomColor: colors.greyE6,
        }}
      >
        <View
          style={{
            backgroundColor: colors.whiteF1,
            paddingHorizontal: 12,
            paddingVertical: 8,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: colors.greyBB,
          }}
        >
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey6C,
              marginBottom: 8,
            }}
          >
            VIP ROOM Terms & Conditions
          </LatoBold>
          <LatoRegular
            style={{ fontSize: 14, lineHeight: 20, color: colors.grey6C }}
          >
            Every year, your rewards and tier positions on VIP Room will be
            reset - so make sure to make the most of them!
          </LatoRegular>
        </View>
      </View>
      <BenefitRewardTable tier={tier} />
    </View>
  );
}

export default RewardsScreen;
