import React from "react";
import {
  Easing,
  StyleSheet,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import BlackLogo from "../../assets/images/black-logo.svg";
import SearchPhone from "../../assets/images/search-icon.svg";
import { LiaSearchSolid } from "react-icons/lia";
import { BsSuitHeart } from "react-icons/bs";
import { PiHandbag } from "react-icons/pi";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { LatoBold, LatoRegular } from "../Text";

function TopNavBar({
  user = {},
  openBurger = false,
  setOpenBurger = () => {},
  openSubBurger = false,
  setOpenSubBurger = () => {},
  animate = () => {},
  setOpenSearch = () => {},
  hover = false,
  setHover = () => {},
  setOpenWishList = () => {},
  items = [],
  setOpenCart = () => {},
  isCheckout = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  return (
    <View
      style={{
        ...style.container,
        backgroundColor: colors.whiteFF,
        paddingHorizontal: isPhone
          ? 16
          : width < 1350
          ? 50
          : width < 1500
          ? 150
          : 230,
        borderBottomColor: colors.greyE6,
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          position: "absolute",
          left: isPhone ? width / 2 - 70 : width / 2 - 125,
        }}
      >
        <TouchableOpacity onPress={() => navigate("/")}>
          <img
            src={BlackLogo}
            alt="Belle&Kate Logo"
            style={{ height: isPhone ? 32 : 60 }}
          />
        </TouchableOpacity>
      </View>
      <View style={style.sideContent}>
        <TouchableOpacity
          onPress={() => {
            if (isTablet) {
              setOpenBurger(!openBurger);
              openSubBurger && openBurger && setOpenSubBurger(false);
              animate(Easing.ease);
            } else {
              setOpenSearch(true);
            }
          }}
          style={{
            ...style.rightItem,
            marginRight: isPhone ? 22 : 45,
          }}
        >
          {isTablet ? (
            <img src={SearchPhone} alt="Search" style={{ height: 24 }} />
          ) : (
            <View
              style={{
                paddingHorizontal: 28,
                paddingVertical: 8,
                borderColor: colors.grey90,
                borderWidth: 1,
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: 250,
              }}
            >
              <LiaSearchSolid size={isPhone ? 20 : 24} color={colors.grey90} />
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey90,
                  width: 175,
                  marginLeft: 8,
                }}
              >
                Search
              </LatoRegular>
            </View>
          )}
        </TouchableOpacity>
      </View>
      <View style={style.sideContent}>
        <View style={style.sideContent}>
          {Boolean(!isPhone) && (
            <TouchableOpacity
              onMouseEnter={() => {
                if (hover !== "login") {
                  setHover("login");
                }
              }}
              onMouseLeave={() => {
                if (hover) {
                  setHover("");
                }
              }}
              onPress={() =>
                navigate(user?.id ? "/account/orders" : "/login", {
                  state: { idx: 0, index: 1 },
                })
              }
              style={{ ...style.rightItem }}
            >
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: hover === "login" ? colors.green5B : colors.grey33,
                  textTransform: "uppercase",
                  textDecorationLine: hover === "login" ? "underline" : "none",
                }}
              >
                {user?.id ? "My Account" : "Sign in/register"}
              </LatoRegular>
            </TouchableOpacity>
          )}
        </View>
        <View style={style.sideContent}>
          <TouchableOpacity
            onMouseEnter={() => {
              if (hover !== "wishlist") {
                setHover("wishlist");
              }
            }}
            onMouseLeave={() => {
              if (hover) {
                setHover("");
              }
            }}
            onPress={() => {
              setOpenWishList(true);
              animate(Easing.ease);
            }}
            style={style.rightItem}
          >
            {isTablet ? (
              <BsSuitHeart size={24} color={colors.grey33} />
            ) : (
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: hover === "wishlist" ? colors.green5B : colors.grey33,
                  textTransform: "uppercase",
                  textDecorationLine:
                    hover === "wishlist" ? "underline" : "none",
                }}
              >
                Wishlist
              </LatoRegular>
            )}
          </TouchableOpacity>
        </View>
        <TouchableOpacity
          onMouseEnter={() => {
            if (hover !== "cart") {
              setHover("cart");
            }
          }}
          onMouseLeave={() => {
            if (hover) {
              setHover("");
            }
          }}
          onPress={() => {
            setOpenCart(true);
            animate(Easing.ease);
          }}
        >
          {items.length && !isCheckout ? (
            <View
              style={{
                borderRadius: "100%",
                backgroundColor: colors.grey33,
                alignItems: "center",
                width: 32,
                height: 32,
              }}
            >
              <LatoBold
                style={{
                  color: colors.whiteFF,
                  fontSize: 14,
                  margin: 7,
                }}
              >
                {items.length}
              </LatoBold>
            </View>
          ) : (
            <PiHandbag
              size={isPhone ? 24 : 32}
              color={hover === "cart" ? colors.green5B : colors.grey33}
            />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default TopNavBar;

const style = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 24,
    paddingHorizontal: 45,
  },
  sideContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  leftItem: {
    marginRight: 40,
    color: colors.black00,
    textDecorationLine: "none",
    fontSize: 14,
    fontFamily: "Lato-Regular",
    zIndex: 5,
  },
  rightItem: {
    color: colors.black00,
    textDecorationLine: "none",
    fontSize: 14,
    fontFamily: "Lato-Regular",
    marginRight: 32,
  },
  textStyle: { fontSize: 16, lineHeight: 24 },
});
