import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import Footer from "../components/Footer";
import ProductCover from "../components/Home/ProductCover";
import ProductGrid from "../components/Home/ProductGrid";
import Navbar from "../components/Navbar";
import { api } from "../utils/api";
import { getDeviceType } from "../tools/Interface";
import BannerCarousel from "../components/Home/BannerCarousel";
import VisitUs from "../components/Home/VisitUs";
import Commitment from "../components/Home/Commitment";
import Classroom from "../components/Home/Classroom";
import Editorial from "../components/Home/Editorial";
import { asyncOpenSubscribe } from "../tools/asyncStorages";
import NewsletterModal from "../components/Home/NewsletterModal";
import { LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { useNavigate } from "react-router";

export default function HomeScreen() {
  const [productArr, setProductArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState([]);
  const [promotion, setPromotion] = useState([]);
  const [show, setShow] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const getData = async () => {
    try {
      const res = await api.get(
        "/api/inventories/v2?page=1&per_page=8&q[active_eq]=true&q[product_sku_not_null]=1"
      );
      const data = res.data.data;

      setProductArr(data.slice(0, isTablet && !isPhone ? 6 : 8));

      const shown = await asyncOpenSubscribe();

      setShow(shown);
    } catch (err) {
      console.log("err:", err.response);
    }
  };

  const getArticles = async () => {
    try {
      setLoading(true);

      const res = await api.get("/api/editorials/articles");

      setArticle(res?.data?.data || []);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  const getVoucherPromotion = async () => {
    try {
      setLoading(true);

      const res = await api.get("/api/vouchers/promoted");

      setPromotion(res?.data?.data || []);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    getArticles();
    getVoucherPromotion();
  }, [width]);

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar promotion={promotion} />
        <BannerCarousel loading={loading} />
        <ProductCover isHome />
        <ProductGrid data={productArr} wrap isHome />
        <Classroom
          data={
            article.filter(({ category }) => category === "belle&kate_class") ||
            []
          }
          loading={loading}
        />
        <Editorial
          data={
            article.filter(
              ({ category }) => category === "fresh_from_her_room"
            ) || []
          }
          loading={loading}
        />
        <Commitment />
        <VisitUs />
        <Footer />
      </ScrollView>
      {Boolean(show) && <NewsletterModal setShow={setShow} />}
    </View>
  );
}
