import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { Dimensions, Platform, Image, Animated, View } from "react-native";
import Shimmer from "../components/Shimmer";

const isBrowser = typeof window !== `undefined`;

export function getScrollPosition({ element, useWindow }) {
  if (!isBrowser) return { x: 0, y: 0 };

  const target = element ? element.current : document.body;
  const position = target.getBoundingClientRect();

  return useWindow
    ? { x: window.scrollX, y: window.scrollY }
    : { x: position.left, y: position.top };
}

export const { width, height } = Dimensions.get("window");

export const getDeviceType = ({ width, height }) => {
  const isTablet = width < 1230;
  const isPhone = width < 825;

  return { isPhone, isTablet };
};

export const isMobile = Platform.OS === "android" || Platform.OS === "ios";

export const CustomImageDimensions = ({ uri, realWidth, realHeight }) => {
  const [desiredHeight, setDesiredHeight] = useState(0);
  const [desiredWidth, setDesiredWidth] = useState(0);

  Image.getSize(uri, (width, height) => {
    setDesiredHeight((realWidth / width) * height || realHeight);
    setDesiredWidth((realHeight / height) * width || realWidth);
  });

  return { desiredHeight, desiredWidth };
};

export const RemoteImage = ({ uri, realWidth, realHeight }) => {
  const animatedImage = useRef(new Animated.Value(0)).current;
  const [desiredHeight, setDesiredHeight] = useState(0);
  const [desiredWidth, setDesiredWidth] = useState(0);

  Image.getSize(uri, (width, height) => {
    setDesiredHeight((realWidth / width) * height || realHeight);
    setDesiredWidth((realHeight / height) * width || realWidth);
  });

  const handleImageLoad = () => {
    Animated.timing(animatedImage, {
      toValue: 1,
      duration: 5000,
      useNativeDriver: true,
    }).start();
  };

  return (
    <View>
      <Shimmer
        autoRun={true}
        visible={false}
        width={desiredWidth}
        height={desiredHeight}
      />
      <Animated.Image
        source={{ uri }}
        style={{
          width: desiredWidth,
          height: desiredHeight,
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
        draggable={false}
        onLoad={handleImageLoad}
        defaultSource={() => (
          <Shimmer
            autoRun={true}
            visible={false}
            width={desiredWidth}
            height={desiredHeight}
          />
        )}
      />
    </View>
  );
};
