import React, { useContext } from "react";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
  Image,
  Linking,
} from "react-native";
import colors from "../assets/colors/colors";
import { LatoRegular } from "./Text";
import { RiCopyrightLine } from "react-icons/ri";
import FooterMenu from "./Home/FooterMenu";
import { getDeviceType } from "../tools/Interface";
import DropDownTab from "./DropDownTab";
import { MyInput, SelectionDropDown } from "./Inputs";
import { Formik } from "formik";
import Buttons from "./Buttons";
import { regexChecker } from "../tools/regexChecker";
import ToasterContext from "../contexts/ToastContext";
import { useNavigate } from "react-router";
import { api } from "../utils/api";
import CurrencyContext from "../contexts/CurrenctContext";

export default function Footer() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const { showSuccessToast } = useContext(ToasterContext);
  const { selected, setSelected } = useContext(CurrencyContext);

  const bnkMenuArr = [
    {
      title: "About us",
      route: "/about",
    },
    {
      title: "Work with us",
      route: "/work-with-us",
    },
    {
      title: "Review",
      route: "/review",
      disable: true,
    },
  ];

  const customerCareMenuArr = [
    {
      title: "Delivery & Shipping",
      route: "/customer-care/shipping-information",
    },
    {
      title: "FAQ",
      route: "/faq",
    },
    {
      title: "Contact Us",
      route: "/contact-us",
    },
  ];

  const legalMenuArr = [
    {
      title: "Terms & conditions",
      route: "/terms-conditions",
    },
    {
      title: "Privacy policy",
      route: "/privacy-policy",
    },
  ];

  const buyMenuArr = [
    {
      title: "Order Tracking",
      route: "/account/orders",
      state: { idx: 0, index: 1 },
    },
    {
      title: "Return Policy",
      route: "/customer-care/return-policy",
    },
    {
      title: "Authentication",
      route: "/authentication",
    },
  ];

  const consignMenuArr = [
    {
      title: "Consignment",
      route: "/sell-with-us/consignment",
    },
    {
      title: "Trade-In",
      route: "/sell-with-us/trade-in",
    },
    {
      title: "Direct Selling",
      route: "/sell-with-us/direct-sell",
    },
  ];

  const menusArr = [
    {
      title: "CUSTOMER CARE",
      datas: customerCareMenuArr,
    },
    {
      title: "BUY",
      datas: buyMenuArr,
    },
    {
      title: "CONSIGN & SELL",
      datas: consignMenuArr,
    },
    {
      title: "BELLE & KATE",
      datas: bnkMenuArr,
    },
  ];

  const handleSubmitSubscribe = async (val) => {
    if (!regexChecker("email", val["email"])) {
      showSuccessToast("Invalid email");
      return;
    }

    await api.post("/api/newsletters", { email: val["email"] });
    showSuccessToast(
      "Welcome! now you’ll be the first to receive updates on new arrivals. and all the latest offers from us"
    );
  };

  return (
    <View
      style={{
        ...style.container,
        paddingHorizontal: isTablet ? 0 : 40,
        paddingTop: isTablet ? 0 : 36,
        paddingBottom: isTablet ? 36 : 0,
      }}
    >
      <View
        style={{
          ...style.middleLeftItem,
          // width: isTablet ? width : "50%",
          flexDirection: isTablet ? "column" : "row",
          justifyContent: "space-between",
        }}
      >
        {menusArr.map(({ title, datas }, idx) =>
          isTablet ? (
            <DropDownTab
              key={idx}
              item={{ title, datas, style: "menu" }}
              noMargin
              inline
            />
          ) : (
            <FooterMenu key={idx} title={title} data={datas} />
          )
        )}
        <View
          style={{
            maxWidth: isPhone ? "100%" : isTablet ? "60%" : "30%",
            justifyContent: isTablet ? "center" : "flex-start",
            alignSelf: isTablet ? "center" : "flex-start",
            paddingHorizontal: isTablet ? 24 : 0,
            marginVertical: isTablet ? 24 : 0,
          }}
        >
          <LatoRegular style={style.middleItemText}>
            Discount IDR500.000* your first app purchase and stay up to date on
            our latest arrivals, exclusive offers, and more with alerts send
            directly to your inbox.
          </LatoRegular>
          <Formik
            onSubmit={handleSubmitSubscribe}
            initialValues={{ email: "" }}
          >
            {({ handleChange, handleBlur, handleSubmit }) => (
              <View
                style={{
                  flexDirection: isPhone ? "column" : "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: isPhone ? 24 : 0,
                }}
              >
                <View
                  style={{
                    width: isPhone
                      ? "100%"
                      : isTablet
                      ? 0.35 * width
                      : 0.2 * width,
                    marginTop: 24,
                  }}
                >
                  <MyInput
                    name={"email"}
                    placeholder={"your@address.com"}
                    color={colors.black00}
                    backgroundColor={colors.whiteFF}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    noLabel
                    bordered
                    customWidth={isPhone ? width - 40 : false}
                    inline
                    autoClear
                  />
                </View>
                <Buttons
                  label="Submit"
                  width={isPhone ? "100%" : "27.5%"}
                  height={40}
                  onPress={handleSubmit}
                  borderRadius={6}
                  backgroundColor={colors.black00}
                  color={colors.whiteFF}
                  borderColor={colors.grey33}
                />
              </View>
            )}
          </Formik>
          <LatoRegular style={style.middleItemText}>*Bags Only</LatoRegular>
          {Boolean(!isTablet) && (
            <View
              style={{
                width: 250,
                paddingTop: 16,
              }}
            >
              <SelectionDropDown
                name={"currency"}
                label="Country | Currency Selector"
                labelStyle={{ color: colors.whiteFF }}
                setValue={setSelected}
                values={selected}
                color={colors.whiteFF}
              />
            </View>
          )}
        </View>
      </View>
      <View>
        <View
          style={{
            ...style.bottomContent,
            justifyContent: "space-between",
            flexDirection: isTablet ? "column-reverse" : "row",
            borderTopColor: colors.grey90,
            borderTopWidth: isTablet ? 0 : 1,
            paddingVertical: isTablet ? 0 : 32,
            marginTop: 24,
          }}
        >
          <View
            style={{
              flexDirection: isTablet ? "column" : "row",
              width: isTablet ? width - 40 : 0.5 * width,
              alignItems: "center",
            }}
          >
            {Boolean(isTablet) && (
              <View
                style={{
                  width: 250,
                  paddingTop: 16,
                }}
              >
                <SelectionDropDown
                  name={"currency"}
                  label="Country | Currency Selector"
                  labelStyle={{ color: colors.whiteFF }}
                  setValue={setSelected}
                  values={selected}
                  color={colors.whiteFF}
                />
              </View>
            )}
            {legalMenuArr.map(({ title, route, disable }, i) => (
              <TouchableOpacity
                key={i}
                onPress={() => navigate(route)}
                disabled={disable}
                style={{
                  paddingHorizontal: 12,
                  paddingLeft: i === 0 ? 0 : 12,
                  borderRightWidth: isTablet ? 0 : 1,
                  borderRightColor: colors.whiteE8,
                  marginBottom: isTablet ? 20 : 0,
                }}
              >
                <LatoRegular style={style.bottomText}>{title}</LatoRegular>
              </TouchableOpacity>
            ))}
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                paddingHorizontal: 12,
                borderRightWidth: isTablet ? 0 : 1,
                borderRightColor: colors.whiteE8,
                marginTop: isTablet ? 24 : 0,
              }}
            >
              <RiCopyrightLine size={20} color={colors.whiteFF} />
              <LatoRegular style={style.bottomText}>
                2022 Belle&Kate All rights Reserved.
              </LatoRegular>
            </View>
            {Boolean(!isTablet) && (
              <TouchableOpacity
                onPress={() => navigate("/contact-us")}
                style={{
                  paddingHorizontal: 12,
                  paddingLeft: 12,
                  marginBottom: isTablet ? 20 : 0,
                }}
              >
                <LatoRegular style={style.bottomText}>
                  Follow Our Social Media
                </LatoRegular>
              </TouchableOpacity>
            )}
          </View>
          {Boolean(isTablet) && (
            <TouchableOpacity
              onPress={() => navigate("/contact-us")}
              style={{
                paddingHorizontal: 12,
                paddingLeft: 12,
                marginBottom: isTablet ? 20 : 0,
              }}
            >
              <LatoRegular style={style.bottomText}>
                Follow Our Social Media
              </LatoRegular>
            </TouchableOpacity>
          )}
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              marginBottom: isTablet ? 20 : 0,
            }}
          >
            <TouchableOpacity
              onPress={async () => {
                await Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.bnkapp&hl=en-ID"
                );
              }}
            >
              <Image
                source={require("../assets/images/playstore.png")}
                alt={"playstore"}
                style={{
                  alignSelf: "center",
                  width: 135,
                  height: 40,
                  marginRight: 12,
                }}
                defaultSource={require("../assets/images/spinning-loading.gif")}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={async () => {
                await Linking.openURL(
                  "https://apps.apple.com/id/app/belle-kate-luxury-goods/id1671119501"
                );
              }}
            >
              <Image
                source={require("../assets/images/appstore.png")}
                alt={"appstore"}
                style={{
                  alignSelf: "center",
                  width: 135,
                  height: 40,
                }}
                defaultSource={require("../assets/images/spinning-loading.gif")}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
          </View>
        </View>
      </View>
    </View>
  );
}

const style = StyleSheet.create({
  container: {
    backgroundColor: colors.black1A,
    paddingHorizontal: 40,
  },
  upperContent: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-around",
    borderBottomWidth: 1,
    borderBottomColor: colors.whiteFF,
    paddingBottom: 6,
  },
  middleContent: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  bottomContent: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  middleItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
  },
  middleLeftItem: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    // width: "50%",
  },
  middleRightItem: {
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    // width: "60%",
  },
  middleSingleLeftItem: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "30%",
    marginLeft: 75,
  },
  upperSideItem: {
    justifyContent: "flex-start",
    alignItems: "center",
    width: "33.33%",
    height: 210,
    paddingTop: 28,
    paddingBottom: 6,
  },
  upperItemTitle: {
    color: colors.whiteFF,
    marginBottom: 40,
    fontSize: 22,
    textAlign: "center",
    paddingHorizontal: 16,
  },
  middleItemTitle: {
    color: colors.whiteFF,
    marginBottom: 10,
    fontSize: 14,
  },
  middleItemTextContainer: { marginBottom: 10 },
  middleItemText: {
    color: colors.whiteFF,
    marginBottom: 10,
    fontSize: 12,
    lineHeight: 16,
  },
  upperItemButton: {
    borderColor: colors.whiteFF,
    borderWidth: 1,
    borderRadius: 6,
    paddingVertical: 12.5,
    paddingHorizontal: 16,
    height: 42,
    width: "40%",
    justifyContent: "center",
    alignItems: "center",
  },
  upperItemButtonText: {
    color: colors.whiteFF,
    fontSize: 14,
    textDecorationLine: "none",
  },
  upperCenterItem: {
    justifyContent: "flex-start",
    alignItems: "center",
    borderLeftColor: colors.whiteFF,
    borderLeftWidth: 1,
    borderRightColor: colors.whiteFF,
    borderRightWidth: 1,
    width: "33.33%",
    height: 315,
    paddingTop: 28,
    paddingBottom: 6,
  },
  upperRightItem: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "33.33%",
    marginLeft: 24,
    paddingTop: 28,
    paddingBottom: 6,
  },
  socialMediaButton: {
    flexDirection: "row",
    alignSelf: "flex-start",
    justifyContent: "center",
    marginBottom: 16,
  },
  socialMediaText: {
    color: colors.whiteFF,
    marginLeft: 6,
    fontSize: 14,
  },
  bottomText: {
    color: colors.whiteFF,
    marginLeft: 6,
    fontSize: 14,
    lineHeight: 20,
  },
});
