import React from "react";
import { View, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";

export default function Modal({
  children,
  width: modalWidth,
  noBackground = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  return (
    <View
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: colors.transparentBlack,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <View
        style={{
          width: modalWidth ? modalWidth : isPhone ? width - 32 : 500,
          padding: noBackground ? 0 : 24,
          backgroundColor: noBackground ? "transparent" : colors.whiteFF,
        }}
      >
        {children}
      </View>
    </View>
  );
}
