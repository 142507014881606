export const addressChecker = (value, initialValue) => {
  for (const a in value) {
    if (typeof value[a] === "object" && value[a]?.id !== initialValue[a]?.id) {
      return true;
    } else if (typeof value[a] !== "object" && value[a] !== initialValue[a]) {
      return true;
    }
  }
  return false;
};
