import { ScrollView, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../tools/Interface";

export const Wrapper = ({ children }) => {
  const { width, height } = useWindowDimensions();
  const { isTablet, isPhone } = getDeviceType({ width, height });

  return isPhone ? (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ height: "100vh", paddingBottom: 24 }}
    >
      {children}
    </ScrollView>
  ) : (
    <View>{children}</View>
  );
};

export const WrapperInsider = ({
  children,
  engineeredHeight,
  style = {},
  centered = false,
  noScroll = false,
}) => {
  const { width, height } = useWindowDimensions();
  const { isTablet, isPhone } = getDeviceType({ width, height });
  return isPhone || noScroll ? (
    <View style={{ marginVertical: 24, ...style }}>{children}</View>
  ) : (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{
        height: engineeredHeight || "100vh",
        marginTop: centered ? height / 5 : 0,
        ...style,
      }}
    >
      {children}
    </ScrollView>
  );
};

export const WrapperHorizontal = ({ children }) => {
  const { width, height } = useWindowDimensions();
  const { isTablet, isPhone } = getDeviceType({ width, height });

  return isPhone ? (
    <ScrollView horizontal showsHorizontalScrollIndicator={false}>
      {children}
    </ScrollView>
  ) : (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginVertical: isPhone ? 0 : 64,
      }}
    >
      {children}
    </View>
  );
};
