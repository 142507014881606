import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Footer from "../components/Footer";
import {
  Image,
  ScrollView,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { getDeviceType } from "../tools/Interface";
import Navbar from "../components/Navbar";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { api } from "../utils/api";
import ProductGrid from "../components/Home/ProductGrid";
import { GoChevronLeft } from "react-icons/go";
import RenderHtml from "react-native-render-html";
import { times, upperCase } from "lodash";
import ImageWithLoader from "../components/ImageWithLoader";
import { format } from "date-fns";

function ClassRoomScreen() {
  const { id, category: paramCat } = useParams();

  const { height, width } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(false);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const getArticle = async () => {
    try {
      setLoading(true);

      const res = await api.get(`/api/editorials/articles?q[id_eq]=${id}`);

      setArticle(res?.data?.data[0]);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticle();
  }, []);

  const {
    header: title,
    sub_header: subheader,
    content = "",
    category,
    products = [],
    main_image: cover,
    additional_images: subImages = [],
    created_at,
  } = article;

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View style={{ marginVertical: isTablet ? 48 : 100 }}>
          <View
            style={{
              paddingHorizontal: gap,
            }}
          >
            <TouchableOpacity
              onPress={() => navigate("/editorial")}
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 48,
              }}
            >
              <GoChevronLeft size={24} color={colors.grey33} />
              <LatoRegular
                style={{
                  fontSize: 20,
                  lineHeight: 24,
                  color: colors.black22,
                  marginLeft: 8,
                }}
              >
                Back to Editorial
              </LatoRegular>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: isPhone ? "column" : "row",
                justifyContent: "space-between",
              }}
            >
              {loading ? (
                <View
                  style={{
                    width: isPhone
                      ? containerWidth
                      : containerWidth / 2 - (isTablet ? 12 : 24),
                    height: isPhone
                      ? (containerWidth * 281) / 358
                      : ((containerWidth / 2 - (isTablet ? 12 : 24)) * 720) /
                        706,
                    marginBottom: isTablet ? 24 : 0,
                    backgroundColor: colors.superTransparentBlack00,
                  }}
                />
              ) : (
                <ImageWithLoader
                  source={{ uri: cover }}
                  containerStyle={{
                    width: isPhone
                      ? containerWidth
                      : containerWidth / 2 - (isTablet ? 12 : 24),
                    height: isPhone
                      ? containerWidth
                      : containerWidth / 2 - (isTablet ? 12 : 24),
                    marginBottom: isTablet ? 24 : 0,
                  }}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  defaultStyle={{
                    width: 48,
                    height: isPhone
                      ? containerWidth
                      : containerWidth / 2 - (isTablet ? 12 : 24),
                  }}
                  defaultSource={require("../assets/images/spinning-loading.gif")}
                  resizeMode={"cover"}
                  woMask
                />
              )}
              <View
                style={{
                  width:
                    cover && !isPhone
                      ? containerWidth / 2 - 40
                      : containerWidth,
                }}
              >
                <View>
                  <LatoBold
                    style={{
                      fontSize: isTablet ? 24 : 32,
                      lineHeight: isTablet ? 32 : 40,
                      color: colors.black00,
                      marginBottom: 16,
                    }}
                  >
                    {title}
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: isTablet ? 16 : 20,
                      lineHeight: isTablet ? 24 : 28,
                      color: colors.grey6C,
                      marginBottom: isPhone ? 24 : 48,
                    }}
                  >
                    {paramCat === "campaign"
                      ? created_at
                        ? format(new Date(created_at), "MM/dd/yyyy")
                        : ""
                      : subheader ||
                        (category === "belle&kate_class"
                          ? "BELLE&KATE CLASSROOM"
                          : upperCase(category))}
                  </LatoRegular>
                </View>
                <RenderHtml
                  source={{ html: content }}
                  tagsStyles={{
                    p: {
                      fontFamily: "lato-regular",
                      fontSize: isTablet ? 14 : 20,
                      lineHeight: isTablet ? 20 : 28,
                      color: colors.grey33,
                      marginBottom: 24,
                    },
                    ol: {
                      fontFamily: "lato-regular",
                      fontSize: isTablet ? 14 : 20,
                      lineHeight: isTablet ? 20 : 28,
                      color: colors.grey33,
                      marginBottom: 24,
                    },
                    li: {
                      fontFamily: "lato-regular",
                      fontSize: isTablet ? 14 : 20,
                      lineHeight: isTablet ? 20 : 28,
                      color: colors.grey33,
                      marginBottom: 24,
                    },
                  }}
                />
              </View>
            </View>
            <View
              style={{
                flexDirection: isPhone ? "column" : "row",
                justifyContent: "space-between",
                marginTop: 32,
              }}
            >
              {loading
                ? times(3, (i) => (
                    <View
                      key={i}
                      style={{
                        width: isPhone
                          ? containerWidth
                          : containerWidth / 3 - (isTablet ? 12 : 24),
                        height: isPhone
                          ? containerWidth
                          : containerWidth / 3 - (isTablet ? 12 : 24),
                        backgroundColor: colors.superTransparentBlack00,
                        marginBottom: isTablet ? 24 : 0,
                      }}
                    />
                  ))
                : subImages?.map((itm, i) => (
                    <ImageWithLoader
                      key={i}
                      source={{ uri: itm }}
                      containerStyle={{
                        width: isPhone
                          ? containerWidth
                          : containerWidth / 3 - (isTablet ? 12 : 24),
                        height: isPhone
                          ? containerWidth
                          : containerWidth / 3 - (isTablet ? 12 : 24),
                        marginBottom: isTablet ? 24 : 0,
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        marginRight:
                          i === subImages.length - 1 ? 0 : isPhone ? 8 : 24,
                        marginBottom: isPhone ? 24 : 0,
                      }}
                      defaultStyle={{
                        width: 48,
                        height: isPhone
                          ? (containerWidth * 281) / 358
                          : containerWidth / 2 - (isTablet ? 12 : 24),
                      }}
                      defaultSource={require("../assets/images/spinning-loading.gif")}
                      resizeMode={"cover"}
                      woMask
                    />
                  ))}
            </View>
          </View>
          {Boolean(products.length && paramCat !== "campaign") && (
            <ProductGrid data={products} noButton isClassroom={title} wrap />
          )}
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default ClassRoomScreen;
