import { format } from "date-fns";
import { enUS } from "date-fns/locale";
import numeral from "numeral";

export const formatCurrency = (string, shorten, fixed) => {
  if (shorten) {
    const price = Number(string);
    const len = String(price).length;

    if (len <= 3) return price;
    if (len > 3 && len <= 6) {
      return `${(price / 1000).toFixed(fixed ? 0 : 2)} Thousand`;
    }
    if (len > 6 && len <= 9) {
      return `${(price / 1000000).toFixed(fixed ? 0 : 2)} Million`;
    }
    if (len > 9) {
      return `${(price / 1000000000).toFixed(fixed ? 0 : 2)} Billion`;
    }

    return price.toFixed(fixed ? 0 : 2);
  } else if (string) {
    const number = String(string).split(".");
    const decimal = number[1];
    if (decimal)
      if (decimal.length > 1) {
        return numeral(string).format("0,0[.]00");
      } else {
        return numeral(string).format("0,0[.]0");
      }
    else {
      return numeral(string).format("0,0[.]00");
    }
  }
  return string;
};

export const customDateFormatter = (
  string,
  fullMonth = false,
  noDay = false
) => {
  const date = string?.split("T")[0];
  const arrayOfDate = date?.split("-");
  const year = arrayOfDate?.shift();
  const monthNumber = Number(arrayOfDate[0]);
  const months = fullMonth
    ? [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ]
    : [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
  const useAbleDate = arrayOfDate.map((date, index) => {
    if (Number(index) === 0) {
      return months[monthNumber - 1];
    }
    return date;
  });
  if (noDay) useAbleDate.splice(0, 1);
  return `${useAbleDate.join(" ")}, ${year}`;
};

export const dateSorter = (array, key, descending = true) =>
  array.sort((a, b) => {
    const dateNum = (date) => new Date(date).getTime();
    const firstItem = descending ? b : a;
    const secondItem = descending ? a : b;
    return dateNum(firstItem[key]) - dateNum(secondItem[key]);
  });

// date string to 'dd mmm yyyy, hh:mm AM/PM'
export const longDateFormatter = (string, is24H = false) => {
  const date = new Date(string);
  if (is24H) return format(date, "d MMMM yyyy HH:mm", { locale: enUS });
  return format(date, "d MMMM yyyy hh:mm aaa", { locale: enUS });
};

export const shortDateFormatter = (string) => {
  if (string) {
    const date = new Date(string);
    return format(date, "dd/MM/yyyy");
  }
  return string;
};

export const requestProductFormatter = async (value, uri) => {
  const formData = new FormData();

  const { designer, condition, name, phone, stamp, notes, color } = value;

  formData.append("request_item[designer_id]", designer?.id);
  formData.append("request_item[condition_id]", condition?.id);
  formData.append("request_item[name]", name);
  formData.append("request_item[colour]", color);
  formData.append("request_item[notes]", notes);
  formData.append("request_item[year]", stamp);
  formData.append("request_item[phone]", phone);
  formData.append("request_item[image]", uri);

  return formData;
};

export const pickUpProductsFormatter = async (data, id, segment) => {
  const formData = new FormData();

  formData.append("pickup[pickup_slot_id]", id);
  formData.append(
    "pickup[consignment_method]",
    segment === "consignment" ? "basic" : segment.replace("-", "_")
  );

  for (let a = 0; a < data.length; a++) {
    formData.append(
      "pickup[products][][front_view]",
      data[a]?.photos[0]?.imageFile || data[a]?.photos[0]
    );
    if (data[a]?.photos[1]) {
      formData.append(
        "pickup[products][][right_side_view]",
        data[a]?.photos[1]?.imageFile || data[a]?.photos[1]
      );
    }
    if (data[a]?.photos[2]) {
      formData.append(
        "pickup[products][][rear_view]",
        data[a]?.photos[2]?.imageFile || data[a]?.photos[2]
      );
    }
    if (data[a]?.photos[3]) {
      formData.append(
        "pickup[products][][left_side_view]",
        data[a]?.photos[3]?.imageFile || data[a]?.photos[3]
      );
    }
    if (data[a]?.photos[4]) {
      formData.append(
        "pickup[products][][top_view]",
        data[a]?.photos[4]?.imageFile || data[a]?.photos[4]
      );
    }
    if (data[a]?.photos[5]) {
      formData.append(
        "pickup[products][][bottom_view]",
        data[a]?.photos[5]?.imageFile || data[a]?.photos[5]
      );
    }

    formData.append("pickup[products][][designer_id]", data[a]?.designer?.id);
    formData.append("pickup[products][][category_id]", 1);
    formData.append("pickup[products][][group_id]", 2);
    formData.append("pickup[products][][name]", data[a]?.name);
    formData.append("pickup[products][][p_colour]", data[a]?.color?.name);
    formData.append("pickup[products][][p_size]", data[a]?.size);
    formData.append("pickup[products][][condition_id]", data[a]?.condition?.id);
    formData.append("pickup[products][][comes_with]", data[a]?.inclusion);
    formData.append("pickup[products][][hardware]", data[a]?.hardware);
    formData.append("pickup[products][][leather]", data[a]?.leather);
    formData.append("pickup[products][][stamp]", data[a]?.stamp);
    formData.append(
      "pickup[products][][additional_condition_description]",
      data[a]?.additional
    );
    formData.append("pickup[products][][seller_story]", data[a]?.story);
  }

  return formData;
};

export const createVideoThumbnail = (
  url = "https://www.youtube.com/watch?v=MfKSY45KIPQ"
) => {
  const videoId = url.includes("v=")
    ? url.split("v=")[1]
    : url.includes("youtu.be/")
    ? url.split("youtu.be/")[1]
    : url.split("shorts/")[1];

  // Construct the thumbnail URL
  const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

  return thumbnailUrl;
};
