import React from "react";
import { View, Image, useWindowDimensions } from "react-native";
import { TbDiscount2 } from "react-icons/tb";
import { VscSend } from "react-icons/vsc";
import Appraisal from "../../assets/images/appraisal-icon.svg";
import Authentication from "../../assets/images/authentication-icon.svg";
import Box from "../../assets/images/box-icon.svg";
import Rate from "../../assets/images/rate-icon.svg";
import FormIcon from "../../assets/images/form-icon.svg";
import Consign1 from "../../assets/images/consign-cover-1.png";
import Consign2 from "../../assets/images/consign-cover-2.png";
import Tradein from "../../assets/images/tradein-cover.png";
import Direct from "../../assets/images/direct-cover.png";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import ExplainationSell from "./ExplainationSell";
import { useNavigate } from "react-router";

function HowToSell({ cat = "", setOpenRate = () => {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const explainArr =
    cat === "consignment"
      ? [
          {
            title: "Belle&Kate ROOM Service",
            cover: Consign1,
            explain: [
              {
                source: FormIcon,
                title: "upload specifications online",
                description:
                  "Submit brief details and photo of your designer bag(s) (min 5 items) that you would like to consign with us. The team will conduct an initial review of the items virtually and will only pick up your bag(s) that have been accepted.",
              },
              {
                source: Authentication,
                title: "AUTHENTICATION PROCESS",
                description:
                  "Upon collection, these bag(s) will be further authenticated either by Bababebi or Entrupy, which will take around 1-2 days.",
                onPress: () => navigate("/authentication"),
              },
              {
                source: Appraisal,
                title: "Appraisal process & listing",
                description:
                  "Once your bag(s) are quality controlled, you can either approve or counter our suggested selling prices. With your agreement, we will then proceed to assist you with professional photography and detailed description of your bag(s).",
              },
              {
                icon: <TbDiscount2 size={isTablet ? 24 : 36} />,
                title: "Period of sale & FEE RATES",
                description:
                  "Post signing price agreement, your bag(s) will be listed on our apps and displayed in store within 2-3 days, and the one month period of consignment sales begin. We charge transparent fixed fee rates for the sale of your bag(s) on our marketplace.",
                onPress: () => setOpenRate(true),
              },
            ],
          },
          {
            title: "Register your item yourself",
            cover: Consign2,
            explain: [
              {
                source: FormIcon,
                title: "upload specifications online",
                description:
                  "Submit detailed photos and descriptions of your designer bag(s) as per our recommended guidelines. The team will review these listings virtually and come back to you with suggested selling price.",
              },
              {
                source: Appraisal,
                title: "Appraisal process & listing",
                description:
                  "You can either approve or counter our suggested selling prices. Upon signing price agreement, your bag(s) will be live within 1-2 days on our apps.",
              },
              {
                source: Box,
                title: "shipping + Authentication",
                description:
                  "When your bag(s) are sold, you can either drop off your bags in store or ship it to us. We will proceed with the authentication process either by Bababebi or Entrupy, which will take around 1-2 days.",
                onPress: () => navigate("/authentication"),
              },
              {
                source: Rate,
                title: "FEE RATES",
                description:
                  "Once your bag(s) are quality controlled, we will ship your bag(s) to the buyer. We charge transparent fixed fee rates for the sale of your bag(s) on our marketplace.",
                onPress: () => setOpenRate(true),
              },
            ],
          },
        ]
      : [
          {
            title:
              cat === "trade-in"
                ? "How to Trade In with us?"
                : "How to Direct Sell with us?",
            cover: cat === "trade-in" ? Tradein : Direct,
            explain: [
              {
                source: FormIcon,
                title: "Upload SPEcifications ONLINE & Drop off your bags",
                description:
                  cat === "trade-in"
                    ? "Fill in the form to start the trade in process and drop off your bags to our ROOM"
                    : "Fill in the form to start the selling process and drop off your bags to our ROOM",
              },
              {
                source: Authentication,
                title:
                  cat === "trade-in"
                    ? "Quality control & price appraisal"
                    : "Wait for our client advisors",
                description:
                  cat === "trade-in"
                    ? "We will check the condition of each bag and continue to price appraisal"
                    : "We will check the condition of each bag thoroughly and continue to price appraisal",
              },
              {
                source: Appraisal,
                title: "AUTHENTICATION PROCESS",
                description:
                  "Once price has been settled, the bag will be carried through towards a virtual authentication process by our third-party US based authenticator - Entrupy/ Bababei",
              },
              {
                icon: <VscSend size={isTablet ? 24 : 36} />,
                title:
                  cat === "trade-in" ? "pick your trade in" : "receive payment",
                description:
                  cat === "trade-in"
                    ? "Select the bag you would like to trade-in with. Add more money into the remaining balance, or even receive more money if the bag(s) you traded-in is cheaper. Bag upgraded!"
                    : "Receive your cash in 1-2 working days post authentication confirmation.",
              },
            ],
          },
        ];

  return (
    <View
      style={{
        width,
        borderTopColor: colors.greyE6,
        borderTopWidth: cat === "consignment" ? 0 : 1,
      }}
    >
      {Boolean(cat === "consignment") && (
        <View
          style={{
            paddingBottom: isPhone ? 24 : 50,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: gap,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.grey6C,
              marginBottom: 12,
              backgroundColor: colors.whiteFF,
              paddingHorizontal: isPhone ? 8 : 56,
              textTransform: "uppercase",
            }}
          >
            How to Consign with us?
          </LatoRegular>
          <View
            style={{
              position: "absolute",
              zIndex: -1,
              top: isPhone ? 12 : 16,
              width: containerWidth,
              height: 1,
              backgroundColor: colors.greyE6,
              left: gap,
            }}
          />
        </View>
      )}
      <View
        style={{ paddingHorizontal: gap, paddingVertical: isPhone ? 48 : 100 }}
      >
        {explainArr.map(({ title, cover, explain }, idx) => (
          <View
            key={idx}
            style={{
              flexDirection: isTablet ? "column-reverse" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: isPhone ? 48 : 100,
            }}
          >
            <View style={{ width: isTablet ? width : containerWidth / 2 - 24 }}>
              <LatoBold
                style={{
                  fontSize: isPhone ? 16 : isTablet ? 24 : 32,
                  lineHeight: isPhone ? 24 : isTablet ? 32 : 40,
                  marginBottom: 12,
                  backgroundColor: colors.whiteFF,
                  // paddingHorizontal: isPhone ? 8 : 56,
                  textAlign: "center",
                }}
              >
                {title}
              </LatoBold>
              <ExplainationSell
                arr={explain}
                scroll={isTablet}
                containerHeight={
                  isPhone
                    ? 225
                    : isTablet
                    ? 200
                    : width < 1285
                    ? 375
                    : width < 1425
                    ? 350
                    : 335
                }
                fontSize={isPhone ? 12 : 16}
                lineHeight={isPhone ? 16 : 24}
                descFontSize={isPhone ? 12 : 14}
                descLineHeight={isPhone ? 16 : 20}
              />
            </View>
            <Image
              source={cover}
              alt={`how-to-${cat}-${idx + 1}`}
              style={{
                alignSelf: "center",
                width: isPhone
                  ? width
                  : isTablet
                  ? containerWidth
                  : containerWidth / 2,
                height: isPhone ? width : containerWidth / 2,
                marginBottom: isTablet ? 24 : 0,
              }}
              defaultSource={require("../../assets/images/spinning-loading.gif")}
              resizeMode={"cover"}
            />
          </View>
        ))}
      </View>
    </View>
  );
}

export default HowToSell;
