import React, { useContext, useEffect, useState } from "react";
import { Image, View, useWindowDimensions } from "react-native-web";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import ToasterContext from "../contexts/ToastContext";
import { api } from "../utils/api";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";
import { IoIosAdd } from "react-icons/io";
import colors from "../assets/colors/colors";
import { AiOutlineBulb, AiOutlineClockCircle } from "react-icons/ai";
import { customDateFormatter } from "../tools/formatter";
import { MdDeleteOutline } from "react-icons/md";

function RequestScreen({ openRequest = false, setOpenRequest = () => {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const containerWidth = isPhone
    ? width - 32
    : isTablet
    ? width - 300
    : width < 1350
    ? width - 450
    : width < 1500
    ? width - 525
    : width - 600;

  const { showToast, showSuccessToast } = useContext(ToasterContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [data, setData] = useState([]);

  const getRequestList = async () => {
    try {
      setLoading(true);

      const res = await api.get("/api/request_items");

      setData(res?.data?.data || []);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      setLoadingDelete(true);

      await api.delete(`/api/request_items/${id}`);

      showSuccessToast("Your request have been deleted.");

      await getRequestList();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    if (!openRequest) {
      getRequestList();
    }
  }, [openRequest]);

  return (
    <View
      style={{
        width: containerWidth,
      }}
    >
      <LatoBold style={{ fontSize: 24, lineHeight: 32, marginBottom: 24 }}>
        My Request
      </LatoBold>
      {data.length ? (
        <View>
          <View
            style={{
              backgroundColor: colors.whiteF1,
              borderWidth: 1,
              borderColor: colors.greyBB,
              borderRadius: 4,
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 8,
              paddingHorizontal: 12,
              marginBottom: 32,
            }}
          >
            <AiOutlineBulb size={20} color={colors.grey6C} />
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : 14,
                lineHeight: isPhone ? 16 : 20,
                color: colors.grey6C,
                marginLeft: 8,
              }}
            >
              Once our admin team has taken in your request, you'll be hearing
              from us soon.
            </LatoRegular>
          </View>
          <Buttons
            label={"Request another item"}
            icon={<IoIosAdd size={20} color={colors.whiteFF} />}
            iconStyle="center"
            isBlack
            width={250}
            onPress={() => {
              navigate("/request-item");
            }}
            containerStyle={{ alignSelf: "flex-end", marginBottom: 24 }}
          />
          {data?.map((item, index) => {
            const {
              id,
              colour,
              name,
              created_at,
              designer,
              condition,
              notes,
              main_image,
              year,
            } = item;
            return loading ? (
              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: containerWidth,
                  height: height - 500,
                }}
              >
                <Image
                  source={require("../assets/images/spinning-loading.gif")}
                  style={{
                    alignSelf: "center",
                    width: 200,
                    height: 200,
                  }}
                  defaultSource={require("../assets/images/spinning-loading.gif")}
                  resizeMode={"contain"}
                />
              </View>
            ) : (
              <View
                key={index}
                style={{
                  borderWidth: 1,
                  borderColor: colors.greyBB,
                  borderRadius: 4,
                  marginBottom: 12,
                }}
              >
                <View
                  style={{
                    borderBottomWidth: 1,
                    borderBottomColor: colors.greyBB,
                    paddingHorizontal: width < 1115 ? 0 : 16,
                    marginHorizontal: width < 1115 ? 12 : 0,
                    paddingVertical: 12,
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    {Boolean(width >= 1115) && (
                      <AiOutlineClockCircle size={20} color={colors.grey33} />
                    )}
                    <LatoBold
                      style={{
                        fontSize: isPhone ? 12 : 14,
                        lineHeight: isPhone ? 16 : 20,
                        color: colors.grey33,
                        marginLeft: width < 1115 ? 0 : 8,
                      }}
                    >
                      {`${
                        (width, 1115 ? "Created" : "CREATED")
                      } : ${customDateFormatter(created_at, true)}`}
                    </LatoBold>
                  </View>
                  <LatoBold
                    style={{
                      fontSize: isPhone ? 12 : 14,
                      lineHeight: isPhone ? 16 : 20,
                      color: colors.green5B,
                      backgroundColor:
                        width < 1115 ? "transparent" : colors.lightGreen5B,
                      padding: width < 1115 ? 0 : 4,
                      borderRadius: 4,
                    }}
                  >
                    Requested
                  </LatoBold>
                </View>
                <View
                  style={{
                    paddingHorizontal: width < 1115 ? 0 : 16,
                    marginHorizontal: width < 1115 ? 12 : 0,
                    paddingVertical: width < 1115 ? 16 : 24,
                  }}
                >
                  <View
                    style={{
                      flexDirection: width < 1115 ? "column" : "row",
                      marginBottom: 32,
                    }}
                  >
                    <View
                      style={{
                        width:
                          (width < 1115 ? 1 : isTablet ? 0.25 : 0.3) *
                          containerWidth,
                        paddingRight: 40,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                        }}
                      >
                        <View
                          style={{
                            borderWidth: width < 1115 ? 0 : 1,
                            padding: width < 1115 ? 0 : 8,
                            borderRadius: 8,
                            justifyContent: "center",
                            borderColor: colors.greyE6,
                            marginRight: 16,
                            height: width < 1115 ? "auto" : isTablet ? 64 : 80,
                          }}
                        >
                          <Image
                            source={{
                              uri: main_image,
                            }}
                            alt={name}
                            style={{
                              alignSelf: "center",
                              width: isTablet ? 48 : 64,
                              height: isTablet ? 48 : 64,
                            }}
                            defaultSource={require("../assets/images/spinning-loading.gif")}
                            resizeMode={"contain"}
                          />
                        </View>
                        <View style={{ flexWrap: "wrap", width: "50%" }}>
                          <LatoBold
                            style={{
                              fontSize: isPhone ? 12 : 14,
                              lineHeight: isPhone ? 16 : 20,
                              color: colors.grey33,
                              marginBottom: 8,
                              textTransform: "uppercase",
                            }}
                          >
                            {designer?.name || ""}
                          </LatoBold>
                          <LatoRegular
                            style={{
                              fontSize: isPhone ? 12 : 14,
                              lineHeight: isPhone ? 16 : 20,
                              color: colors.grey33,
                            }}
                          >
                            {name || ""}
                          </LatoRegular>
                        </View>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: "row",
                        marginVertical: width < 1115 ? 16 : 0,
                      }}
                    >
                      <View
                        style={{
                          width:
                            (width < 1115 ? 0.33 : isTablet ? 0.2 : 0.15) *
                            containerWidth,
                          paddingRight: 40,
                        }}
                      >
                        <LatoBold
                          style={{
                            fontSize: isPhone ? 12 : 14,
                            lineHeight: isPhone ? 16 : 20,
                            color: colors.grey33,
                            marginBottom: 10,
                            textTransform: width < 1115 ? "none" : "uppercase",
                          }}
                        >
                          {`Condition ${width < 1115 ? ":" : ""}`}
                        </LatoBold>
                        <LatoBold
                          style={{
                            fontSize: isPhone ? 12 : 14,
                            lineHeight: isPhone ? 16 : 20,
                            color: colors.green5B,
                            textTransform: "uppercase",
                          }}
                        >
                          {condition?.name || ""}
                        </LatoBold>
                      </View>
                      <View
                        style={{
                          width:
                            (width < 1115 ? 0.33 : isTablet ? 0.2 : 0.15) *
                            containerWidth,
                          paddingRight: 40,
                        }}
                      >
                        <LatoBold
                          style={{
                            fontSize: isPhone ? 12 : 14,
                            lineHeight: isPhone ? 16 : 20,
                            color: colors.grey33,
                            marginBottom: 10,
                            textTransform: width < 1115 ? "none" : "uppercase",
                          }}
                        >
                          {`Colour ${width < 1115 ? ":" : ""}`}
                        </LatoBold>
                        <LatoRegular
                          style={{
                            fontSize: isPhone ? 12 : 14,
                            lineHeight: isPhone ? 16 : 20,
                            color: colors.grey33,
                            textTransform: width < 1115 ? "none" : "uppercase",
                          }}
                        >
                          {colour}
                        </LatoRegular>
                      </View>
                      {Boolean(width < 1115) && (
                        <View
                          style={{
                            width:
                              (width < 1115 ? 0.33 : isTablet ? 0.2 : 0.15) *
                              containerWidth,
                            paddingRight: 40,
                          }}
                        >
                          <LatoBold
                            style={{
                              fontSize: isPhone ? 12 : 14,
                              lineHeight: isPhone ? 16 : 20,
                              color: colors.grey33,
                              marginBottom: 10,
                              textTransform:
                                width < 1115 ? "none" : "uppercase",
                            }}
                          >
                            Year :
                          </LatoBold>
                          <LatoRegular
                            style={{
                              fontSize: isPhone ? 12 : 14,
                              lineHeight: isPhone ? 16 : 20,
                              color: colors.grey33,
                              textTransform: "uppercase",
                            }}
                          >
                            {year}
                          </LatoRegular>
                        </View>
                      )}
                    </View>

                    <View
                      style={{
                        width: (width < 1115 ? 1 : 0.25) * containerWidth,
                      }}
                    >
                      <LatoBold
                        style={{
                          fontSize: isPhone ? 12 : 14,
                          lineHeight: isPhone ? 16 : 20,
                          color: colors.grey33,
                          marginBottom: 10,
                          textTransform: width < 1115 ? "none" : "uppercase",
                        }}
                      >
                        {`Notes ${width < 1115 ? ":" : ""}`}
                      </LatoBold>
                      <LatoRegular
                        style={{
                          fontSize: isPhone ? 12 : 14,
                          lineHeight: isPhone ? 16 : 20,
                          color: colors.grey33,
                          marginBottom: 4,
                        }}
                        numberOfLines={4}
                      >
                        {notes}
                      </LatoRegular>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Buttons
                      loading={loadingDelete}
                      onPress={async () => await handleDelete(id)}
                      icon={
                        <MdDeleteOutline
                          size={20}
                          color={colors.grey33}
                          style={{ marginLeft: 4 }}
                        />
                      }
                      label={"Delete"}
                      width={85}
                      borderColor={colors.greyE6}
                      containerStyle={{ marginRight: width < 1115 ? 0 : 12 }}
                    />
                    {Boolean(width >= 1115) && (
                      <Buttons
                        onPress={() => setOpenRequest(id)}
                        label={"See detail"}
                        width={130}
                        isBlack
                      />
                    )}
                  </View>
                </View>
              </View>
            );
          })}
        </View>
      ) : (
        <View
          style={{
            marginVertical: 32,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LatoRegular
            style={{
              fontSize: 20,
              lineHeight: 28,
              marginBottom: 24,
              textAlign: "center",
            }}
          >
            Request an item and we'll help you find it.
          </LatoRegular>
          <Buttons
            onPress={() => {
              navigate("/request-item");
            }}
            label={"Request item"}
            width={250}
            height={40}
            borderRadius={4}
            isBlack
            noBorder
          />
        </View>
      )}
    </View>
  );
}

export default RequestScreen;
