import React from "react";
import { View, useWindowDimensions } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { IoIosAdd } from "react-icons/io";
import { useAuth } from "../../contexts/AuthContext";
import { GrLocation } from "react-icons/gr";
import { getDeviceType } from "../../tools/Interface";

function AssistedAddresses({
  selectedMethod,
  date,
  selectedTime,
  items,
  navigate,
  outService = false,
}) {
  const { user } = useAuth();
  const { detail = {} } = user || {};

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View
      style={{
        marginBottom: 32,
        width: isPhone ? width - 32 : isTablet ? 700 : 1000,
        marginRight: isPhone ? 24 : isTablet ? 48 : 0,
      }}
    >
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 24,
          color: colors.grey33,
          marginRight: 48,
          textAlign: "left",
          marginBottom: 4,
        }}
      >
        Your Address
      </LatoRegular>
      <LatoRegular
        style={{
          fontSize: 14,
          lineHeight: 20,
          color: colors.grey6C,
          marginRight: 48,
          textAlign: "left",
          marginBottom: 12,
        }}
      >
        {outService
          ? "Currently we only available for Jakarta and Tangerang.\nContact us to discuss about pick up in your area."
          : "Only available for Jakarta and Tangerang"}
      </LatoRegular>
      <View
        style={{
          borderWidth: 1,
          borderColor: detail?.complete_address ? colors.grey33 : colors.greyBB,
          borderRadius: 4,
          padding: 16,
          marginBottom: 32,
        }}
      >
        {detail?.complete_address ? (
          <View style={{ marginBottom: 16 }}>
            <LatoRegular style={{ fontSize: 14, lineHeight: 20 }}>
              {detail?.name || ""}
            </LatoRegular>
            <LatoRegular style={{ fontSize: 14, lineHeight: 20 }}>
              {`+62${detail?.phone || ""}`}
            </LatoRegular>
            <LatoRegular style={{ fontSize: 14, lineHeight: 20 }}>
              {detail?.complete_address}
            </LatoRegular>
          </View>
        ) : (
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey90,
              // marginRight: 48,
              textAlign: "left",
              marginBottom: 16,
            }}
          >
            You have no saved address.
          </LatoRegular>
        )}
        <Buttons
          onPress={() => {
            navigate("/account/address/edit", {
              state: {
                consignment_data: {
                  selectedTime,
                  date,
                  items,
                },
              },
            });
          }}
          label={
            detail?.complete_address ? "Change address" : "Create new address"
          }
          isBlack={!detail?.complete_address}
          width={166}
          icon={
            !detail?.complete_address && (
              <IoIosAdd size={20} color={colors.whiteFF} />
            )
          }
          iconStyle="center"
          color={detail?.complete_address ? colors.grey33 : colors.whiteFF}
        />
      </View>
    </View>
  );
}

export default AssistedAddresses;
