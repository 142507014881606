import React, { useContext, useEffect, useState } from "react";
import { View, ScrollView, useWindowDimensions } from "react-native";
import { useLocation, useNavigate } from "react-router";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import { api } from "../utils/api";
import { getDeviceType } from "../tools/Interface";
import { customDateFormatter } from "../tools/formatter";
import { useAuth } from "../contexts/AuthContext";
import OrderDetail from "../components/Account/OrderDetail";
import Footer from "../components/Footer";
import ToasterContext from "../contexts/ToastContext";

export default function CheckoutSuccessScreen() {
  const [dataArr, setDataArr] = useState([]);

  const { user } = useAuth();
  const { showToast } = useContext(ToasterContext);
  const { email } = user;
  const navigate = useNavigate();
  const { state } = useLocation();
  const { id } = state;

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const getDealsDetail = async () => {
    try {
      const res = await api.get(`/api/deals/${id}`);
      const data = res?.data?.data;

      setDataArr(data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    getDealsDetail();
  }, []);

  const orderDetailArr = [
    {
      title: "Order Number :",
      value: dataArr?.reference_number,
    },
    {
      title: "Date :",
      value: dataArr?.created_at
        ? customDateFormatter(dataArr?.created_at, true)
        : "",
    },
    {
      title: "Email Address :",
      value: email,
    },
  ];

  return (
    <View style={{}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <View
          style={{
            paddingTop: 72,
            paddingHorizontal: isPhone ? 16 : isTablet ? 40 : width / 4,
          }}
        >
          <LatoRegular
            style={{
              fontSize: 24,
              lineHeight: 32,
              color: colors.grey33,
              marginBottom: 60,
            }}
          >
            Thank you. Your order has been received.
          </LatoRegular>
          <View style={{ marginBottom: 44 }}>
            {orderDetailArr.map(({ title, value }, idx) => {
              return (
                <View
                  style={{
                    marginBottom: 16,
                    paddingBottom: 16,
                    borderBottomColor: colors.greyE6,
                    borderBottomWidth: 1,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    {title}
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    {value}
                  </LatoRegular>
                </View>
              );
            })}
          </View>
          <View
            style={{
              marginBottom: 72,
            }}
          >
            <OrderDetail detailID={id} isCheckout />
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
