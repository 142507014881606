import React from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import { IoMdClose } from "react-icons/io";
import { getDeviceType } from "../tools/Interface";

function VideoModal({
  setOpenVideo = () => {},
  setVideoAttr = () => {},
  videoAttr = {},
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  return (
    <View>
      <iframe
        allow="fullscreen;"
        title={"product-video"}
        src={`https://www.youtube.com/embed/${
          videoAttr?.src?.includes("v=")
            ? videoAttr?.src?.split("v=")[1]
            : videoAttr?.src?.includes("youtu.be/")
            ? videoAttr?.src?.split("youtu.be/")[1]
            : videoAttr?.src?.split("shorts/")[1]
        }`}
        width={isPhone ? width - 40 : (isTablet ? 0.75 : 0.5) * width}
        height={
          ((isPhone ? width - 40 : (isTablet ? 0.75 : 0.5) * width) * 600) /
          1040
        }
        frameBorder={0}
      />
      <TouchableOpacity
        onPress={() => {
          setOpenVideo(false);
          setVideoAttr({});
        }}
        style={{
          position: "absolute",
          top: 6,
          right: 6,
          zIndex: 1000,
          // padding: 6,
          width: 24,
          height: 24,
          borderRadius: "100%",
          backgroundColor: colors.black00,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IoMdClose size={16} color={colors.whiteFF} />
      </TouchableOpacity>
    </View>
  );
}

export default VideoModal;
