import React from "react";
import { View, TouchableOpacity } from "react-native";
import { Link } from "react-router-dom";
import colors from "../../assets/colors/colors";
import { useLogout } from "../../contexts/AuthContext";
import { useMutation } from "../../utils/api";
import { LatoRegular } from "../Text";

export default function SignOut({ refetch }) {
  const { loading, logout } = useLogout();
  const handleSignOut = async () => {
    try {
      await logout();
      await refetch();
    } catch (err) {
      console.log("err:", err);
    } finally {
    }
  };
  return (
    <View>
      <LatoRegular
        style={{
          fontSize: 20,
          lineHeight: 28,
          marginBottom: 24,
          textAlign: "center",
        }}
      >
        Are you sure you want to Signing Out?
      </LatoRegular>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={handleSignOut}
          style={{
            width: "40%",
            paddingVertical: 10,
            paddingHorizontal: 12,
            backgroundColor: colors.grey33,
            borderColor: colors.grey33,
            borderWidth: 1,
            borderRadius: 6,
            marginVertical: 16,
            marginRight: 24,
          }}
        >
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.whiteFF,
              textAlign: "center",
              width: "100%",
            }}
          >
            Sign Out
          </LatoRegular>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {}}
          style={{
            width: "40%",
            paddingVertical: 10,
            paddingHorizontal: 12,
            borderColor: colors.grey33,
            borderWidth: 1,
            borderRadius: 6,
            marginVertical: 16,
          }}
        >
          <Link
            to="/products"
            style={{
              color: colors.grey33,
              textDecorationLine: "none",
              fontFamily: "Lato-Regular",
              fontSize: 16,
              textAlign: "center",
            }}
          >
            Back to shop
          </Link>
        </TouchableOpacity>
      </View>
    </View>
  );
}
