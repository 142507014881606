import React, { useContext, useState } from "react";
import Modal from "../Modal";
import { TouchableOpacity, View } from "react-native";
import { LatoBold } from "../Text";
import { GrClose } from "react-icons/gr";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { api } from "../../utils/api";
import ToasterContext from "../../contexts/ToastContext";
import { useNavigate } from "react-router";

function CloseAccountModal({ setOpen = () => {}, refetch = async () => {} }) {
  const [secondContent, setSecondContent] = useState(false);
  const [loading, setLoading] = useState(false);

  const { showToast, showSuccessToast } = useContext(ToasterContext);
  const navigate = useNavigate();

  const handleAccountClose = async () => {
    try {
      setLoading(true);

      await api.delete(`/api/auth`);
      await refetch();

      navigate("/login");

      showSuccessToast(
        "Your departure truly leaves a stylish void in our luxury family. You'll be deeply missed."
      );

      setOpen(false);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 32,
        }}
      >
        <LatoBold style={{ fontSize: 20, lineHeight: 28 }}>
          Close Account
        </LatoBold>
        <TouchableOpacity onPress={() => setOpen(false)}>
          <GrClose size={16} />
        </TouchableOpacity>
      </View>
      <LatoBold
        style={{
          fontSize: 16,
          lineHeight: 24,
          marginBottom: 16,
          color: colors.grey6C,
        }}
      >
        {secondContent
          ? "We're sad to see you go, and if there's anything we can do to improve your experience, please let us know."
          : "Closing your account will permanently remove all your account information and activities from our platform."}
      </LatoBold>
      <LatoBold
        style={{
          fontSize: 16,
          lineHeight: 24,
          marginBottom: 32,
          color: colors.grey6C,
        }}
      >
        {secondContent
          ? "We will send you back to the login page"
          : `By clicking "Close Account", you confirm that you understand and accept the consequences of closing your account.`}
      </LatoBold>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Buttons
          onPress={() => setOpen(false)}
          label={"Cancel"}
          borderColor={colors.greyE6}
          iconStyle="center"
          width="47.5%"
        />
        <Buttons
          loading={loading}
          onPress={async () =>
            secondContent ? await handleAccountClose() : setSecondContent(true)
          }
          label={"Close Account"}
          borderColor={colors.greyE6}
          iconStyle="center"
          width="47.5%"
          isBlack
        />
      </View>
    </Modal>
  );
}

export default CloseAccountModal;
