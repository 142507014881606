// import {
//   appsflyerEvent,
//   appsflyerInit,
//   appsflyerUserSetup,
// } from './appsflyerFunctions';
// import {
//   mixpanelEvent,
//   mixpanelInit,
//   mixpanelReset,
//   mixpanelUserSetup,
// } from './mixpanelFunctions';
// import {
//   oneSignalInit,
//   oneSignalUserReset,
//   oneSignalUserSetup,
// } from './onesignalFunctions';
// import {
//   moengageEvent,
//   moengageInit,
//   moengageReset,
//   moengageUserSetup,
// } from './moengageFunctions';

export const eventsTracker = async (eventName, eventObj = {}) => {
  try {
    console.log("eventName:", eventName, eventObj);
  } catch (error) {
    console.log(error, "eventsTracker error");
  }
};

export const initEvents = () => {};

export const loginEvents = (user) => {};

export const logoutEvents = () => {};
