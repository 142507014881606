import React, { useRef } from "react";
import { useLocation, useParams } from "react-router";
import ConsignmentMethodSelection from "../components/Consignment/ConsignmentMethodSelection";
import { View } from "react-native";
import { LatoRegular } from "../components/Text";
import ConsignmentAssisted from "../components/Consignment/ConsignmentAssisted";
import ConsignmentPickupStatus from "../components/Consignment/ConsignmentPickupStatus";
import SelfListing from "../components/Consignment/SelfListing";
import PickupItems from "../components/Consignment/PickupItems";

function ConsignmentBranchingScreen({
  setOpenModal = () => {},
  setOpenAddPhotosModal = () => {},
  setEditIndex = () => {},
  items = [],
  setItems = () => {},
  // selectedDelivery = 0,
  // setSelectedDelivery = () => {},
  selectedTime,
  setSelectedTime = () => {},
  date,
  setDate = () => {},
  slot = [],
  outService = false,
}) {
  const scrollRef = useRef();
  const { state } = useLocation();
  const { segment, additional, id } = useParams();

  const ScreenDecider = () => {
    if (additional === "method-selection")
      return <ConsignmentMethodSelection />;
    if (additional === "assisted-listing")
      return (
        <ConsignmentAssisted
          setOpenModal={setOpenModal}
          setOpenAddPhotosModal={setOpenAddPhotosModal}
          setEditIndex={setEditIndex}
          items={items}
          setItems={setItems}
          // selectedDelivery={selectedDelivery}
          // setSelectedDelivery={setSelectedDelivery}
          selectedTime={selectedTime}
          setSelectedTime={setSelectedTime}
          date={date}
          setDate={setDate}
          slot={slot}
          outService={outService}
        />
      );
    if (additional === "self-listing") return <SelfListing />;
    if (segment === "pickup-status")
      return additional ? <PickupItems /> : <ConsignmentPickupStatus />;
    return (
      <View>
        <LatoRegular>Masuk nih</LatoRegular>
      </View>
    );
  };

  return <ScreenDecider />;
}

export default ConsignmentBranchingScreen;
