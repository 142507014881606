import React, { useEffect, useState } from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../tools/Interface";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import BenefitRewardTable from "../components/BenefitRewardTable";
import CoverVIP from "../assets/images/cover-vip.jpeg";
import ImageWithLoader from "../components/ImageWithLoader";
import { LatoBold, LatoRegular, PTSerifRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { api } from "../utils/api";
import ExplainationSell from "../components/SellWithUs/ExplainationSell";
import Comission from "../assets/images/comission.svg";
import { PiGiftLight } from "react-icons/pi";
import { SlDiamond } from "react-icons/sl";

function VIPScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const [tiers, setTiers] = useState([]);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const descArr = [
    {
      icon: <PiGiftLight size={isTablet ? 24 : 36} />,
      title: "Shop with us",
      description:
        "Your tier is determined by how much you spend on your Belle&Kate account over a 12-month period, and every purchase you make takes you a step closer to becoming our highest tier VIP customer.",
    },
    {
      icon: <SlDiamond size={isTablet ? 24 : 36} />,
      title: "unlock more benefits",
      description:
        "The more you shop, the more exclusive benefits you'll receive, from birthday gifts, early access to sales and exclusive invitations to the latest brand collaboration and launch events.",
    },
    {
      source: Comission,
      title: "Track your spending",
      description:
        "You will automatically be enrolled into the rewards system when you create an account and start spending with us and will be upgraded to the next tier once your spend meets the next threshold.",
    },
  ];

  const getData = async () => {
    try {
      const res = await api.get("/api/tiers");

      setTiers(res?.data?.data);
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View>
          <ImageWithLoader
            source={CoverVIP}
            alt={`VIP Cover`}
            style={{
              height: isPhone ? (250 / 390) * width : (9 / 16) * width - 120,
              width,
            }}
            defaultStyle={{
              width: 120,
              height: isPhone ? (250 / 390) * width : (9 / 16) * width - 120,
            }}
            resizeMode={"cover"}
          />
          <View
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: colors.transparentBlack00,
              width,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isPhone ? 14 : 18,
                lineHeight: isPhone ? 20 : 26,
                color: colors.whiteFF,
                marginBottom: isPhone ? 8 : 16,
              }}
            >
              WELCOME TO
            </LatoRegular>
            <LatoBold
              style={{
                fontSize: isPhone ? 24 : 40,
                lineHeight: isPhone ? 32 : 48,
                color: colors.whiteFF,
                marginBottom: isPhone ? 8 : 16,
              }}
            >
              BELLE&KATE’S
            </LatoBold>
            <LatoBold
              style={{
                fontSize: isPhone ? 24 : 40,
                lineHeight: isPhone ? 32 : 48,
                color: colors.whiteFF,
                marginBottom: isPhone ? 8 : 16,
              }}
            >
              VIP ROOM
            </LatoBold>
            <LatoRegular
              style={{
                fontSize: isPhone ? 14 : 18,
                lineHeight: isPhone ? 20 : 26,
                color: colors.whiteFF,
              }}
            >
              Get access to a host of exclusive benefits
            </LatoRegular>
          </View>
        </View>
        <View
          style={{
            paddingVertical: isPhone ? 48 : 100,
            borderBottomWidth: 1,
            borderBottomColor: colors.greyE6,
            paddingHorizontal: gap,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 16 : 32,
              lineHeight: isPhone ? 24 : 40,
              color: colors.grey33,
              marginBottom: 24,
              textAlign: "center",
            }}
          >
            BELLE&KATE’S VIP ROOM REWARDS
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: isPhone ? 12 : 24,
              lineHeight: isPhone ? 16 : 32,
              color: colors.grey33,
              textAlign: "center",
            }}
          >
            For our dedicated customers loyalty program that offers you access
            to a host of exclusive benefits, including regular event invitation,
            complimentary express shipping and much more. Each tier is based on
            your yearly spend, with every item you buy counting towards your
            journey in becoming one of our most valued VIP ROOM customers.
          </LatoRegular>
        </View>
        <View
          style={{
            paddingTop: isPhone ? 48 : 100,
            paddingBottom: isPhone ? 0 : 100,
            paddingHorizontal: isTablet ? 0 : gap,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 16 : 32,
              lineHeight: isPhone ? 24 : 40,
              color: colors.grey33,
              marginBottom: isPhone ? 0 : 60,
              textAlign: "center",
              textTransform: "uppercase",
            }}
          >
            How does it work?
          </LatoBold>
          <ExplainationSell
            arr={descArr}
            scroll={isTablet}
            itemWidth={isTablet ? containerWidth : containerWidth / 3}
            containerWidth={containerWidth}
            containerHeight={isPhone ? 200 : 235}
            fontSize={isPhone ? 12 : 16}
            lineHeight={isPhone ? 16 : 24}
            descFontSize={isPhone ? 12 : 14}
            descLineHeight={isPhone ? 16 : 20}
            numbered={false}
          />
        </View>
        <View
          style={{
            backgroundColor: isPhone ? colors.whiteFF : colors.whiteE8,
            paddingHorizontal: gap,
            paddingTop: isPhone ? 32 : 60,
            paddingBottom: isPhone ? 48 : 150,
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 16 : 32,
              lineHeight: isPhone ? 24 : 40,
              color: colors.grey33,
              marginBottom: 60,
              textAlign: "center",
            }}
          >
            REWARDS TIERS
          </LatoBold>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {tiers.map((item, i) => {
              const { name, main_image } = item || {};
              return (
                <View
                  style={{
                    width: containerWidth / (isPhone ? 3.15 : 3.5),
                  }}
                >
                  <ImageWithLoader
                    source={{ uri: main_image }}
                    alt={name}
                    style={{
                      height: containerWidth / (isPhone ? 2 : 3.5),
                      width: containerWidth / (isPhone ? 3.15 : 3.5),
                    }}
                    defaultStyle={{
                      height: containerWidth / (isPhone ? 2 : 3.5),
                      width: 48,
                    }}
                    containerStyle={{
                      height:
                        ((450 / 480) * containerWidth) / (isPhone ? 2 : 3.5),
                      width: containerWidth / (isPhone ? 3.15 : 3.5),
                    }}
                    woMask
                    resizeMode={"cover"}
                  />
                  <View
                    style={{
                      position: "absolute",
                      bottom: 0,
                      backgroundColor: colors.black00,
                      width: containerWidth / (isPhone ? 3.15 : 3.5),
                      paddingHorizontal: isPhone ? 8 : 20,
                      paddingVertical: isPhone ? 8 : 16,
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: isPhone ? 12 : 20,
                        lineHeight: isPhone ? 16 : 28,
                        color: colors.whiteFF,
                      }}
                    >
                      {`TIER ${i + 1}`}
                    </LatoRegular>
                    <PTSerifRegular
                      style={{
                        fontSize: isPhone ? 12 : 32,
                        lineHeight: isPhone ? 16 : 40,
                        color: colors.whiteFF,
                        textTransform: "uppercase",
                      }}
                    >
                      {name}
                    </PTSerifRegular>
                  </View>
                </View>
              );
            })}
          </View>
        </View>
        <BenefitRewardTable isVIP />
        <Footer />
      </ScrollView>
    </View>
  );
}

export default VIPScreen;
