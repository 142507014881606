import React, { createContext, useEffect, useRef, useState } from "react";
import { RiErrorWarningFill } from "react-icons/ri";
import { useWindowDimensions } from "react-native";
import Toast from "react-native-toast-notifications";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import { useNavigate } from "react-router";
import { HiCheckCircle } from "react-icons/hi2";

export const ToasterContext = createContext();

export const ToasterProvider = ({ children }) => {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const customToastRef = useRef(null);
  const iconRef = useRef();
  const navigate = useNavigate();

  const [toastType, setToastType] = useState("error");

  const showToast = (error, isLogin = false) => {
    const message =
      error?.data?.message ||
      error?.data?.errors[0] ||
      error?.data?.errors?.full_messages[0] ||
      "Something went wrong. Please try again";

    setToastType("error");
    customToastRef?.current?.show(message);

    if (error?.status === 401 && !isLogin) {
      navigate("/");
    }
  };

  const showSuccessToast = (message) => {
    setToastType("success");
    customToastRef?.current?.show(message);
  };

  useEffect(() => {}, [width]);

  return (
    <ToasterContext.Provider value={{ showToast, showSuccessToast }}>
      {children}
      <Toast
        icon={
          toastType === "success" ? (
            <HiCheckCircle size={24} color={colors.whiteFF} />
          ) : (
            <RiErrorWarningFill color={colors.whiteFF} size={24} />
          )
        }
        style={{
          backgroundColor: colors.grey33,
          borderRadius: 10,
          padding: 18,
          height: 58,
        }}
        textStyle={{
          fontFamily: "Lato-Regular",
          fontSize: 16,
          lineHeight: 24,
          color: colors.whiteFF,
        }}
        ref={customToastRef}
        duration={3000}
        animationType={"slide-in"}
        animationDuration={1000}
        placement={"bottom"}
        offset={isPhone ? 48 : 24}
      />
    </ToasterContext.Provider>
  );
};

export default ToasterContext;
