import React, { useContext, useEffect, useState } from "react";
import { asyncGetCurrency, asyncSetCurrency } from "../tools/asyncStorages";

const CurrencyContext = React.createContext({
  selected: {},
  setSelected: () => {},
});

export const CurrencyProvider = ({ children }) => {
  const [selected, setSelected] = useState("");

  const getCurrency = async () => {
    const currency = await asyncGetCurrency();
    if (currency) {
      setSelected(currency);
    }
  };

  const onSelect = (value) => {
    setSelected(value);
    asyncSetCurrency(value);
  };

  useEffect(() => {
    getCurrency();
  }, []);

  return (
    <CurrencyContext.Provider
      value={{
        selected,
        setSelected: onSelect,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export default CurrencyContext;

export const useCurrency = () => {
  const { selected, setSelected } = useContext(CurrencyContext);

  return { selected, setSelected };
};
