import React, { useContext, useState } from "react";
import { View, useWindowDimensions, TouchableOpacity } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import DateTimePicker from "../DatePicker";
import Buttons from "../Buttons";
import AssistedListingItems from "./AssistedListingItems";
import AssistedTimePicker from "./AssistedTimePicker";
import AssistedAddresses from "./AssistedAddresses";
import { useNavigate, useParams } from "react-router";
import { api } from "../../utils/api";
import { pickUpProductsFormatter } from "../../tools/formatter";
import {
  asyncRemoveIsMultipart,
  asyncSetIsMultipart,
} from "../../tools/asyncStorages";
import { useAuth } from "../../contexts/AuthContext";
import ToasterContext from "../../contexts/ToastContext";
import { lowerCase } from "lodash";
import { openWhatsapp } from "../../tools/tools";
import { contactDecider } from "../../tools/decider";

function ConsignmentAssisted({
  setOpenModal = () => {},
  setOpenAddPhotosModal = () => {},
  setEditIndex = () => {},
  items = [],
  setItems = () => {},
  selectedTime,
  setSelectedTime = () => {},
  date = new Date(),
  setDate = () => {},
  slot = [],
  outService = false,
}) {
  const [loading, setLoading] = useState(false);

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const navigate = useNavigate();
  const { segment } = useParams();
  const { user } = useAuth();
  const { detail = {} } = user || {};

  const activeSlot = slot.filter(({ active }) => active);

  const minDate = activeSlot[0]?.start_time;
  const maxDate = activeSlot[activeSlot?.length - 1]?.finish_time;

  const noPhotos = items.find(({ photos }) => !photos?.length);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (outService) {
        const isHermes = lowerCase(items[0]?.designer?.name) === "hermes";

        openWhatsapp(
          "",
          contactDecider(isHermes ? "HermesConsignment" : "OtherConsignment")
        );
      } else {
        await asyncSetIsMultipart(true);

        const products = await pickUpProductsFormatter(
          items,
          selectedTime,
          segment
        );

        await api.post("/api/pickups", products);

        navigate("/account/pickup-status", {
          state: { idx: 1, index: 2 },
        });
        setItems([]);
        setSelectedTime(false);
        setDate("");
        showSuccessToast("Pickup has been requested");
      }
    } catch (err) {
      console.log("err:", err);
      showToast(err);

      // if (err?.status === 401) {
      //   navigate("/");
      // }
    } finally {
      await asyncRemoveIsMultipart();
      setLoading(false);
    }
  };

  return (
    <View
      style={{
        justifyContent: "center",
        marginBottom: 50,
        marginHorizontal: gap,
      }}
    >
      <LatoBold
        style={{
          fontSize: isPhone ? 20 : 24,
          lineHeight: isPhone ? 26 : 32,
          color: colors.grey33,
          marginBottom: 8,
          textAlign: "left",
        }}
      >
        Sell Your Item
      </LatoBold>
      <LatoRegular
        style={{
          fontSize: isPhone ? 16 : 18,
          lineHeight: isPhone ? 24 : 26,
          color: colors.grey6C,
          marginBottom: 32,
          textAlign: "left",
        }}
      >
        {
          "Sell your item and let us assist you to pickup your items ( min. 5 bags)"
        }
      </LatoRegular>
      <AssistedListingItems
        isPhone={isPhone}
        items={items}
        setItems={setItems}
        setOpenModal={setOpenModal}
        setOpenAddPhotosModal={setOpenAddPhotosModal}
        setEditIndex={setEditIndex}
        width={width}
        isTablet={isTablet}
        // outService={outService}
      />
      <AssistedAddresses
        isPhone={isPhone}
        isTablet={isTablet}
        selectedTime={selectedTime}
        date={date}
        items={items}
        width={width}
        navigate={navigate}
        outService={outService}
      />
      {Boolean(!outService) && (
        <View>
          <DateTimePicker
            placeholder={"Preferred date to pickup"}
            label={"Pickup Date"}
            onChange={setDate}
            date={date}
            required
            customWidth={isPhone ? width - 32 : isTablet ? 700 : 1000}
            min={minDate}
            max={maxDate}
            disabled={!detail?.complete_address || outService}
          />
          <AssistedTimePicker
            isPhone={isPhone}
            selected={selectedTime}
            setSelected={setSelectedTime}
            date={date}
            slot={slot}
          />
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: containerWidth,
        }}
      >
        <TouchableOpacity
          onPress={() => {
            navigate("/account/seller-dashboard", {
              state: { idx: 0, index: 2 },
            });
          }}
        >
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
              marginBottom: 8,
              textDecorationLine: "underline",
            }}
          >
            Cancel
          </LatoRegular>
        </TouchableOpacity>
        <Buttons
          onPress={handleSubmit}
          loading={loading}
          disabled={
            (!selectedTime && !outService) ||
            (!date && !outService) ||
            items.length < 5 ||
            loading ||
            noPhotos
          }
          label={outService ? "Contact us" : "Submit"}
          isBlack
          width={250}
        />
      </View>
    </View>
  );
}

export default ConsignmentAssisted;
