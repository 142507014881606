import React from "react";
import {
  Image,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import { capitalCase } from "change-case";
import { getDeviceType } from "../../tools/Interface";
import About from "../../assets/images/about-sell.png";
import Play from "../../assets/images/play.svg";
import { createVideoThumbnail } from "../../tools/formatter";

function AboutSell({
  menuArr = [],
  cat = "",
  setAboutPosition = () => {},
  isVideo = false,
  setOpenVideo = () => {},
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View
      onLayout={(e) => {
        setAboutPosition(e.nativeEvent.layout.y);
      }}
      style={{
        paddingHorizontal: gap,
        flexDirection: "row",
        alignItems: "center",
        paddingBottom: isPhone ? 16 : 100,
      }}
    >
      <View
        style={{
          width: isPhone ? containerWidth : containerWidth / 2,
          padding: isPhone ? 24 : 60,
          paddingLeft: isTablet ? 0 : 84,
          alignItems: isPhone ? "center" : "flex-start",
        }}
      >
        <LatoBold
          style={{
            fontSize: isPhone ? 20 : 32,
            lineHeight: isPhone ? 28 : 40,
            marginBottom: isPhone ? 16 : 32,
            fontFamily: isPhone ? "lato-regular" : "lato-bold",
          }}
        >
          {`About ${capitalCase(
            menuArr.filter(({ category }) => cat === category)[0].title
          )}`}
        </LatoBold>
        <LatoRegular
          style={{
            fontSize: isPhone ? 12 : 24,
            lineHeight: isPhone ? 16 : 32,
            marginBottom: 60,
            textAlign: isPhone ? "center" : "left",
          }}
        >
          {menuArr.filter(({ category }) => cat === category)[0].about}
        </LatoRegular>
      </View>
      {Boolean(!isPhone) && (
        <View>
          <Image
            source={
              isVideo
                ? createVideoThumbnail(
                    "https://www.youtube.com/watch?v=yqQfrJw8s0g"
                  )
                : About
            }
            alt={`about ${cat}`}
            style={{
              alignSelf: "center",
              width: containerWidth / 2,
              height: ((containerWidth / 2) * 500) / 730,
            }}
            defaultSource={require("../../assets/images/spinning-loading.gif")}
            resizeMode={"cover"}
          />
          {Boolean(isVideo) && (
            <TouchableOpacity
              onPress={() => setOpenVideo(true)}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={Play}
                alt={"play-button"}
                style={{
                  height: 80,
                  width: 80,
                }}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
}

export default AboutSell;
