import React, { useContext, useEffect, useState } from "react";
import { TouchableOpacity, View, Image } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { GrClose } from "react-icons/gr";
import { api } from "../../utils/api";
import Shimmer from "../Shimmer";
import { formatCurrency } from "../../tools/formatter";
import { MyInput } from "../Inputs";
import Buttons from "../Buttons";
import { ImCancelCircle } from "react-icons/im";
import { FaRegCheckCircle } from "react-icons/fa";
import DropDownTab from "../DropDownTab";
import {
  consignmentStateColorDecider,
  consignmentStateDecider,
  contactDecider,
} from "../../tools/decider";
import { format } from "date-fns";
import { AiOutlineClockCircle } from "react-icons/ai";
import { openWhatsapp } from "../../tools/tools";
import ToasterContext from "../../contexts/ToastContext";

function AppraisalProgress({
  setOpenModal = () => {},
  selected,
  isPhone,
  width,
  openConfirmationModal = false,
  setOpenConfirmationModal = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [item, setItem] = useState({});
  const [offer, setOffer] = useState("0");
  const [startOffer, setStartOffer] = useState(false);

  const { showToast } = useContext(ToasterContext);

  const getAppraisalData = async () => {
    try {
      setLoading(true);
      const res = await api.get(`/api/consignments/${selected}`);
      const data = res?.data?.data;

      setItem(data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleOfferFirstButton = async () => {
    try {
      setLoading(true);
      if (startOffer) {
        await api.put(
          `api/consignments/${selected}`,
          startOffer
            ? {
                state: "counter",
                price: offer.replaceAll(",", ""),
              }
            : { state: "accept" }
        );

        getAppraisalData();
        setStartOffer(false);
      } else {
        setOpenConfirmationModal(true);
      }
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleReject = async () => {
    try {
      setLoading(true);
      await api.put(`api/consignments/${selected}`, {
        state: "reject",
      });

      getAppraisalData();
      setStartOffer(false);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleChat = () => {
    let message = `I appreciate the offer, but would you be open to increasing the percentage of the sale that I receive from the consignment of my product with SKU: ${item?.product?.sku}?`;
    const contact = contactDecider(
      item?.product?.designer_id === 1
        ? "HermesConsignment"
        : "OtherConsignment"
    );
    openWhatsapp(message, contact);
  };

  useEffect(() => {
    getAppraisalData();
  }, [openConfirmationModal]);

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <LatoBold
          style={{ fontSize: 20, lineHeight: 28, color: colors.grey33 }}
        >
          Review Appraisal Offer
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenModal(false)}>
          <GrClose size={16} />
        </TouchableOpacity>
      </View>
      <View style={{ flexDirection: "row" }}>
        <View
          style={{
            width: 0.6 * (isPhone ? width - 32 : 750),
            borderRightWidth: 1,
            borderRightColor: colors.greyE6,
            marginRight: 16,
          }}
        >
          <View
            style={{
              borderWidth: 1,
              padding: 18,
              borderRadius: 4,
              // justifyContent: "center",
              flexDirection: "row",
              marginRight: 16,
              borderColor: colors.greyE6,
            }}
          >
            {loading ? (
              <Shimmer
                autoRun={true}
                visible={false}
                width={60}
                height={60}
                hasBorder
                style={{ marginRight: 16 }}
                // duration={5000}
              />
            ) : item?.product?.summary_detail?.direct_photos?.length ? (
              <View
                style={{
                  borderWidth: 1,
                  padding: 6,
                  borderRadius: 4,
                  justifyContent: "center",
                  marginRight: 16,
                  borderColor: colors.greyE6,
                  width: 60,
                  height: 60,
                }}
              >
                <Image
                  source={{
                    uri: `${item?.product?.summary_detail?.direct_photos[0]}`,
                  }}
                  alt={item?.product?.name}
                  style={{
                    alignSelf: "center",
                    // marginBottom: isCheckout ? 0 : 16,
                    // marginRight: isCheckout ? 0 : 32,
                    width: 48,
                    height: 48,
                  }}
                  defaultSource={require("../../assets/images/spinning-loading.gif")}
                  resizeMode={"contain"}
                />
              </View>
            ) : null}
            <View>
              {loading ? (
                <View>
                  <Shimmer
                    autoRun={true}
                    visible={false}
                    width={"15vw"}
                    height={20}
                    hasBorder
                    style={{ marginBottom: 8 }}
                    // duration={5000}
                  />
                  <Shimmer
                    autoRun={true}
                    visible={false}
                    width={"15vw"}
                    height={20}
                    hasBorder
                    style={{ marginBottom: 8 }}
                    // duration={5000}
                  />
                </View>
              ) : (
                <View
                  style={{
                    // width: isCheckout
                    //   ? isPhone
                    //     ? "65%"
                    //     : "15vw"
                    //   : isPhone
                    //   ? width - 250
                    //   : width / 2 - 250,
                    flexWrap: "wrap",
                  }}
                >
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 4,
                    }}
                  >
                    {item?.product?.summary_detail?.designer}
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 8,
                    }}
                  >
                    {item?.product?.name}
                  </LatoRegular>
                </View>
              )}
            </View>
          </View>
          <View style={{ marginRight: 16 }}>
            <DropDownTab
              item={{
                title: "Tracker Details",
                datas: item?.appraisal?.appraisal_histories?.length
                  ? item?.appraisal?.state === "customer_accepted"
                    ? [
                        {
                          state: "waiting_for_appraisal",
                          created_at: item?.created_at,
                        },
                        {
                          state: "appraisal_negotiation",
                          created_at: item?.updated_at,
                        },
                        ...item?.appraisal?.appraisal_histories,
                        {
                          state: "consignment_in_progress",
                          created_at:
                            item?.appraisal?.appraisal_histories[
                              item?.appraisal?.appraisal_histories?.length - 1
                            ]?.created_at,
                        },
                      ]
                    : [
                        {
                          state: "waiting_for_appraisal",
                          created_at: item?.created_at,
                        },
                        {
                          state: "appraisal_negotiation",
                          created_at: item?.updated_at,
                        },
                        ...item?.appraisal?.appraisal_histories
                          .map((itm, index) => {
                            if (
                              index <
                              item?.appraisal?.appraisal_histories?.length - 1
                            )
                              return itm;
                          })
                          .filter((item) => item),
                        {
                          state: "consignment_in_progress",
                          created_at:
                            item?.appraisal?.appraisal_histories[
                              item?.appraisal?.appraisal_histories?.length - 1
                            ]?.created_at,
                        },
                        ...item?.state_timestamps
                          .map((itm, index) => {
                            if (index > 0) return itm;
                          })
                          .filter((item) => item),
                      ]
                  : [
                      {
                        state: "waiting_for_appraisal",
                        created_at: item?.created_at,
                      },
                      {
                        state: "appraisal_negotiation",
                        created_at: item?.updated_at,
                      },
                    ],
                style: "history",
              }}
              noMargin
              noBorder
              right
              isBold
              titleStyle={{ fontSize: 14, lineHeight: 20 }}
              additional={
                item?.state && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginRight: 12,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: consignmentStateColorDecider(
                          item?.state
                        ),
                        width: 8,
                        height: 8,
                        borderRadius: "100%",
                        marginRight: 8,
                      }}
                    />
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {consignmentStateDecider(
                        item?.state === "completed"
                          ? item?.state_timestamps[
                              item?.state_timestamps?.length - 1
                            ]?.state
                          : item?.state
                      )}
                    </LatoBold>
                  </View>
                )
              }
            />
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey33,
                marginBottom: 12,
              }}
            >
              Created Date :{" "}
              {Boolean(item?.product?.created_at) && (
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey6C,
                    // marginBottom: 4,
                  }}
                >
                  {format(new Date(item?.product?.created_at), "dd/MM/yyyy")}
                </LatoRegular>
              )}
            </LatoBold>
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey33,
                marginBottom: 12,
              }}
            >
              Condition :{" "}
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.green5B,
                }}
              >
                {item?.product?.summary_detail?.condition || "-"}
              </LatoBold>
            </LatoBold>
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey33,
                marginBottom: 8,
              }}
            >
              Seller Story :
            </LatoBold>
            <LatoRegular
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey6C,
              }}
            >
              {item?.seller_story || "-"}
            </LatoRegular>
          </View>
        </View>
        <View
          style={{
            width: 0.32 * (isPhone ? width - 32 : 750),
          }}
        >
          <View
            style={{
              borderWidth: 1,
              padding: 18,
              borderRadius: 4,
              // justifyContent: "center",
              marginBottom: 16,
              borderColor: colors.greyE6,
            }}
          >
            <View
              style={{
                borderBottomWidth: 1,
                marginBottom: 8,
                borderBottomColor: colors.greyE6,
              }}
            >
              <LatoBold
                style={{
                  fontSize: 12,
                  lineHeight: 16,
                  color: colors.grey33,
                  marginBottom: 4,
                }}
              >
                {item?.appraisal?.appraisal_histories?.length === 1 ||
                item?.appraisal?.appraisal_histories[
                  item?.appraisal?.appraisal_histories?.length - 2
                ]?.submitted_by === "admin" ||
                startOffer ||
                item?.appraisal?.appraisal_histories[
                  item?.appraisal?.appraisal_histories?.length - 2
                ]?.state === "customer_accepted"
                  ? "Belle&Kate Offer"
                  : "Your Price Offer"}
              </LatoBold>
              {Boolean(
                item?.appraisal?.appraisal_histories?.length === 1 ||
                  item?.appraisal?.appraisal_histories[
                    item?.appraisal?.appraisal_histories?.length - 2
                  ]?.submitted_by === "admin" ||
                  startOffer ||
                  item?.appraisal?.appraisal_histories[
                    item?.appraisal?.appraisal_histories?.length - 2
                  ]?.state === "customer_accepted"
              ) && (
                <LatoRegular
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.grey90,

                    paddingBottom: 12,
                  }}
                >
                  Our price offer for your item.
                </LatoRegular>
              )}
            </View>
            <LatoBold
              style={{
                fontSize: 16,
                lineHeight: 24,
                color:
                  item?.appraisal?.latest_price !== "0.0"
                    ? colors.grey33
                    : colors.grey90,
                marginBottom: 4,
              }}
            >
              {item?.appraisal?.latest_price !== "0.0"
                ? `IDR ${formatCurrency(
                    startOffer ||
                      item?.appraisal?.appraisal_histories?.length === 1
                      ? item?.appraisal?.latest_price || 0
                      : item?.appraisal?.state === "admin_pending"
                      ? item?.appraisal?.appraisal_histories[
                          item?.appraisal?.appraisal_histories?.length - 2
                        ]?.price || 0
                      : item?.appraisal?.state === "rejected" &&
                        item?.appraisal?.appraisal_histories[
                          item?.appraisal?.appraisal_histories?.length - 3
                        ]?.submitted_by === "customer"
                      ? item?.appraisal?.appraisal_histories[
                          item?.appraisal?.appraisal_histories?.length - 3
                        ]?.price || 0
                      : item?.appraisal?.appraisal_histories[
                          item?.appraisal?.appraisal_histories?.length - 2
                        ]?.submitted_by === "customer"
                      ? item?.appraisal?.appraisal_histories[
                          item?.appraisal?.appraisal_histories?.length - 2
                        ]?.price || 0
                      : item?.appraisal?.appraisal_histories[
                          item?.appraisal?.appraisal_histories?.length - 1
                        ]?.price || 0
                  )}`
                : "Waiting for admin offer"}
            </LatoBold>
            {Boolean(
              (!startOffer &&
                item?.appraisal?.appraisal_histories[
                  item?.appraisal?.appraisal_histories?.length - 1
                ]?.state === "admin_offered" &&
                item?.appraisal?.appraisal_histories?.length > 1) ||
                item?.appraisal?.state === "rejected" ||
                item?.appraisal?.state === "customer_accepted" ||
                item?.state === "waiting_for_live"
            ) && (
              <View
                style={{
                  marginHorizontal: -19,
                  marginBottom: -19,
                  backgroundColor: Boolean(
                    item?.appraisal?.state === "rejected" ||
                      item?.appraisal?.appraisal_histories[
                        item?.appraisal?.appraisal_histories?.length - 1
                      ]?.state === "admin_offered"
                  )
                    ? colors.redB6
                    : colors.green06,
                  borderBottomStartRadius: 4,
                  borderBottomEndRadius: 4,
                  paddingHorizontal: 18,
                  marginTop: 12,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                {Boolean(
                  item?.appraisal?.state === "rejected" ||
                    item?.appraisal?.appraisal_histories[
                      item?.appraisal?.appraisal_histories?.length - 1
                    ]?.state === "admin_offered"
                ) ? (
                  <ImCancelCircle size={16} color={colors.whiteFF} />
                ) : (
                  <FaRegCheckCircle size={16} color={colors.whiteFF} />
                )}
                <LatoBold
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.whiteFF,
                    paddingVertical: 4,
                    marginLeft: 4,
                  }}
                >
                  {item?.appraisal?.state === "rejected"
                    ? "Offer Rejected"
                    : item?.appraisal?.appraisal_histories[
                        item?.appraisal?.appraisal_histories?.length - 1
                      ]?.state === "admin_offered"
                    ? "Your offer was rejected"
                    : "Offer Accepted"}
                </LatoBold>
              </View>
            )}
          </View>
          {Boolean(
            item?.appraisal?.state !== "rejected" &&
              item?.appraisal?.state !== "customer_accepted" &&
              item?.appraisal?.state !== "completed" &&
              (item?.appraisal?.appraisal_histories[
                item?.appraisal?.appraisal_histories?.length - 1
              ]?.submitted_by === "customer" ||
                (item?.appraisal?.appraisal_histories?.length > 1 &&
                  !startOffer))
          ) ? (
            <View
              style={{
                borderWidth: 1,
                padding: 18,
                borderRadius: 4,
                // justifyContent: "center",
                marginBottom: 16,
                borderColor: colors.greyE6,
              }}
            >
              <View
                style={{
                  marginBottom: 8,
                  borderBottomWidth: 1,

                  borderBottomColor: colors.greyE6,
                }}
              >
                <LatoBold
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.grey33,
                    marginBottom:
                      item?.appraisal?.appraisal_histories[
                        item?.appraisal?.appraisal_histories?.length - 1
                      ]?.submitted_by === "customer"
                        ? 12
                        : 4,
                  }}
                >
                  {item?.appraisal?.appraisal_histories[
                    item?.appraisal?.appraisal_histories?.length - 1
                  ]?.submitted_by === "admin"
                    ? "Belle&Kate Offer"
                    : "Your Price Offer"}
                </LatoBold>
                {Boolean(
                  item?.appraisal?.appraisal_histories[
                    item?.appraisal?.appraisal_histories?.length - 1
                  ]?.submitted_by === "admin"
                ) && (
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: colors.grey90,
                      paddingBottom: 12,
                    }}
                  >
                    Our price offer for your item.
                  </LatoRegular>
                )}
              </View>
              <LatoBold
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  color:
                    item?.appraisal?.latest_price !== "0.0"
                      ? colors.grey33
                      : colors.grey90,
                  marginBottom: 4,
                }}
              >
                {item?.appraisal?.latest_price !== "0.0"
                  ? `IDR ${formatCurrency(
                      item?.appraisal?.appraisal_histories[
                        item?.appraisal?.appraisal_histories?.length - 1
                      ]?.submitted_by === "admin"
                        ? item?.appraisal?.appraisal_histories[
                            item?.appraisal?.appraisal_histories?.length - 1
                          ]?.price || 0
                        : item?.appraisal?.latest_price || 0
                    )}`
                  : "Waiting for admin offer"}
              </LatoBold>
              {Boolean(
                item?.appraisal?.appraisal_histories[
                  item?.appraisal?.appraisal_histories?.length - 1
                ]?.submitted_by === "customer"
              ) && (
                <View
                  style={{
                    marginHorizontal: -19,
                    marginBottom: -19,
                    backgroundColor: colors.greyE6,
                    borderBottomStartRadius: 4,
                    borderBottomEndRadius: 4,
                    paddingHorizontal: 18,
                    marginTop: 12,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                  }}
                >
                  <AiOutlineClockCircle size={16} color={colors.grey33} />
                  <LatoBold
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: colors.grey33,
                      paddingVertical: 4,
                      marginLeft: 4,
                    }}
                  >
                    We’re reviewing your offer
                  </LatoBold>
                </View>
              )}
            </View>
          ) : (
            Boolean(startOffer) && (
              <MyInput
                handleChange={setOffer}
                label="Your offer"
                prefix="IDR "
                additional={{ value: formatCurrency(offer) }}
                textStyle={{
                  fontFamily: "Lato-Bold",
                  fontSize: 16,
                  lineHeight: 24,
                  marginLeft: -28,
                  paddingHorizontal: 32,
                }}
                prefixStyle={{ fontSize: 16, lineHeight: 24 }}
                checkByName={false}
              />
            )
          )}
          {Boolean(
            startOffer || item?.appraisal?.state === "customer_accepted"
          ) && (
            <LatoRegular
              style={{
                fontSize: 12,
                lineHeight: 16,
                color: colors.grey90,
                marginBottom: 16,
                marginTop:
                  item?.appraisal?.state === "customer_accepted" ? 0 : -12,
                textAlign: "justify",
              }}
            >
              {Boolean(item?.appraisal?.state === "customer_accepted")
                ? "We are currently reviewing your item, and we will notify you as soon as it is ready to be published."
                : "You can only make 2 offer."}
            </LatoRegular>
          )}
          {Boolean(
            item?.state_timestamps?.length &&
              item?.state_timestamps[item?.state_timestamps?.length - 1]
                ?.state === "disburse_waiting_for_verification"
          ) && (
            <>
              <LatoBold
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  color: colors.grey33,
                  marginBottom: 4,
                  // marginTop: ,
                }}
              >
                Cash-out is in progress
              </LatoBold>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey6C,
                  marginBottom: 16,
                  // marginTop: ,
                }}
              >
                You will be notified once the transfer is completed.
              </LatoRegular>
            </>
          )}
          {Boolean(
            item?.appraisal?.state !== "admin_pending" &&
              item?.appraisal?.state !== "rejected" &&
              item?.appraisal?.state !== "completed" &&
              item?.appraisal?.state !== "customer_accepted"
          ) && (
            <>
              <Buttons
                label={startOffer ? "Make offer" : "Accept Offer"}
                isBlack
                containerStyle={{ marginBottom: 8 }}
                onPress={handleOfferFirstButton}
                loading={loading}
              />
              <Buttons
                label={startOffer ? "Cancel" : "Reject Offer"}
                containerStyle={{ marginBottom: 8 }}
                onPress={() => {
                  if (startOffer) return setStartOffer(false);

                  handleReject();
                }}
                loading={loading}
              />
              {Boolean(!startOffer) &&
              Boolean(item?.appraisal?.total_customer_counter < 2) ? (
                <Buttons
                  label={"Counter Offer"}
                  onPress={() => setStartOffer(true)}
                  loading={loading}
                />
              ) : (
                <LatoRegular
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.grey90,
                    marginBottom: 16,
                    // marginTop: ,
                  }}
                >
                  You cannot make offer anymore. For further discussion please{" "}
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: colors.grey90,
                      textDecorationLine: "underline",
                    }}
                    onPress={handleChat}
                  >
                    contact us
                  </LatoRegular>
                  .
                </LatoRegular>
              )}
            </>
          )}
        </View>
      </View>
    </View>
  );
}

export default AppraisalProgress;
