import React, { useContext, useEffect, useState } from "react";
import { api } from "../../utils/api";
import {
  TouchableOpacity,
  View,
  Image,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { GrClose } from "react-icons/gr";
import VerticalProgress from "../VerticalProgress";
import { getDeviceType } from "../../tools/Interface";
import ToasterContext from "../../contexts/ToastContext";

function ShipmentTracker({ id, setOpenModal = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const { width, height } = useWindowDimensions();
  const { showToast } = useContext(ToasterContext);
  const { isPhone } = getDeviceType({ width, height });

  const getData = async () => {
    try {
      setLoading(true);

      const res = await api.get(`api/deals/${id}`);

      setData(res?.data?.data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const { shipment = {}, cart = {} } = data;
  const { status_histories = [] } = shipment;
  const { products = [] } = cart;

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <LatoBold
          style={{ fontSize: 20, lineHeight: 28, color: colors.grey33 }}
        >
          Track My Order
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenModal(false)}>
          <GrClose size={16} />
        </TouchableOpacity>
      </View>
      {loading ? (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <Image
            source={require("../../assets/images/spinning-loading.gif")}
            // alt={item?.product?.name}
            style={{
              alignSelf: "center",
              width: 200,
              height: 200,
            }}
            defaultSource={require("../../assets/images/spinning-loading.gif")}
            resizeMode={"contain"}
          />
        </View>
      ) : (
        <View>
          <View style={{ marginBottom: 24 }}>
            {products?.map(({ summary_index = {}, name = "" }, idx) => (
              <View
                style={{
                  borderWidth: 1,
                  padding: 18,
                  borderRadius: 4,
                  alignItems: "center",
                  flexDirection: "row",
                  marginRight: 16,
                  borderColor: colors.greyE6,
                  marginBottom: 12,
                }}
              >
                <View
                  style={{
                    borderWidth: 1,
                    padding: 6,
                    borderRadius: 4,
                    justifyContent: "center",
                    marginRight: 16,
                    borderColor: colors.greyE6,
                    width: 60,
                    height: 60,
                  }}
                >
                  <Image
                    source={{
                      uri: `${summary_index?.direct_photos[0]}`,
                    }}
                    alt={name}
                    style={{
                      alignSelf: "center",
                      width: 48,
                      height: 48,
                    }}
                    defaultSource={require("../../assets/images/spinning-loading.gif")}
                    resizeMode={"contain"}
                  />
                </View>
                <View
                  style={{
                    flexWrap: "wrap",
                    width: isPhone ? width - 300 : 400,
                  }}
                >
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 4,
                      textTransform: "uppercase",
                    }}
                  >
                    {summary_index?.designer}
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey6C,
                      marginBottom: 8,
                      width: isPhone ? width - 300 : 400,
                    }}
                  >
                    {name}
                  </LatoRegular>
                </View>
              </View>
            ))}
          </View>
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
              marginBottom: 8,
            }}
          >
            Status Details
          </LatoBold>
          <View
            style={{
              borderBottomWidth: 1,
              borderBottomColor: colors.greyE6,
              borderTopWidth: 1,
              borderTopColor: colors.greyE6,
            }}
          >
            <ScrollView
              showsVerticalScrollIndicator={false}
              style={{
                height: 0.5 * height,
                paddingVertical: 16,
              }}
            >
              <VerticalProgress
                datas={status_histories}
                shipment
                isPhone={isPhone}
              />
            </ScrollView>
          </View>
        </View>
      )}
    </View>
  );
}

export default ShipmentTracker;
