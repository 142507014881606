import React from "react";
import {
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import Hermes from "../../assets/images/hermes-cover.png";
import Chanel from "../../assets/images/chanel-cover.png";
import LV from "../../assets/images/lv-cover.png";
import Dior from "../../assets/images/dior-cover.png";
import HermesBag from "../../assets/images/hermes-bag.png";
import ChanelBag from "../../assets/images/chanel-bag.png";
import LVBag from "../../assets/images/lv-bag.png";
import DiorBag from "../../assets/images/dior-bag.png";
import { LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { useNavigate } from "react-router-dom";
import { getDeviceType } from "../../tools/Interface";

export default function ProductCover({ isHome = false, cat }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const menu =
    cat === "direct-sell"
      ? [
          {
            image: isHome ? HermesBag : Hermes,
            cover: Hermes,
            title: "Hermés",
            value: "Hermes",
          },
          {
            image: isHome ? ChanelBag : Chanel,
            cover: Chanel,
            title: "Chanel",
            value: "Chanel",
          },
        ]
      : [
          {
            image: isHome ? HermesBag : Hermes,
            cover: Hermes,
            title: "Hermés",
            value: "Hermes",
          },
          {
            image: isHome ? ChanelBag : Chanel,
            cover: Chanel,
            title: "Chanel",
            value: "Chanel",
          },

          {
            image: isHome ? DiorBag : Dior,
            cover: Dior,
            title: "Dior",
            value: "Dior",
          },
          {
            image: isHome ? LVBag : LV,
            cover: LV,
            title: "Louis Vuitton",
            value: "Louis Vuitton",
          },
        ];

  const Tag = isHome ? TouchableOpacity : View;

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const Wrapper = ({ children }) =>
    isHome && isPhone ? (
      <ScrollView
        horizontal
        style={{
          width,
          paddingHorizontal: gap,
        }}
        showsHorizontalScrollIndicator={false}
      >
        {children}
      </ScrollView>
    ) : (
      <View
        style={{
          flexDirection: "row",
          flexWrap: isHome ? "nowrap" : "wrap",
          zIndex: 1,
          justifyContent: "space-around",
          alignItems: "center",
          paddingHorizontal: isHome ? gap : 0,
          marginBottom: !isHome ? (isPhone ? 24 : 100) : 0,
        }}
      >
        {children}
      </View>
    );

  return (
    <View
      style={{
        marginTop: isHome ? 100 : 0,
        width,
      }}
    >
      {Boolean(!isHome) && (
        <View
          style={{
            paddingBottom: isPhone ? 24 : 50,
            justifyContent: "center",
            alignItems: "center",
            paddingHorizontal: gap,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.grey6C,
              marginBottom: 12,
              backgroundColor: colors.whiteFF,
              paddingHorizontal: isPhone ? 8 : 56,
              textTransform: "uppercase",
            }}
          >
            Brands accepted
          </LatoRegular>
          <View
            style={{
              position: "absolute",
              zIndex: -1,
              top: isPhone ? 12 : 16,
              width: containerWidth,
              height: 1,
              backgroundColor: colors.greyE6,
              left: gap,
            }}
          />
        </View>
      )}
      <Wrapper>
        {menu.map(({ image, title, value, cover }, index) => {
          return (
            <Tag
              key={index}
              onPress={() =>
                navigate(`/products/designer/${value}`, {
                  state: {
                    menu: [
                      {
                        name: value,
                        section: "Designer",
                        id: index + 1,
                      },
                    ],
                    designer_page: { title: value, image: cover },
                  },
                })
              }
            >
              <View
                style={{
                  width: isHome ? (isPhone ? 110 : 150) : 0.5 * width,
                  height: isHome
                    ? isPhone
                      ? 110
                      : 150
                    : ((width / 2) * (isPhone ? 200 : 450)) /
                      (isPhone ? 195 : 960),
                  backgroundColor: isHome
                    ? colors.superTransparentBlack00
                    : colors.black1A,
                  padding: isHome ? 15 : 0,
                  borderRadius: isHome ? 100 : 0,
                  marginBottom: isHome ? 24 : 0,
                  marginHorizontal: isHome ? 16 : 0,
                }}
                key={index}
              >
                <Image
                  source={image}
                  style={{
                    width: isHome ? (isPhone ? 60 : 100) : 0.5 * width,
                    height: isHome
                      ? isPhone
                        ? 80
                        : 120
                      : ((width / 2) * (isPhone ? 200 : 450)) /
                        (isPhone ? 195 : 960),
                    marginLeft: isHome ? 10 : 0,
                  }}
                  resizeMode={isHome ? "contain" : "cover"}
                />
                {Boolean(!isHome) && (
                  <View
                    style={{
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      left: 0,
                      right: 0,
                      alignItems: "center",
                      justifyContent: "center",
                      backgroundColor: colors.transparentBlack00,
                      transition: "0.5s",
                      width: 0.5 * width,
                      height:
                        ((width / 2) * (isPhone ? 200 : 450)) /
                        (isPhone ? 195 : 960),
                    }}
                  >
                    <LatoRegular
                      style={{
                        color: colors.whiteFF,
                        fontSize: isPhone ? 28 : 54,
                        lineHeight: isPhone ? 36 : 72,
                      }}
                    >
                      {title}
                    </LatoRegular>
                  </View>
                )}
              </View>
              {Boolean(isHome) && (
                <LatoRegular
                  style={{
                    fontSize: isPhone ? 16 : 24,
                    lineHeight: isPhone ? 24 : 32,
                    textTransform: "uppercase",
                    textAlign: "center",
                  }}
                >
                  {title}
                </LatoRegular>
              )}
            </Tag>
          );
        })}
      </Wrapper>
    </View>
  );
}
