import React from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoLight, LatoRegular } from "../components/Text";
import ContactUs from "../assets/images/about-line-1.png";
import ContactUsPhone from "../assets/images/contact-us-phone.png";
import SocialMedia from "../components/SocialMedia";
import { getDeviceType } from "../tools/Interface";
import { Wrapper } from "../components/Wrapper";
import Footer from "../components/Footer";
import { BsWhatsapp } from "react-icons/bs";
// import { ScrollView } from "react-native-web";

export default function ContactUsScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <View style={{ marginBottom: 100 }}>
          {Boolean(isPhone) && (
            <View
              style={{
                marginVertical: 48,
                marginHorizontal: gap,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LatoRegular
                style={{ fontSize: 24, lineHeight: 32, marginBottom: 8 }}
              >
                CONTACT US
              </LatoRegular>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey6C,
                }}
              >
                Belle&Kate customer service is at your disposal. Please contact
                us.
              </LatoRegular>
            </View>
          )}
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-around",
              marginBottom: 48,
              marginHorizontal: gap,
              marginTop: isPhone ? 0 : 100,
            }}
          >
            <View
              style={{
                width: isPhone ? containerWidth : containerWidth / 2 - 40,
                alignItems: "center",
                borderBottomColor: colors.greyE6,
                borderBottomWidth: isPhone ? 1 : 0,
                marginBottom: isPhone ? 16 : 0,
                paddingBottom: isPhone ? 24 : 0,
              }}
            >
              <Image
                source={ContactUs}
                // alt={alt}
                style={{
                  height: isPhone ? containerWidth : containerWidth / 2 - 40,
                  width: isPhone ? containerWidth : containerWidth / 2 - 40,
                }}
                defaultSource={require("../assets/images/spinning-loading.gif")}
                resizeMode={"contain"}
              />
            </View>
            <View
              style={{
                width: isPhone ? containerWidth : containerWidth / 2 - 40,
              }}
            >
              {Boolean(!isPhone) && (
                <View
                  style={{
                    paddingVertical: 12,
                    borderBottomColor: colors.greyE6,
                    borderBottomWidth: 1,
                    marginBottom: 32,
                  }}
                >
                  <LatoRegular
                    style={{ fontSize: 24, lineHeight: 32, marginBottom: 16 }}
                  >
                    CONTACT US
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                    }}
                  >
                    Belle&Kate customer service is at your disposal. Please
                    contact us.
                  </LatoRegular>
                </View>
              )}
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: isPhone ? 16 : 24,
                  marginBottom: isPhone ? 16 : 24,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                >
                  WHATSAPP
                </LatoRegular>
                <a
                  href={"https://web.whatsapp.com/send?phone=6281286095811"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecorationLine: "none",
                  }}
                >
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <View
                      style={{
                        width: 20,
                        height: 20,
                        backgroundColor: "#25d366",
                        padding: 4,
                        borderRadius: 1000,
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: 4,
                      }}
                    >
                      <BsWhatsapp size={16} color={colors.whiteFF} />
                    </View>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        textDecorationLine: "underline",
                      }}
                    >
                      +6281286095811
                    </LatoRegular>
                  </View>
                </a>
              </View>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  paddingBottom: isPhone ? 16 : 24,
                  marginBottom: isPhone ? 16 : 24,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                  }}
                >
                  EMAIL
                </LatoRegular>
                <a
                  href={"mailto:belleandkate.marketing@gmail.com"}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecorationLine: "none",
                    fontSize: isTablet ? 16 : 18,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      textDecorationLine: "underline",
                    }}
                  >
                    belleandkate.marketing@gmail.com
                  </LatoRegular>
                </a>
              </View>
              <LatoRegular
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  marginBottom: isPhone ? 16 : 24,
                }}
              >
                Find more products updated daily through
              </LatoRegular>
              <SocialMedia color={colors.grey33} />
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
