import React, { useEffect } from "react";
import { View } from "react-native";
import RootNavigation from "./navigations/RootNavigation";
import { asyncGetCurrency, asyncSetCurrency } from "./tools/asyncStorages";

function App() {
  const handleCurrency = async () => {
    const currency = await asyncGetCurrency();

    if (!currency) {
      await asyncSetCurrency("IDR");
    }
  };

  useEffect(() => {
    handleCurrency();
  }, []);

  return (
    <View>
      <RootNavigation />
    </View>
  );
}

export default App;
