import React from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { LatoBold } from "./Text";
import colors from "../assets/colors/colors";
import { LatoRegular } from "./Text";
import { getDeviceType } from "../tools/Interface";

function TopMenuSelection({ menu = [], selected, setSelected = () => {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  return (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: colors.greyE6,
        marginBottom: 16,
      }}
    >
      <ScrollView
        style={{ width: width }}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {menu?.map(({ title, value }, index) => {
          const TextDecider = selected === value ? LatoBold : LatoRegular;
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setSelected(value);
              }}
              style={{
                width: 145,
                alignItems: "center",
                borderBottomWidth: selected === value ? 2 : 0,
                borderBottomColor: colors.grey33,
                paddingVertical: 10,
                paddingHorizontal: 8,
                marginRight: 12,
              }}
            >
              <TextDecider
                style={{
                  color: selected === value ? colors.grey33 : colors.grey90,
                  fontSize: 14,
                  lineHeight: 20,
                }}
              >
                {title}
              </TextDecider>
            </TouchableOpacity>
          );
        })}
      </ScrollView>
    </View>
  );
}

export default TopMenuSelection;
