import { sentenceCase } from "change-case";
import { Formik } from "formik";
import React, { useState } from "react";
import { View, TouchableOpacity } from "react-native";
import { MyInput, SelectionDropDown } from "../Inputs";
import { LatoBold } from "../Text";
import { GrClose } from "react-icons/gr";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { useNavigate, useParams } from "react-router";

function AddListingItem({
  setOpenModal = () => {},
  handleAddItem = () => {},
  photos = [],
  item = {},
  loading = false,
  isPhone,
  additionalRequirement = false,
  width,
}) {
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { additional } = useParams();
  const navigate = useNavigate();

  const initialValues =
    additional === "self-listing"
      ? {
          designer: item?.designer || {},
          name: item?.name || "",
          condition: item?.condition || {},
          story: item?.story || "",
        }
      : {
          designer: item?.designer || {},
          name: item?.name || "",
          condition: item?.condition || {},
          story: item?.story || "",
          color: item?.color || "",
          leather: item?.leather || "",
          stamp: item?.stamp || "",
          size: item?.size || "",
          hardware: item?.hardware || "",
          inclusion: item?.inclusion || "",
          additional: item?.additional || "",
        };

  const formArr = [
    {
      name: "name",
      placeholder: "Name your item",
      label: "Item Name",
      required: true,
      initialValue: initialValues?.name,
      type: "text",
    },
    {
      name: "designer",
      placeholder: "Choose designer",
      label: "Designer",
      required: true,
      initialID: initialValues?.designer,
      type: "selection",
      half: additional !== "self-listing",
    },
    {
      name: "condition",
      placeholder: "Choose item condition",
      label: "Condition",
      required: true,
      initialID: initialValues?.condition,
      type: "selection",
      half: additional !== "self-listing",
    },
    {
      name: "color",
      placeholder: "Choose item color",
      label: "Colour",
      required: true,
      initialID: initialValues?.color,
      type: "selection",
      half: additional !== "self-listing",
    },
    {
      name: "leather",
      placeholder: "Input item leather",
      label: "Leather",
      required: true,
      initialValue: initialValues?.leather,
      type: "text",
      half: additional !== "self-listing",
    },
    {
      name: "stamp",
      placeholder: "Input item serial number/stamp",
      label: "Stamp",
      required: true,
      initialValue: initialValues?.stamp,
      type: "text",
      half: additional !== "self-listing",
    },
    {
      name: "hardware",
      placeholder: "Input hardware type",
      label: "Hardware",
      required: true,
      initialValue: initialValues?.hardware,
      type: "text",
      half: additional !== "self-listing",
    },
    {
      name: "size",
      placeholder: "Input item size",
      label: "Size",
      required: true,
      initialValue: initialValues?.size,
      type: "text",
      half: additional !== "self-listing",
    },
    {
      name: "inclusion",
      placeholder: "Input item inclusion",
      label: "Inclusion",
      required: true,
      initialValue: initialValues?.inclusion,
      type: "text",
      half: additional !== "self-listing",
    },
    {
      name: "additional",
      placeholder: "add information about Includes or defects of your items",
      label: "Additional Information",
      required: true,
      initialValue: initialValues?.additional,
      type: "text",
    },
    {
      name: "story",
      placeholder:
        "Please tell us a little bit about yourself, your item, and why you want to sell your bags.",
      label: "Seller Story",
      required: true,
      initialValue: initialValues?.story,
      type: "long-text",
    },
  ];
  return (
    <View>
      {Boolean(additional !== "self-listing") && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <LatoBold
            style={{
              fontSize: 20,
              lineHeight: 28,
              color: colors.grey33,
            }}
          >
            {item?.designer ? "Edit Item" : "Add Item"}
          </LatoBold>
          <TouchableOpacity onPress={() => setOpenModal(false)}>
            <GrClose size={16} />
          </TouchableOpacity>
        </View>
      )}
      <Formik
        onSubmit={handleAddItem}
        validateOnChange={hasSubmitted}
        validateOnBlur={hasSubmitted}
        initialValues={initialValues}
        validate={(values) => {
          !hasSubmitted && setHasSubmitted(true);
          const errors = {};

          for (const a of formArr) {
            const { name, required, label, type } = a;

            if (
              (required && !values[name] && type !== "selection") ||
              (required && !values[name]?.name && type === "selection")
            ) {
              errors[name] = `${sentenceCase(label)} can't be blank`;
            }
          }
          return errors;
        }}
      >
        {({
          values,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          resetForm,
        }) => (
          <View
            style={{
              flexDirection: additional === "self-listing" ? "column" : "row",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {/* {Boolean(additional === "assisted-listing") && (
              <AddItemPhotos
                photos={photos || []}
                handleAddItem={(val) => {
                  setFieldValue("photos", val);
                }}
                noButton
              />
            )} */}
            {formArr.map(
              (
                {
                  name,
                  placeholder,
                  required,
                  hide,
                  initialValue,
                  disabled,
                  type,
                  label,
                  initialID,
                  half,
                },
                index
              ) => {
                const startHalfIndex = formArr.findIndex(({ half }) =>
                  Boolean(half)
                );

                return (
                  <View
                    style={{
                      width:
                        additional === "self-listing"
                          ? "100%"
                          : half
                          ? (isPhone ? width - 32 : 500) / 2 - 32
                          : (isPhone ? width - 32 : 500) - 48,
                      marginRight:
                        half && (index + 1 - startHalfIndex) % 2 !== 0 ? 6 : 0,
                      // alignSelf: "center",
                    }}
                    key={index}
                  >
                    {type === "selection" ? (
                      <SelectionDropDown
                        label={label}
                        placeholder={placeholder}
                        required={required}
                        values={values}
                        name={name}
                        setValue={setFieldValue}
                        initialID={initialID}
                        error={errors[name]}
                      />
                    ) : (
                      <MyInput
                        error={errors[name]}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name={name}
                        label={label}
                        placeholder={placeholder}
                        required={required}
                        hide={hide}
                        initialValue={initialValue}
                        customWidth={
                          additional === "self-listing"
                            ? "100%"
                            : half
                            ? (isPhone ? width - 32 : 500) / 2 - 32
                            : (isPhone ? width - 32 : 500) - 48
                        }
                        disabled={disabled}
                        multiline={type === "long-text"}
                        bordered={type === "long-text"}
                        inline={type === "long-text"}
                      />
                    )}
                  </View>
                );
              }
            )}
            <View
              style={{
                flexDirection: isPhone ? "column" : "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {Boolean(additional === "self-listing") && (
                <Buttons
                  label={"Cancel"}
                  // loading={loading}
                  noBorder
                  onPress={() =>
                    navigate("/account/seller-dashboard", {
                      state: { idx: 0, index: 2 },
                    })
                  }
                  disabled={
                    // errors.designer ||
                    // errors.name ||
                    // errors.condition ||
                    // errors.story ||
                    loading
                  }
                  width={100}
                  containerStyle={{ alignItems: "flex-start" }}
                />
              )}
              <Buttons
                label={item?.designer ? "Edit Item" : "Add Item"}
                loading={loading}
                isBlack
                onPress={handleSubmit}
                disabled={
                  errors.designer ||
                  errors.name ||
                  errors.condition ||
                  errors.story ||
                  loading ||
                  additionalRequirement
                }
                width={
                  additional === "self-listing"
                    ? 250
                    : (isPhone ? width - 32 : 500) - 48
                }
              />
            </View>
          </View>
        )}
      </Formik>
    </View>
  );
}

export default AddListingItem;
