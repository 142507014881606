import React from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { useNavigate } from "react-router";
import { getDeviceType } from "../../tools/Interface";

function TopComponent({ menuArr = [], cat = "" }) {
  const navigate = useNavigate();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;

  return (
    <View style={{ paddingHorizontal: gap }}>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingVertical: isPhone ? 16 : 100,
        }}
      >
        <LatoRegular
          style={{
            fontSize: isPhone ? 24 : isTablet ? 48 : 60,
            lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
            color: colors.black00,
            marginBottom: 16,
          }}
        >
          SELL WITH US
        </LatoRegular>
        <LatoRegular
          style={{
            fontSize: isPhone ? 12 : 24,
            lineHeight: isPhone ? 16 : 32,
            textAlign: "center",
            color: colors.grey6C,
          }}
        >
          Sell your pre-loved items to make some extra money or to declutter
          your closet
        </LatoRegular>
      </View>
      <View
        style={{
          marginBottom: isPhone ? 48 : 100,
          padding: 10,
          borderColor: colors.greyE6,
          borderWidth: 1,
          borderRadius: 4,
          flexDirection: "row",
          width: isPhone ? 344 : 600,
          justifyContent: "center",
          alignSelf: "center",
        }}
      >
        {menuArr.map(({ title, category }, idx) => (
          <TouchableOpacity
            key={idx}
            onPress={() => navigate(`/sell-with-us/${category}`)}
            style={{
              backgroundColor:
                category === cat ? colors.grey33 : colors.whiteFF,
              paddingHorizontal: 16,
              paddingVertical: 12,
              borderRadius: category === cat ? 4 : 0,
              marginRight: idx === 2 ? 0 : 24,
            }}
          >
            <LatoBold
              style={{
                fontSize: isPhone ? 10 : 24,
                lineHeight: isPhone ? 12 : 32,
                color: category === cat ? colors.whiteFF : colors.grey90,
              }}
            >
              {title}
            </LatoBold>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
}

export default TopComponent;
