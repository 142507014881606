import React, { useContext, useEffect, useState } from "react";
import {
  View,
  useWindowDimensions,
  TouchableOpacity,
  FlatList,
} from "react-native";
import { LatoBold, LatoRegular } from "./Text";
import { getDeviceType } from "../tools/Interface";
import ToasterContext from "../contexts/ToastContext";
import { api } from "../utils/api";
import RewardBadge from "./RewardBadge";
import { formatCurrency } from "../tools/formatter";
import colors from "../assets/colors/colors";
import { HiCheckCircle } from "react-icons/hi2";
import { FaChevronRight } from "react-icons/fa";
import { useNavigate } from "react-router";

function BenefitRewardTable({ tier = {}, isVIP = false }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const { showToast } = useContext(ToasterContext);

  const [spendLimit, setSpendLimit] = useState([]);
  const [selected, setSelected] = useState(isVIP ? "togo" : "");

  const {
    birthday_cake,
    broadcast_list,
    dinner,
    discount_bnk_acc,
    discount_bnk_luxury,
    name,
    minimum_spend,
    prestige_motorcars,
    private_event,
    private_viewing,
    referral_cashback,
    seasonal_hampers,
    staycation,
  } = tier || {};

  const getTiers = async () => {
    try {
      const res = await api.get("/api/tiers");

      setSpendLimit(res.data.data || []);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    getTiers();
  }, []);

  const listArr = [
    {
      param: {
        title: name ? "Benefit List" : "Compare VIP tiers",
        subtitle: "Benefits you receive",
      },
      togo: {
        title: <RewardBadge tier={"togo"} style={{ marginBottom: 16 }} />,
        subtitle: `Spend IDR ${formatCurrency(
          minimum_spend || spendLimit[0]?.minimum_spend,
          true,
          true
        )}`,
      },
      ostrich: {
        title: <RewardBadge tier={"ostrich"} style={{ marginBottom: 16 }} />,
        subtitle: `Spend IDR ${formatCurrency(
          minimum_spend || spendLimit[1]?.minimum_spend,
          true,
          true
        )}`,
      },
      croco: {
        title: <RewardBadge tier={"croco"} style={{ marginBottom: 16 }} />,
        subtitle: `Spend IDR ${formatCurrency(
          minimum_spend || spendLimit[2]?.minimum_spend,
          true,
          true
        )}`,
      },
    },
    {
      param: "Birthday cake and flower",
      togo: birthday_cake || spendLimit[0]?.birthday_cake,
      ostrich: birthday_cake || spendLimit[1]?.birthday_cake,
      croco: birthday_cake || spendLimit[2]?.birthday_cake,
    },
    {
      param: "Free access to private viewing",
      togo: private_viewing || spendLimit[0]?.private_viewing,
      ostrich: private_viewing || spendLimit[1]?.private_viewing,
      croco: private_viewing || spendLimit[2]?.private_viewing,
    },
    {
      param: "BNK Hermes broadcast list",
      togo: broadcast_list || spendLimit[0]?.broadcast_list,
      ostrich: broadcast_list || spendLimit[0]?.broadcast_list,
      croco: broadcast_list || spendLimit[0]?.broadcast_list,
    },
    {
      param: "Invitation to private events",
      togo: private_event || spendLimit[0]?.private_event,
      ostrich: private_event || spendLimit[1]?.private_event,
      croco: private_event || spendLimit[2]?.private_event,
    },
    {
      param: "Exclusive seasonal hampers",
      togo: seasonal_hampers || spendLimit[0]?.seasonal_hampers,
      ostrich: seasonal_hampers || spendLimit[1]?.seasonal_hampers,
      croco: seasonal_hampers || spendLimit[2]?.seasonal_hampers,
    },
    {
      param: "Friend referral cashback benefits",
      togo: `IDR ${formatCurrency(
        referral_cashback || spendLimit[0]?.referral_cashback
      )}`,
      ostrich: `IDR ${formatCurrency(
        referral_cashback || spendLimit[1]?.referral_cashback
      )}`,
      croco: `IDR ${formatCurrency(
        referral_cashback || spendLimit[2]?.referral_cashback
      )}`,
    },
    {
      param: "5% OFF all items at BNK Luxury",
      togo: discount_bnk_luxury || spendLimit[0]?.discount_bnk_luxury,
      ostrich: discount_bnk_luxury || spendLimit[1]?.discount_bnk_luxury,
      croco: discount_bnk_luxury || spendLimit[2]?.discount_bnk_luxury,
    },
    {
      param: "5% OFF all items at BNK acc",
      togo: discount_bnk_acc || spendLimit[0]?.discount_bnk_acc,
      ostrich: discount_bnk_acc || spendLimit[1]?.discount_bnk_acc,
      croco: discount_bnk_acc || spendLimit[2]?.discount_bnk_acc,
    },
    {
      param: "A night staycation at 5* Hotel",
      togo: staycation || spendLimit[0]?.staycation,
      ostrich: staycation || spendLimit[1]?.staycation,
      croco: staycation || spendLimit[2]?.staycation,
    },
    {
      param: "Exclusive private dinner at 5* Hotel/ Restaurants",
      togo: dinner || spendLimit[0]?.dinner,
      ostrich: dinner || spendLimit[1]?.dinner,
      croco: dinner || spendLimit[2]?.dinner,
    },
    {
      param: "Pickup/ Drop off service by Prestige Motorcars",
      togo: prestige_motorcars || spendLimit[0]?.prestige_motorcars,
      ostrich: prestige_motorcars || spendLimit[1]?.prestige_motorcars,
      croco: prestige_motorcars || spendLimit[2]?.prestige_motorcars,
    },
  ];

  const shownArr =
    name || (selected && isPhone)
      ? listArr.filter(({ togo, ostrich, croco }) => {
          const indicator = name ? name : selected;
          if (indicator === "regular" || indicator === "togo")
            return Boolean(togo);
          else if (indicator === "ostrich") return Boolean(ostrich);
          else if (indicator === "croco") return Boolean(croco);
        })
      : listArr;

  return (
    <View
      style={{
        paddingBottom: 48,
        marginBottom: isPhone ? 80 : 100,
        borderBottomColor: colors.greyE6,
        borderBottomWidth: name ? 1 : 0,
        width: name
          ? isPhone
            ? width - 32
            : isTablet
            ? width - 300
            : width - 600
          : containerWidth,
        marginHorizontal: name ? 0 : gap,
      }}
    >
      {Boolean(!name && !isPhone) && (
        <View
          style={{
            paddingVertical: 100,
            justifyContent: "center",
            alignItems: "center",
            marginLeft: -gap,
            paddingLeft: gap,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.grey6C,
              marginBottom: 12,
              backgroundColor: colors.whiteFF,
              paddingHorizontal: isPhone ? 8 : 56,
            }}
          >
            REWARDS TIERS
          </LatoRegular>
          <View
            style={{
              position: "absolute",
              zIndex: -1,
              top: isPhone ? 112 : 116,
              width,
              height: 1,
              backgroundColor: colors.greyE6,
              left: 0,
            }}
          />
        </View>
      )}
      {Boolean(isPhone && !name) && (
        <FlatList
          data={spendLimit}
          style={{
            borderBottomColor: colors.greyBB,
            borderBottomWidth: 1,
            marginBottom: 32,
          }}
          horizontal
          showsHorizontalScrollIndicator={false}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => {
            const { name, minimum_spend } = item;

            return (
              <TouchableOpacity
                onPress={() => setSelected(name)}
                style={{
                  padding: 8,
                  marginRight: index === spendLimit.length - 1 ? 0 : 8,
                  opacity: selected === name ? 1 : 0.6,
                  borderBottomColor: colors.black00,
                  borderBottomWidth: selected === name ? 2 : 0,
                }}
              >
                <RewardBadge
                  tier={name}
                  style={{ marginBottom: 16 }}
                  selected={selected === name}
                  noBorder
                />
                <LatoRegular
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: selected === name ? colors.grey6C : colors.grey90,
                    marginTop: 8,
                  }}
                >{`Spend IDR ${formatCurrency(
                  minimum_spend,
                  true,
                  true
                )}`}</LatoRegular>
              </TouchableOpacity>
            );
          }}
        />
      )}
      {shownArr.map(({ param, togo, ostrich, croco }, i) =>
        !name && isPhone && i === 0 ? null : (
          <View
            key={i}
            style={{
              flexDirection: isPhone && !name ? "column" : "row",
              paddingTop: isPhone && !name ? 8 : 0,
              paddingBottom: isPhone && !name ? 8 : i === 0 ? 16 : 24,
              marginBottom: isPhone && !name ? 8 : i === 0 ? 32 : 24,
              borderBottomColor: colors.greyE6,
              borderBottomWidth: isPhone && !name ? 0 : 1,
              alignItems: "center",
            }}
          >
            <View
              style={{
                flexDirection: isPhone && !name ? "row" : "column",
                width: name
                  ? (isPhone ? width : isTablet ? width - 300 : width - 600) / 2
                  : isPhone
                  ? containerWidth
                  : containerWidth / 4,
                justifyContent:
                  isPhone && !name ? "flex-start" : "space-between",
                alignItems: isPhone && !name ? "center" : "flex-start",
                marginBottom: isPhone && !name ? 4 : 0,
              }}
            >
              {Boolean(isPhone && !name) && (
                <HiCheckCircle
                  size={24}
                  color={
                    selected === "togo"
                      ? colors.grey6C
                      : selected === "ostrich"
                      ? colors.green8D
                      : colors.yellowCC
                  }
                  style={{ marginRight: 8 }}
                />
              )}
              <LatoBold
                style={{
                  fontSize: typeof param === "object" ? 20 : 16,
                  lineHeight: typeof param === "object" ? 28 : 24,
                  color: colors.grey33,
                  fontFamily:
                    typeof togo === "object" ? "lato-bold" : "lato-regular",
                  marginBottom: typeof togo === "object" ? 16 : 0,
                }}
              >
                {typeof param === "object" ? param.title : param}
              </LatoBold>
              {typeof param === "object" && (
                <LatoRegular
                  style={{ fontSize: 16, lineHeight: 24, color: colors.grey33 }}
                >
                  {param.subtitle}
                </LatoRegular>
              )}
            </View>
            {Boolean(
              (!name && !isPhone) ||
                selected === "togo" ||
                name === "togo" ||
                name === "regular"
            ) && (
              <View
                style={{
                  width: name
                    ? (isPhone ? width : isTablet ? width - 300 : width - 600) /
                      2
                    : isPhone
                    ? containerWidth
                    : containerWidth / 4,
                  justifyContent:
                    isPhone && !name ? "flex-start" : "space-between",
                  alignItems: isPhone && !name ? "flex-start" : "center",
                }}
              >
                {typeof togo === "object" ? (
                  togo.title
                ) : typeof togo === "boolean" ? (
                  togo && (name || (!name && !isPhone)) ? (
                    <HiCheckCircle size={24} color={colors.grey6C} />
                  ) : null
                ) : (
                  <LatoBold
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      fontFamily:
                        typeof togo === "object" ? "lato-bold" : "lato-regular",
                      marginLeft: isPhone && !name ? 32 : 0,
                    }}
                  >
                    {togo}
                  </LatoBold>
                )}
                {typeof togo === "object" && (
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      marginTop: 16,
                    }}
                  >
                    {togo.subtitle}
                  </LatoRegular>
                )}
              </View>
            )}
            {Boolean(
              (!name && !isPhone) ||
                selected === "ostrich" ||
                name === "ostrich"
            ) && (
              <View
                style={{
                  width: name
                    ? (isPhone ? width : isTablet ? width - 300 : width - 600) /
                      2
                    : isPhone
                    ? containerWidth
                    : containerWidth / 4,
                  justifyContent:
                    isPhone && !name ? "flex-start" : "space-between",
                  alignItems: isPhone && !name ? "flex-start" : "center",
                }}
              >
                {typeof ostrich === "object" ? (
                  ostrich.title
                ) : typeof ostrich === "boolean" ? (
                  ostrich && (name || (!name && !isPhone)) ? (
                    <HiCheckCircle size={24} color={colors.green8D} />
                  ) : null
                ) : (
                  <LatoBold
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      fontFamily:
                        typeof togo === "object" ? "lato-bold" : "lato-regular",
                      marginLeft: isPhone && !name ? 32 : 0,
                    }}
                  >
                    {ostrich}
                  </LatoBold>
                )}
                {typeof ostrich === "object" && (
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      marginTop: 16,
                    }}
                  >
                    {ostrich.subtitle}
                  </LatoRegular>
                )}
              </View>
            )}
            {Boolean(
              (!name && !isPhone) || name === "croco" || selected === "croco"
            ) && (
              <View
                style={{
                  width: name
                    ? (isPhone ? width : isTablet ? width - 300 : width - 600) /
                      2
                    : isPhone
                    ? containerWidth
                    : containerWidth / 4,
                  justifyContent:
                    isPhone && !name ? "flex-start" : "space-between",
                  alignItems: isPhone && !name ? "flex-start" : "center",
                }}
              >
                {typeof croco === "object" ? (
                  croco.title
                ) : typeof croco === "boolean" ? (
                  croco && (name || (!name && !isPhone)) ? (
                    <HiCheckCircle size={24} color={colors.yellowCC} />
                  ) : null
                ) : (
                  <LatoBold
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      fontFamily:
                        typeof togo === "object" ? "lato-bold" : "lato-regular",
                      marginLeft: isPhone && !name ? 32 : 0,
                    }}
                  >
                    {croco}
                  </LatoBold>
                )}
                {typeof croco === "object" && (
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      marginTop: 16,
                    }}
                  >
                    {croco.subtitle}
                  </LatoRegular>
                )}
              </View>
            )}
          </View>
        )
      )}
      {Boolean(name) && (
        <TouchableOpacity
          activeOpacity={1}
          onPress={() => navigate("/vip")}
          style={{
            position: "absolute",
            left:
              (isPhone ? width - 32 : isTablet ? width - 300 : width - 600) /
              (isPhone ? 4.35 : 2.75),
            right: 0,
            bottom: -12,
            width: isPhone ? width - 32 : isTablet ? width - 300 : width - 600,
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <LatoBold
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: colors.grey33,
              paddingRight: 4,
              paddingLeft: 16,
              backgroundColor: colors.whiteFF,
            }}
          >
            See All Benefits
          </LatoBold>
          <FaChevronRight
            size={14}
            color={colors.grey33}
            style={{ backgroundColor: colors.whiteFF, paddingRight: 16 }}
          />
        </TouchableOpacity>
      )}
    </View>
  );
}

export default BenefitRewardTable;
