import React, { useContext, useEffect, useState } from "react";
import ToasterContext from "../contexts/ToastContext";
import { ScrollView, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../tools/Interface";
import { useLocation, useNavigate } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import DateTimePicker from "../components/DatePicker";
import AssistedTimePicker from "../components/Consignment/AssistedTimePicker";
import Buttons from "../components/Buttons";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Formik } from "formik";
import { sentenceCase } from "change-case";
import { regexChecker } from "../tools/regexChecker";
import { MyInput } from "../components/Inputs";
import { format } from "date-fns";
import { api } from "../utils/api";

function AppointmentScreen() {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [selected, setSelected] = useState();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [slot, setSlot] = useState([]);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();
  const { state } = useLocation();
  const { comes_from } = state || {};
  const { user } = useAuth();
  const { email, detail = {} } = user || {};

  const { name, phone } = detail;

  const initialValues = {
    firstname: name
      ?.split(" ")
      .splice(0, name.split(" ")?.length - 1)
      .join(" "),
    lastname: name
      ?.split(" ")
      .splice(name.split(" ")?.length - 1, 1)
      .join(" "),
    phone,
    email,
  };

  const nameFormArr = [
    {
      name: "firstname",
      placeholder: "Your First Name",
      label: "First Name",
      required: true,
      initialValue: initialValues?.firstname,
    },
    {
      name: "lastname",
      placeholder: "Your Last Name",
      label: "Last Name",
      required: true,
      initialValue: initialValues?.lastname,
    },
  ];

  const contactFormArr = [
    {
      name: "email",
      placeholder: "Your Email Address",
      label: "Email Address",
      required: true,
      initialValue: initialValues?.email,
    },
    {
      name: "phone",
      placeholder: "Your Phone Number",
      label: "Phone",
      required: true,
      initialValue: initialValues?.phone,
      prefix: "+62",
    },
  ];

  const option = [
    {
      label: "11:00 AM",
      name: "Slot-1",
      value: "first_slot",
    },
    {
      label: "12:00 PM",
      name: "Slot-2",
      value: "second_slot",
    },
    {
      label: "13:00 PM",
      name: "Slot-3",
      value: "third_slot",
    },
    {
      label: "14:00 PM",
      name: "Slot-4",
      value: "fourth_slot",
    },
    {
      label: "15:00 PM",
      name: "Slot-5",
      value: "fifth_slot",
    },
    {
      label: "16:00 PM",
      name: "Slot-6",
      value: "sixth_slot",
    },
    {
      label: "17:00 PM",
      name: "Slot-7",
      value: "seventh_slot",
    },
    {
      label: "18:00 PM",
      name: "Slot-8",
      value: "eighth_slot",
    },
  ];

  const getSlot = async () => {
    try {
      setLoading(true);
      const res = await api.get("/api/appointments/appointment_slot");
      const data = res?.data?.data;

      const activeSlot = data.filter(({ active }) => active);

      const min = activeSlot[0]?.start_time;
      const max = activeSlot[activeSlot?.length - 1]?.finish_time;

      setSlot(activeSlot);
      setMinDate(min);
      setMaxDate(max);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
      setSlot([]);
      setDate("");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSlot();
  }, []);

  const containerWidth =
    width - (isPhone ? 32 : isTablet ? 100 : width < 1350 ? 450 : 600);

  const handleChanges = async (value) => {
    try {
      setLoading(true);

      const sendToBE = {
        ...value,
        appointment_slot_id: selected,
        comes_from: comes_from || "general",
      };

      await api.post("/api/appointments", sendToBE);

      showSuccessToast(
        "Thank you! Your appointment is confirmed and an email confirmation will be sent to your inbox."
      );
      navigate("/");
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <ScrollView
        style={{ height }}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View
          style={{
            justifyContent: "center",
            marginTop: 52,
            marginHorizontal: isPhone
              ? 16
              : isTablet
              ? 50
              : width < 1350
              ? 225
              : 300,
            marginBottom: 100,
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 20 : 24,
              lineHeight: isPhone ? 26 : 32,
              color: colors.grey33,
              marginBottom: 8,
              textAlign: "left",
            }}
          >
            Book appointment
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 18,
              lineHeight: isPhone ? 24 : 26,
              color: colors.grey6C,
              marginBottom: 32,
              textAlign: "left",
            }}
          >
            Browse our luxury goods in person with the help of our dedicated
            client advisors.
          </LatoRegular>
          <Formik
            onSubmit={handleChanges}
            validateOnChange={hasSubmitted}
            validateOnBlur={hasSubmitted}
            initialValues={{
              email: initialValues?.email,
              firstname: initialValues?.firstname,
              lastname: initialValues?.lastname,
              phone: initialValues?.phone,
            }}
            validate={(values) => {
              !hasSubmitted && setHasSubmitted(true);
              const errors = {};

              const checkArr = [...nameFormArr, ...contactFormArr];

              for (const a of checkArr) {
                const { name, required, label, length, match, disabled } = a;

                if (disabled) {
                  errors[name] = "";
                } else if (required && !disabled && !values[name]) {
                  errors[name] = `${sentenceCase(label)} can't be blank`;
                } else if (!regexChecker(name, values[name])) {
                  errors[name] =
                    name === "password"
                      ? "Password must includes 1 uppercase, 1 lowercase, and 1 number"
                      : `Invalid ${label.toLowerCase()}`;
                } else if (length && values[name]?.length > length) {
                  errors[name] = `${sentenceCase(
                    name.split("_").join(" ")
                  )} limited to ${length} characters`;
                } else if (match && values[name] !== values[match]) {
                  errors[name] = `${sentenceCase(
                    match.split("_").join(" ")
                  )} doesn't match`;
                } else if (name === "phone" && !values[name].startsWith("8")) {
                  errors[name] =
                    "Please enter a valid Indonesian mobile phone number starting with 8xx";
                }
              }
              return errors;
            }}
          >
            {({ errors, handleChange, handleBlur, handleSubmit }) => (
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {nameFormArr.map(
                    (
                      {
                        name,
                        placeholder,
                        required,
                        hide,
                        initialValue,
                        disabled,
                        length,
                        label,
                      },
                      index
                    ) => {
                      return (
                        <View key={index}>
                          <MyInput
                            error={errors[name]}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            required={required}
                            hide={hide}
                            initialValue={initialValue}
                            customWidth={(containerWidth - 32) / 2}
                            disabled={disabled}
                            length={length}
                          />
                        </View>
                      );
                    }
                  )}
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  {contactFormArr.map(
                    (
                      {
                        name,
                        placeholder,
                        required,
                        hide,
                        initialValue,
                        disabled,
                        length,
                        label,
                        prefix,
                      },
                      index
                    ) => {
                      return (
                        <View key={index}>
                          <MyInput
                            error={errors[name]}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            required={required}
                            hide={hide}
                            initialValue={initialValue}
                            customWidth={(containerWidth - 32) / 2}
                            disabled={disabled}
                            length={length}
                            prefix={prefix}
                          />
                        </View>
                      );
                    }
                  )}
                </View>
                <DateTimePicker
                  placeholder={"Preferred date to pickup"}
                  label={"Pickup Date"}
                  onChange={setDate}
                  date={date}
                  required
                  customWidth={containerWidth}
                  min={minDate}
                  max={maxDate}
                />
                <AssistedTimePicker
                  isPhone={isPhone}
                  selected={selected}
                  setSelected={setSelected}
                  date={date}
                  slot={slot}
                  customOption={option}
                  customWidth={containerWidth}
                />
                <MyInput
                  error={errors["purpose_of_appointment"]}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  name={"purpose_of_appointment"}
                  label={"Purpose of appointment"}
                  placeholder={
                    "Please tell us the purpose of your visit (To shop/ to sell with us) for viewing purpose, which products are you interested in"
                  }
                  required
                  customWidth={containerWidth}
                  multiline
                  bordered
                  inline
                />
                <View
                  style={{
                    flexDirection: isPhone ? "column-reverse" : "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: containerWidth,
                  }}
                >
                  <Buttons
                    onPress={() => {
                      navigate("/account/seller-dashboard", {
                        state: { idx: 0, index: 2 },
                      });
                    }}
                    label={"Cancel"}
                    noBorder
                    textStyle={{ fontFamily: "lato-regular" }}
                    containerStyle={{ marginTop: isPhone ? 20 : 0 }}
                    width={isPhone ? containerWidth : 45}
                  />
                  <Buttons
                    onPress={handleSubmit}
                    loading={loading}
                    disabled={
                      // !selected ||
                      !date ||
                      loading ||
                      errors.email ||
                      errors.firstname ||
                      errors.lastname ||
                      errors.phone ||
                      errors.purpose
                    }
                    label="Submit"
                    isBlack
                    width={isPhone ? containerWidth : 250}
                  />
                </View>
              </View>
            )}
          </Formik>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default AppointmentScreen;
