import React from "react";
import {
  Image,
  TouchableOpacity,
  Easing,
  View,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import { useLocation, useParams } from "react-router";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import { descriptionDecider } from "../../tools/decider";
import { AiOutlineArrowDown } from "react-icons/ai";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";

function CatalogHeader({
  total = 0,
  setShownSpecialFilter = () => {},
  setShownFilter = () => {},
  shownFilter = false,
  shownSpecialFilter = false,
  specialFilter = {},
  animate = () => {},
  selected = [],
}) {
  const { state } = useLocation();
  const { id } = useParams();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View>
      <View
        style={{
          alignItems: "center",
          marginTop: 48,
          borderBottomColor: colors.greyE6,
          borderBottomWidth: Boolean(
            state?.querry || (id && state?.menu[0]?.section === "Category")
          )
            ? 1
            : 0,
          marginHorizontal: gap,
          marginBottom: 28,
        }}
      >
        {Boolean(
          state?.querry || (id && state?.menu[0]?.section === "Category") || !id
        ) && (
          <LatoBold
            style={{
              fontSize: 24,
              lineHeight: 32,
              textTransform: "uppercase",
              marginBottom: 16,
            }}
          >
            {Boolean(
              state?.querry || (id && state?.menu[0]?.section === "Category")
            )
              ? `"${state?.querry || state?.designer_page?.title}"`
              : "Shop Now"}
          </LatoBold>
        )}
      </View>
      {Boolean(id && state?.menu[0]?.section === "Designer") && (
        <View
          style={{
            marginBottom: isPhone ? 0 : 48,
            marginHorizontal: gap,
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: colors.black00,
          }}
        >
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
            }}
          >
            <Image
              source={state?.designer_page?.image}
              style={{
                height: ((containerWidth / 2) * 475) / 730,
                width: isPhone ? containerWidth : containerWidth / 2,
              }}
              resizeMode={"cover"}
            />
          </View>
          {Boolean(isPhone) ? (
            <View
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                // paddingBottom: 54,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: colors.transparentBlack00,
                transition: "0.5s",
              }}
            >
              <LatoRegular
                style={{
                  color: colors.whiteFF,
                  fontSize: 36,
                  lineHeight: 48,
                  textShadowColor: colors.transparentBlack00,
                  textShadowOffset: { width: 1, height: 1 },
                  textShadowRadius: 1,
                  textTransform: "uppercase",
                }}
              >
                {state?.designer_page?.title || ""}
              </LatoRegular>
            </View>
          ) : (
            <View
              style={{
                width: containerWidth / 2,
                paddingHorizontal: isTablet ? 16 : 60,
              }}
            >
              <LatoRegular
                style={{
                  color: colors.whiteFF,
                  fontSize: isTablet ? 24 : 32,
                  lineHeight: isTablet ? 32 : 40,
                  marginBottom: isTablet ? 32 : 64,
                  textAlign: "center",
                  textTransform: "uppercase",
                }}
              >
                {state?.designer_page?.title || ""}
              </LatoRegular>
              {Boolean(!isPhone) && (
                <LatoRegular
                  style={{
                    color: colors.whiteFF,
                    fontSize: isTablet ? 14 : 16,
                    lineHeight: isTablet ? 20 : 24,
                    textAlign: "center",
                    alignSelf: "center",
                  }}
                >
                  {descriptionDecider(state?.designer_page?.title) || ""}
                </LatoRegular>
              )}
            </View>
          )}
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingVertical: 12,
          paddingHorizontal: gap,
          width,
        }}
      >
        {Boolean(!isPhone) && <LatoRegular>{`${total} PRODUCTS`}</LatoRegular>}
        <View
          style={{
            flexDirection: "row",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "space-between" : "flex-end",
            width: isPhone ? containerWidth : "auto",
          }}
        >
          {/* {Boolean(!id && !state?.querry) && ( */}
          <TouchableOpacity
            onPress={() => {
              setShownFilter(!shownFilter);
              animate(Easing.ease);
            }}
            style={{
              paddingVertical: selected.length ? 5 : 8.5,
              paddingHorizontal: 20,
              borderWidth: 1,
              borderColor: colors.grey90,
              borderRadius: 4,
              marginRight: 16,
            }}
          >
            <View
              style={{
                flexDirection:
                  isPhone || selected.length ? "row" : "row-reverse",
                alignItems: "center",
              }}
            >
              {Boolean(!selected.length) ? (
                <HiOutlineAdjustmentsHorizontal size={16} />
              ) : (
                Boolean(isPhone && selected.length) && (
                  <View
                    style={{
                      borderRadius: 1000,
                      padding: 4,
                      width: 24,
                      height: 24,
                      backgroundColor: colors.grey33,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        color: colors.whiteFF,
                      }}
                    >
                      {selected.length}
                    </LatoRegular>
                  </View>
                )
              )}
              <LatoRegular
                style={{
                  fontSize: 12,
                  lineHeight: 16,
                  marginLeft: !isPhone ? 0 : 8,
                  marginRight: !isPhone && !selected.length ? 8 : 0,
                }}
              >
                {!isPhone && selected.length ? "FILTER APPLIED" : "FILTER"}
              </LatoRegular>
              {Boolean(!isPhone && selected.length) && (
                <View
                  style={{
                    borderRadius: 1000,
                    padding: 4,
                    width: 24,
                    height: 24,
                    backgroundColor: colors.grey33,
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 8,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: colors.whiteFF,
                    }}
                  >
                    {selected.length}
                  </LatoRegular>
                </View>
              )}
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setShownSpecialFilter(!shownSpecialFilter)}
            style={{
              paddingVertical: 8.5,
              paddingHorizontal: 20,
              borderWidth: 1,
              borderColor: colors.grey90,
              borderRadius: 4,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <LatoRegular
                style={{
                  marginRight: 6,
                  fontSize: 12,
                  lineHeight: 16,
                  textTransform: "uppercase",
                }}
              >
                {specialFilter.title}
              </LatoRegular>
              <AiOutlineArrowDown width={14} height={14} />
            </View>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
}

export default CatalogHeader;
