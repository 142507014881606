import React, { useContext, useEffect, useRef, useState } from "react";
import { View, ScrollView, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import Navbar from "../components/Navbar";
import { LatoBold, LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import { LuPackageSearch } from "react-icons/lu";
import { useLocation, useParams } from "react-router-dom";
import { api } from "../utils/api";
import { useAuth } from "../contexts/AuthContext";
import Footer from "../components/Footer";
import ToasterContext from "../contexts/ToastContext";
import ProductGrid from "../components/Home/ProductGrid";
import LeftDetail from "../components/Catalog/LeftDetail";
import RightDetail from "../components/Catalog/RightDetail";
import { createVideoThumbnail } from "../tools/formatter";
import Modal from "../components/Modal";
import ConditionModal from "../components/Catalog/ConditionModal";
import VideoModal from "../components/VideoModal";
import NotifyModal from "../components/Catalog/NotifyModal";

export default function ProductDetailScreen() {
  const [productData, setProductData] = useState({});
  const [productArr, setProductArr] = useState([]);
  const [inCart, setInCart] = useState(false);
  const [inWishlist, setInWishlist] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [openCart, setOpenCart] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [openConditionModal, setOpenConditionModal] = useState(false);
  const [videoAttr, setVideoAttr] = useState({});
  const [disableScroll, setDisableScroll] = useState(false);
  const [notify, setNotify] = useState(false);

  const { id: item_id } = useParams();
  const { state } = useLocation();
  const { index: sourceIndex } = state || {};
  const { showToast } = useContext(ToasterContext);
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const { user } = useAuth();

  const scrollRef = useRef();

  const getProductData = async () => {
    try {
      const res = await api.get(
        `/api/inventories/v2?page=${Math.ceil(sourceIndex / 20)}&per_page=20`
      );
      const data = res.data.data;

      const index = data.findIndex(({ id }) => Number(id) === Number(item_id));

      const shown =
        index > 0 && index < (isTablet ? 7 : 9)
          ? [
              ...data.slice(0, index),
              ...data.slice(index + 1, isTablet ? 7 : 9),
            ]
          : data.slice(index + 1, isTablet ? 7 : 9);

      setProductArr(shown);
    } catch (err) {
      console.log("err:", err.response);
      showToast(err);
    }
  };

  const getData = async () => {
    try {
      setLoadingData(true);
      const res = await api.get(`/api/inventories/${item_id}`);
      const data = res.data.data;

      const photosArr = data?.product?.summary_detail?.direct_photos?.filter(
        (item) => {
          if (item !== null) {
            return item;
          }
          return null;
        }
      );

      if (data?.product?.formatted_video_links?.length) {
        const thumbnailArr = data?.product?.formatted_video_links?.map((itm) =>
          createVideoThumbnail(itm)
        );
        setPhotos([...thumbnailArr, ...photosArr]);
      } else {
        setPhotos(photosArr);
      }

      setProductData(data);

      if (user?.id) {
        const res2 = await api.get("api/carts");
        const products = res2?.data?.data?.products || [];
        setInCart(
          products.find(
            ({ id: productID }) =>
              Number(data?.product_id) === Number(productID)
          )
        );

        const res3 = await api.get("/api/wishlist_items");
        const wishlist = res3?.data?.data || [];

        setInWishlist(
          wishlist.find(
            ({ product_id }) => Number(data?.product_id) === Number(product_id)
          )
        );
      }
    } catch (err) {
      console.log("err:", err.response);
      setNotFound(true);
      showToast(err);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getData();
    getProductData();
  }, [item_id, notify]);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;

  useEffect(() => {
    scrollRef?.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [item_id]);

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        scrollEnabled={!disableScroll}
        showsVerticalScrollIndicator={false}
        style={{ height }}
      >
        <Navbar
          backgroundColor="white"
          customOpenCart={openCart}
          setCustomOpenCart={setOpenCart}
          getData={getData}
        />
        {notFound ? (
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              height: isPhone ? 0.5 * height : 0.75 * height,
            }}
          >
            <LuPackageSearch size={isPhone ? 75 : 100} color={colors.redAD} />
            <LatoBold
              style={{
                paddingHorizontal: isPhone ? 0 : 60,
                marginVertical: 40,
                fontSize: isPhone ? 16 : 22,
                lineHeight: isPhone ? 24 : 36,
                textAlign: "center",
                width: isPhone
                  ? width - 32
                  : isTablet
                  ? 0.85 * width
                  : 0.65 * width,
              }}
            >
              Oops! The item you seek has gone astray. But fear not! Our
              recommendations are a treasure trove of fabulous options that will
              surely pique your curiosity.
            </LatoBold>
          </View>
        ) : (
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              alignItems: "flex-start",
              justifyContent: "space-between",
              paddingHorizontal: gap,
              paddingTop: 60,
            }}
          >
            <LeftDetail
              photos={photos}
              productData={productData}
              setOpenVideo={setOpenVideo}
              openVideo={openVideo}
              videoAttr={videoAttr}
              setVideoAttr={setVideoAttr}
              setDisableScroll={setDisableScroll}
            />
            <RightDetail
              loadingData={loadingData}
              productData={productData}
              getData={getData}
              inCart={inCart}
              inWishlist={inWishlist}
              setOpenCart={setOpenCart}
              setOpenConditionModal={setOpenConditionModal}
              setNotify={setNotify}
            />
          </View>
        )}
        <View style={{ marginVertical: 100 }}>
          <LatoRegular
            style={{
              paddingHorizontal: gap,
              marginBottom: 40,
              fontSize: 22,
              lineHeight: 30,
              textAlign: isPhone ? "center" : "left",
            }}
          >
            BROWSE SIMILAR ITEMS
          </LatoRegular>
          <ProductGrid data={productArr} isDetail wrap noButton />
        </View>
        <Footer />
      </ScrollView>
      {Boolean(openVideo) && (
        <Modal
          noBackground
          width={isPhone ? width - 40 : (isTablet ? 0.75 : 0.5) * width}
        >
          <VideoModal
            setOpenVideo={setOpenVideo}
            setVideoAttr={setVideoAttr}
            videoAttr={videoAttr}
          />
        </Modal>
      )}
      {Boolean(openConditionModal) && (
        <Modal>
          <ConditionModal setOpenConditionModal={setOpenConditionModal} />
        </Modal>
      )}
      {Boolean(notify) && (
        <NotifyModal
          productData={productData}
          photos={photos}
          setNotify={setNotify}
        />
      )}
    </View>
  );
}
