import React from "react";
import { View, Image, useWindowDimensions } from "react-native";
import colors from "../../assets/colors/colors";
import { LatoBold, LatoRegular } from "../Text";
import { customDateFormatter, formatCurrency } from "../../tools/formatter";
import Buttons from "../Buttons";
import {
  consignmentStateColorDecider,
  consignmentStateDecider,
  consignmentStatusDecider,
} from "../../tools/decider";
import { getDeviceType } from "../../tools/Interface";
import Withdraw from "../../assets/images/Withdraw.png";
import { useNavigate } from "react-router";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { HiCheckCircle } from "react-icons/hi2";
import { AiOutlineClockCircle } from "react-icons/ai";
import { MdCancel } from "react-icons/md";

function ConsignmentItemCard({
  item = {},
  setOpenModal = () => {},
  setSelected = () => {},
  setOpenCashout = () => {},
  index,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const { state_timestamps, state, updated_at } = item;

  const soldAt =
    (state_timestamps.filter(
      ({ state }) => state === "waiting_for_disbursement"
    ) &&
      state_timestamps.filter(
        ({ state }) => state === "waiting_for_disbursement"
      )[0]?.timestamp) ||
    (state_timestamps.filter(
      ({ state }) => state === "completed_without_deal"
    ) &&
      state_timestamps.filter(
        ({ state }) => state === "completed_without_deal"
      )[0]?.timestamp);
  const liveAt =
    state_timestamps.filter(({ state }) => state === "on_sale") &&
    state_timestamps.filter(({ state }) => state === "on_sale")[0]?.timestamp;
  const failedAt =
    (state_timestamps.filter(({ state }) => state === "failed") &&
      state_timestamps.filter(({ state }) => state === "failed")[0]
        ?.timestamp) ||
    (state === "failed" && updated_at);
  const cancelledAt =
    (state_timestamps.filter(({ state }) => state === "cancelled") &&
      state_timestamps.filter(({ state }) => state === "cancelled")[0]
        ?.timestamp) ||
    (state === "cancelled" && updated_at);

  return (
    <View
      style={{
        borderWidth: 1,
        borderColor: colors.greyBB,
        borderRadius: 4,
        marginBottom: 12,
      }}
    >
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.greyBB,
          paddingHorizontal: 32,
          paddingVertical: 12,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {soldAt ? (
            <BsArrowUpRightCircle size={20} color={colors.green06} />
          ) : liveAt ? (
            <HiCheckCircle size={20} color={colors.green06} />
          ) : failedAt || cancelledAt ? (
            <MdCancel size={20} color={colors.redAD} />
          ) : (
            <AiOutlineClockCircle size={20} color={colors.grey33} />
          )}
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
              marginLeft: 16,
            }}
          >
            {`${
              soldAt
                ? "SOLD DATE"
                : liveAt
                ? "ACTIVE DATE"
                : failedAt
                ? "REJECTED"
                : cancelledAt
                ? "CANCELLED"
                : "CREATED DATE"
            } : ${customDateFormatter(
              soldAt || liveAt || item?.created_at,
              true
            )}`}
          </LatoBold>
        </View>
        <View
          style={{
            flexDirection: "row",
            borderWidth: 1,
            borderColor: colors.greyE6,
            borderRadius: 4,
            paddingHorizontal: 8,
          }}
        >
          <View
            style={{
              backgroundColor: consignmentStateColorDecider(item?.state),
              width: 8,
              height: 8,
              borderRadius: "100%",
              marginRight: 6,
              marginVertical: 6.5,
            }}
          />
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
            }}
          >
            {consignmentStateDecider(
              item?.disbursement_state !== "created"
                ? item?.disbursement_state
                : item?.state,
              item?.appraisal?.state,
              item?.appraisal?.appraisal_histories[
                item?.appraisal?.appraisal_histories.length - 1
              ]?.submitted_by
            )}
          </LatoRegular>
        </View>
      </View>
      <View style={{ paddingHorizontal: 32, paddingVertical: 24 }}>
        <View
          style={{
            flexDirection: "row",
            // justifyContent: "space-between",
            marginBottom: 32,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              width: isPhone
                ? width < 750
                  ? width - 150
                  : width - 300
                : isTablet
                ? width > 900
                  ? width - 500
                  : width - 350
                : width - 725,
              marginRight: 12,
            }}
          >
            <View style={{ width: isTablet ? "30%" : "45%" }}>
              <View style={{ flexDirection: "row" }}>
                <View
                  style={{
                    borderWidth: 1,
                    padding: 8,
                    borderRadius: 8,
                    justifyContent: "center",
                    borderColor: colors.greyE6,
                    marginRight: 16,
                    height: isTablet ? 64 : 80,
                  }}
                >
                  <Image
                    source={{
                      uri: `${item?.product?.summary_index?.direct_photos[0]}`,
                    }}
                    alt={item?.product?.name}
                    style={{
                      alignSelf: "center",
                      width: isTablet ? 48 : 64,
                      height: isTablet ? 48 : 64,
                    }}
                    defaultSource={require("../../assets/images/spinning-loading.gif")}
                    resizeMode={"contain"}
                  />
                </View>
                <View style={{ flexWrap: "wrap", width: "50%" }}>
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 8,
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.product?.summary_index?.designer || ""}
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    {item?.product?.name || ""}
                  </LatoRegular>
                </View>
              </View>
            </View>
            <View style={{ width: "25%" }}>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginBottom: 10,
                }}
              >
                {item?.state === "waiting_for_appraisal" ||
                item?.state === "appraisal_negotiation" ||
                item?.state === "failed"
                  ? "OFFERED PRICE"
                  : "ACCEPTED PRICE"}
              </LatoBold>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                }}
              >
                {item?.appraisal?.latest_price !== "0.0"
                  ? `IDR ${formatCurrency(item?.appraisal?.latest_price)}`
                  : "-"}
              </LatoBold>
            </View>
            <View style={{ width: "30%" }}>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginBottom: 10,
                }}
              >
                STATUS
              </LatoBold>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                }}
              >
                {consignmentStatusDecider(
                  item?.disbursement_state !== "created"
                    ? item?.disbursement_state
                    : item?.state,
                  item?.appraisal?.state,
                  item?.appraisal?.appraisal_histories[
                    item?.appraisal?.appraisal_histories.length - 1
                  ]?.submitted_by,
                  item?.appraisal?.appraisal_histories[
                    item?.appraisal?.appraisal_histories.length - 2
                  ]?.state
                )}
              </LatoRegular>
            </View>
          </View>
          {Boolean(width > 900 || (isPhone && width > 750)) && (
            <View style={{ width: 225 }}>
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginBottom: 10,
                }}
              >
                SELLER STORY
              </LatoBold>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginBottom: 4,
                }}
                numberOfLines={4}
              >
                {item?.seller_story || "-"}
              </LatoRegular>
            </View>
          )}
        </View>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          {Boolean(
            item?.state === "completed" &&
              item?.disbursement_state === "created"
          ) && (
            <Buttons
              onPress={() => {
                setSelected(item?.id);
                setOpenCashout(true);
              }}
              label={"Cashout"}
              width={120}
              icon={
                <Image
                  source={Withdraw}
                  style={{ width: 20, height: 20, marginRight: 8 }}
                />
              }
              iconStyle="center"
              containerStyle={{ marginRight: 12 }}
            />
          )}
          {Boolean(item?.state !== "waiting_for_appraisal") && (
            <Buttons
              onPress={() => {
                if (item?.state === "on_sale")
                  return navigate(
                    `/products/${item?.product?.summary_index?.inventory_id}`,
                    { state: { item: item?.product, consignment: true } }
                  );
                setSelected(item?.id);
                setOpenModal(true);
              }}
              // loading={loadingCancel}
              // disabled={loadingCancel}
              label={
                item?.state === "on_sale" ||
                item?.state === "waiting_for_disbursement" ||
                item?.state === "completed" ||
                item?.state === "completed_without_deal"
                  ? "See Details"
                  : "Review Offer"
              }
              width={130}
              isBlack
              containerStyle={{ marginRight: 16 }}
            />
          )}
        </View>
      </View>
    </View>
  );
}

export default ConsignmentItemCard;
