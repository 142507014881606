import React from "react";
import { SlDiamond } from "react-icons/sl";
import { Image, View, Linking, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import FormIcon from "../../assets/images/form-icon.svg";
import Authentication from "../../assets/images/authentication-icon.svg";
import Rate from "../../assets/images/rate-icon.svg";
import Appraisal from "../../assets/images/appraisal-icon.svg";
import { TbDiscount2 } from "react-icons/tb";
import { VscSend } from "react-icons/vsc";
import { LatoBold, LatoRegular } from "../Text";
import Work1 from "../../assets/images/work-1.png";
import Work2 from "../../assets/images/contact-us.png";
import How from "../../assets/images/faq.png";
import Comission from "../../assets/images/comission.svg";
import Tag from "../../assets/images/pricetag.svg";
import ExplainationSell from "../SellWithUs/ExplainationSell";
import Buttons from "../Buttons";
import colors from "../../assets/colors/colors";

function MainComponent({ setAboutPosition = () => {}, aboutPosition = {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const sectionArr = [
    {
      title: "Belle&Kate Clique",
      image: Work1,
      value: "clique",
      explain:
        "Our Belle&Kate Clique program is suitable for you who have significant social media following and would like to share a special promo code for your followers.",
      why: [
        {
          source: Comission,
          title: "commission",
          description: "Get commission on each item",
        },
        {
          icon: <SlDiamond size={isTablet ? 24 : 36} />,
          title: "vip events",
          description:
            "Get invited to our VIP events and latest product promotions",
        },
      ],
      how: {
        image: How,
        steps: [
          {
            source: FormIcon,
            title: "Fill in Form",
            description:
              "Fill in our application to list yourself as Belle&Kate Clique Program",
          },
          {
            source: Authentication,
            title: "Wait for our review",
            description:
              "Our team will review your date and process your approval",
          },
          {
            icon: <TbDiscount2 size={isTablet ? 24 : 36} />,
            title: "bring a friend to our store to shop",
            description:
              "We will activate and notify your promo code to give special promotion to your audience and viewers",
          },
          {
            source: Authentication,
            title: "Commission",
            description:
              "Our team will review your date and process your approval",
          },
        ],
      },
    },
    {
      title: "Belle&Kate Agents",
      image: Work2,
      value: "agents",
      explain:
        "Our Belle&Kate Agent program is fitting for individuals who would like to receive extra income with no requirements of a significant social media following nor Instagram business account.",
      why: [
        {
          source: Comission,
          title: "commission",
          description: "Get commission on each item",
        },
        {
          source: Tag,
          title: "lower price",
          description: "Special pricing for our agents only",
        },
        {
          icon: <SlDiamond size={isTablet ? 24 : 36} />,
          title: "vip events",
          description:
            "Get invited to our VIP events and latest product promotions",
        },
      ],
      how: {
        image: How,
        steps: [
          {
            source: FormIcon,
            title: "Fill in Form",
            description:
              "Fill in our application to list yourself as a Belle&Kate Agent",
          },
          {
            source: Authentication,
            title: "Wait for our review",
            description:
              "Our team will review your application maximum 7 working days",
          },
          {
            source: Appraisal,
            title: "get a verification",
            description:
              "We will activate and notify your admission to our Agents Program and take you to the next step",
          },
          {
            source: Rate,
            title: "Meet Our Team",
            description:
              "We will organize an online meeting with you to discuss the T&C of the program also welcoming you as an agent",
          },
          {
            icon: <VscSend size={isTablet ? 24 : 36} />,
            title: "Start promoting",
            description:
              "You can start posting and selling the products on your social media",
          },
        ],
      },
    },
  ];
  return sectionArr.map(({ title, image, explain, why, how, value }, i) => (
    <View
      key={i}
      onLayout={(e) => {
        aboutPosition[value] = e.nativeEvent.layout.y;
        setAboutPosition(aboutPosition);
      }}
      style={{
        paddingHorizontal: gap,
        paddingBottom: isPhone ? 16 : 100,
      }}
    >
      <View
        style={{
          marginBottom: isPhone ? 48 : 100,
          flexDirection: isPhone ? "column-reverse" : "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: isPhone ? containerWidth : containerWidth / 2,
            padding: isPhone ? 24 : 60,
            paddingLeft: isTablet ? 0 : 84,
            alignItems: isPhone ? "center" : "flex-start",
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 20 : isTablet ? 24 : 32,
              lineHeight: isPhone ? 28 : isTablet ? 32 : 40,
              marginBottom: isPhone ? 16 : 32,
              fontFamily: isPhone ? "lato-regular" : "lato-bold",
            }}
          >
            {title}
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: isPhone ? 12 : isTablet ? 16 : 24,
              lineHeight: isPhone ? 16 : isTablet ? 24 : 32,
              marginBottom: 32,
              textAlign: isPhone ? "center" : "left",
            }}
          >
            {explain}
          </LatoRegular>
          <Buttons
            onPress={() =>
              Linking.openURL(
                value === "clique"
                  ? "https://docs.google.com/forms/d/e/1FAIpQLSfWknknAFTfSUjE-BfDevSMbzPEgbVJcthcrhcF63i-ebRVRA/viewform?usp=sf_link"
                  : "https://docs.google.com/forms/d/e/1FAIpQLSev7gHl7LKznRv3L8ZCaxa31IVDm82cvwpvhRZmx4iRJ9RRBw/viewform?usp=sf_link"
              )
            }
            label={"JOIN US NOW"}
            width={"auto"}
            containerStyle={{ paddingHorizontal: 20, paddingVertical: 16 }}
            isBlack
            borderRadius={2}
          />
        </View>
        <Image
          source={image}
          alt={`about ${title}`}
          style={{
            alignSelf: "center",
            width: isPhone ? containerWidth : containerWidth / 2,
            height:
              ((isPhone ? containerWidth : containerWidth / 2) *
                (isPhone ? 281 : 500)) /
              (isPhone ? 358 : 730),
          }}
          defaultSource={require("../../assets/images/spinning-loading.gif")}
          resizeMode={"cover"}
        />
      </View>
      <View
        style={{
          marginHorizontal: isTablet ? -gap : 0,
          paddingBottom: isTablet ? 0 : 100,
          borderTopColor: colors.greyE6,
          borderTopWidth: isPhone ? 1 : 0,
          paddingTop: isPhone ? 48 : 0,
        }}
      >
        <LatoBold
          style={{
            fontSize: isPhone ? 20 : 32,
            lineHeight: isPhone ? 28 : 40,
            marginBottom: isPhone ? 16 : 32,
            textAlign: "center",
          }}
        >
          WHY YOU SHOULD JOIN?
        </LatoBold>
        <ExplainationSell
          arr={why}
          scroll={isTablet}
          itemWidth={containerWidth / why.length}
          containerWidth={containerWidth}
          containerHeight={isPhone ? 150 : 200}
          fontSize={isPhone ? 12 : 16}
          lineHeight={isPhone ? 16 : 24}
          descFontSize={isPhone ? 12 : 14}
          descLineHeight={isPhone ? 16 : 20}
          numbered={false}
          isCenter
        />
      </View>
      <View
        style={{
          width,
          borderTopColor: colors.greyE6,
          borderTopWidth: 1,
          borderBottomColor: colors.greyE6,
          borderBottomWidth: 1,
          marginHorizontal: -gap,
          paddingHorizontal: isTablet ? gap : gap / 2,
          marginBottom: isTablet ? 48 : 100,
          paddingVertical: isPhone ? 48 : 100,
          paddingBottom: isTablet ? 0 : 100,
          flexDirection: isTablet ? "column-reverse" : "row",
          alignItems: "center",
        }}
      >
        <View
          style={{
            width: isTablet ? width : (2 * containerWidth) / 3 + gap - 40,
            justifyContent: "center",
            marginHorizontal: isTablet ? -gap : 0,
          }}
        >
          <LatoBold
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              marginBottom: 12,
              paddingHorizontal: isPhone ? 8 : 56,
              textAlign: "center",
            }}
          >
            {`How to join ${title} Program?`}
          </LatoBold>
          <ExplainationSell
            arr={how?.steps}
            scroll={isTablet}
            itemWidth={
              (isTablet
                ? containerWidth
                : (2 * containerWidth) / 3 - 100 + gap) / 2
            }
            containerWidth={
              isTablet ? containerWidth : (2 * containerWidth) / 3 + gap - 100
            }
            containerHeight={isPhone ? 175 : 200}
            fontSize={isPhone ? 12 : 16}
            lineHeight={isPhone ? 16 : 24}
            descFontSize={isPhone ? 12 : 14}
            descLineHeight={isPhone ? 16 : 20}
          />
        </View>
        <Image
          source={how.image}
          alt={`about ${title}`}
          style={{
            alignSelf: "center",
            width: isTablet ? containerWidth : (containerWidth + gap) / 3,
            height: isPhone
              ? 0.75 * containerWidth
              : isTablet
              ? containerWidth / 2
              : (containerWidth + gap) / 2,
            marginBottom: isTablet ? 48 : 0,
          }}
          defaultSource={require("../../assets/images/spinning-loading.gif")}
          resizeMode={"cover"}
        />
      </View>
    </View>
  ));
}

export default MainComponent;
