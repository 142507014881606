import React from "react";
import {
  Image,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Work1 from "../../assets/images/work-1.png";
import Work2 from "../../assets/images/work-2.png";
import { BsArrowRightShort } from "react-icons/bs";

function TopComponent({ setYPosition = () => {}, aboutPosition }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const coverArr = [
    {
      image: Work1,
      title: "Belle&Kate Clique",
      value: "clique",
    },
    {
      image: Work2,
      title: "Belle&Kate Agents",
      value: "agents",
    },
  ];

  return (
    <View>
      <View
        style={{
          justifyContent: "center",
          alignItems: "center",
          paddingVertical: isPhone ? 16 : 100,
        }}
      >
        <LatoRegular
          style={{
            fontSize: isPhone ? 24 : isTablet ? 48 : 60,
            lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
            color: colors.black00,
            marginBottom: 16,
          }}
        >
          WORK WITH US
        </LatoRegular>
        <LatoRegular
          style={{
            fontSize: isPhone ? 12 : isTablet ? 16 : 24,
            lineHeight: isPhone ? 16 : isTablet ? 24 : 32,
            textAlign: "center",
            color: colors.grey6C,
          }}
        >
          Get additional income without capital as part of our tribe and receive
          outstanding benefits!
        </LatoRegular>
      </View>
      <View
        style={{
          flexDirection: isPhone ? "column" : "row",
          alignItems: "center",
          marginBottom: isPhone ? 48 : 100,
        }}
      >
        {coverArr.map(({ image, title, value }, i) => (
          <View key={i}>
            <Image
              source={image}
              alt={value}
              style={{
                alignSelf: "center",
                width: isPhone ? width : width / 2,
                height:
                  ((isPhone ? width : width / 2) *
                    (isPhone ? 225 : isTablet ? 600 : 450)) /
                  (isPhone ? 390 : 960),
              }}
              defaultSource={require("../../assets/images/spinning-loading.gif")}
              resizeMode={"cover"}
            />
            <View
              style={{
                position: "absolute",
                top: 0,
                width: isPhone ? width : width / 2,
                height:
                  ((isPhone ? width : width / 2) *
                    (isPhone ? 225 : isTablet ? 600 : 450)) /
                  (isPhone ? 390 : 960),
                backgroundColor: colors.superTransparentBlack00,
              }}
            >
              <TouchableOpacity
                onPress={() => setYPosition(aboutPosition[value])}
                style={{
                  backgroundColor: colors.black00,
                  position: "absolute",
                  width: isPhone ? width : "auto",
                  bottom: isPhone ? 0 : isTablet ? 12 : 24,
                  left: isPhone ? 0 : isTablet ? 24 : 48,
                  paddingHorizontal: 20,
                  paddingVertical: 16,
                }}
              >
                <LatoRegular
                  style={{
                    fontSize: isTablet ? 14 : 24,
                    lineHeight: isTablet ? 20 : 32,
                    color: colors.whiteFF,
                    marginBottom: 4,
                    textTransform: "uppercase",
                  }}
                >
                  {title}
                </LatoRegular>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <LatoRegular
                    style={{
                      fontSize: isTablet ? 12 : 20,
                      lineHeight: isTablet ? 16 : 28,
                      color: colors.whiteFF,
                    }}
                  >
                    Learn more
                  </LatoRegular>
                  <BsArrowRightShort
                    size={isTablet ? 16 : 28}
                    color={colors.whiteFF}
                  />
                </View>
              </TouchableOpacity>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

export default TopComponent;
