import React from "react";
import { View } from "react-native";
import { LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { RadioSelection } from "../Inputs";

function AssistedTimePicker({
  isPhone,
  selected,
  setSelected,
  date = new Date().toDateString(),
  slot = [],
  customOption = [],
  customWidth,
}) {
  const option = [
    {
      label: "11 a.m. - 3 p.m.",
      name: "Midday",
      value: "first_slot",
    },
    {
      label: "3 p.m. - 6 p.m.",
      name: "Late Afternoon",
      value: "second_slot",
    },
  ];

  const activeSelectedSlot = slot?.filter(
    ({ active, start_time }) =>
      active &&
      new Date(start_time).toDateString() === new Date(date).toDateString()
  );

  const selection = (customOption?.length ? customOption : option).map(
    (item, index) => {
      if (activeSelectedSlot.length === 2) {
        return { ...item, value: activeSelectedSlot[index]?.id };
      } else if (!activeSelectedSlot.length) {
        return { ...item, disabled: true };
      } else if (customOption?.length) {
        const checker = activeSelectedSlot.find(
          ({ slot_name }) => slot_name === item.name
        );
        return checker
          ? { ...item, value: slot[index]?.id }
          : { ...item, value: slot[index]?.id, disabled: true };
      } else {
        return activeSelectedSlot[0].slot_name === item.name
          ? { ...item, value: activeSelectedSlot[0]?.id }
          : { ...item, disabled: true };
      }
    }
  );

  return (
    <View
      style={{ flexDirection: isPhone ? "column" : "row", marginVertical: 48 }}
    >
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 24,
          color: colors.grey33,
          marginRight: 48,
          textAlign: "left",
          marginBottom: isPhone ? 24 : 0,
        }}
      >
        Time Slot
        <LatoRegular
          style={{
            fontSize: 16,
            lineHeight: 24,
            color: colors.redAD,
          }}
        >
          *
        </LatoRegular>
      </LatoRegular>
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent:
            isPhone && !customOption?.length ? "center" : "flex-start",
          width: customWidth - (isPhone ? 0 : 150) || "auto",
        }}
      >
        <RadioSelection
          selection={selection}
          selected={selected}
          setSelected={setSelected}
          bordered={!customOption?.length || (customOption?.length && !isPhone)}
          circle
        />
      </View>
    </View>
  );
}

export default AssistedTimePicker;
