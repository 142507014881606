import React from "react";
import { Text } from "react-native";
import colors from "../assets/colors/colors";

export const is_safari =
  navigator.userAgent.toLowerCase().indexOf("safari/") > -1;

export function PlayfairDisplayRegular({ style, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          fontFamily: "PlayfairDisplay-Regular",
          color: colors.black00,
        },
        style,
      ]}
    />
  );
}

export function PlayfairDisplayItalic({ style, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          fontFamily: "PlayfairDisplay-Italic",
          color: colors.black00,
        },
        style,
      ]}
    />
  );
}

export function PlayfairDisplayBold({ style, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          fontFamily: "PlayfairDisplay-Bold",
          color: colors.black00,
        },
        style,
      ]}
    />
  );
}

export function Helvetia({ style, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          fontFamily: "Helvetia",
          color: colors.black00,
        },
        style,
      ]}
    />
  );
}

export function LatoRegular({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "Lato-Regular",
        },
        style,
      ]}
    >
      <span className="lato-regular">{children}</span>
    </Text>
  );
}

export function LatoItalic({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "Lato-RegularItalic",
        },
        style,
      ]}
    >
      <span className="lato-regular-italic">{children}</span>
    </Text>
  );
}

export function PTSerifBold({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "PTSerif-Bold",
        },
        style,
      ]}
    >
      <span className={"PTSerif-Bold"}>{children}</span>
    </Text>
  );
}

export function PTSerifRegular({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "PTSerif-Regular",
        },
        style,
      ]}
    >
      <span className={"PTSerif-Regular"}>{children}</span>
    </Text>
  );
}

export function LatoBold({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "Lato-Bold",
        },
        style,
      ]}
    >
      <span className={style?.fontFamily || "lato-bold"}>{children}</span>
    </Text>
  );
}

export function LatoBlack({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "Lato-Black",
        },
        style,
      ]}
    >
      <span className="lato-black">{children}</span>
    </Text>
  );
}

export function LatoLight({ style, children, ...rest }) {
  return (
    <Text
      {...rest}
      style={[
        {
          color: colors.black00,
          fontFamily: "Lato-Light",
        },
        style,
      ]}
    >
      <span className="lato-light">{children}</span>
    </Text>
  );
}
