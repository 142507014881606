import { format } from "date-fns";
import { createElement, useEffect, useRef, useState } from "react";
import {
  View,
  TextInput,
  Platform,
  useWindowDimensions,
  TouchableOpacity,
} from "react-native";
import { LatoRegular } from "./Text";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import { BiCalendar } from "react-icons/bi";

export default function DateTimePicker({
  placeholder,
  label,
  required,
  color,
  customWidth,
  backgroundColor,
  onChange,
  date,
  min = new Date(),
  max = new Date(),
  disabled = false,
}) {
  const inputRef = useRef();
  const [type, setType] = useState("text");
  const [currentDate, setCurrentDate] = useState(new Date(date));

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const handleDateChange = (event) => {
    const newDate = new Date(event.target.value);

    // Check if the month has changed
    if (newDate.getMonth() === currentDate.getMonth()) {
      onChange(format(new Date(newDate), "yyyy-MM-dd"));
    }

    // Update the current date
    setCurrentDate(newDate);
  };

  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.setNativeProps(
        // type === "text"
        //   ? { type: "text", placeholder }
        //   : {
        //       type: "date",
        //       min: format(new Date(min), "yyyy-MM-dd"),
        //       max: format(new Date(max), "yyyy-MM-dd"),
        //       pattern: "d{4}-d{2}-d{2}",
        //     }
        {
          type: "date",
          min: format(new Date(min), "yyyy-MM-dd"),
          max: format(new Date(max), "yyyy-MM-dd"),
        }
      );
    }
  }, [inputRef?.current, type, label, min, max]);

  // if (Platform.OS === 'web') {
  return (
    <View
      style={{
        marginBottom: 16,
      }}
    >
      {Boolean(label) && (
        <LatoRegular style={{ paddingHorizontal: 6 }}>
          {label || placeholder}
          {Boolean(required) && (
            <LatoRegular style={{ color: colors.redB6 }}>*</LatoRegular>
          )}
        </LatoRegular>
      )}
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <TextInput
          ref={inputRef}
          editable={!disabled}
          // onFocus={() => {
          //   if (type === "text") {
          //     setType("date");
          //   }
          // }}
          // onBlur={() => {
          //   if (type === "date") {
          //     setType("text");
          //   }
          // }}
          onChange={handleDateChange}
          // onChangeText={(e) => {
          //   onChange(e);
          // }}
          value={date}
          placeholderTextColor={colors.grey90}
          style={
            Platform.OS === "web"
              ? {
                  borderColor: "transparent",
                  borderBottomColor: label ? colors.grey33 : colors.greyE6,
                  borderBottomWidth: 1,
                  height: 40,
                  width: customWidth
                    ? customWidth
                    : isPhone
                    ? "80vw"
                    : isTablet
                    ? "40vw"
                    : "32.5vw",
                  fontFamily: ["Lato-Regular", "FontAwesome"],
                  fontSize: 14,
                  lineHeight: 20,
                  paddingHorizontal: 6,
                  marginBottom: 6,
                  outlineWidth: 0,
                  color: color || colors.black00,
                  backgroundColor: backgroundColor || "transparent",
                }
              : {
                  borderColor: "transparent",
                  borderBottomColor: color
                    ? color
                    : label
                    ? colors.grey33
                    : colors.greyE6,
                  borderBottomWidth: 1,
                  height: 40,
                  fontSize: 14,
                  lineHeight: 20,
                  width: customWidth
                    ? customWidth
                    : isPhone
                    ? "80vw"
                    : isTablet
                    ? "40vw"
                    : "32.5vw",
                  fontFamily: ["Lato-Regular", "FontAwesome"],
                  paddingHorizontal: 6,
                  marginBottom: 6,
                  color: color || colors.black00,
                  backgroundColor: backgroundColor || "transparent",
                }
          }
        />
        {/* {Boolean(type === "text") && ( */}
        <TouchableOpacity
          style={{ marginLeft: -23, zIndex: -1, marginBottom: 8 }}
        >
          <BiCalendar size={16} color={colors.grey33} />
        </TouchableOpacity>
        {/* )} */}
      </View>
    </View>
  );
  // }
}
