import React from "react";
import { View, useWindowDimensions } from "react-native";
import { PiStarFour } from "react-icons/pi";
import { MdOutlineThumbUp } from "react-icons/md";
import { getDeviceType } from "../tools/Interface";
import colors from "../assets/colors/colors";
import { LatoBold } from "./Text";

function ProductTag({
  containerStyle = {},
  influencer = false,
  isDetails = false,
  editor = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isTablet } = getDeviceType({ width, height });
  return Boolean(influencer || editor) ? (
    <View
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: influencer ? colors.lightPinkCB : colors.lightGreen5B,
        padding: 8,
        borderRadius: 3.4,
        ...containerStyle,
      }}
    >
      {influencer ? (
        <PiStarFour
          size={isTablet && !isDetails ? 12 : 20}
          color={colors.pinkCB}
        />
      ) : (
        <MdOutlineThumbUp
          size={isTablet && !isDetails ? 12 : 20}
          color={colors.green5B}
        />
      )}
      <LatoBold
        style={{
          fontSize: isTablet && !isDetails ? 8 : 14,
          lineHeight: isTablet && !isDetails ? 12 : 20,
          color: influencer ? colors.pinkCB : colors.green5B,
          marginLeft: 8,
        }}
      >
        {influencer ? "Influencer Pre-loved" : "Editors Pick"}
      </LatoBold>
    </View>
  ) : (
    <View />
  );
}

export default ProductTag;
