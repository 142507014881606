import React, { useEffect, useState } from "react";
import {
  View,
  ScrollView,
  useWindowDimensions,
  StyleSheet,
} from "react-native";
import colors from "../assets/colors/colors";
import AddressForm from "../components/Account/AddressForm";
import Cart from "../components/Cart";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { LatoRegular } from "../components/Text";
import { useAuth } from "../contexts/AuthContext";
import { getDeviceType } from "../tools/Interface";
import { useLocation } from "react-router";
import Modal from "../components/Modal";
import LocationPicker from "../components/LocationPicker";
import Geolocation from "@react-native-community/geolocation";
import CheckoutTracker from "../components/CheckoutTracker";

export default function CheckoutAddressScreen() {
  const [openModal, setOpenModal] = useState(false);
  const [addressObj, setAddressObj] = useState();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const { user } = useAuth();
  const { address = {} } = user;
  const { state } = useLocation();
  const { courier, fromPayment, fromShipment } = state || {};

  const [userPosition, setUserPosition] = useState({
    latitude: -6.2,
    longitude: 106.8166,
  });

  const style = StyleSheet.create({
    sectionContainer: {
      width: isTablet ? containerWidth : (2 * containerWidth) / 3 - 40,
    },
    sectionHeader: {
      fontSize: 20,
      lineHeight: 24,
    },
  });

  const getUserPosition = () => {
    Geolocation.getCurrentPosition((e) => {
      setUserPosition({
        latitude: e.coords.latitude,
        longitude: e.coords.longitude,
      });
    });
  };

  useEffect(() => {
    getUserPosition();
  }, []);

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        <Navbar backgroundColor={colors.whiteFF} isCheckout />
        <CheckoutTracker position={"address"} />
        <View
          style={{
            flexDirection: isTablet ? "column-reverse" : "row",
            justifyContent: "space-between",
            paddingHorizontal: gap,
            paddingBottom: 72,
          }}
        >
          <View style={style.sectionContainer}>
            <AddressForm
              user={user}
              isCheckout={
                isTablet ? containerWidth : (2 * containerWidth) / 3 - 100
              }
              fromPayment={fromPayment}
              fromShipment={fromShipment}
              courier={courier}
              setOpenModal={setOpenModal}
              addressObj={addressObj}
            />
          </View>
          <View
            style={{
              marginTop: isTablet ? 32 : -90,
              marginBottom: isTablet ? 48 : 0,
              paddingBottom: isTablet ? 48 : 0,
              borderBottomColor: colors.greyE6,
              marginHorizontal: isTablet ? -gap : 0,
              paddingHorizontal: isTablet ? gap : 0,
              borderBottomWidth: isTablet ? 1 : 0,
              width: isTablet ? width : containerWidth / 3 - 40,
            }}
          >
            <LatoRegular style={style.sectionHeader}>Order Summary</LatoRegular>
            <Cart
              isCheckout={isTablet ? containerWidth : containerWidth / 3 - 40}
            />
          </View>
        </View>
        <Footer />
      </ScrollView>
      {Boolean(openModal) && (
        <Modal>
          <LocationPicker
            width={isPhone ? width - 80 : 452}
            setOpenModal={setOpenModal}
            latitude={Number(address?.latitude) || userPosition.latitude}
            longitude={Number(address?.longitude) || userPosition.longitude}
            setAddressObj={setAddressObj}
            addressObj={addressObj}
          />
        </Modal>
      )}
    </View>
  );
}
