import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import Navbar from "../components/Navbar";
import colors from "../assets/colors/colors";
import { LatoBold, LatoRegular } from "../components/Text";
import OTPInputs from "../components/OTPInputs";
import { useAuth } from "../contexts/AuthContext";
import { getDeviceType } from "../tools/Interface";
import Timer from "../components/Timer";
import moment from "moment";
import Buttons from "../components/Buttons";
import { RxCheckCircled } from "react-icons/rx";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import { api } from "../utils/api";
import ToasterContext from "../contexts/ToastContext";

function PhoneVerificationScreen() {
  const navigate = useNavigate();
  const { user, refetch } = useAuth();
  const { detail = {} } = user || {};
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const [loading, setLoading] = useState(false);
  const [otpCode, setOTPCode] = useState("");
  const [isPinReady, setIsPinReady] = useState(false);
  const [timer, setTimer] = useState(true);
  const refTimer = useRef();
  const { showSuccessToast, showToast } = useContext(ToasterContext);

  const timerCallbackFunc = (timerFlag) => {
    setTimer(false);
  };

  const handleResendOTP = async () => {
    try {
      setLoading(true);
      await api.post("/api/users/request_otp");
      setTimer(true);

      showSuccessToast("Code has been sent to your phone number");
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleVerifyOTP = async () => {
    try {
      setLoading(true);

      if (otpCode.length === 6) {
        await api.post("api/users/verify_otp", {
          otp_code: otpCode,
        });
      }

      refetch();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
      setTimer(false);
    }
  };

  const maximumCodeLength = 6;

  useEffect(() => {}, [timer]);

  useEffect(() => {
    if (isPinReady) {
      handleVerifyOTP();
    }
  }, [isPinReady]);

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        {/* <Wrapper> */}
        <Navbar backgroundColor={colors.whiteFF} />
        {Boolean(detail?.phone_verified) ? (
          <View
            style={{
              justifyContent: "center",
              marginTop: 100,
              alignItems: "center",
              marginHorizontal: isPhone ? 16 : 0,
              marginBottom: 0.7 * height,
            }}
          >
            <RxCheckCircled size={80} color={colors.black00} />
            <LatoBold
              style={{
                fontSize: 24,
                lineHeight: 30,
                color: colors.grey33,
                marginBottom: 24,
                marginTop: 12,
              }}
            >
              Phone number verified
            </LatoBold>
            <LatoRegular
              style={{
                fontSize: 16,
                lineHeight: 24,
                color: colors.grey6C,
                marginBottom: 32,
                textAlign: "center",
              }}
            >
              <LatoBold
                style={{
                  fontSize: 16,
                  lineHeight: 24,
                  color: colors.grey6C,
                }}
              >{`+62${user?.detail?.phone} `}</LatoBold>
              has been successfully verified.
            </LatoRegular>
            <Buttons
              onPress={() => {
                navigate("/verification/upload-id");
              }}
              label="Continue"
              isBlack
              width={250}
            />
          </View>
        ) : (
          <View
            style={{
              justifyContent: "center",
              marginTop: 100,
              alignItems: "center",
              marginHorizontal: isPhone ? 16 : 0,
              marginBottom: 0.7 * height,
            }}
          >
            <LatoBold
              style={{
                fontSize: 24,
                lineHeight: 30,
                color: colors.grey33,
                marginBottom: 24,
              }}
            >
              Verify your phone number
            </LatoBold>
            <LatoRegular
              style={{
                fontSize: 16,
                lineHeight: 24,
                color: colors.grey33,
                marginBottom: 24,
                textAlign: "center",
              }}
            >
              Enter a verification code that we send via SMS to registered phone
              number
            </LatoRegular>
            <LatoBold
              style={{
                fontSize: 16,
                lineHeight: 24,
                color: colors.grey33,
                marginBottom: 60,
              }}
            >
              {`+62${user?.detail?.phone}`}
            </LatoBold>
            <OTPInputs
              code={otpCode}
              setCode={setOTPCode}
              maximumLength={maximumCodeLength}
              setIsPinReady={setIsPinReady}
            />
            <View style={{ marginTop: 24, flexDirection: "row" }}>
              <LatoRegular
                style={{
                  marginRight: 8,
                  fontSize: 12,
                  lineHeight: 16,
                  color: colors.grey90,
                }}
              >
                Didn’t receive a code?
              </LatoRegular>
              {timer ? (
                <Timer
                  ref={refTimer}
                  timestamp={moment(new Date().getTime() + 120000).diff(
                    moment(new Date().getTime()),
                    "seconds"
                  )}
                  timerCallback={timerCallbackFunc}
                  textStyle={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.grey33,
                  }}
                />
              ) : (
                <TouchableOpacity onPress={handleResendOTP}>
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      color: colors.grey6C,
                      textDecorationLine: "underline",
                    }}
                  >
                    Resend OTP
                  </LatoRegular>
                </TouchableOpacity>
              )}
            </View>
          </View>
        )}
        <Footer />
      </ScrollView>
    </View>
  );
}

export default PhoneVerificationScreen;
