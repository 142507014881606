import React, { useContext, useState } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native-web";
import { getDeviceType } from "../../tools/Interface";
import { AiOutlineHeart } from "react-icons/ai";
import { CgShoppingBag } from "react-icons/cg";
import { BsWhatsapp } from "react-icons/bs";
import { FaShoppingBag } from "react-icons/fa";
import Shimmer from "../Shimmer";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import DropDownTab from "../DropDownTab";
import { useLocation, useNavigate, useParams } from "react-router";
import { contactDecider } from "../../tools/decider";
import { openWhatsapp } from "../../tools/tools";
import { api } from "../../utils/api";
import ToasterContext from "../../contexts/ToastContext";
import { formatCurrency } from "../../tools/formatter";
import ProductTag from "../ProductTag";
import BNK1 from "../../assets/images/own-similar-1.png";
import BNK2 from "../../assets/images/own-similar-2.png";
import { capitalize } from "lodash";
import { RiShoppingBagFill } from "react-icons/ri";
import { IoIosNotificationsOutline } from "react-icons/io";
import CurrencyContext from "../../contexts/CurrenctContext";

function RightDetail({
  loadingData = false,
  productData = {},
  setOpenCart = () => {},
  inWishlist = false,
  inCart = false,
  getData = () => {},
  setOpenConditionModal = () => {},
  setNotify = () => {},
}) {
  const { state } = useLocation();
  const { id } = useParams();
  const { selected: currency } = useContext(CurrencyContext);
  const navigate = useNavigate();
  const { consignment: consign } = state || {};
  const { product = {}, product_id, quantity } = productData;
  const {
    summary_detail = {},
    details: productDetails,
    condition_description,
    product_description: productDesc,
    shipping,
    sku,
    name,
    selling_price,
    default_price,
    comes_with,
    multi_currency_price,
  } = product;
  const {
    designer,
    category,
    product_description,
    condition,
    badges = [],
    details = [],
  } = summary_detail;

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const [consignment, setConsignment] = useState(consign || false);
  const [loading, setLoading] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;
  const isHermes = designer === "Hermes" && category !== "Accessories";

  const dropdownArr = [
    {
      title: "DETAILS & CARE",
      datas: product_description
        ? typeof product_description === "object"
          ? Object.entries(product_description).map(
              (item) => `${capitalize(item[0].replace("_", " "))}: ${item[1]}`
            )
          : [...product_description?.split("\r\n")]
        : productDesc
        ? [...productDesc?.split("\r\n").join()]
        : [],
      style: product_description ? "button" : "paragraph",
    },
    {
      title: "SPECIFICATIONS",
      datas: productDetails
        ? productDetails?.includes("\r\n")
          ? [
              ...productDetails
                ?.split("\r\n")
                .join()
                .split(",,")
                .join()
                .split(","),
            ]
          : [
              ...productDetails
                ?.split(" x ")
                .join()
                .split("; ")
                .join()
                .split(","),
            ]
        : details
        ? Object.entries(details).map(
            (item) => `${capitalize(item[0].replace("_", " "))}: ${item[1]}`
          )
        : [],
      style: "button",
    },
    {
      title: "CONDITIONS",
      datas:
        condition || condition_description
          ? [condition, condition_description]
          : [],
      style: "condition",
      onPress: () => setOpenConditionModal(true),
    },
    {
      title: "SHIPPING",
      datas: shipping ? [shipping] : [],
      style: "paragraph",
    },
  ];

  const handleAddCart = async () => {
    try {
      setLoading(true);
      const isAccessories = category === "Accessories";
      if ((isHermes && !isAccessories) || sku?.includes("GT")) {
        let message = `Hi, I'd like to purchase the following products:\n ${designer} ${name} ${category}. SKU: ${sku} (https://www.belleandkate.com/products/${id})`;
        const contact = contactDecider(
          isAccessories ? "HermesAccessories" : "HermesBags"
        );
        openWhatsapp(message, contact);
      } else {
        await api.post("/api/carts", { product_id: product_id });
        setOpenCart(true);
      }

      inWishlist && (await api.delete(`api/wishlist_items/${product_id}`));
      getData();
      !isHermes && showSuccessToast("Item added to cart");
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleAddWishList = async () => {
    try {
      setLoading(true);
      await api.post("/api/wishlist_items", {
        product_id: product_id,
      });
      getData();
      showSuccessToast("Item added to wishlist");
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View
      style={{
        paddingBottom: 48,
        width: isPhone ? containerWidth : (containerWidth - 80) / 2,
        paddingLeft: isPhone ? 0 : isTablet ? 24 : 0,
        paddingRight: isPhone ? 0 : isTablet ? 24 : 48,
        paddingTop: 85,
      }}
    >
      {loadingData ? (
        <Shimmer
          autoRun={true}
          visible={false}
          width={(containerWidth - 80) / 2}
          height={24}
          hasBorder
          style={{ marginBottom: 8 }}
        />
      ) : (
        <LatoBold
          style={{
            fontSize: 24,
            lineHeight: 32,
            textTransform: "uppercase",
            marginBottom: 4,
          }}
        >
          {designer || ""}
        </LatoBold>
      )}
      {loadingData ? (
        <Shimmer
          autoRun={true}
          visible={false}
          width={isTablet ? "100%" : "50%"}
          height={isTablet ? 16 : 18}
          hasBorder
          style={{ marginBottom: 32 }}
        />
      ) : (
        <LatoRegular
          style={{
            fontSize: isTablet ? 16 : 18,
            lineHeight: isTablet ? 24 : 26,
            marginBottom: true ? 0 : 16,
            color: colors.grey6C,
          }}
        >
          {name || ""}
        </LatoRegular>
      )}
      {Boolean(badges?.length) && (
        <ProductTag
          containerStyle={{
            width: badges?.includes("Influencer Preloved") ? 175 : 120,
            marginVertical: 16,
          }}
          influencer={badges?.includes("Influencer Preloved")}
          editor={badges?.includes("Editor's Pick")}
          isDetails
        />
      )}
      {loadingData ? (
        <Shimmer
          autoRun={true}
          visible={false}
          width={isTablet ? "60%" : "30%"}
          height={isTablet ? 16 : 18}
          hasBorder
          style={{ marginBottom: 18 }}
        />
      ) : (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            width: isPhone ? containerWidth : (containerWidth - 80) / 2,
            marginBottom: 16,
          }}
        >
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color:
                (multi_currency_price?.selling_price[currency] ||
                  selling_price) !==
                (multi_currency_price?.default_price[currency] || default_price)
                  ? colors.grey6C
                  : colors.grey33,
              textDecorationLine:
                (multi_currency_price?.selling_price[currency] ||
                  selling_price) !==
                (multi_currency_price?.default_price[currency] || default_price)
                  ? "line-through"
                  : "none",
            }}
          >{`${currency} ${formatCurrency(
            multi_currency_price?.default_price[currency] || default_price
          )}`}</LatoBold>
          <LatoRegular style={{ textDecorationLine: "none" }}>
            {"  "}
          </LatoRegular>
          {Boolean(
            (multi_currency_price?.selling_price[currency] || selling_price) !==
              (multi_currency_price?.default_price[currency] || default_price)
          ) && (
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey33,
              }}
            >
              {`${currency} ${formatCurrency(
                multi_currency_price?.selling_price[currency] || selling_price
              )}  `}
            </LatoBold>
          )}
          {Boolean(
            (multi_currency_price?.selling_price[currency] || selling_price) !==
              (multi_currency_price?.default_price[currency] || default_price)
          ) && (
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.redAD,
              }}
            >
              {`Save ${(
                (((multi_currency_price?.default_price[currency] ||
                  default_price) -
                  (multi_currency_price?.selling_price[currency] ||
                    selling_price)) /
                  (multi_currency_price?.default_price[currency] ||
                    default_price)) *
                100
              ).toFixed(0)}%`}
            </LatoBold>
          )}
        </View>
      )}
      {loadingData ? (
        <View style={{ marginBottom: 24 }}>
          <Shimmer
            autoRun={true}
            visible={false}
            width={"100%"}
            height={isTablet ? 14 : 16}
            hasBorder
            style={{ marginBottom: 8 }}
          />
          <Shimmer
            autoRun={true}
            visible={false}
            width={"100%"}
            height={isTablet ? 14 : 16}
            hasBorder
          />
        </View>
      ) : (
        <LatoRegular
          style={{
            fontSize: isTablet ? 14 : 16,
            lineHeight: isTablet ? 20 : 24,
            color: colors.grey33,
            marginBottom: 16,
          }}
        >
          {`Comes with: ${comes_with || ""}`}
        </LatoRegular>
      )}
      {Boolean(!consignment) && (
        <Buttons
          disabled={!quantity}
          icon={
            quantity ? (
              isHermes || sku?.includes("GT") || sku?.startsWith("BKS") ? (
                <BsWhatsapp
                  color="white"
                  size={isTablet ? 18 : 20}
                  style={{ marginRight: 6 }}
                />
              ) : (
                Boolean(!inCart) && (
                  <CgShoppingBag
                    color="white"
                    size={isTablet ? 18 : 20}
                    style={{ marginRight: 6 }}
                  />
                )
              )
            ) : null
          }
          label={
            quantity
              ? isHermes || sku?.includes("GT") || sku?.startsWith("BKS")
                ? "Contact Us"
                : inCart
                ? "Go to Cart"
                : "Add to Cart"
              : "Sold Out"
          }
          width={"100%"}
          isBlack={Boolean(quantity)}
          onPress={inCart ? () => setOpenCart(true) : handleAddCart}
          loading={loading}
          borderRadius={4}
          color={quantity ? colors.whiteFF : colors.greyBB}
          noBorder={Boolean(quantity)}
          containerStyle={{
            marginBottom: 16,
            paddingVertical: 10,
            paddingHorizontal: 12,
            backgroundColor: quantity ? colors.grey33 : colors.whiteFF,
          }}
          textStyle={{ fontFamily: "lato-regular" }}
          fontSize={isTablet ? 12 : 14}
          lineHeight={isTablet ? 18 : 20}
          iconStyle={"center"}
        />
      )}
      {Boolean(!quantity) && (
        <Buttons
          icon={
            <IoIosNotificationsOutline
              color="white"
              size={isTablet ? 18 : 20}
              style={{ marginRight: 6 }}
            />
          }
          label={"Notify me when there is similar item"}
          width={"100%"}
          isBlack
          noBorder
          onPress={() => setNotify(true)}
          loading={loading}
          borderRadius={4}
          containerStyle={{
            marginBottom: 16,
            paddingVertical: 10,
            paddingHorizontal: 12,
          }}
          textStyle={{ fontFamily: "lato-regular" }}
          fontSize={isTablet ? 12 : 14}
          lineHeight={isTablet ? 18 : 20}
          iconStyle={"center"}
        />
      )}
      {Boolean(inCart || inWishlist) ? (
        <LatoRegular
          style={{
            fontSize: isTablet ? 16 : 18,
            lineHeight: isTablet ? 24 : 26,
            color: colors.grey90,
            textAlign: "center",
            width: "100%",
            marginBottom: 16,
          }}
        >
          {inCart ? "Item added to cart" : "Item added to wishlist"}
        </LatoRegular>
      ) : (
        Boolean(!consignment) && (
          <Buttons
            icon={
              Boolean(!inCart) && (
                <AiOutlineHeart
                  color={colors.black00}
                  size={isTablet ? 18 : 20}
                  style={{ marginRight: 6 }}
                />
              )
            }
            label={"Add to Wishlist"}
            width={"100%"}
            onPress={handleAddWishList}
            loading={loading}
            borderRadius={4}
            containerStyle={{
              marginBottom: 16,
              paddingVertical: 10,
              paddingHorizontal: 12,
            }}
            textStyle={{ fontFamily: "lato-regular" }}
            fontSize={isTablet ? 12 : 14}
            lineHeight={isTablet ? 18 : 20}
            iconStyle="center"
          />
        )
      )}
      {Boolean(isHermes) && (
        <View
          style={{
            borderWidth: 1,
            borderColor: colors.greyE6,
            borderRadius: 4,
            padding: 14,
            marginTop: 12,
            marginBottom: 36,
          }}
        >
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}
          >
            <FaShoppingBag color={colors.goldE1} size={20} />
            <LatoBold
              style={{
                fontSize: isTablet ? 16 : 18,
                lineHeight: isTablet ? 24 : 26,
                color: colors.goldE1,
                marginLeft: 8,
              }}
            >
              EXCLUSIVE ITEMS
            </LatoBold>
          </View>
          <LatoRegular
            style={{
              fontSize: isTablet ? 14 : 16,
              lineHeight: isTablet ? 20 : 24,
              color: colors.grey6C,
            }}
          >
            We will assist you to order any exclusive items from Belle&Kate.
            Simply click ‘contact us’ and we’ll be with you in a flash.
          </LatoRegular>
        </View>
      )}
      {dropdownArr?.map((item, index) => (
        <DropDownTab
          item={item}
          key={index}
          noMargin
          noBorder={isPhone}
          isLast={dropdownArr?.length - 1 === index}
          opened
        />
      ))}
      <TouchableOpacity
        onPress={() => navigate("/sell-with-us/consignment")}
        style={{
          marginTop: 10,
          backgroundColor: colors.green8D,
          borderRadius: 4,
          width: isPhone
            ? containerWidth
            : (containerWidth - 80) / 2 - (isPhone ? 0 : isTablet ? 24 : 48),
          height: isPhone ? 89 : 70,
          overflow: "hidden",
          justifyContent: "center",
          padding: 16,
        }}
      >
        {Boolean(!isPhone) && (
          <View
            style={{
              position: "absolute",
              left: isPhone
                ? -containerWidth / 4
                : -(
                    (containerWidth - 80) / 2 -
                    (isPhone ? 0 : isTablet ? 24 : 48)
                  ) / 3.25,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <Image
              source={BNK1}
              alt={`sell-similar-button`}
              style={{
                width: isPhone ? containerWidth : (containerWidth - 80) / 2,
                height: isPhone ? 89 : 70,
              }}
              resizeMode={"contain"}
            />
          </View>
        )}
        <View
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >
          <Image
            source={BNK2}
            alt={`sell-similar-button`}
            style={{
              width: isPhone
                ? containerWidth * (1 + (width - 360) / 360) ** 0.7
                : (containerWidth - 80) / 1.4,
              height: isPhone ? 89 : 70,
            }}
            resizeMode={"contain"}
          />
        </View>
        <View>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 6,
            }}
          >
            <RiShoppingBagFill size={20} color={colors.whiteFF} />
            <LatoBold
              style={{
                fontSize: 16,
                lineHeight: 24,
                color: colors.whiteFF,
                marginLeft: 8,
              }}
            >
              OWN SOMETHING SIMILAR?
            </LatoBold>
          </View>
          <LatoRegular
            style={{
              fontSize: 12,
              lineHeight: 16,
              color: colors.whiteFF,
              width: isPhone
                ? containerWidth - 48
                : (containerWidth - 80) / 2 - 64,
            }}
          >
            Sell with us and find a new lovely home for your pre-loved accessory
          </LatoRegular>
        </View>
      </TouchableOpacity>
    </View>
  );
}

export default RightDetail;
