import React, { useRef } from "react";
import { ScrollView, View, useWindowDimensions } from "react-native";
import GridLoading from "./GridLoading";
import { getDeviceType } from "../../tools/Interface";
import { times } from "lodash";

function GridWrapper({
  loading,
  style = {},
  wrap = false,
  isSearch = false,
  isDetail = false,
  isClassroom = false,
  isCrush = false,
  catalog = false,
  divider = 1,
  children,
}) {
  const scrollRef = useRef();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 24 : width < 1350 ? 50 : width < 1500 ? 150 : 220;
  const containerWidth = width - gap * 2;

  return loading ? (
    <View
      style={{
        ...style.container,
        flexWrap: wrap ? "wrap" : "nowrap",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        paddingHorizontal:
          isClassroom || isCrush
            ? 0
            : wrap || isDetail
            ? gap
            : isPhone
            ? 16
            : 20,
        paddingTop: isSearch ? 0 : 48,
        paddingBottom: catalog ? 200 : 80,
        width: containerWidth,
      }}
    >
      {children}
      {times(isTablet && !isPhone ? 21 : 20, (i) => (
        <GridLoading
          key={i}
          style={style}
          divider={divider}
          index={i}
          wrap={wrap}
        />
      ))}
    </View>
  ) : wrap ? (
    <View
      style={{
        ...style.container,
        flexWrap: "wrap",
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        paddingHorizontal: isCrush ? 0 : gap,
        paddingTop: 48,
        paddingBottom: catalog ? (isPhone ? 100 : 200) : 80,
      }}
    >
      {children}
    </View>
  ) : (
    <View>
      <ScrollView
        ref={scrollRef}
        horizontal
        scrollEventThrottle={200}
        disableScrollViewPanResponder={true}
        showsHorizontalScrollIndicator={false}
        style={{
          paddingLeft: isDetail ? gap : isPhone ? 16 : 60,
        }}
        contentContainerStyle={{
          flex: 1,
          justifyContent: isClassroom || isCrush ? "center" : "flex-start",
        }}
      >
        {children}
      </ScrollView>
    </View>
  );
}

export default GridWrapper;
