import React, { useContext, useEffect, useState } from "react";
import { View, Image, useWindowDimensions } from "react-native";
import { useNavigate } from "react-router";
import { useAuth } from "../contexts/AuthContext";
import { api } from "../utils/api";
import NeedKYC from "../components/Consignment/NeedKYC";
import WaitingKYC from "../components/Consignment/WaitingKYC";
import ConsignmentList from "../components/Consignment/ConsignmentList";
import { getDeviceType } from "../tools/Interface";
import { asyncSetConsignmentItem } from "../tools/asyncStorages";
import { sortingDecider } from "../tools/decider";
import ToasterContext from "../contexts/ToastContext";

function ConsignmentListScreen({
  setOpenModal = () => {},
  setSelected = () => {},
  setOpenCashout = () => {},
  openModal = false,
  openCashout = false,
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { detail = {} } = user || {};

  const [loading, setLoading] = useState(false);
  const [loadOnce, setLoadOnce] = useState(false);
  const [datas, setDatas] = useState([]);

  const { showToast } = useContext(ToasterContext);
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const getConsignmentList = async () => {
    try {
      Boolean(!loadOnce) && setLoading(true);

      const res = await api.get("api/consignments");
      const data = res?.data?.data;

      const inventories = data
        .map(({ product }) => product?.summary_index?.inventory_id)
        .filter((item) => item);

      setDatas(data);

      await asyncSetConsignmentItem(inventories);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      Boolean(!loadOnce) && setLoadOnce(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (detail?.state === "verified") {
      getConsignmentList();
    }
  }, [detail, openModal, openCashout]);

  return loading ? (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: isPhone ? width : isTablet ? width - 300 : width - 450,
        height: height - 500,
      }}
    >
      <Image
        source={require("../assets/images/spinning-loading.gif")}
        // alt={item?.product?.name}
        style={{
          alignSelf: "center",
          width: 200,
          height: 200,
        }}
        defaultSource={require("../assets/images/spinning-loading.gif")}
        resizeMode={"contain"}
      />
    </View>
  ) : Boolean(detail?.state === "verified") ? (
    <ConsignmentList
      navigate={navigate}
      datas={sortingDecider(datas, "newest")}
      setOpenModal={setOpenModal}
      setOpenCashout={setOpenCashout}
      setSelected={setSelected}
    />
  ) : Boolean(detail?.state !== "created") ? (
    <WaitingKYC navigate={navigate} />
  ) : (
    <NeedKYC navigate={navigate} detail={detail} />
  );
}

export default ConsignmentListScreen;
