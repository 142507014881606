import React from "react";
import { View, StyleSheet, useWindowDimensions } from "react-native";
import { useNavigate } from "react-router-dom";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";
import { LatoRegular } from "../Text";
import Buttons from "../Buttons";
import GridWrapper from "../Catalog/GridWrapper";
import GridItem from "../Catalog/GridItem";

export default function ProductGrid({
  catalog = false,
  wrap = false,
  data = [],
  loading = false,
  isSearch = false,
  isDetail = false,
  querry = "",
  setOpenSearch = () => {},
  isHome = false,
  noButton = false,
  isClassroom = false,
  isCrush = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const divider = isPhone || isCrush ? 2 : isTablet ? 3 : 4;
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 220;
  const containerWidth = width - gap * 2;

  const style = StyleSheet.create({
    container: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
    },
    itemContainer: {
      marginRight: isPhone ? 0 : 40,
    },
    upperItem: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
    },
    variants: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      flexWrap: "wrap",
      width: "50%",
    },
    variant: {
      width: 8,
      height: 8,
      borderRadius: "100%",
      marginRight: 24,
      marginBottom: 12,
      shadowColor: colors.black00,
      shadowRadius: 2,
      shadowOpacity: 0.75,
      shadowOffset: { height: 0, width: 0 },
    },
    condition: {
      paddingHorizontal: 8,
      paddingVertical: 4,
      borderRadius: 16,
      borderWidth: 1,
    },
    merk: {
      fontSize: isPhone ? 12 : 14,
      lineHeight: isPhone ? 16 : 20,
      marginBottom: 4,
      color: colors.black22,
    },
    title: {
      fontSize: isPhone ? 12 : 14,
      lineHeight: isPhone ? 16 : 20,
      marginBottom: 10,
      numberOfLines: 2,
      color: colors.black22,
      height: isPhone ? 32 : 40,
    },
    price: {
      fontSize: isPhone ? 12 : 14,
      lineHeight: isPhone ? 16 : 20,
      color: colors.black22,
      textDecorationLine: "none",
      height: isPhone ? 16 : 20,
    },
  });

  return (
    <>
      {Boolean(isHome || isClassroom || isCrush) && (
        <View
          style={{
            paddingTop: isCrush && !isPhone ? 0 : isPhone ? 16 : 100,
            paddingBottom: isCrush && !isPhone ? 0 : isPhone ? 16 : 50,
            justifyContent: "center",
            alignItems: "center",
            width: isCrush
              ? isPhone
                ? containerWidth
                : containerWidth / 2
              : containerWidth + 2 * gap,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.grey6C,
              marginBottom: 12,
              backgroundColor: colors.whiteFF,
              paddingHorizontal: isPhone ? 8 : 56,
              textAlign: "center",
            }}
          >
            {isCrush
              ? "SHOP THE LOOKS"
              : isClassroom
              ? `${isClassroom} Collection`
              : "WHAT'S NEW"}
          </LatoRegular>
          {Boolean(!isCrush || (isCrush && isPhone)) && (
            <View
              style={{
                position: "absolute",
                zIndex: -1,
                top: isPhone ? 28 : 116,
                width: containerWidth,
                height: 1,
                backgroundColor: colors.greyE6,
                left: isCrush ? 0 : gap,
              }}
            />
          )}
        </View>
      )}
      <GridWrapper
        containerWidth={containerWidth}
        gap={gap}
        loading={loading}
        catalog={catalog}
        divider={divider}
        isSearch={isSearch}
        style={style}
        wrap={wrap}
        isDetail={isDetail}
        isClassroom={isClassroom}
        isCrush={isCrush}
      >
        {data.map((item, index) => {
          return (
            <GridItem
              key={index}
              index={index}
              divider={divider}
              item={item}
              style={style}
              wrap={wrap}
              isCrush={isCrush}
              isClassroom={isClassroom}
            />
          );
        })}
      </GridWrapper>
      {Boolean(!catalog && !noButton) ? (
        isSearch ? (
          <Buttons
            label={`View All ${isSearch} results`}
            onPress={() => {
              setOpenSearch(false);
              navigate("/products", {
                state: {
                  querry: querry,
                },
              });
            }}
            width={200}
            height={44}
            containerStyle={{
              marginLeft: isPhone ? 28 : isTablet ? 8 : 100,
              marginVertical: 24,
            }}
          />
        ) : (
          <Buttons
            onPress={() => navigate("/products")}
            label="View All"
            isBlack
            containerStyle={{
              borderRadius: 2,
              alignSelf: "center",
            }}
            height={48}
            width={isPhone ? width - 32 : 102}
          />
        )
      ) : null}
    </>
  );
}
