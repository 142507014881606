import React, { useContext, useState } from "react";
import {
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native-web";
import Navbar from "../components/Navbar";
import colors from "../assets/colors/colors";
import Footer from "../components/Footer";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import { Formik } from "formik";
import { api } from "../utils/api";
import { useNavigate } from "react-router";
import ToasterContext from "../contexts/ToastContext";
import { useAuth } from "../contexts/AuthContext";
import { sentenceCase } from "change-case";
import { regexChecker } from "../tools/regexChecker";
import { MyInput } from "../components/Inputs";
import Buttons from "../components/Buttons";

function PhoneConsignmentScreen() {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const { showSuccessToast, showToast } = useContext(ToasterContext);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { user, updateUser } = useAuth();

  const formFieldArr = [
    {
      name: "phone",
      placeholder: "Input your phone number",
      label: "Phone Number",
      required: true,
      prefix: "+62",
    },
  ];

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      const { phone } = value;

      await updateUser({
        user: {
          detail_attributes: { phone_code_id: 99, phone },
        },
      });
      await api.post("/api/users/request_otp");
      navigate("/verification/input-otp");

      showSuccessToast("Code has been sent to your phone number");
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        {/* <Wrapper> */}
        <Navbar backgroundColor={colors.whiteFF} />
        <View
          style={{
            justifyContent: "center",
            marginTop: 100,
            alignItems: "center",
            marginHorizontal: isPhone ? 16 : 0,
            marginBottom: 0.7 * height,
          }}
        >
          <LatoBold
            style={{
              fontSize: 24,
              lineHeight: 30,
              color: colors.grey33,
              marginBottom: 24,
            }}
          >
            Verify account
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: colors.grey33,
              marginBottom: 24,
              textAlign: "center",
            }}
          >
            You will receive a 6 digit OTP via SMS to your inputted phone
            number.
          </LatoRegular>
          <Formik
            onSubmit={handleSubmit}
            validateOnChange={hasSubmitted}
            validateOnBlur={hasSubmitted}
            initialValues={{
              phone: "",
            }}
            validate={(values) => {
              !hasSubmitted && setHasSubmitted(true);
              const errors = {};

              for (const a of formFieldArr) {
                const { name, required, placeholder, label } = a;

                if (required && !values[name]) {
                  errors[name] = "You haven’t input any number yet.";
                } else if (!values[name].startsWith("8")) {
                  errors[
                    name
                  ] = `Please enter a valid Indonesian mobile phone number starting with 8xx`;
                }
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <View>
                {formFieldArr.map(
                  (
                    { name, placeholder, required, hide, label, prefix },
                    index
                  ) => {
                    return (
                      <View key={index}>
                        <MyInput
                          error={errors[name]}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          name={name}
                          placeholder={placeholder}
                          label={label}
                          required={required}
                          hide={hide}
                          prefix={prefix}
                        />
                      </View>
                    );
                  }
                )}
                <Buttons
                  label="Continue"
                  width={"100%"}
                  isBlack
                  onPress={handleSubmit}
                  disabled={errors.email}
                  loading={loading}
                  borderRadius={6}
                  noBorder
                  containerStyle={{ marginVertical: 32 }}
                />
              </View>
            )}
          </Formik>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default PhoneConsignmentScreen;
