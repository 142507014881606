import React from "react";
import { Image, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import ConsignmentMethod1 from "../../assets/images/sell-method-1.svg";
import ConsignmentMethod2 from "../../assets/images/sell-method-2.svg";
import ConsignmentMethod3 from "../../assets/images/sell-method-3.svg";
import { useNavigate, useParams } from "react-router";
import Buttons from "../Buttons";
import { contactDecider } from "../../tools/decider";
import { openWhatsapp } from "../../tools/tools";

function ConsignmentMethodSelection() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();
  const { segment } = useParams();

  const methodArr =
    segment === "consignment"
      ? [
          {
            title: "Belle&Kate ROOM Service",
            icon: ConsignmentMethod1,
            subtitle: [
              "Upload item specifications online and book a pick up slot (Jakarta and Tangerang only)",
              "Admin team will review details virtually and will pick up accepted items only",
              "Further authentication process begins once items are received by the team",
              "Accept or counter suggested selling price",
              "Your items will be listed by the team within a few days after signing price agreement",
              "Please note that period of consignment is minimum a month, in which if item is withdrawn before this window, a penalty charge may apply",
            ],
            navigation: `/account/${segment}/assisted-listing`,
          },
          {
            title: "Register Your Items",
            icon: ConsignmentMethod2,
            subtitle: [
              "Upload item specifications and high quality photographs yourself",
              "Admin team will review details virtually",
              "Accept or counter suggested selling price",
              "Your items will be listed after signing price agreement",
              "Once item is sold, drop off or ship item to our ROOM",
              "We will conduct further authentication and ship item to buyer",
            ],
            navigation: `/account/${segment}/self-listing`,
          },
          {
            title: "Drop-off item",
            icon: ConsignmentMethod3,
            subtitle:
              "To arrange a drop-off of your items at our Belle&Kate warehouse, kindly get in touch with us. Please contact us to schedule your drop-off.",
            navigation: "/book-appointment",
            state: { comes_from: segment.replace("-", "_") },
          },
        ]
      : [
          // {
          //   title: "Belle&Kate ROOM Service",
          //   icon: ConsignmentMethod1,
          //   subtitle: [
          //     "Upload item specifications online and book a pick up slot (Jakarta and Tangerang only)",
          //     "Admin team will review details virtually and will pick up accepted items only",
          //     "Further authentication process begins once items are received by the team",
          //     "Accept or counter suggested selling price",
          //     "Your items will be listed by the team within a few days after signing price agreement",
          //   ],
          //   navigation: `/account/${segment}/assisted-listing`,
          // },
          {
            title: "Drop-off item",
            icon: ConsignmentMethod3,
            subtitle:
              "To arrange a drop-off of your items at our Belle&Kate warehouse, kindly get in touch with us. Please contact us to schedule your drop-off.",
            navigation: "/book-appointment",
            state: { comes_from: segment.replace("-", "_") },
          },
        ];

  const handleChat = () => {
    let message =
      "Hi! I have a consignment item that I'd like to drop off. Can you let me know when is the best time to come by?";
    const contact = contactDecider("OtherConsignment");
    openWhatsapp(message, contact);
  };

  return (
    <View
      style={{
        justifyContent: "center",
        marginTop: isPhone ? -48 : 52,
        alignItems: isPhone ? "flex-start" : "center",
        marginHorizontal: isPhone ? 0 : isTablet ? 48 : 100,
        marginBottom: 125,
      }}
    >
      <LatoRegular
        style={{
          fontSize: 24,
          lineHeight: 32,
          color: colors.grey33,
          marginBottom: methodArr?.length > 1 ? 8 : 32,
          textAlign: "left",
          width: isPhone ? width - 32 : isTablet ? 0.65 * width : 0.5 * width,
        }}
      >
        Sell your item
      </LatoRegular>
      {Boolean(methodArr?.length > 1) && (
        <LatoRegular
          style={{
            fontSize: 18,
            lineHeight: 26,
            color: colors.grey6C,
            marginBottom: 32,
            textAlign: "left",
            width: isPhone ? width - 32 : isTablet ? 0.65 * width : 0.5 * width,
          }}
        >
          {`Choose one of our ${methodArr?.length} easy methods to start selling.`}
        </LatoRegular>
      )}
      {methodArr?.map(
        ({ title, icon, navigation, subtitle, additional, state }, idx) => (
          <View key={idx}>
            <View
              style={{
                borderWidth: 1,
                borderColor: colors.greyBB,
                borderRadius: 4,
                padding: 24,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: isPhone
                  ? width - 32
                  : isTablet
                  ? 0.65 * width
                  : 0.5 * width,
                marginRight: isPhone ? 24 : 0,
                marginBottom: 24,
              }}
            >
              <Image
                source={icon}
                style={{
                  width: isPhone ? 24 : 100,
                  height: isPhone ? 24 : 100,
                  marginRight: isPhone ? 12 : 24,
                }}
              />
              <View>
                <View
                  style={{
                    flexDirection: "row",
                    marginBottom: 8,
                  }}
                >
                  <LatoBold
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      color: colors.grey33,
                      marginBottom: isPhone ? 0 : 8,
                      textAlign: "left",
                    }}
                  >
                    {title}
                    {Boolean(idx === 0 && segment === "consignment") && (
                      <LatoRegular
                        style={{
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.grey6C,
                          marginBottom: isPhone ? 0 : 8,
                          textAlign: "left",
                        }}
                      >
                        {" (min. 5 bags)"}
                      </LatoRegular>
                    )}
                  </LatoBold>
                </View>
                {Boolean(typeof subtitle === "string") ? (
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey6C,
                      marginBottom: 22,
                      textAlign: "left",
                      width: isPhone
                        ? width - 115
                        : isTablet
                        ? 0.65 * width - 175
                        : 0.5 * width - 175,
                    }}
                  >
                    {subtitle}
                  </LatoRegular>
                ) : (
                  <View
                    style={{
                      marginBottom: 22,
                      width: isPhone
                        ? width - 115
                        : isTablet
                        ? 0.65 * width - 175
                        : 0.5 * width - 175,
                    }}
                  >
                    {subtitle.map((itm, i) => (
                      <View key={i} style={{ flexDirection: "row" }}>
                        <LatoRegular
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey6C,
                            textAlign: "left",
                          }}
                        >
                          {`${i + 1}. `}
                        </LatoRegular>
                        <LatoRegular
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey6C,
                            textAlign: "left",
                          }}
                        >
                          {itm}
                        </LatoRegular>
                      </View>
                    ))}
                  </View>
                )}
                <Buttons
                  label={
                    idx === methodArr.length - 1
                      ? "Book an appointment"
                      : "Select"
                  }
                  onPress={() => {
                    navigate(navigation, { state });
                  }}
                  width={idx === methodArr.length - 1 ? 150 : 66}
                />
              </View>
            </View>
            {Boolean(additional) && (
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.redAD,
                  textAlign: "left",
                  marginTop: -16,
                  marginBottom: 24,
                  marginLeft: 6,
                }}
              >
                {"* "}
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey6C,
                    textAlign: "left",
                  }}
                >
                  {additional}
                </LatoRegular>
              </LatoBold>
            )}
          </View>
        )
      )}
    </View>
  );
}

export default ConsignmentMethodSelection;
