import React, { useContext, useEffect, useRef, useState } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import { useLocation, useNavigate, useParams } from "react-router";
import colors from "../assets/colors/colors";
import AccountDetails from "../components/Account/AccountDetails";
import AddressForm from "../components/Account/AddressForm";
import MyAddress from "../components/Account/MyAddress";
import MyOrder from "../components/Account/MyOrder";
import OrderDetail from "../components/Account/OrderDetail";
import SignOut from "../components/Account/SignOut";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import { LatoBold, LatoRegular } from "../components/Text";
import { WrapperInsider } from "../components/Wrapper";
import { useAuth, useLogout } from "../contexts/AuthContext";
import { getDeviceType } from "../tools/Interface";
import DropDownTab from "../components/DropDownTab";
import Modal from "../components/Modal";
import LocationPicker from "../components/LocationPicker";
import Geolocation from "@react-native-community/geolocation";
import ConsignmentListScreen from "./ConsignmentListScreen";
import ConsignmentBranchingScreen from "./ConsignmentBranchingScreen";
import AddListingItem from "../components/Consignment/AddListingItem";
import AddItemPhotos from "../components/Consignment/AddItemPhotos";
import { api } from "../utils/api";
import AppraisalProgress from "../components/Consignment/AppraisalProgress";
import ShipmentTracker from "../components/Account/ShipmentTracker";
import CashoutForm from "../components/Consignment/CashoutForm";
import ToasterContext from "../contexts/ToastContext";
import AppraisalConfirmation from "../components/Consignment/AppraisalConfirmation";
import RewardsScreen from "./RewardsScreen";
import RequestScreen from "./RequestScreen";
import RequestDetail from "../components/Account/RequestDetail";
import PrivacyScreen from "./PrivacyScreen";
import CloseAccountModal from "../components/Account/CloseAccountModal";

export default function AccountScreen() {
  const scrollRef = useRef();
  const { state } = useLocation();
  const { segment, additional, id } = useParams();
  const { showToast } = useContext(ToasterContext);

  const [detailID, setDetailID] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [openAddItemModal, setOpenAddItemModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [openAddPhotosModal, setOpenAddPhotosModal] = useState(false);
  const [openAppraisalModal, setOpenAppraisalModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openCloseAccount, setOpenCloseAccount] = useState(false);
  const [openRefund, setOpenRefund] = useState(false);
  const [openTracker, setOpenTracker] = useState(false);
  const [openCashout, setOpenCashout] = useState(false);
  const [addressObj, setAddressObj] = useState();
  const [userPosition, setUserPosition] = useState({
    latitude: -6.2,
    longitude: 106.8166,
  });
  const [loadingConsignment, setLoadingConsignment] = useState(false);
  const [outService, setOutService] = useState(false);
  const [items, setItems] = useState(state?.consignment_data?.items || []);
  const [selectedTime, setSelectedTime] = useState(
    state?.consignment_data?.selectedTime || false
  );
  const [date, setDate] = useState(state?.consignment_data?.date || "");
  const [editIndex, setEditIndex] = useState(false);
  const [slot, setSlot] = useState([]);

  const navigate = useNavigate();

  const { user, refetch } = useAuth();
  const { logout } = useLogout();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const menuArr = [
    {
      title: "Account Details",
      subtitle: [
        {
          title: "Account Information",
          navigation: "details",
        },
        {
          title: "My Address",
          navigation: "address",
        },
        {
          title: "My Rewards",
          navigation: "rewards",
        },
        {
          title: "My Request",
          navigation: "request",
        },
      ],
    },
    {
      title: "My Order",
      subtitle: [
        {
          title: "All Transactions",
          navigation: "orders",
        },
      ],
    },
    {
      title: "My Sales",
      subtitle:
        user?.detail?.state === "verified"
          ? [
              {
                title: "Seller Dashboard",
                navigation: "seller-dashboard",
              },
              {
                title: "Pickup Request Status",
                navigation: "pickup-status",
              },
            ]
          : [
              {
                title: "Seller Dashboard",
                navigation: "seller-dashboard",
              },
            ],
    },
    {
      title: "Privacy Settings",
      subtitle: [
        {
          title: "Account & Privacy",
          navigation: "privacy",
        },
        {
          title: "Sign Out",
          navigation: "logout",
        },
      ],
    },
  ];

  const phoneDropDown = {
    title: menuArr[state?.index]?.subtitle[state?.idx]?.title || "My Order",
    navigation: menuArr[state?.index]?.subtitle[state?.idx]?.navigation,
    datas: menuArr,
    style: "sideMenuAccount",
  };

  const handleLogout = async () => {
    try {
      await logout();
      await refetch();

      navigate("/");
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  const handleAddItem = async (val) => {
    try {
      setLoadingConsignment(true);
      if (items[editIndex]) {
        items[editIndex] = { ...items[editIndex], ...val };
      }

      setItems(items[editIndex] ? [...items] : [...items, val]);

      setOpenAddItemModal(false);
      setEditIndex(false);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoadingConsignment(false);
    }
  };

  const handleAddPhotos = async (val) => {
    try {
      setLoadingConsignment(true);
      items[editIndex].photos = val;
      setItems(items);

      setOpenAddPhotosModal(false);
      setEditIndex(false);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoadingConsignment(false);
    }
  };

  useEffect(() => {
    refetch();
  }, [additional]);

  useEffect(() => {
    scrollRef?.current?.scrollTo({
      y: 0,
      animated: true,
    });
  }, [segment, additional]);

  const getUserPosition = () => {
    Geolocation.getCurrentPosition((e) => {
      setUserPosition({
        latitude: e.coords.latitude,
        longitude: e.coords.longitude,
      });
    });
  };

  const getSlot = async () => {
    try {
      setLoadingConsignment(true);
      setOutService(false);
      const res = await api.get("/api/pickups/pickup_slot");
      const data = res?.data?.data;

      setSlot(data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
      setSlot([]);
      setDate("");
      setOutService(true);
    } finally {
      setLoadingConsignment(false);
    }
  };

  useEffect(() => {
    if (segment === "consignment" && additional === "assisted-listing") {
      getSlot();
    }
    if (segment === "address" && additional === "edit") {
      getUserPosition();
    }
    if (additional !== "assisted-listing" && !state?.consignment_data) {
      setItems([]);
    }
  }, [segment, additional]);

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        style={{ height: "100vh" }}
        showsVerticalScrollIndicator={false}
      >
        {/* <Wrapper> */}
        <Navbar backgroundColor={colors.whiteFF} />
        <View
          style={{
            flexDirection: isPhone ? "column" : "row",
            justifyContent: Boolean(
              (segment !== "address" &&
                segment !== "consignment" &&
                segment !== "trade-in" &&
                segment !== "direct-sell") ||
                (segment === "address" && !state?.consignment_data)
            )
              ? "flex-start"
              : "center",
            paddingTop: isPhone ? 0 : 120,
            marginBottom: 100,
          }}
        >
          {Boolean(
            isPhone &&
              ((segment !== "address" &&
                segment !== "consignment" &&
                segment !== "trade-in" &&
                segment !== "direct-sell") ||
                (segment === "address" && !state?.consignment_data))
          ) ? (
            <View
              style={{
                position: "absolute",
                zIndex: 5,
                backgroundColor: colors.whiteFF,
                width: "100vw",
              }}
            >
              <DropDownTab item={phoneDropDown} handleLogout={handleLogout} />
            </View>
          ) : (
            Boolean(
              (segment !== "address" &&
                segment !== "consignment" &&
                segment !== "trade-in" &&
                segment !== "direct-sell") ||
                (segment === "address" && !state?.consignment_data)
            ) && (
              <View
                style={{
                  width: isTablet ? 150 : 200,
                  marginLeft: 52,
                }}
              >
                {menuArr.map(({ title: main, subtitle }, index) => (
                  <View
                    key={index}
                    style={{
                      borderBottomWidth: index === menuArr.length - 1 ? 0 : 1,
                      borderBottomColor: colors.greyE6,
                      marginBottom: 16,
                    }}
                  >
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 24,
                      }}
                    >
                      {main}
                    </LatoBold>
                    {subtitle.map(({ title, navigation }, idx) => (
                      <TouchableOpacity
                        onPress={() => {
                          if (
                            index === menuArr.length - 1 &&
                            idx === subtitle.length - 1
                          ) {
                            handleLogout();
                          } else {
                            navigate(`/account/${navigation}`, {
                              state: { index: index, idx: idx },
                            });
                          }
                        }}
                        key={idx}
                        style={{
                          marginBottom: 20,
                        }}
                      >
                        <View
                          style={{
                            borderBottomColor:
                              index === menuArr.length - 1 &&
                              idx === subtitle.length - 1
                                ? colors.redB6
                                : colors.grey33,
                            borderBottomWidth:
                              (state?.idx === idx && state?.index === index) ||
                              (!state && index === 1)
                                ? 1
                                : 0,
                            paddingBottom: 4,
                            width:
                              title.length * (title.length < 15 ? 7.75 : 6.75),
                          }}
                        >
                          <LatoRegular
                            style={{
                              fontSize: 14,
                              lineHeight: 20,
                              color:
                                index === menuArr.length - 1 &&
                                idx === subtitle.length - 1
                                  ? colors.redB6
                                  : state?.idx === idx && state?.index === index
                                  ? colors.grey33
                                  : colors.grey6C,
                            }}
                          >
                            {title}
                          </LatoRegular>
                        </View>
                      </TouchableOpacity>
                    ))}
                  </View>
                ))}
              </View>
            )
          )}
          <View
            style={{
              width:
                isPhone ||
                segment === "seller-dashboard" ||
                segment === "consignment" ||
                segment === "trade-in" ||
                segment === "direct-sell"
                  ? width
                  : isTablet
                  ? width - 300
                  : width - 450,
              marginHorizontal: isPhone ? 16 : 0,
              marginLeft:
                segment === "address" && state?.consignment_data
                  ? isPhone
                    ? 16
                    : isTablet
                    ? 125
                    : 200
                  : isPhone
                  ? 16
                  : segment === "consignment" ||
                    segment === "trade-in" ||
                    segment === "direct-sell" ||
                    segment === "seller-dashboard"
                  ? 0
                  : isTablet
                  ? 50
                  : 125,
              marginTop: isPhone ? 48 : 0,
            }}
          >
            <WrapperInsider
              engineeredHeight={height - 275}
              noScroll={Boolean(
                segment === "consignment" ||
                  segment === "trade-in" ||
                  segment === "direct-sell" ||
                  (segment === "address" && state?.consignment_data)
              )}
            >
              {segment === "details" ? (
                <AccountDetails />
              ) : segment === "logout" ? (
                <SignOut refetch={refetch} />
              ) : segment === "privacy" ? (
                <PrivacyScreen
                  email={user?.email}
                  setOpen={setOpenCloseAccount}
                />
              ) : segment === "orders" ? (
                additional ? (
                  <OrderDetail
                    detailID={detailID}
                    setOpenRefund={setOpenRefund}
                    openRefund={openRefund}
                  />
                ) : (
                  <MyOrder
                    setDetailID={setDetailID}
                    setOpenTracker={setOpenTracker}
                    setOpenRefund={setOpenRefund}
                  />
                )
              ) : segment === "seller-dashboard" ? (
                <ConsignmentListScreen
                  setOpenModal={setOpenAppraisalModal}
                  setOpenCashout={setOpenCashout}
                  openModal={openAppraisalModal}
                  setSelected={setEditIndex}
                  openCashout={openCashout}
                />
              ) : segment === "pickup-status" ||
                (segment === "consignment" && additional) ||
                (segment === "trade-in" && additional) ||
                (segment === "direct-sell" && additional) ? (
                <ConsignmentBranchingScreen
                  setOpenModal={setOpenAddItemModal}
                  setOpenAddPhotosModal={setOpenAddPhotosModal}
                  setEditIndex={setEditIndex}
                  items={items}
                  setItems={setItems}
                  selectedTime={selectedTime}
                  setSelectedTime={setSelectedTime}
                  date={date}
                  setDate={setDate}
                  slot={slot}
                  outService={outService}
                />
              ) : segment === "address" && additional ? (
                <>
                  <AddressForm
                    user={user}
                    setOpenModal={setOpenModal}
                    addressObj={addressObj}
                  />
                </>
              ) : segment === "address" ? (
                <MyAddress user={user} />
              ) : segment === "rewards" ? (
                <RewardsScreen user={user} />
              ) : segment === "request" ? (
                <RequestScreen
                  openRequest={openRequestModal}
                  setOpenRequest={setOpenRequestModal}
                />
              ) : (
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    height: height - 500,
                  }}
                >
                  <LatoBold style={{ fontSize: 24, lineHeight: 32 }}>
                    Page Not Found
                  </LatoBold>
                </View>
              )}
            </WrapperInsider>
          </View>
        </View>
        <Footer />
      </ScrollView>
      {Boolean(openModal) && (
        <Modal>
          <LocationPicker
            width={isPhone ? width - 80 : 356}
            setOpenModal={setOpenModal}
            latitude={Number(user?.address?.latitude) || userPosition.latitude}
            longitude={
              Number(user?.address?.longitude) || userPosition.longitude
            }
            setAddressObj={setAddressObj}
            addressObj={addressObj}
          />
        </Modal>
      )}
      {Boolean(openAddItemModal) && (
        <Modal>
          <AddListingItem
            setOpenModal={setOpenAddItemModal}
            handleAddItem={handleAddItem}
            item={items[editIndex] || {}}
            loading={loadingConsignment}
            handleAddPhoto={handleAddPhotos}
            photos={items[editIndex]?.photos || []}
            width={width}
            isPhone={isPhone}
          />
        </Modal>
      )}
      {Boolean(openAddPhotosModal) && (
        <Modal>
          <AddItemPhotos
            setOpenModal={setOpenAddPhotosModal}
            handleAddItem={handleAddPhotos}
            photos={items[editIndex]?.photos || []}
            loading={loadingConsignment}
            isPhone={isPhone}
            height={height}
          />
        </Modal>
      )}
      {Boolean(openAppraisalModal) && (
        <Modal width={isPhone ? width - 32 : 750}>
          <AppraisalProgress
            setOpenModal={setOpenAppraisalModal}
            setOpenConfirmationModal={setOpenConfirmationModal}
            openConfirmationModal={openConfirmationModal}
            selected={editIndex}
            loading={loadingConsignment}
            isPhone={isPhone}
            height={height}
            width={width}
          />
        </Modal>
      )}
      {Boolean(openTracker) && (
        <Modal width={isPhone ? width - 32 : 500}>
          <ShipmentTracker id={detailID} setOpenModal={setOpenTracker} />
        </Modal>
      )}
      {Boolean(openCashout || openRefund) && (
        <Modal width={isPhone ? width - 32 : 500}>
          <CashoutForm
            id={openRefund ? additional || id : editIndex}
            setOpenModal={openRefund ? setOpenRefund : setOpenCashout}
            refund={openRefund}
          />
        </Modal>
      )}
      {Boolean(openConfirmationModal) && (
        <Modal width={isPhone ? width - 32 : 500}>
          <AppraisalConfirmation
            selected={editIndex}
            setOpenConfirmationModal={setOpenConfirmationModal}
            setOpenAppraisalModal={setOpenAppraisalModal}
          />
        </Modal>
      )}
      {Boolean(openRequestModal) && (
        <RequestDetail id={openRequestModal} setOpen={setOpenRequestModal} />
      )}
      {Boolean(openCloseAccount) && (
        <CloseAccountModal setOpen={setOpenCloseAccount} refetch={refetch} />
      )}
    </View>
  );
}
