import React from "react";
import { View, useWindowDimensions } from "react-native-web";
import { getDeviceType } from "../tools/Interface";
import { HiCheckCircle } from "react-icons/hi2";
import colors from "../assets/colors/colors";
import { TfiLayoutLineSolid } from "react-icons/tfi";
import { LatoRegular } from "./Text";
import { BiCircle } from "react-icons/bi";

function CheckoutTracker({ position = "address" }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;

  return (
    <View
      style={{
        paddingHorizontal: gap,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 32,
        marginTop: isTablet ? 48 : 100,
      }}
    >
      <View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <HiCheckCircle size={24} color={colors.grey33} />
          <TfiLayoutLineSolid
            size={isPhone ? width / 10 : 60}
            color={colors.grey33}
          />
        </View>
        <LatoRegular>Cart</LatoRegular>
      </View>
      <View style={{ alignItems: "center" }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TfiLayoutLineSolid
            size={isPhone ? width / 10 : 60}
            color={colors.grey33}
          />
          {position === "payment" || position === "shipment" ? (
            <HiCheckCircle size={24} color={colors.grey33} />
          ) : (
            <BiCircle size={24} color={colors.grey33} />
          )}
          <TfiLayoutLineSolid
            size={isPhone ? width / 10 : 60}
            color={
              position === "payment" || position === "shipment"
                ? colors.grey33
                : colors.greyE6
            }
          />
        </View>
        <LatoRegular>Address</LatoRegular>
      </View>
      <View style={{ alignItems: "center" }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TfiLayoutLineSolid
            size={isPhone ? width / 10 : 60}
            color={
              position === "payment" || position === "shipment"
                ? colors.grey33
                : colors.greyE6
            }
          />
          {position === "payment" ? (
            <HiCheckCircle size={24} color={colors.grey33} />
          ) : (
            <BiCircle
              size={24}
              color={position === "shipment" ? colors.grey33 : colors.greyE6}
            />
          )}
          <TfiLayoutLineSolid
            size={isPhone ? width / 10 : 60}
            color={position === "payment" ? colors.grey33 : colors.greyE6}
          />
        </View>
        <LatoRegular>Shipping</LatoRegular>
      </View>
      <View style={{ alignItems: "center" }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TfiLayoutLineSolid
            size={isPhone ? width / 10 : 60}
            color={position === "payment" ? colors.grey33 : colors.greyE6}
          />
          <BiCircle
            size={24}
            color={position === "payment" ? colors.grey33 : colors.greyE6}
          />
        </View>
        <LatoRegular>Payment</LatoRegular>
      </View>
    </View>
  );
}

export default CheckoutTracker;
