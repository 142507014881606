import React, { useState } from "react";
import { TouchableOpacity, ActivityIndicator, View } from "react-native";
import colors from "../assets/colors/colors";
import { LatoBold } from "./Text";

export default function Buttons({
  label = "",
  onPress = () => {},
  width = "100%",
  height = 40,
  isBlack = false,
  noBorder = false,
  secondButton = false,
  disabled = false,
  backgroundColor = false,
  color = false,
  hover = false,
  hoverColor = colors.transparentBlack,
  hoverTextColor = colors.grey33,
  borderRadius = false,
  loading = false,
  containerStyle = {},
  icon = false,
  rightIcon = false,
  iconStyle = "space-between",
  fontSize,
  lineHeight,
  textStyle = {},
  borderColor = false,
}) {
  const [mouseEffect, setMouseEffect] = useState(false);
  return (
    <TouchableOpacity
      onMouseEnter={() => {
        hover && setMouseEffect(true);
      }}
      onMouseLeave={() => {
        hover && setMouseEffect(false);
      }}
      onPress={onPress}
      disabled={disabled || loading}
      style={{
        width,
        height,
        backgroundColor: mouseEffect
          ? hoverColor
          : backgroundColor
          ? backgroundColor
          : disabled
          ? colors.greyBB
          : isBlack
          ? colors.grey33
          : colors.whiteFF,
        borderRadius: borderRadius ? borderRadius : noBorder ? 0 : 6,
        borderColor: disabled
          ? colors.greyBB
          : borderColor || color || colors.grey33,
        borderWidth: noBorder ? 0 : 1,
        justifyContent: "center",
        marginLeft: secondButton ? 40 : 0,
        ...containerStyle,
      }}
    >
      {loading ? (
        <ActivityIndicator
          color={color ? color : isBlack ? colors.whiteFF : colors.grey33}
          size={24}
        />
      ) : (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: iconStyle ? iconStyle : "space-between",
          }}
        >
          {Boolean(icon) ? icon : <View />}
          <LatoBold
            style={{
              fontSize: fontSize || 14,
              lineHeight: lineHeight || 20,
              color: mouseEffect
                ? hoverTextColor
                : color
                ? color
                : isBlack
                ? colors.whiteFF
                : colors.grey33,
              textAlign: "center",
              textDecorationLine:
                !isBlack && noBorder && !backgroundColor ? "underline" : "none",
              ...textStyle,
              // textShadowColor: colors.black00,
              // textShadowOffset: { width: 1, height: 1 },
              // textShadowRadius: 1,
            }}
          >
            {label}
          </LatoBold>
          {Boolean(rightIcon) ? rightIcon : <View />}
        </View>
      )}
    </TouchableOpacity>
  );
}
