import React, { useContext, useState } from "react";
import { api } from "../../utils/api";
import ToasterContext from "../../contexts/ToastContext";
import { TouchableOpacity, View, useWindowDimensions } from "react-native-web";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { GrClose } from "react-icons/gr";
import { RiCheckLine } from "react-icons/ri";
import { useNavigate } from "react-router";
import Buttons from "../Buttons";
import { getDeviceType } from "../../tools/Interface";

function AppraisalConfirmation({
  selected,
  setOpenConfirmationModal = () => {},
  setOpenAppraisalModal = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const { showToast } = useContext(ToasterContext);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await api.put(`api/consignments/${selected}`, { state: "accept" });
      setOpenConfirmationModal(false);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <LatoBold
          style={{ fontSize: 20, lineHeight: 28, color: colors.grey33 }}
        >
          Accept appraisal offer?
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenConfirmationModal(false)}>
          <GrClose size={16} />
        </TouchableOpacity>
      </View>
      <LatoRegular
        style={{ fontSize: 14, lineHeight: 20, color: colors.grey6C }}
      >
        By accepting, you acknowledge that the offered price is final and cannot
        be changed. Period of consignment is minimum a month, in which if item
        is withdrawn before this window, a penalty charge may apply.{" "}
      </LatoRegular>
      <View
        style={{
          flexDirection: "row",
          marginTop: 32,
          marginBottom: 16,
        }}
      >
        <TouchableOpacity
          onPress={() => setChecked(!checked)}
          style={{
            backgroundColor: checked ? colors.grey33 : colors.whiteFF,
            borderRadius: 4,
            borderWidth: 1,
            borderColor: checked ? colors.grey33 : colors.greyE6,
            padding: 2,
            justifyContent: "center",
            alignItems: "center",
            height: 16,
            width: 16,
            marginRight: 8,
          }}
        >
          <RiCheckLine size={12} color={colors.whiteFF} />
        </TouchableOpacity>
        <LatoRegular
          style={{
            fontSize: 12,
            lineHeight: 16,
            color: colors.grey6C,
          }}
        >
          {"I’ve read and agreed to the consignment "}
          <TouchableOpacity
            onPress={() => {
              navigate("/terms-conditions");
              setOpenConfirmationModal(false);
              setOpenAppraisalModal(false);
            }}
          >
            <LatoBold
              style={{
                fontSize: 12,
                lineHeight: 16,
                color: colors.grey33,
                textDecorationLine: "underline",
              }}
            >
              Terms and Conditions
            </LatoBold>
          </TouchableOpacity>
        </LatoRegular>
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Buttons
          label="Cancel"
          loading={loading}
          onPress={() => setOpenConfirmationModal(false)}
          width={(isPhone ? width - 32 : 500) / 2 - 48}
        />
        <Buttons
          isBlack
          label="Accept offer"
          loading={loading}
          onPress={handleSubmit}
          disabled={!checked}
          width={(isPhone ? width - 32 : 500) / 2 - 48}
        />
      </View>
    </View>
  );
}

export default AppraisalConfirmation;
