import React from "react";
import { View } from "react-native";
import { PTSerifBold } from "./Text";
import colors from "../assets/colors/colors";
import { upperCase } from "lodash";

function RewardBadge({ tier, selected = true, noBorder = false }) {
  return (
    <View style={{ flexDirection: "row" }}>
      <PTSerifBold
        style={{
          fontSize: 14,
          lineHeight: 20,
          color: selected
            ? tier === "ostrich"
              ? colors.green5B
              : tier === "croco"
              ? colors.goldA1
              : tier === "togo" && !noBorder
              ? colors.grey6C
              : colors.grey33
            : colors.grey33,
          backgroundColor: selected
            ? tier === "regular"
              ? "transparent"
              : tier === "togo"
              ? colors.whiteF1
              : tier === "ostrich"
              ? colors.greenDD
              : colors.lightYellow
            : colors.greyE6,
          paddingHorizontal: tier === "regular" ? 0 : 12,
          paddingVertical: 4,
          borderRadius: 4,
          borderWidth: tier === "regular" || noBorder ? 0 : 1,
          borderColor: selected
            ? tier === "ostrich"
              ? colors.green5B
              : tier === "croco"
              ? colors.goldA1
              : colors.grey6C
            : colors.grey33,
        }}
      >
        {tier === "regular" ? "No Tier" : upperCase(tier)}
      </PTSerifBold>
    </View>
  );
}

export default RewardBadge;
