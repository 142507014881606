import Geolocation from "@react-native-community/geolocation";
import React, { useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { View, TouchableOpacity } from "react-native";
import MapView from "react-native-web-maps";
import colors from "../assets/colors/colors";
import { LatoBold, LatoRegular } from "./Text";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  geocodeByLatLng,
} from "react-google-places-autocomplete";
import { GMAPS_API } from "../constants/api";
import Marker from "react-native-web-maps/dist/Marker";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { BiTargetLock } from "react-icons/bi";
import Buttons from "./Buttons";

export default function LocationPicker({
  latitude = -6.2,
  longitude = 106.8166,
  width = "50vw",
  height = "70vh",
  setOpenModal = () => {},
  setAddressObj = () => {},
  addressObj,
}) {
  const [desiredLocation, setDesiredLocation] = useState({
    latitude: addressObj?.geometry
      ? addressObj?.geometry?.location?.lat()
      : latitude,
    longitude: addressObj?.geometry
      ? addressObj?.geometry?.location?.lng()
      : longitude,
    latitudeDelta: 0.01,
    longitudeDelta: 0.05,
  });
  const [address, setAddress] = useState(addressObj || "");

  const getCurrentLocation = (isSubmit = false) => {
    Geolocation.getCurrentPosition((e) => {
      setDesiredLocation({
        ...desiredLocation,
        latitude: e.coords.latitude,
        longitude: e.coords.longitude,
      });
      geocodeByLatLng({
        lat: e.coords.latitude,
        lng: e.coords.longitude,
      })
        .then((results) => {
          setAddress({
            ...results[0],
            label: results[0]?.formatted_address,
            value: { place_id: results[0]?.place_id },
          });

          if (isSubmit) {
            setAddressObj({
              ...results[0],
              label: results[0]?.formatted_address,
              value: { place_id: results[0]?.place_id },
            });
          }
        })
        .catch((error) => console.error(error));
    });
  };

  const onMovePinPoint = (e) => {
    setDesiredLocation({
      ...desiredLocation,
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng(),
    });

    geocodeByLatLng({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    })
      .then((results) => {
        setAddress({
          ...results[0],
          label: results[0]?.formatted_address,
          value: { place_id: results[0]?.place_id },
        });
      })
      .catch((error) => console.error(error));
  };

  const zoomIn = () => {
    setDesiredLocation({
      ...desiredLocation,
      longitudeDelta:
        desiredLocation?.latitudeDelta < 0.0011
          ? desiredLocation?.longitudeDelta
          : desiredLocation?.longitudeDelta - 0.01,
      latitudeDelta:
        desiredLocation?.latitudeDelta < 0.0011
          ? desiredLocation?.latitudeDelta
          : desiredLocation?.latitudeDelta < 0.0031
          ? desiredLocation?.latitudeDelta - 0.002
          : desiredLocation?.latitudeDelta < 0.012
          ? desiredLocation?.latitudeDelta - 0.0035
          : desiredLocation?.latitudeDelta - 0.05,
    });
  };

  const zoomOut = () => {
    setDesiredLocation({
      ...desiredLocation,
      longitudeDelta:
        desiredLocation?.latitudeDelta < 0.15
          ? desiredLocation?.longitudeDelta + 0.01
          : desiredLocation?.longitudeDelta,
      latitudeDelta:
        desiredLocation?.latitudeDelta < 0.0065
          ? desiredLocation?.latitudeDelta + 0.0035
          : desiredLocation?.latitudeDelta < 0.15
          ? desiredLocation?.latitudeDelta + 0.05
          : desiredLocation?.latitudeDelta,
    });
  };

  useEffect(() => {
    if (typeof address === "object" && address?.label !== addressObj?.label) {
      setTimeout(() => {
        geocodeByAddress(address.label)
          .then((results) => getLatLng(results[0]))
          .then(({ lat, lng }) => {
            setDesiredLocation({ latitude: lat, longitude: lng });
          });
      }, 500);
    }
  }, [address]);

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <LatoBold
          style={{
            fontSize: 20,
            lineHeight: 28,
            color: colors.grey33,
          }}
        >
          Mark Location
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenModal(false)}>
          <IoMdClose size={24} color={colors.grey33} />
        </TouchableOpacity>
      </View>
      <LatoRegular
        style={{
          fontSize: 14,
          lineHeight: 20,
          color: colors.grey33,
        }}
      >
        Make sure your location match with your address.
      </LatoRegular>
      <View style={{ marginVertical: 16, zIndex: 2 }}>
        <GooglePlacesAutocomplete
          apiOptions={{ language: "en", region: "id" }}
          apiKey={GMAPS_API}
          selectProps={{
            value: address["value"] ? address : "",
            onChange: setAddress,
          }}
        />
      </View>
      <View style={{ height, width, marginBottom: 24 }}>
        <MapView
          region={desiredLocation}
          options={{
            disableDefaultUI: true,
          }}
        >
          <Marker
            coordinate={desiredLocation}
            draggable
            onDragEnd={(e) => {
              onMovePinPoint(e);
            }}
          />
          <View style={{ position: "absolute", bottom: 0, right: -12 }}>
            <TouchableOpacity
              style={{
                backgroundColor: colors.whiteFC,
                borderRadius: 2,
                padding: 8,
                marginVertical: 4,
                shadowColor: colors.whiteE8,
                shadowOffset: { height: 1, width: 1 },
                shadowRadius: 8,
                shadowOpacity: 0.85,
              }}
              onPress={zoomIn}
            >
              <AiOutlinePlus size={16} />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: colors.whiteFC,
                borderRadius: 2,
                padding: 8,
                marginVertical: 4,
                shadowColor: colors.whiteE8,
                shadowOffset: { height: 1, width: 1 },
                shadowRadius: 8,
                shadowOpacity: 0.85,
              }}
              onPress={zoomOut}
            >
              <AiOutlineMinus size={16} />
            </TouchableOpacity>
            <TouchableOpacity
              style={{
                backgroundColor: colors.whiteFC,
                borderRadius: 2,
                padding: 8,
                marginVertical: 4,
                shadowColor: colors.whiteE8,
                shadowOffset: { height: 1, width: 1 },
                shadowRadius: 8,
                shadowOpacity: 0.85,
              }}
              onPress={getCurrentLocation}
            >
              <BiTargetLock size={16} />
            </TouchableOpacity>
          </View>
        </MapView>
      </View>
      <Buttons
        onPress={() => {
          if (address) {
            setAddressObj(address);
          } else {
            getCurrentLocation(true);
          }
          setOpenModal(false);
        }}
        isBlack
        label="Set this location"
      />
    </View>
  );
}
