import React from "react";
import { View } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { formatCurrency } from "../../tools/formatter";
import { format } from "date-fns";
import { useWindowDimensions } from "react-native-web";
import { getDeviceType } from "../../tools/Interface";
import RewardBadge from "../RewardBadge";

function RewardSummary({ current, expiry, tier, next }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const summaryArr = [
    {
      title: "Current spending",
      val: `IDR ${formatCurrency(current, true)}`,
    },
    {
      title: tier === "croco" ? "Congratulations! ✨" : "To reach next tier",
      val:
        tier === "croco"
          ? "You've reached the highest tier"
          : `IDR ${formatCurrency(Number(next), true)}`,
    },
    {
      title: "Rewards expiry",
      val: expiry ? format(new Date(expiry), "do MMM yyyy") : "",
    },
  ];
  return (
    <View
      style={{
        borderWidth: 2,
        borderColor: colors.greyE6,
        borderRadius: 8,
        padding: 16,
      }}
    >
      <RewardBadge tier={tier} />
      {Boolean(tier === "regular") && (
        <LatoRegular
          style={{ fontSize: 14, lineHeight: 20, color: colors.grey6C }}
        >
          Once you reach Togo tier, your progress will be updated
        </LatoRegular>
      )}
      <View
        style={{
          paddingTop: 16,
          marginTop: 16,
          flexDirection: "row",
          alignItems: "center",
          borderTopColor: colors.greyE6,
          borderTopWidth: 1,
        }}
      >
        {summaryArr.map(({ title, val }, i) => (
          <View
            key={i}
            style={{
              width:
                ((isPhone ? width : isTablet ? width - 300 : width - 600) -
                  64) /
                3,
              borderRightWidth: i === summaryArr.length - 1 ? 0 : 1,
              borderRightColor: colors.greyBB,
              marginRight: 16,
              paddingRight: 16,
            }}
          >
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey33,
                marginBottom: 8,
              }}
            >
              {title}
            </LatoBold>
            <LatoRegular
              style={{ fontSize: 14, lineHeight: 20, color: colors.grey33 }}
            >
              {val}
            </LatoRegular>
          </View>
        ))}
      </View>
    </View>
  );
}

export default RewardSummary;
