import React from "react";
import { View } from "react-native";
import { LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";

function NeedKYC({ navigate, detail = {} }) {
  return (
    <View
      style={{
        justifyContent: "center",
        marginTop: 48,
        alignItems: "center",
      }}
    >
      <LatoRegular
        style={{
          fontSize: 20,
          lineHeight: 24,
          color: colors.grey33,
          marginBottom: 24,
        }}
      >
        Verify your account to start selling with Belle and Kate.
      </LatoRegular>
      <Buttons
        label={
          detail?.phone_verified ? "Continue verification" : "Verify account"
        }
        isBlack
        width={250}
        onPress={() => {
          navigate(
            detail?.phone_verified
              ? "/verification/upload-id"
              : "/verification/input-phone"
          );
        }}
      />
    </View>
  );
}

export default NeedKYC;
