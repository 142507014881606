import React, { useContext, useEffect } from "react";
import colors from "../assets/colors/colors";
import { useDropzone } from "react-dropzone";
import { View } from "react-native";
import { FiFileText } from "react-icons/fi";
import { LatoBold, LatoRegular } from "./Text";
import { useFilePicker } from "use-file-picker";
import ToasterContext from "../contexts/ToastContext";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";

function DragDropFile({
  setImage = () => {},
  setImageURI = () => {},
  image = [],
  multiple = false,
  maxFile = 1,
  isSquare = false,
  width = "auto",
}) {
  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const acceptedFormat = ["image/png", "image/jpeg", "image/jpg"];

  const { getRootProps, isDragActive, acceptedFiles } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      // "image/tiff": [".tif", ".tiff"],
      // "image/heic": [".heic"],
    },
    maxFiles:
      multiple && maxFile - image?.length > 0
        ? maxFile - image?.length
        : multiple
        ? 0
        : 1,
    multiple,
    maxSize: 10000000,
  });

  const [
    openFileSelector,
    { filesContent, loading: pickerLoading, plainFiles, errors },
  ] = useFilePicker({
    accept: [".png", ".jpg", ".jpeg"],
    readAs: "DataURL",
    multiple,
    maxFileSize: 10,
    limitFilesConfig: {
      min: 1,
      max:
        multiple && maxFile - image?.length > 0 ? maxFile - image?.length : 1,
    },
    onFilesRejected: (data) => {
      if (data?.errors[0]?.maxLimitExceeded) {
        showSuccessToast(
          `Maximum file to upload is ${maxFile - image?.length} files`
        );
      }
    },
  });

  const handleDrop = (e) => {
    // Note: preventDefault is on nativeEvent
    e.nativeEvent.preventDefault();
    if (!e) return;
    // Note: files are under nativeEvent.dataTransfer
    const files = e.nativeEvent.dataTransfer.files;
    const fileList = Array.from(files);
    if (
      image.length < maxFile &&
      maxFile - image?.length > 0 &&
      maxFile - image?.length >= fileList?.length
    ) {
      console.log(
        "maxFile - image?.length <= fileList?.length:",
        maxFile - image?.length,
        fileList?.length,
        maxFile - image?.length >= fileList?.length
      );
      const images = fileList
        .map((image) => {
          if (acceptedFormat.includes(image.type)) {
            return {
              imageName: image.name,
              imageFile: image,
            };
          } else {
            showSuccessToast("We only accept .JPG, .JPEG, or .PNG extension");
          }
        })
        .filter((item) => {
          if (item) return item;
        });

      if (multiple) {
        // images.map(({ imageFile: file }, idx) => {
        //   images[idx].content = URL.createObjectURL(file);
        // });

        setImage([...image, ...images]);
        return;
      }

      images.map(({ imageFile: file }) => {
        setImage({
          content: URL.createObjectURL(file),
          ...file,
        });
        // setImageURI(file);
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          // Do whatever you want with the file contents
          setImageURI(file);
        };

        reader.readAsDataURL(file);
      });
    } else {
      showSuccessToast(
        `Maximum file to upload is ${maxFile - image?.length} files`
      );
    }
  };

  const handleSelectedImage = () => {
    if (multiple) {
      // plainFiles.map((file, idx) => {
      //   plainFiles[idx].content = URL.createObjectURL(file);
      // });

      setImage([...image, ...plainFiles]);
      return;
    }

    plainFiles.map((file) => {
      setImage({ content: URL.createObjectURL(file), ...file });

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        setImageURI(file);
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    handleSelectedImage();
  }, [filesContent]);

  return (
    <div
      {...getRootProps()}
      onClick={(event) => {
        if (image.length < maxFile) {
          openFileSelector();
        } else {
          showSuccessToast(`We only accept ${maxFile} photos for each item`);
        }
      }}
      style={{
        padding: 20,
        borderWidth: 1,
        borderColor: isDragActive ? colors.green03 : colors.greyBB,
        borderRadius: 4,
        borderStyle: isSquare ? "none" : "dashed",
        backgroundColor: isSquare ? colors.greyE6 : colors.transparentGreyE6,
        marginBottom: 16,
        cursor: image.length < maxFile ? "grab" : "default",
        width,
        height: isSquare ? 0.75 * width : "auto",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => {
        handleDrop(e);
      }}
    >
      <View>
        <View
          style={{
            borderRadius: 8,
            marginBottom: isSquare ? 0 : 12,
          }}
        >
          <View
            style={{
              paddingHorizontal: 12,
              paddingVertical: 10,
              backgroundColor: colors.whiteF1,
              width: isSquare ? 60 : 48,
              alignSelf: "center",
              height: isSquare ? 60 : 44,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isSquare ? (
              <MdOutlineAddPhotoAlternate size={24} />
            ) : (
              <FiFileText size={24} color={colors.grey33} />
            )}
          </View>
        </View>
        {Boolean(!isSquare) && (
          <View>
            <LatoBold
              style={{
                fontSize: 14,
                lineHeight: 20,
                color: colors.grey33,
                marginBottom: 4,
                textAlign: "center",
              }}
            >
              Browse or drag files here to upload
            </LatoBold>
            <LatoRegular
              style={{
                fontSize: 12,
                lineHeight: 16,
                color: colors.grey6C,
                textAlign: "center",
              }}
            >
              Max. size for each file: 10 MB
            </LatoRegular>
          </View>
        )}
      </View>
    </div>
  );
}

export default DragDropFile;
