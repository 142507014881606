import React, { useCallback, useEffect, useRef, useState } from "react";
import Tag1 from "../../assets/images/tag-1.svg";
import Tag2 from "../../assets/images/tag-2.svg";
import Tag3 from "../../assets/images/tag-3.svg";
import Tag4 from "../../assets/images/tag-4.svg";
import Tag5 from "../../assets/images/tag-5.svg";
import { Image, ScrollView, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";

function Commitment() {
  const [count, setCount] = useState(0);

  const commitmentScrollRef = useRef();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const arr = [
    {
      image: Tag1,
      title: "Guaranteed Authenticity",
      message:
        "All items available are guaranteed 100% authentic. Our in-house experts assess the items with strict quality control policies.",
    },
    {
      image: Tag2,
      title: "Most Compatible Rates",
      message:
        "All the goods listed is at a competitive price. We provide three different methods such as consignment, direct sell and trade-in.",
    },
    {
      image: Tag3,
      title: "Curated Inventory",
      message:
        "Items available in our site is carefully curated by our in-house.",
    },
    {
      image: Tag4,
      title: "Dedicated Client Advisors",
      message:
        "Our team of client advisors are ready to help you with inquiries and requests.",
    },
    {
      image: Tag5,
      title: "Private Courier Pickup Service",
      message:
        "Our trusted in-house courier will assure to deliver your luxury goods safely to your home",
    },
  ];

  const autoSlideCarousel = useCallback(() => {
    if (isPhone) {
      setTimeout(() => {
        const multiplier = count + 1;
        const slideX = width - 16;
        const slide = multiplier * slideX;

        commitmentScrollRef?.current?.scrollTo({
          x: slide,
          y: 0,
          animated: true,
        });
        setCount(count === arr.length - 2 ? -1 : count + 1);
      }, 5000);
    }
  }, [count, arr.length, width, isPhone]);

  useEffect(() => {
    autoSlideCarousel();
  }, [count, autoSlideCarousel]);

  const CommitItems = () =>
    arr.map(({ image, message, title }, idx) => (
      <View
        key={idx}
        style={{
          flex: 1,
          justifyContent: !isPhone ? "flex-start" : "space-between",
          alignItems: isPhone ? "center" : "flex-start",
        }}
      >
        <Image
          source={image}
          alt={`Tag ${idx}`}
          style={{
            height: 48,
            width: 48,
          }}
          defaultSource={image}
          resizeMode={"contain"}
        />
        <View
          style={{
            width: isPhone ? width - 16 : "auto",
            paddingRight: 16,
          }}
        >
          <LatoBold
            style={{
              marginVertical: 16,
              fontSize: isPhone
                ? 20
                : isTablet
                ? 12
                : width < 1350
                ? 14
                : width < 1500
                ? 16
                : 20,
              lineHeight: isPhone
                ? 24
                : isTablet
                ? 16
                : width < 1350
                ? 20
                : width < 1500
                ? 24
                : 28,
              color: colors.grey33,
              textAlign: isPhone ? "center" : "left",
            }}
          >
            {title}
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: isTablet && !isPhone ? 10 : 12,
              lineHeight: isTablet && !isPhone ? 12 : 16,
              color: colors.grey33,
              textAlign: isPhone ? "center" : "left",
            }}
          >
            {message}
          </LatoRegular>
        </View>
      </View>
    ));

  return (
    <View
      style={{
        width,
        paddingHorizontal: isPhone ? 16 : isTablet ? 24 : 60,
        paddingTop: 32,
        paddingBottom: 48,
        backgroundColor: colors.whiteFC,
      }}
    >
      <LatoBold
        style={{
          marginBottom: 60,
          fontSize: 24,
          lineHeight: 32,
          color: colors.grey6C,
          textAlign: "center",
        }}
      >
        OUR COMMITMENT
      </LatoBold>
      {isPhone ? (
        <ScrollView
          ref={commitmentScrollRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          onContentSizeChange={autoSlideCarousel}
          style={{ marginBottom: 24 }}
        >
          <CommitItems />
        </ScrollView>
      ) : (
        <View
          style={{
            flexDirection: "row",
            zIndex: 1,
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <CommitItems />
        </View>
      )}
      {Boolean(isPhone) && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: width,
            flexWrap: "wrap",
          }}
        >
          {arr.map((i, idx) => {
            return (
              <View
                style={{
                  backgroundColor:
                    idx === count || (idx === arr.length - 1 && count === -1)
                      ? colors.grey33
                      : colors.greyE6,
                  borderRadius: "100%",
                  width: 12,
                  height: 12,
                  marginHorizontal: isPhone ? 4 : 6,
                  marginBottom: 20,
                }}
                key={idx}
              />
            );
          })}
        </View>
      )}
    </View>
  );
}

export default Commitment;
