import React, { useState } from "react";
import Modal from "../Modal";
import { TouchableOpacity, View } from "react-native-web";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import ImageWithLoader from "../ImageWithLoader";
import { formatCurrency } from "../../tools/formatter";
import { MyInput } from "../Inputs";
import { regexChecker } from "../../tools/regexChecker";
import { api } from "../../utils/api";
import Buttons from "../Buttons";
import { GrClose } from "react-icons/gr";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router";

function NotifyModal({ productData = {}, photos = [], setNotify = () => {} }) {
  const { user } = useAuth();
  const { email } = user || {};
  const navigate = useNavigate();

  const { product = {}, product_id, added_to_notify_me: added } = productData;
  const { summary_detail = {}, name, default_price } = product;
  const { designer } = summary_detail;

  const [loadingApply, setLoadingApply] = useState(false);
  const [showSecondContent, setShowSecondContent] = useState(false);

  const handleApply = async () => {
    try {
      setLoadingApply(true);

      if (!added) {
        await api.post("/api/inventories/notify_me", {
          notify_me: {
            product_id,
          },
        });
      }

      setShowSecondContent(true);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoadingApply(false);
    }
  };

  const handleCloseModal = async () => {
    setNotify(false);
  };

  return (
    <Modal>
      {user?.id ? (
        <View>
          <LatoBold
            style={{
              fontSize: 18,
              lineHeight: 26,
              textAlign: "center",
              paddingBottom: 24,
              marginBottom: 24,
              borderBottomColor: colors.greyE6,
              borderBottomWidth: 1,
            }}
          >
            Notify Me
          </LatoBold>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: 24,
              marginBottom: 24,
              borderBottomColor: colors.greyE6,
              borderBottomWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                width: "100%",
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  padding: 8,
                  borderRadius: 8,
                  justifyContent: "center",
                  marginRight: 16,
                  borderColor: colors.greyE6,
                }}
              >
                {photos.length ? (
                  <ImageWithLoader
                    source={{
                      uri: `${photos[0]}`,
                    }}
                    containerStyle={{
                      width: 100,
                      height: 100,
                    }}
                    defaultStyle={{
                      width: 48,
                      height: 100,
                    }}
                    style={{
                      width: "100%",
                      height: `${100 * 1.5}%`,
                    }}
                    resizeMode={"contain"}
                    woMask
                  />
                ) : null}
              </View>
              <View>
                <View
                  style={{
                    width: 300,
                    flexWrap: "wrap",
                  }}
                >
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 8,
                    }}
                  >
                    {designer}
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 8,
                    }}
                    numberOfLines={2}
                  >
                    {name}
                  </LatoRegular>
                </View>
                <LatoBold
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                  }}
                >{`IDR ${formatCurrency(default_price)}`}</LatoBold>
              </View>
            </View>
          </View>
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey6C,
              marginBottom: 20,
            }}
          >
            {showSecondContent
              ? `We will email you at ${email} when this item becomes available again.`
              : `Tap notify me to receive an email notification at ${email} when a similar item is available.`}
          </LatoRegular>
          <Buttons
            loading={loadingApply}
            onPress={showSecondContent ? handleCloseModal : handleApply}
            isBlack
            label={showSecondContent ? "Done" : "Notify me"}
            borderRadius={2}
          />
        </View>
      ) : (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <LatoRegular
            style={{
              fontSize: 20,
              lineHeight: 28,
              color: colors.grey33,
              textAlign: "center",
              marginVertical: 24,
            }}
          >
            {"Please login or create an account first."}
          </LatoRegular>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Buttons
              label={"Create Account"}
              onPress={() => {
                navigate("/register");
                setNotify(false);
              }}
              width={175}
              containerStyle={{ marginRight: 24 }}
            />
            <Buttons
              label={"Login"}
              onPress={() => {
                navigate("/login");
                setNotify(false);
              }}
              isBlack
              width={175}
            />
          </View>
        </View>
      )}
      <TouchableOpacity
        onPress={handleCloseModal}
        style={{ position: "absolute", top: 12, right: 12 }}
      >
        <GrClose size={16} />
      </TouchableOpacity>
    </Modal>
  );
}

export default NotifyModal;
