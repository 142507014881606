import React from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { BsClockHistory } from "react-icons/bs";
import colors from "../../assets/colors/colors";
import { LatoBold, LatoRegular } from "../Text";

function WaitingKYC({ navigate }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  return (
    <View
      style={{
        justifyContent: "center",
        // marginTop: 100,
        alignItems: "center",
        // marginHorizontal: isPhone ? 16 : isTablet ? 48 : 100,
        width: isPhone ? width - 32 : "100%",
        marginBottom: 0.6 * height,
      }}
    >
      <BsClockHistory size={80} color={colors.black00} />
      <LatoBold
        style={{
          fontSize: 24,
          lineHeight: 30,
          color: colors.grey33,
          marginBottom: 24,
          marginTop: 12,
        }}
      >
        Verifying Your Account
      </LatoBold>
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 24,
          color: colors.grey6C,
          marginBottom: 8,
          textAlign: "center",
        }}
      >
        We're reviewing your account information. It may take up to 24 hours.
      </LatoRegular>
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 24,
          color: colors.grey6C,
          marginBottom: 32,
          textAlign: "center",
        }}
      >
        We'll let you know when your account is verified.
      </LatoRegular>
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <LatoRegular
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey90,
            marginBottom: 8,
            marginRight: 12,
          }}
        >
          If you have any questions, please contact our customer service team.
        </LatoRegular>
        <TouchableOpacity
          onPress={() => {
            navigate("/contact-us");
          }}
        >
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
              marginBottom: 8,
              textDecorationLine: "underline",
            }}
          >
            Contact Us
          </LatoBold>
        </TouchableOpacity>
      </View>
    </View>
  );
}

export default WaitingKYC;
