import React from "react";
import { StyleSheet, View, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import { LatoBold, LatoRegular } from "./Text";
import { appraisalProgressTitle } from "../tools/decider";
import { formatCurrency } from "../tools/formatter";
import { format } from "date-fns";
import { sentenceCase } from "change-case";

function VerticalProgress({ datas = [], shipment = false, isPhone = false }) {
  const { width } = useWindowDimensions();

  return datas?.map((item, index) => {
    const notFirst = index != 0;
    const isLast = datas.length - 1 == index;
    const { lineStyle, mainContainer } = StyleSheet.create({
      lineStyle: {
        width: 1.5,
        flexShrink: 1,
        height: "auto",
        // height:
        //   shipment && item?.name === "confirmed" && isPhone
        //     ? 100
        //     : // : 80
        //     shipment && item?.name === "out_for_pickup"
        //     ? isPhone
        //       ? 100
        //       : 80
        //     : shipment &&
        //       (item?.name === "allocated" ||
        //         item?.name === "picked" ||
        //         item?.name === "out_for_delivery")
        //     ? isPhone
        //       ? 100
        //       : 80
        //     : isPhone
        //     ? 80
        //     : 64,
        backgroundColor: isLast ? "transparent" : colors.grey90,
        marginLeft: 12,
        marginTop: notFirst ? 0 : 24,
        paddingBottom: isLast ? 0 : 12,
      },
      mainContainer: {
        // position: "absolute",
        marginLeft: -3,
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: isLast ? 0 : 24,
      },
    });

    const specialDesc =
      item?.description?.includes("href") &&
      item?.description?.split(">")[1]?.replace("</a", "");

    return (
      <View key={index} style={lineStyle}>
        <View style={mainContainer}>
          <View
            style={{
              backgroundColor: isLast ? "transparent" : colors?.grey33,
              borderWidth: 2,
              borderColor: isLast ? colors?.green46 : colors?.grey33,
              width: 8,
              height: 8,
              borderRadius: "100%",
              marginRight: 8,
            }}
          />
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              justifyContent: isPhone ? "flex-start" : "space-between",
            }}
          >
            <View style={{ paddingTop: -12, marginTop: -8 }}>
              {shipment ? (
                <View
                  style={{
                    flexWrap: "wrap",
                    width: isPhone ? width - 110 : 300,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginBottom: 4,
                    }}
                  >
                    {sentenceCase(item?.name || item?.status) || ""}
                  </LatoRegular>
                  {specialDesc ? (
                    <View>
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: colors.grey6C,
                          marginBottom: 4,
                        }}
                      >
                        {`${item?.description?.split(".")[0]}.  ` || ""}
                        <a
                          href={specialDesc}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            fontSize: 14,
                            color: colors.grey33,
                          }}
                        >
                          <LatoRegular
                            style={{
                              color: colors.grey33,
                              fontSize: 14,
                            }}
                          >
                            Live track disini
                          </LatoRegular>
                        </a>
                      </LatoRegular>
                    </View>
                  ) : (
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        marginBottom: 4,
                      }}
                    >
                      {item?.description || item?.note || ""}
                    </LatoRegular>
                  )}
                </View>
              ) : (
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey6C,
                    marginBottom: 4,
                  }}
                >
                  {appraisalProgressTitle(item) || ""}
                  {Boolean(item?.price && item?.state !== "completed") && (
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                      }}
                    >
                      {formatCurrency(item?.price || 0)}
                    </LatoBold>
                  )}
                </LatoRegular>
              )}
              {Boolean(!shipment) && (
                <LatoRegular
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.grey90,
                  }}
                >
                  {format(new Date(item?.created_date), "dd MMMM yyyy, hh:mm")}
                </LatoRegular>
              )}
            </View>
            {Boolean(shipment) && (
              <View style={{ width: isPhone ? "auto" : 125 }}>
                <LatoRegular
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    marginTop: -6,
                    color: colors.grey90,
                    textAlign: isPhone ? "justify" : "right",
                  }}
                >
                  {/* {item?.created_at || ""} */}
                  {format(new Date(item?.updated_at), "dd MMMM yyyy hh:mm")}
                </LatoRegular>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  });
}

export default VerticalProgress;
