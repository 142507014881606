import React, { useContext, useEffect, useState } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import TopMenuSelection from "../TopMenuSelection";
import { api } from "../../utils/api";
import {
  AiOutlineArrowDown,
  AiOutlineArrowUp,
  AiOutlineBulb,
  AiOutlineClockCircle,
} from "react-icons/ai";
import { customDateFormatter } from "../../tools/formatter";
import { format } from "date-fns";
import {
  pickupStateColorDecider,
  pickupStateDecider,
  pickupStatusDecider,
  sortingDecider,
} from "../../tools/decider";
import { useAuth } from "../../contexts/AuthContext";
import Buttons from "../Buttons";
import { lowerCase, upperCase } from "lodash";
import { useNavigate } from "react-router";
import ToasterContext from "../../contexts/ToastContext";
import { IoIosAdd } from "react-icons/io";
import { HiCheckCircle } from "react-icons/hi2";
import { MdCancel } from "react-icons/md";

function ConsignmentPickupStatus() {
  const [pickupsData, setPickupsData] = useState([]);
  const [selected, setSelected] = useState("basic");
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterState, setFilterState] = useState({
    title: "All Status",
    value: null,
  });
  const [newest, setNewest] = useState(true);
  const [openFilter, setOpenFilter] = useState(false);

  const { width, height } = useWindowDimensions();
  const { showToast } = useContext(ToasterContext);
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { user = {} } = useAuth();
  const navigate = useNavigate();
  const { detail = {} } = user;

  const getPickupData = async () => {
    try {
      setLoading(true);
      const res = await api.get("/api/pickups");
      const data = res?.data?.data;

      const filterredData = data?.filter(({ consignment_method, state }) =>
        filterState?.value
          ? consignment_method === selected && state === filterState.value
          : consignment_method === selected
      );

      setPickupsData(data);
      setData(sortingDecider(filterredData, "newest"));
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPickupData();
  }, [selected]);

  const menuArr = [
    {
      title: "Consignment",
      value: "basic",
    },
    {
      title: "Trade In",
      value: "trade_in",
    },
    {
      title: "Direct Sell",
      value: "direct_sell",
    },
  ];

  const filterArr = [
    {
      title: "All Status",
      value: null,
    },
    {
      title: "Request Submitted",
      value: "requested",
    },
    {
      title: "Request Confirmed",
      value: "confirmed",
    },
    {
      title: "Request Rejected",
      value: "rejected",
    },
    {
      title: "Request Cancelled",
      value: "cancelled",
    },
  ];

  const handleCancel = async (id) => {
    try {
      setLoadingCancel(true);
      await api.delete(`/api/pickups/${id}`);

      getPickupData();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoadingCancel(false);
    }
  };

  const handlePressMenu = (val) => {
    setSelected(val);
  };

  return (
    <View
      style={{
        justifyContent: "center",
        marginHorizontal: isPhone ? 0 : isTablet ? 48 : 60,
        marginRight: isPhone ? 24 : isTablet ? 48 : 0,
        marginBottom: 250,
      }}
    >
      {Boolean(!isPhone) && (
        <LatoBold
          style={{
            fontSize: isPhone ? 20 : 24,
            lineHeight: isPhone ? 26 : 32,
            color: colors.grey33,
            marginBottom: 42,
            textAlign: "left",
          }}
        >
          Pickup Request Status
        </LatoBold>
      )}
      <TopMenuSelection
        menu={menuArr}
        selected={selected}
        setSelected={handlePressMenu}
      />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 32,
          marginBottom: 24,
          zIndex: 100,
        }}
      >
        <View>
          <Buttons
            label={upperCase(filterState?.title)}
            width={"auto"}
            onPress={() => {
              setOpenFilter(!openFilter);
            }}
            containerStyle={{ marginHorizontal: 12, paddingHorizontal: 12 }}
          />
          {Boolean(openFilter) && (
            <View
              style={{
                position: "absolute",
                right: 12,
                top: 48,
                // height: 100,
                width: 200,
                borderWidth: 1,
                backgroundColor: colors.whiteFF,

                paddingHorizontal: 8,
                paddingVertical: 6,
                borderRadius: 8,
                borderColor: colors.grey90,
              }}
            >
              {filterArr.map((item, idx) => (
                <TouchableOpacity
                  key={idx}
                  onPress={() => {
                    setFilterState(item);
                    setData(
                      item?.title === "All Status"
                        ? pickupsData?.filter(
                            ({ consignment_method }) =>
                              consignment_method === selected
                          )
                        : pickupsData.filter(
                            ({ state, consignment_method }) =>
                              item?.value?.includes(state) &&
                              consignment_method === selected
                          )
                    );
                    setOpenFilter(false);
                  }}
                  style={{
                    paddingVertical: 12,
                    backgroundColor:
                      filterState?.title === item?.title
                        ? colors.transparentGrey6C
                        : colors.whiteFF,
                    marginHorizontal: -8,
                    paddingHorizontal: 8,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.title}
                  </LatoRegular>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
        <Buttons
          label={newest ? "NEWEST" : "OLDEST"}
          rightIcon={
            newest ? (
              <AiOutlineArrowDown size={14} color={colors.grey33} />
            ) : (
              <AiOutlineArrowUp size={14} color={colors.grey33} />
            )
          }
          iconStyle="center"
          width={"auto"}
          onPress={() => {
            setNewest(!newest);
            setData(sortingDecider(data, !newest ? "newest" : "oldest"));
          }}
          containerStyle={{ marginHorizontal: 12, paddingHorizontal: 12 }}
          textStyle={{ marginRight: 8 }}
        />
      </View>
      {Boolean(selected === "requested") && (
        <View
          style={{
            backgroundColor: colors.whiteF1,
            borderWidth: 1,
            borderColor: colors.greyBB,
            borderRadius: 4,
            flexDirection: "row",
            alignItems: "center",
            paddingVertical: 8,
            paddingHorizontal: 12,
            marginBottom: 32,
          }}
        >
          <AiOutlineBulb size={20} color={colors.grey6C} />
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey6C,
              marginLeft: 12,
            }}
          >
            Request for pickup has been submitted and will be confirmed by Belle
            & Kate.
          </LatoRegular>
        </View>
      )}
      {loading ? (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            // width: isPhone ? width : isTablet ? width - 300 : width - 450,
            // height: height - 500,
          }}
        >
          <Image
            source={require("../../assets/images/spinning-loading.gif")}
            // alt={item?.product?.name}
            style={{
              alignSelf: "center",
              width: 200,
              height: 200,
            }}
            defaultSource={require("../../assets/images/spinning-loading.gif")}
            resizeMode={"contain"}
          />
        </View>
      ) : data?.length ? (
        data?.map((item, index) => {
          const isConfirmed = item?.state === "confirmed" && item?.updated_at;
          const isRejected = item?.state === "rejected" && item?.updated_at;
          const isCancel = item?.state === "cancelled" && item?.updated_at;

          const isReceived = item?.pickup_products?.filter(
            ({ state }) => state === "received"
          )[0]?.updated_at;

          return (
            <View
              key={index}
              style={{
                borderWidth: 1,
                borderColor: colors.greyBB,
                borderRadius: 4,
                marginBottom: 12,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderBottomWidth: 1,
                  borderBottomColor: colors.greyBB,
                  paddingHorizontal: 32,
                  paddingVertical: 12,
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  {isCancel || isRejected ? (
                    <MdCancel size={20} color={colors.redAD} />
                  ) : isReceived ? (
                    <HiCheckCircle size={20} color={colors.green06} />
                  ) : (
                    <AiOutlineClockCircle size={20} color={colors.grey33} />
                  )}
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      marginLeft: 16,
                    }}
                  >
                    {`${
                      isRejected
                        ? "PICK UP REJECTED"
                        : isCancel
                        ? "PICK UP CANCELLED"
                        : isReceived
                        ? "PICKED UP ON"
                        : "PICK UP DATE"
                    } : ${customDateFormatter(
                      isReceived ||
                        isRejected ||
                        isCancel ||
                        item?.pickup_slot?.start_time,
                      true
                    )}${
                      Boolean(!isReceived && !isRejected && !isCancel)
                        ? ` (time slot: ${format(
                            new Date(item?.pickup_slot?.start_time),
                            "HH:mma"
                          )} - ${format(
                            new Date(item?.pickup_slot?.finish_time),
                            "HH:mma"
                          )})`
                        : ""
                    }`}
                  </LatoBold>
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    borderWidth: 1,
                    borderColor: colors.greyE6,
                    borderRadius: 4,
                    paddingHorizontal: 8,
                  }}
                >
                  <View
                    style={{
                      backgroundColor: pickupStateColorDecider(
                        item?.state,
                        isReceived
                      ),
                      width: 8,
                      height: 8,
                      borderRadius: "100%",
                      marginRight: 6,
                      marginVertical: 6.5,
                    }}
                  />
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    {pickupStateDecider(item?.state, false, isReceived)}
                  </LatoRegular>
                </View>
              </View>
              <View style={{ paddingHorizontal: 32, paddingVertical: 24 }}>
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    marginBottom: 32,
                  }}
                >
                  <View style={{ width: 150 }}>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 10,
                      }}
                    >
                      CODE NUMBER
                    </LatoBold>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {item?.reference_number}
                    </LatoRegular>
                  </View>
                  <View style={{ width: 150 }}>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 10,
                      }}
                    >
                      TOTAL ITEM
                    </LatoBold>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {`${item?.products?.length} items`}
                    </LatoRegular>
                  </View>
                  {Boolean(!isPhone) && (
                    <View style={{ width: 150 }}>
                      <LatoBold
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: colors.grey33,
                          marginBottom: 10,
                        }}
                      >
                        STATUS
                      </LatoBold>
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: colors.grey33,
                        }}
                      >
                        {pickupStatusDecider(item?.state, isReceived)}
                      </LatoRegular>
                    </View>
                  )}
                  <View style={{ width: width <= 1230 ? 200 : 225 }}>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 10,
                      }}
                    >
                      PICK UP FROM
                    </LatoBold>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 4,
                      }}
                    >
                      {detail?.name}
                    </LatoBold>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        marginBottom: 4,
                      }}
                    >
                      {`0${detail?.phone}`}
                    </LatoRegular>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {detail?.complete_address}
                    </LatoRegular>
                  </View>
                </View>
                {Boolean(item?.rejection_note) && (
                  <View
                    style={{
                      backgroundColor: colors.greyBB,
                      marginBottom: -25,
                      marginHorizontal: -33,
                      paddingHorizontal: 32,
                      paddingVertical: 12,
                      borderBottomEndRadius: 4,
                      borderBottomStartRadius: 4,
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {`We reject your pickup request because ${lowerCase(
                        item?.rejection_note
                      )}`}
                    </LatoRegular>
                  </View>
                )}
                {Boolean(item?.state !== "rejected") && (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Buttons
                      onPress={() =>
                        navigate(`/account/pickup-status/${item?.id}`, {
                          state: { idx: 1, index: 2 },
                        })
                      }
                      loading={loadingCancel}
                      disabled={loadingCancel}
                      label="See Item Details"
                      width={130}
                      isBlack
                      containerStyle={{
                        marginRight: item?.state === "requested" ? 12 : 0,
                      }}
                    />
                    {Boolean(item?.state === "requested") && (
                      <Buttons
                        onPress={() => handleCancel(item?.id)}
                        loading={loadingCancel}
                        disabled={loadingCancel}
                        label="Cancel Request"
                        width={130}
                      />
                    )}
                  </View>
                )}
              </View>
            </View>
          );
        })
      ) : (
        <View
          style={{
            borderWidth: 1,
            borderColor: colors.greyBB,
            borderRadius: 4,
            marginBottom: 12,
            justifyContent: "center",
            alignItems: "center",
            height: 150,
          }}
        >
          <LatoRegular
            style={{
              fontSize: 24,
              lineHeight: 32,
              color: colors.grey33,
            }}
          >
            There is no pickup request yet.
          </LatoRegular>
        </View>
      )}
    </View>
  );
}

export default ConsignmentPickupStatus;
