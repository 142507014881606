import React, { useContext, useEffect, useState } from "react";
import Modal from "../Modal";
import { LatoBold, LatoRegular } from "../Text";
import { api } from "../../utils/api";
import ToasterContext from "../../contexts/ToastContext";
import { Image, TouchableOpacity, View } from "react-native-web";
import { GrClose } from "react-icons/gr";
import colors from "../../assets/colors/colors";
import { format } from "date-fns";
import Buttons from "../Buttons";
import { MdDeleteOutline } from "react-icons/md";

function RequestDetail({ id, setOpen = () => {} }) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { showToast, showSuccessToast } = useContext(ToasterContext);

  const {
    colour,
    name,
    created_at,
    designer,
    condition,
    notes,
    main_image,
    year,
  } = data || {};

  const getData = async () => {
    try {
      setLoading(true);

      const res = await api.get(`/api/request_items/${id}`);

      setData(res?.data?.data || {});
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoadingDelete(true);

      await api.delete(`/api/request_items/${id}`);

      showSuccessToast("Your request have been deleted.");

      setOpen(false);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoadingDelete(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Modal>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 32,
        }}
      >
        <LatoBold style={{ fontSize: 20, lineHeight: 28 }}>
          Request Details
        </LatoBold>
        <TouchableOpacity onPress={() => setOpen(false)}>
          <GrClose size={16} />
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          borderWidth: 1,
          padding: 12,
          borderRadius: 4,
          borderColor: colors.greyE6,
        }}
      >
        <View
          style={{
            borderWidth: 1,
            padding: 8,
            borderRadius: 8,
            justifyContent: "center",
            borderColor: colors.greyE6,
            marginRight: 16,
            height: 60,
          }}
        >
          <Image
            source={{
              uri: main_image,
            }}
            alt={name}
            style={{
              alignSelf: "center",
              width: 48,
              height: 48,
            }}
            defaultSource={require("../../assets/images/spinning-loading.gif")}
            resizeMode={"contain"}
          />
        </View>
        <View style={{ flexWrap: "wrap", width: "50%" }}>
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
              marginBottom: 8,
              textTransform: "uppercase",
            }}
          >
            {designer?.name || ""}
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
            }}
          >
            {name || ""}
          </LatoRegular>
        </View>
      </View>
      <View style={{ marginTop: 24, marginBottom: 16 }}>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey33,
            marginBottom: 12,
          }}
        >
          {"Created Date : "}
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
            }}
          >
            {created_at ? format(new Date(created_at), "dd/MM/yyyy") : ""}
          </LatoRegular>
        </LatoBold>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey33,
            marginBottom: 12,
          }}
        >
          {"Year : "}
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
            }}
          >
            {year || ""}
          </LatoRegular>
        </LatoBold>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey33,
            marginBottom: 12,
          }}
        >
          {"Color : "}
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey33,
            }}
          >
            {colour || ""}
          </LatoRegular>
        </LatoBold>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey33,
            marginBottom: 12,
          }}
        >
          {"Condition : "}
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.green5B,
              textTransform: "uppercase",
            }}
          >
            {condition?.name || ""}
          </LatoBold>
        </LatoBold>
      </View>
      <LatoBold
        style={{
          fontSize: 14,
          lineHeight: 20,
          color: colors.grey33,
          marginBottom: 8,
        }}
      >
        Notes :
      </LatoBold>
      <LatoRegular
        style={{
          fontSize: 14,
          lineHeight: 20,
          color: colors.grey33,
          marginBottom: 32,
        }}
      >
        {notes || ""}
      </LatoRegular>
      <Buttons
        loading={loadingDelete}
        onPress={async () => await handleDelete(id)}
        icon={
          <MdDeleteOutline
            size={20}
            color={colors.grey33}
            style={{ marginLeft: 4 }}
          />
        }
        label={"Delete"}
        borderColor={colors.greyE6}
        iconStyle="center"
      />
    </Modal>
  );
}

export default RequestDetail;
