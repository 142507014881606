import React from "react";
import { View, TouchableOpacity, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { useNavigate } from "react-router";
import ImageWithLoader from "../ImageWithLoader";
import { times } from "lodash";

function Editorial({ isEditorial = false, data = [], loading = false }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - 2 * gap;

  return (
    <View style={{ marginBottom: isPhone ? 0 : 100 }}>
      <View
        style={{
          paddingTop: isPhone ? 48 : 100,
          paddingBottom: isEditorial && !isPhone ? 100 : 48,
          justifyContent: "center",
          alignItems: "center",
          marginHorizontal: isEditorial ? -gap : 0,
          paddingHorizontal: gap,
        }}
      >
        {Boolean(!isEditorial || isPhone) && (
          <LatoRegular
            style={{
              fontSize: isPhone ? 16 : 24,
              lineHeight: isPhone ? 24 : 32,
              color: colors.grey6C,
              backgroundColor: colors.whiteFF,
              paddingHorizontal: isPhone ? 8 : 56,
            }}
          >
            FRESH FROM ROOM
          </LatoRegular>
        )}
        <View
          style={{
            position: "absolute",
            zIndex: -1,
            width: isEditorial ? width : containerWidth,
            height: 1,
            backgroundColor: colors.greyE6,
            left: gap,
          }}
        />
      </View>
      {Boolean(isEditorial && !isPhone) && (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: isPhone ? 48 : 100,
          }}
        >
          <LatoRegular
            style={{
              fontSize: isPhone ? 24 : isTablet ? 48 : 60,
              lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
              color: colors.black00,
              marginBottom: 16,
            }}
          >
            FRESH FROM HER ROOM
          </LatoRegular>
          <LatoRegular
            style={{
              fontSize: isPhone ? 12 : isTablet ? 16 : 24,
              lineHeight: isPhone ? 16 : isTablet ? 24 : 32,
              textAlign: "center",
              color: colors.grey6C,
              width: isPhone ? width - 32 : width - 300,
            }}
          >
            Take a peek into our influencer’s personal style
          </LatoRegular>
        </View>
      )}
      <View
        style={{
          flexDirection: isPhone ? "column" : "row",
          alignItems: "flex-start",
          paddingHorizontal: gap,
        }}
      >
        {loading
          ? times(3, (i) => (
              <View
                key={i}
                style={{
                  width: isPhone ? containerWidth : (containerWidth - 100) / 3,
                  height:
                    ((isPhone ? containerWidth : (containerWidth - 100) / 3) *
                      (isPhone ? 281 : 600)) /
                    (isPhone ? 358 : 454),
                  backgroundColor: colors.superTransparentBlack00,
                  marginBottom: isPhone ? 40 : 80,
                  marginRight: isPhone ? 0 : 48,
                }}
              />
            ))
          : data.map(
              (
                { main_image: cover, header: title, content, sub_header, id },
                idx
              ) => (
                <TouchableOpacity
                  key={idx}
                  onPress={() => {
                    navigate(`/editorial/fresh-from-room/${id}`);
                  }}
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: isPhone ? 40 : 80,
                    marginRight: isPhone ? 0 : 48,
                  }}
                >
                  <ImageWithLoader
                    source={cover}
                    containerStyle={{
                      width: isPhone
                        ? containerWidth
                        : (containerWidth - 100) / 3,
                      height: isPhone
                        ? containerWidth
                        : (containerWidth - 100) / 3,
                    }}
                    style={{
                      width: "100%",
                      height: "100%",
                    }}
                    defaultStyle={{
                      width: 48,
                      height: isPhone
                        ? containerWidth
                        : (containerWidth - 100) / 3,
                    }}
                    resizeMode={"cover"}
                    woMask
                  />
                  <LatoRegular
                    style={{
                      fontSize: isPhone ? 12 : width < 1500 ? 16 : 20,
                      lineHeight: isPhone ? 16 : width < 1500 ? 24 : 28,
                      color: colors.grey33,
                      marginTop: 24,
                      marginBottom: 8,
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: isPhone ? 14 : width < 1500 ? 16 : 20,
                      lineHeight: isPhone ? 20 : width < 1500 ? 24 : 28,
                      color: colors.black22,
                      marginBottom: 24,
                      textTransform: "capitalize",
                      width: isPhone
                        ? containerWidth - 32
                        : (containerWidth - 100) / 3,
                      textAlign: "center",
                    }}
                  >
                    {sub_header}
                  </LatoRegular>
                </TouchableOpacity>
              )
            )}
      </View>
      {Boolean(!isEditorial) && (
        <Buttons
          onPress={() => navigate("/editorial")}
          label={"See All Edits"}
          width={isPhone ? containerWidth : 135}
          height={48}
          borderRadius={2}
          containerStyle={{ alignSelf: "center" }}
          isBlack
        />
      )}
    </View>
  );
}

export default Editorial;
