import React, { useEffect, useRef } from "react";
import { Image, ScrollView, View, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import About1 from "../assets/images/about-line-1.png";
import DropDownTab from "../components/DropDownTab";
import Buttons from "../components/Buttons";
import { BsShield, BsWhatsapp } from "react-icons/bs";
import { openWhatsapp } from "../tools/tools";
import { contactDecider } from "../tools/decider";
import ExplainationSell from "../components/SellWithUs/ExplainationSell";
import { GrLocation } from "react-icons/gr";
import { useParams } from "react-router";

function CustomerCareScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const { id } = useParams();
  const scrollRef = useRef();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const shipArr = [
    {
      title: "NATIONWIDE",
      datas: [
        "FREE shipping nationwide for all Hermes bags. Shipping fees applied to other brands and Hermes accessories will be calculated based on package size.",
        "We ship nationwide across Indonesia via TIKI ONS/TIKI Regular.",
        "Specially for JABODETABEK area, we offer:",
        ["GO-JEK/GRAB", "Private Courier Service", "COD (Cash on Delivery)"],
      ],
      style: "paragraph",
    },
    {
      title: "WORLDWIDE",
      datas: [
        "All items are available for worldwide shipping from Jakarta, Indonesia via <b>DHL Express.</b> Shipping fee will be calculated based on package size.",
        "Kindly note that clients are responsible for ensuring that items purchased can be legally imported into the destination country. Different regulations may apply for certain types of items, such as exotic leathers.",
        "Please contact our Client Advisors for more information regarding international shipments.",
      ],
      style: "paragraph",
    },
  ];

  const returnArr = [
    "All orders are final, we do not accept refund/returns for :",
    [
      "Discounted/Sale items",
      "Items shipped with international shipping",
      "Items that have been received for more than 24 hours",
      "Items that are not according to your liking/taste",
    ],
    "We can only accept returns for items that do not match the description or conditions written on the product description page. Delivery will be borne by customer.",
    "<b>IMPORTANT:</b> Submit your Return Request with an unboxing video within 24 hours after you receive your order",
    "<b>You can always consign the non-returnable items at www.belleandkate.com. Contact our Client Advisors for more information.</b>",
  ];

  const whyArr = [
    {
      icon: <GrLocation size={isTablet ? 24 : 36} />,
      title: "PACKAGE TRACKING",
      description:
        "Once your package has been shipped, we will send you a notification with your tracking number.",
    },
    {
      icon: <BsShield size={isTablet ? 24 : 36} />,
      title: "INSURANCE",
      description:
        "You may choose whether or not you want to add insurance to your delivery. The cost of insurance will be calculated according to the value of your order on the checkout page.",
    },
  ];

  useEffect(() => {
    if (id) {
      scrollRef?.current?.scrollTo({
        y:
          id === "return-policy"
            ? isPhone
              ? 1700
              : 1600
            : isPhone
            ? 450
            : 300,
        animated: true,
      });
    }
  }, [id]);

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View style={{ paddingHorizontal: gap }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: isPhone ? 48 : 100,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isPhone ? 24 : isTablet ? 48 : 60,
                lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
                color: colors.black00,
                marginBottom: 16,
                textTransform: "uppercase",
              }}
            >
              Customer Care
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : 24,
                lineHeight: isPhone ? 16 : 32,
                textAlign: "center",
                color: colors.grey6C,
              }}
            >
              All items sold on our platform are guaranteed authentic. We take
              our authentication process thoroughly to make sure all our luxury
              items are certified authentic.
            </LatoRegular>
          </View>
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: isPhone ? 48 : 100,
              borderBottomColor: colors.greyE6,
              borderBottomWidth: 1,
              marginBottom: isPhone ? 48 : 100,
              marginHorizontal: -gap,
              paddingHorizontal: gap,
            }}
          >
            <Image
              source={About1}
              alt={"Shipping Information"}
              style={{
                alignSelf: "center",
                width: isPhone ? containerWidth : (containerWidth - 40) / 2,
                height: isPhone
                  ? (containerWidth * 281) / 358
                  : (containerWidth - 40) / 2,
              }}
              defaultSource={require("../assets/images/spinning-loading.gif")}
              resizeMode={isPhone ? "cover" : "contain"}
            />
            <View
              style={{
                width: isPhone ? containerWidth : (containerWidth - 40) / 2,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: isPhone ? 24 : 32,
                  lineHeight: isPhone ? 32 : 40,
                  color: colors.black00,
                  marginBottom: 32,
                  textTransform: "uppercase",
                }}
              >
                SHIPPING INFORMATION
              </LatoRegular>
              {shipArr.map((itm, i) => (
                <DropDownTab
                  key={i}
                  item={itm}
                  isLast={i === shipArr.length - 1}
                  right
                  noMargin
                  opened
                />
              ))}
            </View>
          </View>
          <View style={{ marginHorizontal: isPhone ? -gap : 0 }}>
            <ExplainationSell
              arr={whyArr}
              isCenter
              itemWidth={(containerWidth - 40) / 2}
              containerWidth={containerWidth - 40}
              numbered={false}
              containerHeight={
                isPhone ? 200 : isTablet ? 250 : width < 1240 ? 275 : 300
              }
              scroll={isPhone}
              fontSize={
                isPhone
                  ? 12
                  : width < 935
                  ? 14
                  : isTablet
                  ? 16
                  : width < 1240
                  ? 20
                  : 24
              }
              lineHeight={
                isPhone
                  ? 16
                  : width < 935
                  ? 20
                  : isTablet
                  ? 24
                  : width < 1240
                  ? 28
                  : 32
              }
              descFontSize={
                isPhone
                  ? 12
                  : width < 935
                  ? 14
                  : isTablet
                  ? 16
                  : width < 1240
                  ? 20
                  : 24
              }
              descLineHeight={
                isPhone
                  ? 16
                  : width < 935
                  ? 20
                  : isTablet
                  ? 24
                  : width < 1240
                  ? 28
                  : 32
              }
            />
          </View>
          <View
            style={{
              flexDirection: isPhone ? "column" : "row",
              justifyContent: isPhone ? "center" : "space-between",
              alignItems: "center",
              paddingBottom: isPhone ? 48 : 100,
              marginBottom: isPhone ? 48 : 100,
            }}
          >
            <Image
              source={About1}
              alt={"Return Policy"}
              style={{
                alignSelf: "center",
                width: isPhone ? containerWidth : (containerWidth - 40) / 2,
                height: isPhone
                  ? (containerWidth * 281) / 358
                  : (containerWidth - 40) / 2,
              }}
              defaultSource={require("../assets/images/spinning-loading.gif")}
              resizeMode={isPhone ? "cover" : "contain"}
            />
            <View
              style={{
                width: isPhone ? containerWidth : (containerWidth - 40) / 2,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: isPhone ? 24 : 32,
                  lineHeight: isPhone ? 32 : 40,
                  color: colors.black00,
                  marginBottom: 32,
                  textTransform: "uppercase",
                }}
              >
                return policy
              </LatoRegular>
              {returnArr.map((item, index) => (
                <View
                  key={index}
                  style={{
                    marginTop: typeof item === "string" ? 20 : 8,
                  }}
                >
                  {typeof item === "string" ? (
                    <LatoRegular
                      style={{
                        fontSize: isTablet ? 16 : 18,
                        lineHeight: isTablet ? 24 : 26,
                        color: colors.grey33,
                      }}
                    >
                      {item.includes("<b>") ? item.split("<b>")[0] : item}
                      {Boolean(item.includes("<b>")) && (
                        <LatoBold
                          style={{
                            fontSize: isTablet ? 16 : 18,
                            lineHeight: isTablet ? 24 : 26,
                            color: colors.grey33,
                          }}
                        >
                          {item.split("<b>")[1].split("</b>")[0]}
                        </LatoBold>
                      )}
                      {Boolean(item.includes("<b>")) &&
                        item.split("<b>")[1].split("</b>")[1]}
                    </LatoRegular>
                  ) : (
                    item.map((itm, i) => (
                      <View
                        key={i}
                        style={{
                          marginTop: 8,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: colors.grey6C,
                            borderRadius: "100%",
                            width: 5,
                            height: 5,
                          }}
                        />
                        <LatoRegular
                          style={{
                            fontSize: isTablet ? 16 : 18,
                            lineHeight: isTablet ? 24 : 26,
                            color: colors.grey6C,
                            marginLeft: 10,
                          }}
                        >
                          {itm}
                        </LatoRegular>
                      </View>
                    ))
                  )}
                </View>
              ))}
              <Buttons
                label="CONTACT US"
                icon={
                  <BsWhatsapp
                    color={colors.whiteFF}
                    size={20}
                    style={{ marginRight: 8 }}
                  />
                }
                onPress={() => {
                  openWhatsapp("", contactDecider("customer-care"));
                }}
                isBlack
                backgroundColor={colors.black1A}
                width={180}
                iconStyle="center"
                containerStyle={{
                  marginTop: 32,
                  alignSelf: isPhone ? "center" : "flex-start",
                }}
              />
            </View>
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default CustomerCareScreen;
