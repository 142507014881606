import React, { useCallback, useEffect, useRef, useState } from "react";
import { ScrollView, View, Image, useWindowDimensions } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";

function ExplainationSell({
  arr = [],
  isCenter = false,
  itemWidth,
  containerWidth,
  numbered = true,
  containerHeight = 300,
  scroll = false,
  fontSize,
  lineHeight,
  descFontSize,
  descLineHeight,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const scrollRef = useRef();

  const [count, setCount] = useState(0);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const defaultWidth = width - gap * 2;

  const autoSlideCarousel = useCallback(() => {
    if (scroll) {
      setTimeout(() => {
        const multiplier = count + 1;
        const slideX = width;
        const slide = multiplier * slideX;

        scrollRef?.current?.scrollTo({
          x: slide,
          y: 0,
          animated: true,
        });
        setCount(count === arr.length - 2 ? -1 : count + 1);
      }, 5000);
    }
  }, [count, arr.length, width, scroll]);

  useEffect(() => {
    autoSlideCarousel();
  }, [count, autoSlideCarousel]);

  const ExplainItem = () => {
    return arr.map(
      ({ source, icon, description, title, withLearnMore, onPress }, idx) => (
        <View
          key={idx}
          style={{
            width: scroll
              ? defaultWidth
              : (arr.length % 2 === 0 ||
                (arr.length % 2 !== 0 && idx < arr.length - 3) ||
                !numbered
                  ? itemWidth - 24
                  : (2 * itemWidth) / 3 - 24) || defaultWidth / 4 - 24,
            height:
              arr.length % 2 === 0 ||
              (arr.length % 2 !== 0 && idx < arr.length - 3) ||
              !numbered ||
              isTablet
                ? containerHeight
                : width < 1375
                ? containerHeight + 85
                : containerHeight + 50,
            marginBottom: scroll ? 0 : 32,
            padding: 32,
            borderColor: colors.greyE6,
            borderWidth: 1,
            borderRadius: 12,
            marginHorizontal: scroll ? gap : 0,
            alignItems: isCenter ? "center" : "flex-start",
            // justifyContent: isCenter ? "center" : "flex-start",
          }}
        >
          {Boolean(source) ? (
            <Image
              source={source}
              alt={`Tag ${idx}`}
              style={{
                height: isPhone ? 24 : 36,
                width: isPhone ? 24 : 36,
              }}
              defaultSource={source}
            />
          ) : (
            icon
          )}
          <View style={{ flexDirection: "row" }}>
            {Boolean(numbered) && (
              <LatoBold
                style={{
                  marginVertical: 16,
                  fontSize: fontSize ? fontSize : isPhone ? 12 : 18,
                  lineHeight: lineHeight ? lineHeight : isPhone ? 16 : 26,
                  color: colors.grey33,
                  marginRight: 6,
                }}
              >
                {`${idx + 1}.`}
              </LatoBold>
            )}
            <LatoBold
              style={{
                marginVertical: 16,
                fontSize: fontSize ? fontSize : isPhone ? 12 : 18,
                lineHeight: lineHeight ? lineHeight : isPhone ? 16 : 26,
                color: colors.grey33,
                textTransform: "uppercase",
              }}
            >
              {title}
            </LatoBold>
          </View>
          <LatoRegular
            style={{
              fontSize: descFontSize || 14,
              lineHeight: descLineHeight || 20,
              color: colors.grey6C,
            }}
          >
            {`${description} `}
            {Boolean(onPress) && (
              <LatoBold
                style={{
                  fontSize: descFontSize || 14,
                  lineHeight: descLineHeight || 20,
                  color: colors.grey6C,
                  textDecorationLine: "underline",
                }}
                onPress={onPress}
              >
                Learn More
              </LatoBold>
            )}
          </LatoRegular>
        </View>
      )
    );
  };

  return (
    <View
      style={{
        width: scroll ? width : defaultWidth / 2,
        paddingTop: 32,
        paddingBottom: 48,
      }}
    >
      {scroll ? (
        <ScrollView
          ref={scrollRef}
          horizontal
          showsHorizontalScrollIndicator={false}
          onContentSizeChange={autoSlideCarousel}
          style={{ marginBottom: 24 }}
        >
          <ExplainItem />
        </ScrollView>
      ) : (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            zIndex: 1,
            justifyContent: "space-between",
            alignItems: "flex-start",
            width: containerWidth || defaultWidth / 2 - 24,
          }}
        >
          <ExplainItem />
        </View>
      )}
      {Boolean(scroll) && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            width: width,
            flexWrap: "wrap",
          }}
        >
          {arr.map((i, idx) => {
            return (
              <View
                style={{
                  backgroundColor:
                    idx === count || (idx === arr.length - 1 && count === -1)
                      ? colors.grey33
                      : colors.greyE6,
                  borderRadius: "100%",
                  width: 12,
                  height: 12,
                  marginHorizontal: isPhone ? 4 : 6,
                  marginBottom: 20,
                }}
                key={idx}
              />
            );
          })}
        </View>
      )}
    </View>
  );
}

export default ExplainationSell;
