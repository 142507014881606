import React, { useEffect, useRef, useState } from "react";
import { View, TextInput, Pressable, useWindowDimensions } from "react-native";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import { LatoBold } from "./Text";
import Timer from "./Timer";
import moment from "moment";

function OTPInputs({
  code,
  setCode = () => {},
  maximumLength = 6,
  setIsPinReady = () => {},
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const inputRef = useRef();

  const boxArray = new Array(maximumLength).fill(0);

  const [isInputBoxFocused, setIsInputBoxFocused] = useState(false);

  const handleOnPress = () => {
    setIsInputBoxFocused(true);
    inputRef.current.focus();
  };

  const handleOnBlur = () => {
    setIsInputBoxFocused(false);
  };

  useEffect(() => {
    // update pin ready status
    setIsPinReady(code.length === maximumLength);
    // clean up function
    // return () => {
    //   setIsPinReady(false);
    // };
  }, [code]);

  return (
    <View style={{ justifyContent: "center", alignItems: "center" }}>
      <Pressable
        onPress={handleOnPress}
        style={{
          width: "80%",
          flexDirection: "row",
          justifyContent: "space-evenly",
          // alignItems: "center",
          height: 60,
          zIndex: isInputBoxFocused ? 1 : 5,
          cursor: "text",
        }}
      >
        {boxArray.map((_, index) => {
          const emptyInput = "";
          const digit = code[index] || emptyInput;

          const isCurrentValue = index === code.length;
          const isLastValue = index === maximumLength - 1;
          const isCodeComplete = code.length === maximumLength;

          const isValueFocused =
            isCurrentValue || (isLastValue && isCodeComplete);

          return (
            <View
              style={{
                borderColor:
                  isInputBoxFocused && isValueFocused
                    ? colors.grey6C
                    : "transparent",
                borderWidth: 1,
                borderRadius: 5,
                paddingVertical: 16,
                paddingHorizontal: 14,
                width: isPhone ? 45 : 50,
                marginHorizontal: isPhone ? 4 : 12,
                backgroundColor: colors.whiteF7,
              }}
            >
              <LatoBold
                style={{
                  fontSize: 20,
                  // lineHeight: 28,
                  textAlign: "center",
                  color: colors.black1A,
                }}
              >
                {digit}
              </LatoBold>
            </View>
          );
        })}
      </Pressable>
      <TextInput
        style={{
          width: 0.35 * width,
          borderColor: colors.whiteE8,
          borderWidth: 1,
          borderRadius: 5,
          padding: 15,
          position: "absolute",
          opacity: 0,
        }}
        value={code}
        onChangeText={setCode}
        maxLength={maximumLength}
        ref={inputRef}
        onBlur={handleOnBlur}
      />
    </View>
  );
}

export default OTPInputs;
