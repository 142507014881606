import React, { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import { api } from "../../utils/api";
import { Image, View, useWindowDimensions, ScrollView } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { format } from "date-fns";
import { customDateFormatter } from "../../tools/formatter";
import {
  pickupStateColorDecider,
  pickupStateDecider,
} from "../../tools/decider";
import ToasterContext from "../../contexts/ToastContext";

function PickupItems() {
  const { additional: id } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const { width, height } = useWindowDimensions();
  const { showToast } = useContext(ToasterContext);
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const getItems = async () => {
    try {
      setLoading(true);

      const res = await api.get(`/api/pickups/${id}`);

      setData(res?.data?.data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const { pickup_slot = {}, pickup_products = [], reference_number } = data;

  return loading ? (
    <View
      style={{
        justifyContent: "center",
        alignItems: "center",
        width: isPhone ? width : isTablet ? width - 300 : width - 450,
        height: height - 500,
      }}
    >
      <Image
        source={require("../../assets/images/spinning-loading.gif")}
        // alt={item?.product?.name}
        style={{
          alignSelf: "center",
          width: 200,
          height: 200,
        }}
        defaultSource={require("../../assets/images/spinning-loading.gif")}
        resizeMode={"contain"}
      />
    </View>
  ) : (
    <View>
      <View
        style={{
          paddingBottom: 16,
          borderBottomWidth: 1,
          borderBottomColor: colors.greyE6,
        }}
      >
        <LatoBold
          style={{
            fontSize: 24,
            lineHeight: 32,
            color: colors.grey33,
            marginBottom: 16,
          }}
        >
          {`Code Number: ${reference_number}`}
        </LatoBold>
        {Boolean(pickup_slot?.start_time) && (
          <LatoBold
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.grey6C,
              textTransform: "uppercase",
            }}
          >
            {`${customDateFormatter(
              pickup_slot?.start_time
            )} (time slot: ${format(
              new Date(pickup_slot?.start_time),
              "HH:mma"
            )} - ${format(new Date(pickup_slot?.finish_time), "HH:mma")})`}
          </LatoBold>
        )}
      </View>
      <ScrollView
        style={{ height: height - 375 }}
        showsVerticalScrollIndicator={false}
      >
        {pickup_products?.map(({ state, product, rejection_note }, index) => (
          <View
            key={index}
            style={{
              flexDirection: "row",
              paddingHorizontal: 36,
              paddingVertical: 24,
              borderBottomWidth: 1,
              borderBottomColor: colors.greyE6,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Image
                source={{
                  uri: `${product?.summary_index?.direct_photos[0]}`,
                }}
                alt={product?.name}
                style={{
                  alignSelf: "center",
                  width: isTablet ? 48 : 100,
                  height: isTablet ? 48 : 100,
                  marginRight: 16,
                }}
                defaultSource={require("../../assets/images/spinning-loading.gif")}
                resizeMode={"contain"}
              />
              <View style={{ width: 350 }}>
                <LatoBold
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                    marginBottom: 8,
                    textTransform: "uppercase",
                  }}
                >
                  {product?.summary_index?.designer || ""}
                </LatoBold>
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                    marginBottom: 16,
                  }}
                >
                  {product?.name || ""}
                </LatoRegular>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <View
                    style={{
                      backgroundColor: pickupStateColorDecider(state),
                      width: 8,
                      height: 8,
                      borderRadius: "100%",
                      marginRight: 6,
                      marginTop: 2,
                    }}
                  />
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                    }}
                  >
                    {pickupStateDecider(state, true)}
                  </LatoRegular>
                </View>
              </View>
            </View>
            {Boolean(state === "rejected") && (
              <View style={{ marginLeft: 60 }}>
                <LatoBold
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                    marginBottom: 8,
                    textTransform: "uppercase",
                  }}
                >
                  Notes
                </LatoBold>
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                  }}
                >
                  {rejection_note || "-"}
                </LatoRegular>
              </View>
            )}
          </View>
        ))}
      </ScrollView>
    </View>
  );
}

export default PickupItems;
