import React, { useState } from "react";
import {
  Image,
  View,
  TouchableOpacity,
  useWindowDimensions,
  Animated,
} from "react-native";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import Play from "../assets/images/play.svg";
import ImageWithLoader from "./ImageWithLoader";
import { isMobile } from "react-device-detect";

function ProductItem({
  containerWidth,
  containerHeight,
  widthItem,
  heightItem,
  uri,
  alt = "",
  pressable = false,
  onPress = () => {},
  isDetails = false,
  selected = false,
  smallPlay = false,
  setDisableScroll = () => {},
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const [zoomed, setZoomed] = useState(false);
  const [scale] = useState(new Animated.Value(1));
  const [position] = useState(
    new Animated.ValueXY({ x: containerWidth / 5, y: containerHeight / 8 })
  );
  const [isMouseMoving, setIsMouseMoving] = useState(false);

  const handleMouseEnter = () => {
    if ((isPhone && isMobile) || !isPhone) {
      setZoomed(true);
      setDisableScroll(true);

      Animated.spring(scale, {
        toValue: 4, // Adjust this value for your desired zoom level
        useNativeDriver: false,
      }).start();
    }
  };

  const handleMouseMove = (event) => {
    if (zoomed) {
      !isMouseMoving && setIsMouseMoving(true);

      if (isTablet && isMobile) {
        const newX =
          6 * (containerWidth - event.targetTouches[0].clientX) - 300;
        const newY =
          8 * (containerHeight - event.targetTouches[0].clientY) + 400;

        position.setValue({ x: newX, y: newY });

        return;
      } else if (!isPhone) {
        const newX = 1.5 * (containerWidth - event.nativeEvent.offsetX) - 300;
        const newY = 4 * (containerHeight - event.nativeEvent.offsetY) - 800;

        position.setValue({ x: newX, y: newY });
      }
    }
  };

  const handleMouseLeave = () => {
    setIsMouseMoving(false);
    setDisableScroll(false);

    if (!isMouseMoving && zoomed) {
      // If the mouse stopped moving and it was in the zoomed state, reset the zoom
      setZoomed(false);

      position.setValue({ x: containerWidth / 5, y: containerHeight / 8 });

      Animated.spring(scale, {
        toValue: 1,
        useNativeDriver: false,
      }).start();
    }
  };

  return !pressable ? (
    <View>
      <Animated.View
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onTouchStart={handleMouseEnter}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseLeave}
        onTouchCancel={handleMouseLeave}
        onTouchEndCapture={handleMouseLeave}
        style={{
          height: containerHeight,
          width: containerWidth,
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <Animated.View
          style={
            isMouseMoving
              ? [
                  {
                    transform: [{ scale }],
                    position: "absolute",
                  },
                  position.getLayout(),
                ]
              : {}
          }
        >
          <ImageWithLoader
            source={{
              uri,
            }}
            alt={alt}
            style={{
              height: selected ? containerHeight : heightItem,
              width: selected ? containerWidth : widthItem,
            }}
            defaultStyle={{
              height: containerHeight,
              width: 48,
            }}
            resizeMode={selected && !isPhone ? "cover" : "contain"}
            woMask
          />
        </Animated.View>
        {Boolean((!isPhone && isDetails) || !isDetails) && (
          <View
            style={{
              position: "absolute",
              backgroundColor: colors.superTransparentBlack00,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            }}
          />
        )}
      </Animated.View>
    </View>
  ) : (
    <TouchableOpacity
      onPress={onPress}
      disabled={!pressable}
      activeOpacity={pressable ? 0.5 : 1}
      style={{
        height: containerHeight,
        width: containerWidth,
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <ImageWithLoader
        source={{
          uri,
        }}
        alt={alt}
        style={{
          height:
            selected && pressable
              ? containerHeight
              : pressable
              ? heightItem
              : heightItem - 100,
          width: selected && pressable ? containerWidth : widthItem,
        }}
        defaultStyle={{
          width: 48,
          height:
            selected && pressable
              ? containerHeight
              : pressable
              ? heightItem
              : heightItem - 100,
        }}
        resizeMode={selected && !isPhone ? "cover" : "contain"}
        woMask
      />
      {Boolean((!isPhone && isDetails) || !isDetails) && (
        <View
          style={{
            position: "absolute",
            backgroundColor: colors.superTransparentBlack00,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          }}
        />
      )}
      {Boolean(selected) && (
        <View
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image
            source={Play}
            alt={"play-button"}
            style={{
              height: smallPlay ? 48 : 80,
              width: smallPlay ? 48 : 80,
            }}
            resizeMode={"contain"}
          />
        </View>
      )}
    </TouchableOpacity>
  );
}

export default ProductItem;
