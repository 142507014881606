import React from "react";
import {
  Animated,
  TouchableOpacity,
  View,
  Image,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import { GoSearch } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import Hermes from "../../assets/images/hermes-cover.png";
import Chanel from "../../assets/images/chanel-cover.png";
import LV from "../../assets/images/lv-cover.png";
import Dior from "../../assets/images/dior-cover.png";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { MyInput } from "../Inputs";
import { LatoBold, LatoRegular } from "../Text";
import ProductGrid from "../Home/ProductGrid";
import { capitalCase } from "change-case";

function Search({
  hasQuerry = false,
  searchResult = [],
  loading = false,
  openBurger = false,
  opacity = 1,
  handleSearch = () => {},
  setOpenSearch = () => {},
  setHasQuerry = () => {},
  setSearchResult = () => {},
  totalResult = 0,
  querry = "",
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const searchDesignersArr = [
    { title: "Brands", menu: ["Hermes", "Chanel", "Dior", "Louis Vuitton"] },
    { title: "Category", menu: ["Bags", "Shoes", "Accessories"] },
  ];

  const animatedHeight = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, openBurger ? 0.9 * height : 300],
  });

  return (
    <View
      style={{
        zIndex: 10,
        position: "sticky",
        top: 0,
        backgroundColor: colors.transparentBlack,
        height: height,
        width: width,
        marginBottom: -(height - 88),
      }}
    >
      <Animated.View
        style={{
          zIndex: 20,
          width: width,
          height: isPhone
            ? height
            : hasQuerry
            ? (searchResult?.length && loading) || searchResult?.length
              ? 450
              : 200
            : animatedHeight,
          paddingHorizontal: isPhone ? 16 : 40,
          backgroundColor: colors.whiteFF,
          shadowColor: colors.greyBB,
          shadowOffset: { height: 1, width: 0 },
          shadowOpacity: 0.15,
          shadowRadius: 2,
          opacity,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            paddingTop: 30,
          }}
        >
          <MyInput
            placeholder="Search by brand, product name.."
            customWidth={isTablet ? width - 64 : width - 125}
            name={"search"}
            handleChange={handleSearch}
            noLabel
            checkByName={false}
            textStyle={{ fontSize: 16, lineHeight: 24 }}
            leftIcon={
              <GoSearch
                size={20}
                color={colors.grey6C}
                style={{ marginRight: 8, marginBottom: 6 }}
              />
            }
            handlingEnter={(e) => {
              if (e.code === "Enter") {
                navigate("/products", { state: { querry } });
                setOpenSearch(false);
              }
            }}
          />
          <TouchableOpacity
            style={{ marginLeft: isPhone ? 8 : 24, marginBottom: 16 }}
            onPress={() => {
              setOpenSearch(false);
              setHasQuerry(false);
              setSearchResult([]);
            }}
          >
            <GrClose size={isPhone ? 16 : 20} color={colors.grey33} />
          </TouchableOpacity>
        </View>
        {Boolean(!hasQuerry) && (
          <View style={{ flexDirection: "row" }}>
            {searchDesignersArr.map(({ title, menu }, i) => (
              <View key={i} style={{ marginRight: 100 }}>
                <LatoBold
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    textTransform: "uppercase",
                    marginBottom: 16,
                    color: colors.grey90,
                  }}
                >
                  {title}
                </LatoBold>
                {menu.map((item, idx) => (
                  <TouchableOpacity
                    onPress={() => {
                      setOpenSearch(false);
                      setHasQuerry(false);
                      setSearchResult([]);
                      navigate(`/products/designer/${item}`, {
                        state: {
                          menu: [
                            {
                              name: item,
                              section:
                                title === "Category" ? "Category" : "Designer",
                              id: idx + 1,
                            },
                          ],
                          designer_page: {
                            title: item,
                            image:
                              item === "Chanel"
                                ? Chanel
                                : item === "Louis Vuitton"
                                ? LV
                                : item === "Dior"
                                ? Dior
                                : Hermes,
                          },
                        },
                      });
                    }}
                    key={idx}
                    style={{ marginBottom: 16 }}
                  >
                    <LatoRegular>{item}</LatoRegular>
                  </TouchableOpacity>
                ))}
              </View>
            ))}
          </View>
        )}
        {Boolean(hasQuerry && searchResult.length && !loading) ? (
          <View style={{ marginTop: 20 }}>
            <LatoBold
              style={{
                fontSize: isPhone ? 14 : 16,
                lineHeight: isPhone ? 20 : 24,
                color: colors.grey90,
                marginBottom: 14,
              }}
            >
              {`SUGGESTIONS FOR “${capitalCase(hasQuerry)}”`}
            </LatoBold>
            {searchResult.map((item, x) => {
              const { product, id } = item;
              const { name } = product;
              return (
                <TouchableOpacity
                  key={x}
                  onPress={() => {
                    navigate(`/products/${id}`, { state: { item } });
                    setOpenSearch(false);
                    setHasQuerry(false);
                    setSearchResult([]);
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: isPhone ? 16 : 18,
                      lineHeight: isPhone ? 24 : 26,
                      marginVertical: 14,
                      textTransform: "uppercase",
                    }}
                  >
                    {name}
                  </LatoRegular>
                </TouchableOpacity>
              );
            })}
          </View>
        ) : hasQuerry && !loading && !searchResult?.length ? (
          <View style={{ alignItems: "center", marginBottom: 24 }}>
            <LatoRegular>
              {`Sorry, we couldn't find any results for "${capitalCase(
                hasQuerry
              )}"`}
            </LatoRegular>
            <LatoRegular style={{ marginBottom: 16 }}>
              But there's plenty more to see. Let us help get you back on track
            </LatoRegular>
            <LatoBold>Return to our homepage</LatoBold>
          </View>
        ) : null}
      </Animated.View>
    </View>
  );
}

export default Search;
