import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Footer from "../components/Footer";
import { ScrollView, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../tools/Interface";
import Navbar from "../components/Navbar";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { api } from "../utils/api";
import ProductGrid from "../components/Home/ProductGrid";
import { upperCase } from "lodash";
import ImageWithLoader from "../components/ImageWithLoader";

function CrushScreen() {
  const { id } = useParams();

  const { height, width } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const [article, setArticle] = useState([]);
  const [loading, setLoading] = useState(false);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const getArticle = async () => {
    try {
      setLoading(true);

      const res = await api.get(`/api/editorials/crushes?q[id_eq]=${id}`);

      setArticle(res?.data?.data[0]);
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getArticle();
  }, []);

  const {
    header: title,
    content = "",
    main_image: cover,
    sub_crushes: subArr,
  } = article;

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View style={{ marginBottom: isTablet ? 48 : 100 }}>
          <View>
            <View>
              <ImageWithLoader
                source={{ uri: cover }}
                containerStyle={{
                  width,
                  height:
                    ((width + 2 * gap) * (isPhone ? 360 : 600)) /
                    (isPhone ? 390 : 1920),
                  marginBottom: isTablet ? 24 : 50,
                }}
                style={{
                  width: "100%",
                  height: `${
                    ((isPhone ? 360 : 600) / (isPhone ? 390 : 1920)) *
                    100 *
                    (isPhone ? 1.25 : 3.5)
                  }%`,
                }}
                defaultStyle={{
                  width: 120,
                  height:
                    ((width + 2 * gap) * (isPhone ? 360 : 600)) /
                    (isPhone ? 390 : 1920),
                }}
                resizeMode={"cover"}
                woMask
              />
              <View
                style={{
                  width: width + 2 * gap,
                  marginBottom: isPhone ? 40 : 100,
                  paddingBottom: isPhone ? 40 : 100,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                  marginHorizontal: -gap,
                  paddingHorizontal: 2 * gap,
                }}
              >
                <View>
                  <LatoBold
                    style={{
                      fontSize: isTablet ? 16 : 32,
                      lineHeight: isTablet ? 24 : 40,
                      color: colors.black00,
                      marginVertical: 32,
                      textAlign: "center",
                    }}
                  >
                    {`BELLE&KATE CRUSH: ${upperCase(title)}`}
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: isTablet ? 12 : 20,
                      lineHeight: isTablet ? 16 : 28,
                      color: colors.grey6C,
                      textAlign: "center",
                      marginBottom: isPhone ? 40 : 48,
                    }}
                  >
                    {content}
                  </LatoRegular>
                </View>
              </View>
            </View>
            {subArr?.map((itm, i) => {
              const { main_image: model, header, content, products = [] } = itm;
              return (
                <View
                  key={i}
                  style={{
                    flexDirection: isPhone
                      ? "column-reverse"
                      : i % 2 === 0
                      ? "row"
                      : "row-reverse",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    paddingHorizontal: gap,
                    borderBottomWidth:
                      isPhone && i !== subArr.length - 1 ? 1 : 0,
                    borderBottomColor: colors.greyE6,
                  }}
                >
                  <View
                    style={{
                      width: isPhone
                        ? width
                        : containerWidth / 2 - (isTablet ? 12 : 24),
                    }}
                  >
                    <View
                      style={{
                        paddingBottom: isPhone ? 0 : 32,
                        marginBottom: 32,
                        borderBottomColor: colors.greyE6,
                        borderBottomWidth: isPhone ? 0 : 1,
                        width: containerWidth / 2 - (isTablet ? 12 : 24),
                      }}
                    >
                      <LatoBold
                        style={{
                          fontSize: isPhone ? 14 : 24,
                          lineHeight: isPhone ? 20 : 32,
                          color: colors.black00,
                          marginBottom: 20,
                          textAlign: isPhone ? "justify" : "center",
                        }}
                      >
                        {header}
                      </LatoBold>
                      <LatoRegular
                        style={{
                          fontSize: isPhone ? 12 : 16,
                          lineHeight: isPhone ? 16 : 24,
                          color: colors.grey6C,
                          textAlign: isPhone ? "justify" : "center",
                        }}
                      >
                        {content}
                      </LatoRegular>
                    </View>
                    {Boolean(products.length) && (
                      <ProductGrid data={products} noButton wrap isCrush />
                    )}
                  </View>
                  <ImageWithLoader
                    source={{ uri: model }}
                    style={{
                      width: isPhone
                        ? containerWidth
                        : containerWidth / 2 - (isTablet ? 12 : 24),
                      height: isPhone
                        ? (containerWidth * 281) / 358
                        : (820 / 730) *
                          (containerWidth / 2 - (isTablet ? 12 : 24)),
                      marginBottom: isPhone ? 24 : 0,
                    }}
                    defaultStyle={{
                      width: 48,
                      height: isPhone
                        ? (containerWidth * 281) / 358
                        : (820 / 730) *
                          (containerWidth / 2 - (isTablet ? 12 : 24)),
                      marginBottom: isPhone ? 24 : 0,
                    }}
                    containerStyle={{
                      width: isPhone
                        ? containerWidth
                        : containerWidth / 2 - (isTablet ? 12 : 24),
                      height: isPhone
                        ? (containerWidth * 281) / 358
                        : (820 / 730) *
                          (containerWidth / 2 - (isTablet ? 12 : 24)),
                      marginBottom: isPhone ? 24 : 0,
                    }}
                    resizeMode={"cover"}
                    woMask
                  />
                </View>
              );
            })}
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default CrushScreen;
