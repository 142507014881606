import AsyncStorage from "@react-native-async-storage/async-storage";
import { decryptCredentials, encryptCredentials } from "../utils/api";

export const asyncSetHasLoggedIn = async (email) => {
  try {
    await AsyncStorage.setItem("hasLoggedIn", email);
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncGetHasLoggedIn = async () => {
  try {
    const email = (await AsyncStorage.getItem("hasLoggedIn")) || false;
    return email;
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncSetOpenSubscribe = async () => {
  try {
    await AsyncStorage.setItem("showSubsModal", "hide");
  } catch (err) {
    console.log("err:", err);
  }
};

export const asyncOpenSubscribe = async () => {
  try {
    const show = (await AsyncStorage.getItem("showSubsModal")) || false;

    return show !== "hide";
  } catch (err) {
    console.log("err:", err);
  }
};

export const asyncSetUser = async (user = {}) => {
  try {
    const userStr = JSON.stringify(user);
    await AsyncStorage.setItem("user", userStr);
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncGetUser = async () => {
  try {
    const value = await AsyncStorage.getItem("user");
    if (value) return JSON.parse(value);
    return null;
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncSetConsignmentItem = async (items = []) => {
  try {
    const str = JSON.stringify(items);
    await AsyncStorage.setItem("consignmentItems", str);
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncGetConsignmentItem = async () => {
  try {
    const value = await AsyncStorage.getItem("consignmentItems");
    if (value) return JSON.parse(value);
    return null;
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncRemoveUser = async () => {
  try {
    await AsyncStorage.removeItem("user");
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};
export const asyncSetCredentials = async (credentials = {}) => {
  try {
    const encryptedCredentials = encryptCredentials(
      JSON.stringify(credentials)
    );

    await AsyncStorage.setItem("credentials", encryptedCredentials);
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncGetCredentials = async () => {
  try {
    const value = await AsyncStorage.getItem("credentials");
    if (value) {
      const decryptedCredentials = decryptCredentials(value);
      return JSON.parse(decryptedCredentials);
    }
    return null;
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncRemoveIsMultipart = async () => {
  try {
    await AsyncStorage.removeItem("multipart");
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncSetIsMultipart = async (val) => {
  try {
    await AsyncStorage.setItem("multipart", val);
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncGetIsMultipart = async () => {
  try {
    const value = await AsyncStorage.getItem("multipart");
    return value;
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const asyncRemoveCredentials = async () => {
  try {
    await AsyncStorage.removeItem("credentials");
  } catch (e) {
    console.log(e, "AsyncStorage Error");
  }
};

export const getApiMode = async () => {
  const result = (await AsyncStorage.getItem("apiMode")) || "default";
  return result;
};

export const toggleApiMode = async () => {
  const result = await getApiMode();
  if (result === "default") {
    await AsyncStorage.setItem("apiMode", "alternate");
    return "alternate";
  } else {
    await AsyncStorage.setItem("apiMode", "default");
    return "default";
  }
};

export const asyncSetIsDevMode = async (isDevMode) => {
  await AsyncStorage.setItem("isDevMode", isDevMode);
};

export const asyncGetIsDevMode = async () => {
  const result = (await AsyncStorage.getItem("isDevMode")) === "true";
  return result;
};

export const asyncSetCurrency = async (curr) => {
  await AsyncStorage.setItem("currency", curr);
};

export const asyncGetCurrency = async () => {
  const result = await AsyncStorage.getItem("currency");
  return result;
};
