import React, { useState } from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import colors from "../../assets/colors/colors";
import ProductItem from "../ProductItem";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import { LatoRegular } from "../Text";
import { GoChevronLeft } from "react-icons/go";
import { useNavigate } from "react-router";

function LeftDetail({
  photos = [],
  productData = {},
  setOpenVideo = () => {},
  openVideo = false,
  videoAttr = {},
  setVideoAttr = () => {},
  setDisableScroll = () => {},
}) {
  const [imageIndex, setImageIndex] = useState(0);
  const [shownImage, setShownImage] = useState(0);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View
      style={{
        width: isPhone ? width : (containerWidth - 80) / 2,
        borderBottomColor: colors.greyE6,
        borderBottomWidth: isPhone ? 1 : 0,
        marginHorizontal: isPhone ? -gap : 0,
        paddingHorizontal: isPhone ? gap : 0,
        paddingBottom: isPhone ? 10 : 0,
      }}
    >
      <TouchableOpacity
        onPress={() => navigate("/products")}
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <GoChevronLeft size={24} color={colors.grey33} />
        <LatoRegular
          style={{
            fontSize: 20,
            lineHeight: 24,
            color: colors.black22,
            marginLeft: 8,
          }}
        >
          Back
        </LatoRegular>
      </TouchableOpacity>
      <View
        style={{
          flexDirection: "row",
          justifyContent: isPhone ? "center" : "space-between",
          alignItems: "center",
          paddingTop: 64,
          paddingBottom: isPhone ? 24 : 0,
        }}
      >
        {Boolean(isPhone) && (
          <TouchableOpacity
            onPress={() => {
              if (imageIndex === 0) {
                setImageIndex(photos?.length - 1);
              } else {
                setImageIndex(imageIndex - 1);
              }
            }}
            style={{ position: "absolute", left: 0, zIndex: 5 }}
          >
            <AiFillLeftCircle size={42} color={colors.grey33} />
          </TouchableOpacity>
        )}
        {Boolean(photos?.length) && (
          <View>
            <ProductItem
              imageIndex={imageIndex}
              shownImage={shownImage}
              photos={photos}
              productData={productData}
              containerHeight={
                isPhone
                  ? (containerWidth * 250) / 390
                  : isTablet
                  ? ((containerWidth / 2) * 581) / 730
                  : (((containerWidth - 300) / 2) * 581) / 730
              }
              containerWidth={
                isPhone
                  ? containerWidth
                  : isTablet
                  ? containerWidth / 2
                  : (containerWidth - 40) / 2
              }
              heightItem={
                isPhone
                  ? (containerWidth * 250) / 390
                  : isTablet
                  ? ((containerWidth / 2) * 581) / 730
                  : (((containerWidth - 300) / 2) * 581) / 730
              }
              widthItem={isPhone ? containerWidth : containerWidth / 2}
              uri={`${photos[isPhone ? imageIndex : shownImage]}`}
              alt={`${productData?.product?.name} ${
                isPhone ? imageIndex + 1 : shownImage + 1
              }`}
              isDetails
              isPhone={isPhone}
              pressable={
                (isPhone ? imageIndex : shownImage) <
                productData?.product?.formatted_video_links?.length
              }
              selected={
                (isPhone ? imageIndex : shownImage) <
                productData?.product?.formatted_video_links?.length
              }
              onPress={() => {
                setOpenVideo(!openVideo);
                setVideoAttr(
                  videoAttr?.poster
                    ? {}
                    : {
                        src: `${
                          productData?.product?.formatted_video_links[
                            isPhone ? imageIndex : shownImage
                          ]
                        }`,
                        poster: photos[isPhone ? imageIndex : shownImage],
                        controls: "controls",
                      }
                );
              }}
              setDisableScroll={setDisableScroll}
            />
            {Boolean(!isPhone) && (
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: 16,
                  width: isTablet
                    ? containerWidth / 2
                    : (containerWidth - 40) / 2,
                  // justifyContent: "space-between",
                }}
              >
                {Boolean(imageIndex > 0) && (
                  <TouchableOpacity
                    onPress={() => {
                      if (imageIndex === 0) {
                        setImageIndex(photos?.length - 1);
                      } else {
                        setImageIndex(imageIndex - 1);
                      }
                    }}
                    style={{
                      position: "absolute",
                      left: -24,
                      zIndex: 5,
                    }}
                  >
                    <BsArrowLeftCircleFill size={42} color={colors.grey33} />
                  </TouchableOpacity>
                )}
                {photos.map((itm, i) =>
                  i < 4 ? (
                    <View key={i} style={{ marginRight: i === 3 ? 0 : 20 }}>
                      <ProductItem
                        key={i}
                        containerHeight={
                          isPhone
                            ? height / 3
                            : (((isTablet
                                ? containerWidth - 120
                                : containerWidth - 160) /
                                8) *
                                250) /
                              170
                        }
                        containerWidth={
                          isPhone
                            ? width / 2
                            : (isTablet
                                ? containerWidth - 120
                                : containerWidth - 160) / 8
                        }
                        heightItem={
                          isPhone
                            ? height / 3
                            : (((isTablet
                                ? containerWidth - 240
                                : containerWidth - 320) /
                                8) *
                                250) /
                              170
                        }
                        widthItem={
                          isPhone
                            ? width / 2
                            : (isTablet
                                ? containerWidth - 240
                                : containerWidth - 320) / 8
                        }
                        uri={`${photos[imageIndex + i]}`}
                        alt={`${productData?.product?.name} ${
                          imageIndex + 1 + i
                        }`}
                        onPress={() => setShownImage(imageIndex + i)}
                        pressable
                        isDetails
                        selected={
                          imageIndex + i <
                          productData?.product?.formatted_video_links?.length
                        }
                        smallPlay
                      />
                    </View>
                  ) : null
                )}
                {Boolean(imageIndex < photos?.length - 4) && (
                  <TouchableOpacity
                    onPress={() => {
                      if (imageIndex === photos?.length - 1) {
                        setImageIndex(0);
                      } else {
                        setImageIndex(imageIndex + 1);
                      }
                    }}
                    style={{
                      position: "absolute",
                      right: -24,
                      zIndex: 5,
                    }}
                  >
                    <BsArrowRightCircleFill size={42} color={colors.grey33} />
                  </TouchableOpacity>
                )}
              </View>
            )}
          </View>
        )}
        {Boolean(isPhone) && (
          <TouchableOpacity
            onPress={() => {
              if (imageIndex === photos?.length - 1) {
                setImageIndex(0);
              } else {
                setImageIndex(imageIndex + 1);
              }
            }}
            style={{ position: "absolute", right: 0, zIndex: 5 }}
          >
            <AiFillRightCircle size={42} color={colors.grey33} />
          </TouchableOpacity>
        )}
      </View>
      {Boolean(isPhone) && (
        <View
          style={{
            position: "absolute",
            bottom: 10,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            width: width - 25,
            marginTop: 24,
          }}
        >
          <View
            style={{
              paddingHorizontal: 8,
              paddingVertical: 4,
              backgroundColor: colors.transparentGreyDD,
              borderRadius: 4,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 16 : 18,
                lineHeight: isTablet ? 24 : 26,
              }}
            >{`${imageIndex + 1}/${photos?.length}`}</LatoRegular>
          </View>
        </View>
      )}
    </View>
  );
}

export default LeftDetail;
