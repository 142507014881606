import React from "react";
import { TouchableOpacity, View } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import { IoMdClose } from "react-icons/io";

function ConditionModal({ setOpenConditionModal = () => {} }) {
  const conditionArr = [
    {
      title: "NEW",
      desc: "Bought directly from the store, but never been used. This brand new item comes with all original accompaniments.",
    },
    {
      title: "LIKE NEW",
      desc: "This item has only been worn a few times, usually less than 3 times. The condition is as good as the Brand new items and comes with all original accompaniments.",
    },
    {
      title: "EXCELLENT",
      desc: "Pre-loved item but is still in a good condition. There will be minor signs of wear to the leather and/or hardware but the wear does not distract from the overall look of the item.",
    },
    {
      title: "GOOD",
      desc: "This item has been used often and you can see signs of wear to the leather and/or hardware although still very much acceptable.",
    },
  ];

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <LatoBold
          style={{
            fontSize: 20,
            lineHeight: 28,
          }}
        >
          CONDITIONS
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenConditionModal(false)}>
          <IoMdClose size={24} />
        </TouchableOpacity>
      </View>
      {conditionArr.map(({ title, desc }, i) => (
        <View key={i} style={{ marginBottom: 16 }}>
          <LatoRegular
            style={{
              fontSize: 18,
              lineHeight: 26,
              marginBottom: 8,
            }}
          >
            {title}
          </LatoRegular>
          <LatoRegular
            style={{
              fontSize: 16,
              lineHeight: 24,
            }}
          >
            {desc}
          </LatoRegular>
        </View>
      ))}
    </View>
  );
}

export default ConditionModal;
