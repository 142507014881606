import { sentenceCase } from "change-case";
import { Formik } from "formik";
import React, { useContext, useState } from "react";
import { View, TouchableOpacity, useWindowDimensions } from "react-native";
import colors from "../../assets/colors/colors";
import { useAuth } from "../../contexts/AuthContext";
import { regexChecker } from "../../tools/regexChecker";
import { api } from "../../utils/api";
import { MyInput } from "../Inputs";
import { LatoRegular } from "../Text";
import { getDeviceType } from "../../tools/Interface";
import ToasterContext from "../../contexts/ToastContext";

export default function AccountDetails() {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [openChangePassword, setOpenChangePassword] = useState(false);

  const { loading, updateUser, loadingUpdateUser, user, refetch } = useAuth();
  const { email, detail = {}, address = {} } = user;
  const { firstname, lastname, phone } = detail;
  const { recipient_number } = address;

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { showToast } = useContext(ToasterContext);

  const nameFormArr = [
    {
      name: "firstname",
      placeholder: "First Name",
      required: true,
      initialValue: firstname,
    },
    {
      name: "lastname",
      placeholder: "Last Name",
      required: true,
      initialValue: lastname,
    },
  ];

  const detailDataArr = openChangePassword
    ? [
        {
          name: "email",
          placeholder: "Email Address",
          initialValue: email,
          disabled: true,
        },
        {
          name: "phone",
          placeholder: "Phone",
          initialValue: phone || recipient_number,
          prefix: "+62",
          disabled: true,
        },
      ]
    : [
        {
          name: "email",
          placeholder: "Email Address",
          initialValue: email,
          disabled: true,
        },
        {
          name: "phone",
          placeholder: "Phone",
          initialValue: phone || recipient_number,
          disabled: true,
          prefix: "+62",
        },
        {
          name: "password",
          placeholder: "Password",
          length: 8,
          initialValue: "********",
          disabled: true,
        },
      ];

  const changePassArr = [
    {
      name: "current_password",
      placeholder: "Current Password",
      required: true,
      hide: true,
      length: 8,
    },
    {
      name: "new_password",
      placeholder: "New Password",
      required: true,
      hide: true,
      length: 8,
    },
    {
      name: "confirm_new_password",
      placeholder: "Confirm New Password",
      required: true,
      hide: true,
      match: "new_password",
    },
  ];

  const handleChanges = async (value) => {
    const {
      firstname,
      lastname,
      email,
      phone,
      current_password,
      new_password,
      confirm_new_password,
    } = value;
    try {
      const sendToBE = {
        user: {
          detail_attributes: {
            name: `${firstname} ${lastname}`,
          },
          address_attributes: {
            recipient_number: phone,
          },
        },
      };

      const changePassPayload = {
        current_password,
        password: new_password,
        password_confirmation: confirm_new_password,
      };

      await updateUser(sendToBE);

      if (current_password) {
        await api.put("/api/auth", changePassPayload);
        setOpenChangePassword(false);
      }
      await refetch();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  return (
    <View>
      {Boolean(!isPhone) && (
        <LatoRegular style={{ fontSize: 20, lineHeight: 28, marginBottom: 24 }}>
          Account Details
        </LatoRegular>
      )}
      {loading ? null : (
        <Formik
          onSubmit={handleChanges}
          validateOnChange={hasSubmitted}
          validateOnBlur={hasSubmitted}
          initialValues={{
            email: email,
            firstname: firstname,
            lastname: lastname,
            displayname: firstname,
            phone: phone || recipient_number,
          }}
          validate={(values) => {
            !hasSubmitted && setHasSubmitted(true);
            const errors = {};

            const checkArr = openChangePassword
              ? [...nameFormArr, ...detailDataArr, ...changePassArr]
              : [...nameFormArr, ...detailDataArr];

            for (const a of checkArr) {
              const { name, required, placeholder, length, match, disabled } =
                a;

              if (!disabled) {
                if (required && !disabled && !values[name]) {
                  errors[name] = `${sentenceCase(placeholder)} can't be blank`;
                } else if (!regexChecker(name, values[name])) {
                  errors[name] =
                    name === "password"
                      ? "Password must includes 1 uppercase, 1 lowercase, and 1 number"
                      : `Invalid ${placeholder.toLowerCase()}`;
                } else if (length && values[name].length < length) {
                  errors[name] = `${sentenceCase(
                    name.split("_").join(" ")
                  )} must have at least ${length} characters`;
                } else if (match && values[name] !== values[match]) {
                  errors[name] = `${sentenceCase(
                    match.split("_").join(" ")
                  )} doesn't match`;
                }
              }
            }
            return errors;
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <View>
              <View
                style={{
                  flexDirection: isPhone ? "column" : "row",
                  alignItems: isPhone ? "flex-start" : "center",
                  justifyContent: "space-between",
                }}
              >
                {nameFormArr.map(
                  (
                    {
                      name,
                      placeholder,
                      required,
                      hide,
                      initialValue,
                      disabled,
                    },
                    index
                  ) => {
                    return (
                      <View key={index}>
                        <MyInput
                          error={errors[name]}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          name={name}
                          label={placeholder}
                          placeholder={placeholder}
                          required={required}
                          hide={hide}
                          initialValue={initialValue}
                          customWidth={isPhone ? "90vw" : "30vw"}
                          disabled={disabled}
                        />
                      </View>
                    );
                  }
                )}
              </View>
              {detailDataArr.map(
                (
                  {
                    name,
                    placeholder,
                    required,
                    hide,
                    initialValue,
                    disabled,
                    prefix,
                  },
                  index
                ) => {
                  return (
                    <View key={index}>
                      <MyInput
                        error={errors[name]}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        name={name}
                        label={placeholder}
                        placeholder={placeholder}
                        required={required}
                        hide={hide}
                        initialValue={initialValue}
                        customWidth={isPhone ? "90vw" : "72.5vw"}
                        disabled={disabled}
                        prefix={prefix}
                      />
                    </View>
                  );
                }
              )}
              {Boolean(openChangePassword) && (
                <>
                  <LatoRegular
                    style={{ fontSize: 20, lineHeight: 28, marginVertical: 24 }}
                  >
                    Change Password
                  </LatoRegular>
                  {changePassArr.map(
                    (
                      {
                        name,
                        placeholder,
                        required,
                        hide,
                        initialValue,
                        disabled,
                      },
                      index
                    ) => {
                      return (
                        <View key={index}>
                          <MyInput
                            error={errors[name]}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name={name}
                            label={placeholder}
                            placeholder={placeholder}
                            required={required}
                            hide={hide}
                            initialValue={initialValue}
                            customWidth={isPhone ? "90vw" : "72.5vw"}
                            disabled={disabled}
                          />
                        </View>
                      );
                    }
                  )}
                </>
              )}
              <View
                style={{
                  flexDirection: isPhone ? "column" : "row",
                  alignItems: isPhone ? "flex-start" : "center",
                }}
              >
                <TouchableOpacity
                  onPress={handleSubmit}
                  disabled={
                    errors.email ||
                    errors.firstname ||
                    errors.lastname ||
                    errors.displayname ||
                    errors.phone ||
                    loading ||
                    loadingUpdateUser
                  }
                  style={{
                    width: 250,
                    paddingVertical: 10,
                    paddingHorizontal: 12,
                    borderColor: colors.grey33,
                    borderWidth: 1,
                    borderRadius: 6,
                    marginVertical: 16,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Save Changes
                  </LatoRegular>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={() => {
                    setOpenChangePassword(!openChangePassword);
                  }}
                  style={{
                    paddingVertical: 4,
                    // paddingHorizontal: 12,
                    borderBottomColor: colors.grey33,
                    borderBottomWidth: 1,
                    marginLeft: isPhone ? 0 : 40,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    {openChangePassword ? "Cancel" : "Change Password"}
                  </LatoRegular>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </Formik>
      )}
    </View>
  );
}
