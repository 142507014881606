import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  View,
  TouchableOpacity,
  useWindowDimensions,
  FlatList,
} from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import AddListingItem from "./AddListingItem";
import { AiOutlineBulb, AiOutlinePlus } from "react-icons/ai";
import DragDropFile from "../DragDropFile";
import { upperCase } from "lodash";
import { api } from "../../utils/api";
import { useNavigate } from "react-router";
import { useFilePicker } from "use-file-picker";
import {
  asyncRemoveIsMultipart,
  asyncSetIsMultipart,
} from "../../tools/asyncStorages";
import Buttons from "../Buttons";
import ToasterContext from "../../contexts/ToastContext";
import { WrapperInsider } from "../Wrapper";

function SelfListing() {
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [photoArr, setPhotosArr] = useState([
    {
      name: "front_view",
      value: "",
      required: true,
      title: "Front",
    },
    {
      name: "rear_view",
      value: "",
      required: true,
      title: "Back",
    },
    {
      name: "bottom_view",
      value: "",
      required: true,
      title: "Bottom view",
    },
    {
      name: "left_side_view",
      value: "",
      required: true,
      title: "Left",
    },
    {
      name: "right_side_view",
      value: "",
      required: true,
      title: "Right",
    },
    {
      name: "top_view",
      value: "",
      required: true,
      title: "Top view",
    },
  ]);

  const { showToast } = useContext(ToasterContext);
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const [
    openFileSelector,
    { filesContent, loading: pickerLoading, plainFiles },
  ] = useFilePicker({
    accept: [".png", ".jpg", ".jpeg"],
    readAs: "DataURL",
    multiple: false,
  });

  const handleSelectedImage = () => {
    plainFiles.map((file) => {
      photoArr[selectedIndex].value = file;

      setPhotosArr([...photoArr]);
    });
  };

  const handleAddAnotherPhotos = () => {
    setPhotosArr([
      ...photoArr,
      {
        name: "additional",
        value: "",
        required: false,
        title: "Additional Photo",
      },
    ]);
  };

  useEffect(() => {
    handleSelectedImage();
  }, [filesContent]);

  const handleAddItem = async (val) => {
    try {
      setLoading(true);
      await asyncSetIsMultipart(true);
      const {
        designer,
        condition,
        name,
        story,
        color,
        additional: add,
        stamp,
        size,
        inclusion,
        hardware,
        leather,
      } = val;

      const additional = photoArr
        ?.filter((itm, index) => {
          if (index > 5 && itm?.value) {
            return itm;
          }
        })
        ?.map(({ value }) => value);

      const formData = new FormData();

      if (additional?.length) {
        for (let a = 0; a < additional.length; a++) {
          formData.append(
            "consignment[product_attributes][photo_details][]",
            additional[a],
            `additional_photo_${a}`
          );
        }
      }
      formData.append(
        "consignment[product_attributes][front_view]",
        photoArr[0]?.value
      );
      formData.append(
        "consignment[product_attributes][right_side_view]",
        photoArr[4]?.value
      );
      formData.append(
        "consignment[product_attributes][rear_view]",
        photoArr[1]?.value
      );
      formData.append(
        "consignment[product_attributes][left_side_view]",
        photoArr[3]?.value
      );
      formData.append(
        "consignment[product_attributes][top_view]",
        photoArr[5]?.value
      );
      formData.append(
        "consignment[product_attributes][bottom_view]",
        photoArr[2]?.value
      );
      formData.append(
        "consignment[product_attributes][designer_id]",
        designer?.id
      );
      formData.append("consignment[product_attributes][name]", name);
      formData.append("consignment[consignment_type]", "online");
      formData.append("consignment[consignment_method]", "basic");
      formData.append("consignment[seller_story]", story);
      formData.append("consignment[product_attributes][p_colour]", color?.name);
      formData.append("consignment[product_attributes][p_size]", size);
      formData.append(
        "consignment[product_attributes][condition_id]",
        condition?.id
      );
      formData.append("consignment[product_attributes][comes_with]", inclusion);
      formData.append("consignment[product_attributes][hardware]", hardware);
      formData.append("consignment[product_attributes][leather]", leather);
      formData.append("consignment[product_attributes][stamp]", stamp);
      formData.append(
        "consignment[product_attributes][additional_condition_description]",
        add
      );

      await api.post("/api/consignments", formData);

      navigate("/account/seller-dashboard", {
        state: { idx: 0, index: 2 },
      });
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      await asyncRemoveIsMultipart();
      setLoading(false);
    }
  };

  return (
    <View
      style={{
        flexDirection: isPhone ? "column" : "row",
        justifyContent: "space-between",
        marginTop: -24,
        marginHorizontal: isPhone ? 0 : isTablet ? 48 : 60,
        marginRight: isPhone ? 24 : isTablet ? 48 : 60,
        marginBottom: 50,
      }}
    >
      <View
        style={{
          width: isPhone ? width - 32 : isTablet ? 0.4 * width : 0.35 * width,
          marginRight: isPhone ? 0 : 48,
          marginBottom: isPhone ? 48 : 0,
        }}
      >
        <LatoBold
          style={{
            fontSize: 18,
            lineHeight: 26,
            color: colors.grey33,
            marginBottom: 8,
            textAlign: "left",
          }}
        >
          ADD PHOTOS
        </LatoBold>
        <LatoRegular
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey6C,
            marginBottom: 16,
            textAlign: "left",
          }}
        >
          Upload pictures of your items against a white backdrop.
        </LatoRegular>
        <LatoRegular
          style={{
            fontSize: 12,
            lineHeight: 16,
            color: colors.grey90,
            marginBottom: 16,
            textAlign: "left",
          }}
        >
          Allowed file extensions: .JPG, .JPEG, .PNG. Maximum size 10 MB per
          file.
        </LatoRegular>
        <LatoRegular
          style={{
            fontSize: 12,
            lineHeight: 16,
            color: colors.redB6,
            marginBottom: 16,
          }}
        >
          Required
          <LatoRegular style={{ color: colors.redB6 }}>*</LatoRegular>
        </LatoRegular>
        <View
          style={{
            backgroundColor: colors.whiteF1,
            borderWidth: 1,
            borderColor: colors.greyBB,
            borderRadius: 4,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            paddingVertical: 8,
            paddingHorizontal: 12,
            marginBottom: 32,
          }}
        >
          <AiOutlineBulb size={20} color={colors.grey6C} />
          <LatoRegular
            style={{
              fontSize: 12,
              lineHeight: 16,
              color: colors.grey6C,
              marginLeft: 12,
            }}
          >
            Tip : make sure your image is on 4:3 scale to get a better
            proportions
          </LatoRegular>
        </View>
        <FlatList
          data={photoArr}
          numColumns={3}
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item, index }) => {
            return (
              <View key={index} style={{ marginBottom: 20, width: "34.25%" }}>
                {Boolean(item?.value) ? (
                  <TouchableOpacity
                    onPress={(event) => {
                      openFileSelector();
                      setSelectedIndex(index);
                    }}
                  >
                    <View
                      style={{
                        borderWidth: 1,
                        borderColor: colors.greyE6,
                        borderRadius: 4,
                        padding: 20,
                        width: isPhone
                          ? 0.325 * (width - 80)
                          : (0.35 * width - 64) / 3,
                        height:
                          0.75 *
                          (isPhone ? 0.375 * (width - 80) : (0.35 * width) / 3),
                        marginBottom: 12,
                      }}
                    >
                      <Image
                        source={URL.createObjectURL(
                          item?.value?.imageFile || item?.value
                        )}
                        style={{
                          width: isPhone
                            ? (0.65 * (width - 80)) / 3
                            : (0.35 * width - 48) / 4,
                          height:
                            0.75 *
                            (isPhone
                              ? (0.65 * (width - 80)) / 3
                              : (0.35 * width - 48) / 4),
                        }}
                        resizeMode="contain"
                      />
                    </View>
                  </TouchableOpacity>
                ) : (
                  <DragDropFile
                    isSquare
                    setImageURI={(val) => {
                      photoArr[index].value = val;

                      setPhotosArr([...photoArr]);
                    }}
                    width={
                      isPhone
                        ? width < 450
                          ? 0.225 * (width - 80)
                          : 0.26 * (width - 80)
                        : (0.35 * width - 48) / 4
                    }
                  />
                )}
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 16,
                    color: colors.grey33,
                    textAlign: "center",
                    marginLeft: -20,
                  }}
                >
                  {item?.title}
                  {Boolean(item?.required) && (
                    <LatoRegular style={{ color: colors.redB6 }}>*</LatoRegular>
                  )}
                </LatoRegular>
              </View>
            );
          }}
          ListFooterComponent={
            <View>
              <View
                style={{
                  height: 16,
                  borderBottomColor: colors.greyE6,
                  borderBottomWidth: 1,
                  marginBottom: 16,
                }}
              />
              {Boolean(photoArr?.length < 16) && (
                <Buttons
                  onPress={handleAddAnotherPhotos}
                  label="Add more photos"
                  icon={
                    <AiOutlinePlus
                      width={20}
                      height={20}
                      color={colors.whiteFF}
                      style={{ marginRight: 8 }}
                    />
                  }
                  iconStyle="center"
                  isBlack
                />
              )}
            </View>
          }
        />
      </View>
      <View
        style={{
          width: isPhone ? width - 32 : isTablet ? 0.45 * width : 0.5 * width,
        }}
      >
        <LatoBold
          style={{
            fontSize: 24,
            lineHeight: 28,
            color: colors.grey33,
            marginBottom: 24,
          }}
        >
          Tell us about your item
        </LatoBold>
        <WrapperInsider engineeredHeight={height - 350}>
          <AddListingItem
            handleAddItem={handleAddItem}
            loading={loading}
            additionalRequirement={photoArr.find(
              ({ value, required }) => !value && required
            )}
          />
        </WrapperInsider>
      </View>
    </View>
  );
}

export default SelfListing;
