import React, { useContext, useEffect, useState } from "react";
import {
  TouchableOpacity,
  View,
  Image,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import colors from "../../assets/colors/colors";
import { api } from "../../utils/api";
import { getDeviceType } from "../../tools/Interface";
import { Formik } from "formik";
import { sentenceCase } from "change-case";
import { MyInput } from "../Inputs";
import Buttons from "../Buttons";
import { formatCurrency } from "../../tools/formatter";
import { RiCheckLine } from "react-icons/ri";
import { regexChecker } from "../../tools/regexChecker";
import ToasterContext from "../../contexts/ToastContext";
import { LatoBold, LatoRegular } from "../Text";
import { GrClose } from "react-icons/gr";
import { openWhatsapp } from "../../tools/tools";
import { contactDecider } from "../../tools/decider";

function CashoutForm({ id, setOpenModal, refund = false }) {
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [item, setItem] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [selected, setSelected] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const { showToast } = useContext(ToasterContext);

  const getData = async () => {
    try {
      setLoading(true);
      const res = await api.get(
        refund ? `api/deals/${id}` : `/api/consignments/${id}`
      );
      const data = res?.data?.data;

      setItem(data);
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = refund
    ? {
        bank: item?.bank || "",
        bank_account_name: item?.bank_account_name || "",
        bank_account_number: item?.bank_account_number || "",
        bank_branch: item?.bank_branch || "",
        return_tracking_number: item?.return_tracking_number || "",
      }
    : {
        bank: item?.bank || "",
        bank_account_name: item?.bank_account_name || "",
        bank_account_number: item?.bank_account_number || "",
      };

  const consignmentArr = [
    {
      name: "bank",
      placeholder: "Input bank name",
      label: "Bank Name",
      required: true,
      initialValue: initialValues?.bank,
      disabled: refund && initialValues?.bank,
    },
    {
      name: "bank_account_name",
      placeholder: "Input bank account owner name",
      label: "Account Name",
      required: true,
      initialValue: initialValues?.bank_account_name,
      disabled: refund && initialValues?.bank_account_name,
    },
    {
      name: "bank_account_number",
      placeholder: "Input account number",
      label: "Account Number",
      required: true,
      initialValue: initialValues?.bank_account_number,
      disabled: refund && initialValues?.bank_account_number,
    },
  ];

  const refundFormArr = item?.state_timestamp_logs?.find(
    ({ state }) => state === "completed"
  )
    ? [
        ...consignmentArr,
        {
          name: "bank_branch",
          placeholder: "Input bank branch",
          label: "Bank Branch",
          required: true,
          initialValue: initialValues?.bank_branch,
          disabled: refund && initialValues?.bank_branch,
        },
        {
          name: "return_tracking_number",
          placeholder: "Input delivery tracking receipt number",
          label: "Delivery Receipt Number",
          required: true,
          initialValue: initialValues?.return_tracking_number,
        },
      ]
    : [
        ...consignmentArr,
        {
          name: "bank_branch",
          placeholder: "Input bank branch",
          label: "Bank Branch",
          required: true,
          initialValue: initialValues?.bank_branch,
          disabled: refund && initialValues?.bank_branch,
        },
      ];

  const formArr = refund ? refundFormArr : consignmentArr;

  useEffect(() => {
    getData();
  }, []);

  const { product = {}, buying_price, cart = {} } = item;
  const { products = [] } = cart;

  const handleCashout = async (val) => {
    try {
      setLoadingSubmit(true);

      if (refund) {
        await api.put(`/api/deals/${id}/return`, {
          deal: val,
        });
      } else {
        await api.put(`/api/consignments/${id}/cashout`, {
          consignment: val,
        });
      }
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoadingSubmit(false);
      setOpenModal(false);
    }
  };

  return (
    <View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 40,
        }}
      >
        <LatoBold
          style={{ fontSize: 20, lineHeight: 28, color: colors.grey33 }}
        >
          {refund
            ? item?.state_timestamp_logs?.find(
                ({ state }) => state === "completed"
              )
              ? "Request Return"
              : "Request Refund"
            : "Cashout"}
        </LatoBold>
        <TouchableOpacity onPress={() => setOpenModal(false)}>
          <GrClose size={16} />
        </TouchableOpacity>
      </View>
      {loading ? (
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            height: 300,
          }}
        >
          <Image
            source={require("../../assets/images/spinning-loading.gif")}
            // alt={item?.product?.name}
            style={{
              alignSelf: "center",
              width: 200,
              height: 200,
            }}
            defaultSource={require("../../assets/images/spinning-loading.gif")}
            resizeMode={"contain"}
          />
        </View>
      ) : (
        <View>
          <ScrollView
            style={{ height: 125, marginBottom: 24 }}
            showsVerticalScrollIndicator={false}
          >
            {products?.length ? (
              products.map((product, idx) => {
                return (
                  <View key={idx} style={{ marginBottom: 6 }}>
                    <View
                      style={{
                        borderWidth: 1,
                        padding: 18,
                        borderRadius: 4,
                        alignItems: "center",
                        flexDirection: "row",
                        borderColor: colors.greyE6,
                      }}
                    >
                      <View
                        style={{
                          borderWidth: 1,
                          padding: 6,
                          borderRadius: 4,
                          justifyContent: "center",
                          marginRight: 16,
                          borderColor: colors.greyE6,
                          width: 60,
                          height: 60,
                        }}
                      >
                        <Image
                          source={{
                            uri: `${product?.summary_index?.direct_photos[0]}`,
                          }}
                          alt={product?.name}
                          style={{
                            alignSelf: "center",
                            width: 48,
                            height: 48,
                          }}
                          defaultSource={require("../../assets/images/spinning-loading.gif")}
                          resizeMode={"contain"}
                        />
                      </View>
                      <View
                        style={{
                          flexWrap: "wrap",
                          width: isPhone ? width - 200 : 475,
                        }}
                      >
                        <LatoBold
                          style={{
                            fontSize: 12,
                            lineHeight: 16,
                            color: colors.grey33,
                            marginBottom: 4,
                            textTransform: "uppercase",
                          }}
                        >
                          {product?.summary_index?.designer}
                        </LatoBold>
                        <LatoRegular
                          style={{
                            fontSize: 12,
                            lineHeight: 16,
                            color: colors.grey6C,
                            marginBottom: 4,
                            width: isPhone ? width - 200 : 350,
                          }}
                        >
                          {product?.name}
                        </LatoRegular>
                        <LatoBold
                          style={{
                            fontSize: 12,
                            lineHeight: 16,
                            color: colors.grey33,
                            marginBottom: 8,
                          }}
                        >
                          {`IDR ${formatCurrency(product?.selling_price)}`}
                        </LatoBold>
                      </View>
                    </View>
                  </View>
                );
              })
            ) : (
              <View>
                <View
                  style={{
                    borderWidth: 1,
                    padding: 18,
                    borderRadius: 4,
                    alignItems: "center",
                    flexDirection: "row",
                    marginRight: 16,
                    borderColor: colors.greyE6,
                    marginBottom: 12,
                  }}
                >
                  <View
                    style={{
                      borderWidth: 1,
                      padding: 6,
                      borderRadius: 4,
                      justifyContent: "center",
                      marginRight: 16,
                      borderColor: colors.greyE6,
                      width: 60,
                      height: 60,
                    }}
                  >
                    <Image
                      source={{
                        uri: `${
                          product?.summary_detail?.direct_photos[0] || product
                        }`,
                      }}
                      alt={product?.name}
                      style={{
                        alignSelf: "center",
                        width: 48,
                        height: 48,
                      }}
                      defaultSource={require("../../assets/images/spinning-loading.gif")}
                      resizeMode={"contain"}
                    />
                  </View>
                  <View
                    style={{
                      flexWrap: "wrap",
                      width: isPhone ? width - 200 : 475,
                    }}
                  >
                    <LatoBold
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        color: colors.grey33,
                        marginBottom: 4,
                        textTransform: "uppercase",
                      }}
                    >
                      {product?.summary_detail?.designer}
                    </LatoBold>
                    <LatoRegular
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        color: colors.grey6C,
                        marginBottom: 4,
                      }}
                    >
                      {product?.name}
                    </LatoRegular>
                    <LatoBold
                      style={{
                        fontSize: 12,
                        lineHeight: 16,
                        color: colors.grey33,
                        marginBottom: 8,
                      }}
                    >
                      {`IDR ${formatCurrency(buying_price)}`}
                    </LatoBold>
                  </View>
                </View>
              </View>
            )}
          </ScrollView>
          <Formik
            onSubmit={handleCashout}
            validateOnChange={hasSubmitted}
            validateOnBlur={hasSubmitted}
            initialValues={initialValues}
            validate={(values) => {
              !hasSubmitted && setHasSubmitted(true);
              const errors = {};

              for (const a of formArr) {
                const { name, required, label } = a;

                if (required && !values[name]) {
                  errors[name] = `${sentenceCase(label)} can't be blank`;
                } else if (
                  name !== "return_tracking_number" &&
                  name.includes("number")
                ) {
                  if (!regexChecker(name, values[name])) {
                    errors[name] = `${sentenceCase(label)} only accept number`;
                  }
                }
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <View>
                <ScrollView
                  style={{
                    height: item?.state_timestamp_logs?.find(
                      ({ state }) => state === "completed"
                    )
                      ? 0.45 * height
                      : refund
                      ? 0.35 * height
                      : "auto",
                    marginBottom: 12,
                  }}
                  showsVerticalScrollIndicator={false}
                >
                  {formArr.map(
                    (
                      {
                        name,
                        placeholder,
                        required,
                        hide,
                        initialValue,
                        disabled,
                        label,
                      },
                      index
                    ) => {
                      return (
                        <View key={index}>
                          <MyInput
                            error={errors[name]}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            required={required}
                            hide={hide}
                            initialValue={initialValue}
                            customWidth={"100%"}
                            disabled={disabled}
                          />
                        </View>
                      );
                    }
                  )}
                </ScrollView>
                {Boolean(
                  !refund ||
                    (refund &&
                      item?.state_timestamp_logs?.find(
                        ({ state }) => state === "completed"
                      ))
                ) && (
                  <View
                    style={{
                      flexDirection: "row",
                      // alignItems: "center",
                      marginBottom: 16,
                    }}
                  >
                    {Boolean(!refund) && (
                      <TouchableOpacity
                        onPress={() => setSelected(!selected)}
                        style={{
                          backgroundColor: selected
                            ? colors.grey33
                            : colors.whiteFF,
                          borderRadius: 4,
                          borderWidth: 1,
                          borderColor: selected ? colors.grey33 : colors.greyE6,
                          padding: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          height: 16,
                          width: 16,
                          marginRight: 8,
                        }}
                      >
                        <RiCheckLine size={12} color={colors.whiteFF} />
                      </TouchableOpacity>
                    )}
                    {!refund ? (
                      <LatoRegular
                        style={{
                          fontSize: 12,
                          lineHeight: 16,
                          color: colors.grey6C,
                        }}
                      >
                        I confirm that the bank account information is the
                        intended beneficiary. I absolve Belle&Kate of any
                        financial responsibility due to wrong information
                        provided by me.
                      </LatoRegular>
                    ) : (
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: colors.grey33,
                        }}
                      >
                        <LatoBold
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey33,
                          }}
                          onPress={() => {
                            const contact = contactDecider();
                            openWhatsapp(
                              `Could you please assist me in returning the item(s) associated with reference number ${item?.reference_number}?`,
                              contact
                            );
                          }}
                        >
                          Contact us
                        </LatoBold>
                        {" for return procedures."}
                      </LatoRegular>
                    )}
                  </View>
                )}
                <View
                  style={{
                    flexDirection: isPhone ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Buttons
                    label={
                      refund
                        ? item?.state_timestamp_logs?.find(
                            ({ state }) => state === "completed"
                          )
                          ? "Request Return"
                          : "Request Refund"
                        : "Cashout"
                    }
                    loading={loadingSubmit}
                    isBlack
                    onPress={handleSubmit}
                    disabled={
                      errors.bank ||
                      errors.bank_account_name ||
                      errors.bank_account_number ||
                      (refund && errors.bank_branch) ||
                      Boolean(!selected && !refund) ||
                      loadingSubmit
                    }
                    width={"100%"}
                  />
                </View>
              </View>
            )}
          </Formik>
        </View>
      )}
    </View>
  );
}

export default CashoutForm;
