import React, { useEffect, useState } from "react";
import { Image, TouchableOpacity, View, ScrollView } from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import { GrClose } from "react-icons/gr";
import DragDropFile from "../DragDropFile";
import Buttons from "../Buttons";

function AddItemPhotos({
  setOpenModal = () => {},
  handleAddItem = () => {},
  photos = [],
  loading = false,
  isPhone = false,
  height = "100%",
  noButton = false,
}) {
  const [photosArr, setPhotosArr] = useState(photos);

  const handleCancelPhoto = (i) => {
    setPhotosArr(
      photosArr.filter((item, index) => {
        if (index !== i) return item;
      })
    );
  };

  useEffect(() => {
    if (noButton) {
      handleAddItem(photosArr);
    }
  }, [photosArr]);

  return (
    <View>
      {Boolean(!noButton) && (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: 4,
          }}
        >
          <LatoBold
            style={{ fontSize: 20, lineHeight: 28, color: colors.grey33 }}
          >
            Add item photo
          </LatoBold>
          <TouchableOpacity onPress={() => setOpenModal(false)}>
            <GrClose size={16} />
          </TouchableOpacity>
        </View>
      )}
      {Boolean(!noButton) && (
        <LatoRegular
          style={{
            fontSize: 14,
            lineHeight: 20,
            marginBottom: 24,
            color: colors.grey6C,
          }}
        >
          Fill in your item details.
        </LatoRegular>
      )}
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 24,
          marginBottom: 16,
          color: colors.grey33,
        }}
      >
        You can upload up to 6 files.
        <LatoRegular
          style={{
            fontSize: 16,
            lineHeight: 24,
            color: colors.redB6,
          }}
        >
          *
        </LatoRegular>
      </LatoRegular>

      <DragDropFile
        multiple
        setImage={setPhotosArr}
        image={photosArr}
        maxFile={6}
      />
      {Boolean(photosArr?.length) && (
        <LatoRegular
          style={{
            fontSize: 14,
            lineHeight: 20,
            marginTop: 16,
            marginBottom: 8,
            color: colors.black00,
          }}
        >
          {`${photosArr?.length} photo(s) uploaded.`}
        </LatoRegular>
      )}
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{
          marginBottom: 32,
          height: noButton
            ? photosArr?.length > 1
              ? 100
              : "auto"
            : 0.25 * height,
        }}
      >
        {photosArr?.map((item, i) => {
          const name = item?.name || item?.imageFile?.name || "";
          const size = item?.size || item?.imageFile?.size || "";

          return (
            <View
              key={i}
              style={{
                borderWidth: 1,
                borderColor: colors.greyE6,
                borderRadius: 4,
                padding: 12,
                marginBottom: 8,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {Boolean(item) && (
                  <Image
                    source={URL.createObjectURL(item?.imageFile || item)}
                    style={{ width: 40, height: 40, marginRight: 12 }}
                    resizeMode="contain"
                  />
                )}
                <View style={{ width: isPhone ? 150 : 250 }}>
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      // marginBottom: 8,
                      color: colors.grey33,
                    }}
                  >
                    {name || ""}
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      // marginBottom: 24,
                      color: colors.grey90,
                    }}
                  >
                    {`${(size / 1000).toFixed(2)} KB` || ""}
                  </LatoRegular>
                </View>
              </View>
              <TouchableOpacity onPress={() => handleCancelPhoto(i)}>
                <GrClose size={14} color={colors.grey90} />
              </TouchableOpacity>
            </View>
          );
        })}
      </ScrollView>
      {Boolean(!noButton) && (
        <Buttons
          isBlack
          label="Add photo(s)"
          disabled={!photosArr.length}
          loading={loading}
          onPress={() => handleAddItem(photosArr)}
        />
      )}
    </View>
  );
}

export default AddItemPhotos;
