// import { sleep } from "../tools";
import axios from "axios";
import { ROOT_URL, ROOT_URL_ALTERNATE } from "../constants/api.js";
import CryptoJS from "react-native-crypto-js";
import {
  asyncGetCredentials,
  asyncGetIsMultipart,
  asyncSetCredentials,
  getApiMode,
} from "../tools/asyncStorages";
import { logApi } from "../tools/log";
// import { navigate } from "./navigationService";
import { useContext, useState } from "react";
import { ToasterContext } from "../contexts/ToastContext";
import { useQuery as useReactQuery } from "@tanstack/react-query";
// import { useLoadingModal } from "../contexts/ModalContext";
import _ from "lodash";

const AESPassword = "Belle&KateAESP";
export const getRootUrl = async () => {
  const apiMode = await getApiMode();
  const root = apiMode === "default" ? ROOT_URL : ROOT_URL_ALTERNATE;
  return root;
};

export const encryptCredentials = (credentialsStr) => {
  const safeCredentials = credentialsStr || "";
  const encryptedCredentials = CryptoJS.AES.encrypt(
    safeCredentials,
    AESPassword
  ).toString();
  return encryptedCredentials;
};

export const decryptCredentials = (encryptedCredentials) => {
  const bytes = CryptoJS.AES.decrypt(encryptedCredentials, AESPassword);
  const result = bytes.toString(CryptoJS.enc.Utf8);
  return result;
};
const createObject = (baseURL, timeout = 30000) => ({
  baseURL,
  timeout,
  CancelToken: axios.CancelToken,
});

const requestFunc = async (config) => {
  config.baseURL = await getRootUrl();
  const credentials = await asyncGetCredentials();
  const multipart = await asyncGetIsMultipart();
  if (credentials) {
    config.headers = multipart
      ? { "Content-Type": "multipart/form-data", ...credentials }
      : { ...credentials };
  }
  return config;
};

const responseFunc = async (res) => {
  const accessToken = _.get(res, "headers.access-token");
  const client = _.get(res, "headers.client");
  const uid = _.get(res, "headers.uid");
  const tokenType = _.get(res, "headers.token-type");

  if (accessToken && client && uid && tokenType) {
    const credentials = {
      "access-token": accessToken,
      "token-type": tokenType,
      client,
      uid,
    };
    await asyncSetCredentials(credentials);
  }
  logApi(res);
  return res;
};

export const errorApiDecider = (error) => {
  const stringErr = String(error || "");
  const isNoInternet = stringErr.includes("Network Error");
  const isServerError = stringErr.includes("500");
  const invalidData = stringErr.includes("422");
  const isUnauthorized = stringErr.includes("401");

  return { isNoInternet, isServerError, invalidData, isUnauthorized };
};

const errorFunc = async (error) => {
  const { isNoInternet, isUnauthorized } = errorApiDecider(error);

  // if (isNoInternet || isServerError) Sentry.captureException(new Error(error));

  if (error.response) {
    const hasCredentials = Boolean(await asyncGetCredentials());

    if (error.response.status === 401) {
      if (hasCredentials) {
        // navigate("LogoutScreen", { sessionExpired: true });
      }
      // navigate("AuthStack");
    }
  } else {
    console.log("error:", error);
  }
  return Promise.reject(error?.response || error);
};

export const api = axios.create(createObject(ROOT_URL));

api.interceptors.request.use(requestFunc);
api.interceptors.response.use(responseFunc, errorFunc);

// const apiDecider = type => {
//   if (type === 'strapi') return strapi;
//   return apiBusiness;
// };

export const mockApi = async (payload) => {
  // await sleep(1000);
  return { message: "Success!", payload };
};

export const useMutation = ({
  defaultValue = {},
  method = "put",
  url,
  resultFormatter = (data) => data,
  afterSuccess,
  withError = true,
  handleError,
  defaultLoading = false,
  showToast = () => {},
}) => {
  const [loading, setLoading] = useState(defaultLoading);
  const [result, setResult] = useState(defaultValue);

  const mutation = async (value) => {
    try {
      setLoading(true);
      const response = await api[method](url, value);
      setResult((prev) => resultFormatter(response, prev));

      const { token, ...payload } = value || {};
      typeof afterSuccess == "function" &&
        (await afterSuccess(resultFormatter(response), payload));
    } catch (error) {
      console.log(error, "useMutation error");
      handleError && handleError(error);
      if (withError) showToast(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutation, result, setResult };
};

// export const useQuery = ({
//   url,
//   identifier, // key for caching
//   formatter = (val) => val,
//   type,
//   withErrorToast,
//   defaultValue = [],
//   afterSuccess,
//   withCaching,
//   autoFetching = true, // set to false if need to fetch only on trigger
//   handleError,
// }) => {
//   // const { showErrorToast } = useToast();
//   const [loading, setLoading] = useState(true);
//   const [result, setResult] = useState(defaultValue);

//   const getData = async () => {
//     try {
//       setLoading(true);
//       const { data } = await api.get(url);
//       const formattedData = formatter(data);
//       setResult(formattedData);
//       afterSuccess && afterSuccess(formattedData);
//       return formattedData;
//     } catch (err) {
//       console.log(err, "useQuery error");
//       // Alert.alert('useQueryError', JSON.stringify(err, null, 4));
//       // if (withErrorToast) showErrorToast(err);
//       handleError && handleError(err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   // if (withCaching) {
//   //   // automatically queries on call and caches data based on identifier
//   //   const queryObj = useReactQuery([identifier || url], getData, {
//   //     initialData: defaultValue,
//   //     enabled: autoFetching,
//   //   });

//   //   const {
//   //     isLoading, //loading if the query is in a "hard" loading state. This means there is no cached data and the query is currently fetching, eg isFetching === true
//   //     isFetching,
//   //     isError,
//   //     error,
//   //     data,
//   //     refetch,
//   //   } = queryObj;
//   //   return { data, loading: isLoading, isFetching, refetch, isError, error };
//   // }

//   return { data: result, loading, refetch: getData };
// };

export const axiosCanceller = (cancelTokenRef, cancelTokenSource) => {
  // cancel previous axios call if it's still ongoing to prevent wrong rates data from being presented
  if (cancelTokenRef.current) {
    cancelTokenRef.current();
  }
  cancelTokenRef.current = () =>
    cancelTokenSource.cancel("Operation cancelled by the user.");
};
