import React, { useEffect, useState } from "react";
import { GrClose } from "react-icons/gr";
import {
  View,
  TouchableOpacity,
  useWindowDimensions,
  Animated,
} from "react-native";
import { useNavigate } from "react-router-dom";
import colors from "../assets/colors/colors";
import { useAuth } from "../contexts/AuthContext";
import { getDeviceType } from "../tools/Interface";
import { api } from "../utils/api";
import Shimmer from "./Shimmer";
import { LatoRegular } from "./Text";
import Buttons from "./Buttons";
import CartProduct from "./CartProduct";
import CheckoutProduct from "./CheckoutProduct";

export default function Cart({
  setOpenCart = () => {},
  setCustomOpenCart = () => {},
  getData,
  getItem,
  isCheckout = false,
  calculator = false,
  customLoading = false,
  animatedWidth = 450,
  opacity = 1,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const { user } = useAuth();
  const navigate = useNavigate();

  const getItems = async () => {
    try {
      setLoading(true);
      const res = await api.get("/api/carts");
      const data = res?.data?.data;

      setData(data || {});
    } catch (err) {
      console.log("err:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getItems();
  }, [calculator]);

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        if (!isPhone) {
          setOpenCart(false);
          setCustomOpenCart(false);
        }
      }}
      style={{
        zIndex: 10,
        position: isCheckout ? "relative" : "sticky",
        top: 0,
        backgroundColor: colors.transparentBlack,
        height: isCheckout ? "auto" : height,
        width: isCheckout ? isCheckout : width,
        marginBottom: isCheckout ? 0 : -(height - 88),
      }}
    >
      <Animated.View
        style={{
          zIndex: 4,
          backgroundColor: colors.whiteFF,
          width: isCheckout ? isCheckout : animatedWidth,
          alignSelf: isCheckout ? "flex-start" : "flex-end",
          height: isCheckout ? "auto" : height,
          paddingHorizontal: isCheckout ? 0 : 24,
          paddingVertical: isCheckout ? 0 : 44,
          opacity,
        }}
      >
        <TouchableOpacity
          activeOpacity={1}
          style={{ height: isCheckout ? "auto" : height - 48 }}
        >
          {Boolean(!isCheckout) && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: 20,
                borderBottomColor: colors.greyE6,
                borderBottomWidth: 1,
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <LatoRegular>CART</LatoRegular>
                {loading ? (
                  <View style={{ marginHorizontal: 10 }}>
                    <Shimmer
                      autoRun={true}
                      visible={false}
                      width={"100%"}
                      height={16}
                      hasBorder
                    />
                  </View>
                ) : Boolean(data?.products?.length && user?.id) ? (
                  <>
                    <View
                      style={{
                        backgroundColor: colors.grey33,
                        borderRadius: "100%",
                        width: 5,
                        height: 5,
                        marginHorizontal: 10,
                      }}
                    />
                    <LatoRegular>{`${data.products.length} items`}</LatoRegular>
                  </>
                ) : null}
              </View>
              <TouchableOpacity
                onPress={async () => {
                  setOpenCart(false);
                  setCustomOpenCart(false);
                }}
              >
                <GrClose size={14} />
              </TouchableOpacity>
            </View>
          )}
          {Boolean(data?.products?.length && user?.id) ? (
            <CartProduct
              isCheckout={isCheckout}
              getData={getData}
              getItem={getItem}
              getItems={getItems}
              items={data?.products}
              loading={loading}
            />
          ) : (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: height - 300,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 20,
                  lineHeight: 28,
                  color: colors.grey33,
                  textAlign: "center",
                  marginBottom: 24,
                }}
              >
                {user?.id
                  ? "YOUR CART IS EMPTY"
                  : "Please create an account first."}
              </LatoRegular>
              {Boolean(!user?.id) && (
                <Buttons
                  label={"Create Account"}
                  onPress={() => {
                    setOpenCart(false);
                    setCustomOpenCart(false);
                    navigate("/register");
                  }}
                  width={260}
                  containerStyle={{ marginBottom: 12 }}
                />
              )}
              <Buttons
                label={user?.id ? "Shop now" : "Login"}
                onPress={() => {
                  setOpenCart(false);
                  setCustomOpenCart(false);
                  navigate(user?.id ? "/products" : "/login");
                }}
                isBlack
                width={262}
              />
            </View>
          )}
          {Boolean(data?.products?.length && user?.id) && (
            <CheckoutProduct
              data={data}
              customLoading={customLoading}
              isCheckout={isCheckout}
              loading={loading}
              getItems={getItems}
              getItem={getItem}
              getData={getData}
            />
          )}
        </TouchableOpacity>
      </Animated.View>
    </TouchableOpacity>
  );
}
