import React from "react";
import { View, Image, ScrollView, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import AboutUs from "../assets/images/about-us.png";
import AboutUsPhone from "../assets/images/about-us-phone.png";
import About1 from "../assets/images/about-line-1.png";
import About2 from "../assets/images/about-line-2.png";
import About3 from "../assets/images/about-line-3.png";
import colors from "../assets/colors/colors";
import { LatoRegular } from "../components/Text";
import { getDeviceType } from "../tools/Interface";
import Footer from "../components/Footer";
import Commitment from "../components/Home/Commitment";
import VisitUs from "../components/Home/VisitUs";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";

export default function AboutUsScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const navigate = useNavigate();

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View>
      <ScrollView
        style={{ height: "100vh" }}
        showsVerticalScrollIndicator={false}
      >
        <Navbar backgroundColor={colors.whiteFF} />
        <Image
          source={isPhone ? AboutUsPhone : AboutUs}
          alt={"About Us"}
          style={{
            alignSelf: "center",
            width,
            height: isPhone ? 360 : (width * 600) / 1920,
            // marginRight: 12,
          }}
          defaultSource={require("../assets/images/spinning-loading.gif")}
          resizeMode={isPhone ? "cover" : "contain"}
        />
        <LatoRegular
          style={{
            fontSize: isPhone ? 24 : isTablet ? 48 : 60,
            lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
            marginTop: isPhone ? 48 : 100,
            marginBottom: 16,
            textAlign: "center",
          }}
        >
          RELEASE. RELOVE. REVOLVE.
        </LatoRegular>
        <LatoRegular
          style={{
            fontSize: isPhone ? 12 : 24,
            lineHeight: isPhone ? 16 : 32,
            textAlign: "center",
            color: colors.grey6C,
          }}
        >
          GIVE YOUR LUXURY GOODS A SECOND LIFE.
        </LatoRegular>
        <View
          style={{
            flexDirection: isPhone ? "column" : "row",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "center" : "space-between",
            marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 48 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <Image
            source={About1}
            alt={"A Trusted Room"}
            style={{
              alignSelf: "center",
              width: isPhone ? containerWidth : (containerWidth - 40) / 2,
              height: isPhone
                ? (containerWidth * 281) / 358
                : (containerWidth - 40) / 2,
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
              marginLeft: isPhone ? 0 : 24,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              A TRUSTED ROOM
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginVertical: 32,
              }}
            >
              Our ROOM is a resource for tens of thousands of members in our
              community to trade, sell, consign and shop luxury goods. We
              believe in extending the life of the luxury items to transform the
              way we consume them. We aim to ensure that the luxury goods get a
              second life for those who want to purchase their dream high-end
              designer goods while also maximizing the original owner’s value of
              investment.
            </LatoRegular>
            <Buttons
              label="SHOP COLLECTIONS"
              onPress={() => navigate("/products")}
              noBorder
            />
          </View>
        </View>
        <View
          style={{
            flexDirection: isPhone ? "column" : "row-reverse",
            alignItems: isPhone ? "flex-start" : "center",
            justifyContent: isPhone ? "center" : "space-between",
            marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 24 : 100,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <Image
            source={About2}
            alt={"A Trusted Room"}
            style={{
              alignSelf: "center",
              width: isPhone ? containerWidth : (containerWidth - 40) / 2,
              height: isPhone
                ? (containerWidth * 281) / 358
                : (containerWidth - 40) / 2,
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: isPhone ? containerWidth : containerWidth / 2,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
              marginRight: isPhone ? 0 : 24,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 0,
              }}
            >
              HOW WE STARTED
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginVertical: 32,
              }}
            >
              Our founder, Ardine Nakety, found an opportunity to build
              Belle&Kate ROOM from her own personal experience. Her friends
              encountered difficulties in managing their luxury goods that they
              no longer use while wanting to find new luxury items for their
              wardrobe. From this problem, Ardine came up with a solution by
              creating a platform where people can #RELEASE and #RELOVE their
              luxury goods by allowing the luxury items to #REVOLVE
            </LatoRegular>
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            // marginBottom: 50,
            width: containerWidth,
            marginHorizontal: gap,
            paddingVertical: isPhone ? 24 : 100,
          }}
        >
          <Image
            source={isPhone ? AboutUsPhone : About3}
            alt={"A Trusted Room"}
            style={{
              alignSelf: "center",
              width: containerWidth,
              height:
                (containerWidth * (isPhone ? 281 : 500)) /
                (isPhone ? 358 : 1460),
            }}
            defaultSource={require("../assets/images/spinning-loading.gif")}
            resizeMode={isPhone ? "cover" : "contain"}
          />
          <View
            style={{
              width: containerWidth,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <LatoRegular
              style={{
                fontSize: isTablet ? 24 : 32,
                lineHeight: isTablet ? 32 : 40,
                marginTop: isPhone ? 24 : 32,
              }}
            >
              HOW WE HAVE EVOLVED
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginVertical: 32,
              }}
            >
              Over the years, Belle&Kate has become the go-to trusted platform
              to buy, sell and trade amongst our community. In 2019, we opened
              our first showroom for our customers’ private viewing in PIK,
              North Jakarta and then In 2021, moved to one of Jakarta’s most
              prominent locations in South Jakarta; located at the lobby of
              Fairgrounds, SCBD.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginBottom: 32,
              }}
            >
              We strive to continue to grow our community through intimate in
              store events and aim to give the best service from our dedicated
              client advisors towards our customers. Not only that we have
              expanded to curating and selling luxury watches, we have also
              started to make available authentication services for all luxury
              bags. Personalized services such as Home Shopping for our VIP
              customers who would like to shop from the comfort of their own
              home as well as B&K Selects, a service that will allow our
              dedicated sales advisors to help you choose which luxury bags are
              better for you to consign, direct sell or trade are also
              available.
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : isTablet ? 14 : 20,
                lineHeight: isPhone ? 16 : isTablet ? 20 : 28,
                textAlign: "center",
                marginBottom: 32,
              }}
            >
              As the years go by, we will keep expanding our stores to
              continuously reach new markets. Now, our newly expanded Jakarta
              store has been relaunched with more curated selections of
              pre-owned luxury goods. Moreover, we are also opening our first
              offline store in Singapore’s upscale location, Orchard, in mid
              2023.
            </LatoRegular>
          </View>
        </View>
        <Commitment />
        <VisitUs />
        <Footer />
      </ScrollView>
    </View>
  );
}
