import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer";
import { ScrollView, View, useWindowDimensions } from "react-native";
import Navbar from "../components/Navbar";
import TopComponent from "../components/WorkWithUs/TopComponent";
import MainComponent from "../components/WorkWithUs/MainComponent";
import { useLocation } from "react-router";

function WorkWithUsScreen() {
  const scrollRef = useRef();
  const { state } = useLocation();
  const { section } = state || {};

  const [yPosition, setYPosition] = useState(0);
  const [aboutPosition, setAboutPosition] = useState({ clique: 0, agents: 0 });

  const { height } = useWindowDimensions();

  useEffect(() => {
    if (yPosition) {
      scrollRef?.current?.scrollTo({
        y: yPosition - 250,
        animated: true,
      });
      setYPosition(0);
    }
  }, [yPosition]);

  useEffect(() => {
    setTimeout(() => {
      scrollRef?.current?.scrollTo({
        y: yPosition
          ? yPosition - 250
          : aboutPosition.hasOwnProperty(section)
          ? aboutPosition[section] - 250
          : 0,
        animated: true,
      });
      setYPosition(0);
    }, 500);
  }, [section]);

  return (
    <View>
      <ScrollView
        ref={scrollRef}
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <TopComponent
          setYPosition={setYPosition}
          aboutPosition={aboutPosition}
        />
        <MainComponent
          setAboutPosition={setAboutPosition}
          aboutPosition={aboutPosition}
        />
        <Footer />
      </ScrollView>
    </View>
  );
}

export default WorkWithUsScreen;
