import React, { useState } from "react";
import {
  Image,
  ScrollView,
  View,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import About1 from "../assets/images/about-line-1.png";
import ContactUs from "../assets/images/contact-us.png";
import Entrupy from "../assets/images/entrupy.png";
import Bababebi from "../assets/images/bababebi.png";
import Authenticator from "../assets/images/authenticator.png";
import Buttons from "../components/Buttons";
import { BsWhatsapp } from "react-icons/bs";
import { openWhatsapp } from "../tools/tools";
import { contactDecider } from "../tools/decider";
import { createVideoThumbnail } from "../tools/formatter";
import Play from "../assets/images/play-circle-outline.svg";
import Modal from "../components/Modal";
import VideoModal from "../components/VideoModal";

function AuthenticationScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const [openVideo, setOpenVideo] = useState(false);

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const processArr = [
    {
      image: About1,
      title: "Quality control",
      content: [
        "We cautiously inspect each goods that step into our ROOM to make sure the quality links up to our standard. Each one of them will be set down into a rigorous validation process by our in-house experts who have a great knowledge and experience in the art of authentication.",
        "Once the authenticity is verified, the goods will be up on our website for sale.",
      ],
    },
    {
      image: ContactUs,
      title: "entrupy / bababebi check",
      content: [
        "We utilize ENTRUPY, the world’s first authentication solution for high-value goods.",
        "ENTRUPY has developed an AI-powered technology that can instantly identify authentic and counterfeit luxury goods with a success rate of 99,1%. On the other hand, specifically for Hermès, we use BABABEBI’s authentication service that is owned by Ms. Dimity Giles.",
        "She developed her expertise in authentication based on over thirty years of collecting and meticulous comparative study of the construction details and artisanship methods of Hermès handbags and other leather goods.",
        "<b>*The opinions rendered by BABABEBI are recognized and accepted worldwide.</b>",
      ],
    },
    {
      image: Authenticator,
      content: [
        {
          logo: Bababebi,
          tag: "Hermes Bags Only",
          services: [
            "<b>Normal leather :</b> Rp3.000.000",
            "<b>Exotic Skin Including touch :</b> Rp5.000.000",
            "<b>Himalaya and Diamond Hardware :</b> Rp3.000.000",
          ],
        },
        // {
        //   logo: Entrupy,
        //   tag: "Other European Brands Bags Only",
        //   services: ["<b>Non-Hermes :</b> Rp650.000"],
        // },
      ],
    },
  ];

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View style={{ paddingHorizontal: gap }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              paddingVertical: isPhone ? 48 : 100,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isPhone ? 24 : isTablet ? 48 : 60,
                lineHeight: isPhone ? 32 : isTablet ? 56 : 72,
                color: colors.black00,
                marginBottom: 16,
                textTransform: "uppercase",
              }}
            >
              We hate counterfeit goods!
            </LatoRegular>
            <LatoRegular
              style={{
                fontSize: isPhone ? 12 : 24,
                lineHeight: isPhone ? 16 : 32,
                textAlign: "center",
                color: colors.grey6C,
              }}
            >
              All items sold on our platform are guaranteed authentic. We take
              our authentication process thoroughly to make sure all our luxury
              items are certified authentic.
            </LatoRegular>
          </View>
          <View
            style={{
              paddingBottom: isPhone ? 24 : 50,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: gap,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isPhone ? 16 : 24,
                lineHeight: isPhone ? 24 : 32,
                color: colors.grey6C,
                marginBottom: 12,
                backgroundColor: colors.whiteFF,
                paddingHorizontal: isPhone ? 8 : 56,
                textTransform: "uppercase",
              }}
            >
              Watch how we do it
            </LatoRegular>
            <View
              style={{
                position: "absolute",
                zIndex: -1,
                top: isPhone ? 12 : 16,
                width: containerWidth,
                marginHorizontal: -gap,
                height: 1,
                backgroundColor: colors.greyE6,
                left: gap,
              }}
            />
          </View>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginBottom: isPhone ? 48 : 100,
            }}
          >
            <Image
              source={createVideoThumbnail(
                "https://www.youtube.com/watch?v=Oq30x5QpPgg"
              )}
              // alt={alt}
              style={{
                height:
                  ((isPhone ? 250 : 517) * width) / (isPhone ? 390 : 1920),
                width: isPhone ? width : (920 * width) / 1920,
              }}
              defaultSource={require("../assets/images/spinning-loading.gif")}
              resizeMode={"cover"}
            />
            <TouchableOpacity
              onPress={() => {
                setOpenVideo(true);
              }}
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                source={Play}
                alt={"play-button"}
                style={{
                  height: 48,
                  width: 48,
                }}
                resizeMode={"contain"}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{
              paddingBottom: isPhone ? 24 : 50,
              justifyContent: "center",
              alignItems: "center",
              paddingHorizontal: gap,
            }}
          >
            <LatoRegular
              style={{
                fontSize: isPhone ? 16 : 24,
                lineHeight: isPhone ? 24 : 32,
                color: colors.grey6C,
                marginBottom: 12,
                backgroundColor: colors.whiteFF,
                paddingHorizontal: isPhone ? 8 : 56,
                textTransform: "uppercase",
              }}
            >
              Our Authentication Process
            </LatoRegular>
            <View
              style={{
                position: "absolute",
                zIndex: -1,
                top: isPhone ? 12 : 16,
                width: containerWidth,
                marginHorizontal: -gap,
                height: 1,
                backgroundColor: colors.greyE6,
                left: gap,
              }}
            />
          </View>
          {processArr.map(({ image, title, content }, i) => (
            <View
              key={i}
              style={{
                flexDirection: isPhone
                  ? "column"
                  : (i + 1) % 2 === 0
                  ? "row-reverse"
                  : "row",
                alignItems: isPhone ? "flex-start" : "center",
                justifyContent: isPhone ? "center" : "space-between",
                marginBottom: isPhone ? 0 : 50,
                paddingVertical: isPhone ? 48 : 100,
                borderBottomColor: colors.greyE6,
                borderBottomWidth: 1,
              }}
            >
              <Image
                source={image}
                alt={"A Trusted Room"}
                style={{
                  alignSelf: "center",
                  width: isPhone ? containerWidth : (containerWidth - 40) / 2,
                  height: isPhone
                    ? (containerWidth * 281) / 358
                    : (containerWidth - 40) / 2,
                }}
                defaultSource={require("../assets/images/spinning-loading.gif")}
                resizeMode={
                  isPhone || i === processArr.length - 1 ? "cover" : "contain"
                }
              />
              {Boolean(i === processArr.length - 1) && (
                <View
                  style={{
                    position: "absolute",
                    top: isPhone ? 50 : 100,
                    bottom: 0,
                    width: isPhone ? containerWidth : (containerWidth - 40) / 2,
                    height: isPhone
                      ? (containerWidth * 281) / 358
                      : (containerWidth - 40) / 2,
                    justifyContent: "center",
                    alignItems: "center",
                    paddingHorizontal: isPhone ? 24 : 60,
                  }}
                >
                  <LatoBold
                    style={{
                      fontSize: isTablet ? 24 : 32,
                      lineHeight: isTablet ? 32 : 40,
                      color: colors.whiteFF,
                      textTransform: "uppercase",
                      textAlign: "center",
                    }}
                  >
                    authentication check
                  </LatoBold>
                  <LatoRegular
                    style={{
                      fontSize: isTablet ? 14 : 20,
                      lineHeight: isTablet ? 20 : 28,
                      color: colors.whiteFF,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    {"We are open for third-party authentication checks! "}
                    <LatoBold
                      style={{
                        fontSize: isTablet ? 14 : 20,
                        lineHeight: isTablet ? 20 : 28,
                        color: colors.whiteFF,
                      }}
                    >
                      BABABEBI
                    </LatoBold>
                    {" and "}
                    <LatoBold
                      style={{
                        fontSize: isTablet ? 16 : 20,
                        lineHeight: isTablet ? 24 : 28,
                        color: colors.whiteFF,
                      }}
                    >
                      ENTRUPY
                    </LatoBold>
                    {
                      " are able to check the authenticity of your luxury goods. We are here to help you utilize their service."
                    }
                  </LatoRegular>
                </View>
              )}
              <View
                style={{
                  width: isPhone ? containerWidth : containerWidth / 2,
                  paddingHorizontal: isPhone ? 0 : isTablet ? 24 : 60,
                  marginLeft: isPhone || (i + 1) % 2 === 0 ? 0 : 24,
                  marginRight: isPhone || !((i + 1) % 2 === 0) ? 0 : 24,
                }}
              >
                {Boolean(title) && (
                  <LatoRegular
                    style={{
                      fontSize: isTablet ? 24 : 32,
                      lineHeight: isTablet ? 32 : 40,
                      marginTop: isPhone ? 24 : 0,
                      textTransform: "uppercase",
                    }}
                  >
                    {title}
                  </LatoRegular>
                )}
                {content.map((itm, idx) =>
                  typeof itm === "string" ? (
                    <LatoRegular
                      key={idx}
                      style={{
                        fontSize: isTablet ? 16 : 24,
                        lineHeight: isTablet ? 24 : 32,
                        color: colors.grey33,
                        marginTop: 20,
                      }}
                    >
                      {itm.includes("<b>") ? itm.split("<b>")[0] : itm}
                      {Boolean(itm.includes("<b>")) && (
                        <LatoBold
                          style={{
                            fontSize: isTablet ? 16 : 24,
                            lineHeight: isTablet ? 24 : 32,
                            color: colors.grey33,
                          }}
                        >
                          {itm.split("<b>")[1].split("</b>")[0]}
                        </LatoBold>
                      )}
                      {Boolean(itm.includes("<b>")) &&
                        itm.split("<b>")[1].split("</b>")[1]}
                    </LatoRegular>
                  ) : (
                    <View
                      key={idx}
                      style={{
                        padding: isPhone ? 16 : 32,
                        borderWidth: 1,
                        borderColor: colors.greyE6,
                        borderRadius: 12,
                        marginBottom: 16,
                        marginTop: isPhone && idx === 0 ? 24 : 0,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: isPhone ? "column-reverse" : "row",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <Image
                          source={itm.logo}
                          alt={"A Trusted Room"}
                          style={{
                            width: isPhone
                              ? ((idx === 0 ? 125 : 111) * width) / 390
                              : ((idx === 0 ? 288 : 148) * width) / 1920,
                            height: isPhone
                              ? (30 * width) / 390
                              : ((idx === 0 ? 69 : 40) * width) / 1920,
                          }}
                          defaultSource={require("../assets/images/spinning-loading.gif")}
                          resizeMode={"contain"}
                        />
                        <View
                          style={{
                            paddingHorizontal: 16,
                            paddingVertical: 6,
                            borderRadius: 1000,
                            backgroundColor: colors.lightGreen5B,
                            marginBottom: isPhone ? 8 : 0,
                          }}
                        >
                          <LatoBold
                            style={{
                              fontSize: 14,
                              lineHeight: 20,
                              color: colors.green5B,
                            }}
                          >
                            {itm.tag}
                          </LatoBold>
                        </View>
                      </View>
                      {itm.services.map((item, index) => (
                        <LatoRegular
                          key={index}
                          style={{
                            fontSize: isTablet ? 16 : 20,
                            lineHeight: isTablet ? 24 : 28,
                            color: colors.grey33,
                            marginTop: 20,
                          }}
                        >
                          {item.includes("<b>") ? item.split("<b>")[0] : item}
                          {Boolean(item.includes("<b>")) && (
                            <LatoBold
                              style={{
                                fontSize: isTablet ? 16 : 20,
                                lineHeight: isTablet ? 24 : 28,
                                color: colors.grey33,
                              }}
                            >
                              {item.split("<b>")[1].split("</b>")[0]}
                            </LatoBold>
                          )}
                          {Boolean(item.includes("<b>")) &&
                            item.split("<b>")[1].split("</b>")[1]}
                        </LatoRegular>
                      ))}
                      <Buttons
                        label="CONTACT US"
                        icon={
                          <BsWhatsapp
                            color={colors.whiteFF}
                            size={20}
                            style={{ marginRight: 8 }}
                          />
                        }
                        onPress={() => {
                          openWhatsapp(
                            "",
                            contactDecider(idx === 0 ? "bababebi" : "entrupy")
                          );
                        }}
                        isBlack
                        backgroundColor={colors.black1A}
                        width={isPhone ? "100%" : 180}
                        iconStyle="center"
                        containerStyle={{
                          marginTop: 32,
                          alignSelf: isPhone ? "center" : "flex-start",
                        }}
                      />
                    </View>
                  )
                )}
              </View>
            </View>
          ))}
        </View>
        <Footer />
      </ScrollView>
      {Boolean(openVideo) && (
        <Modal
          noBackground
          width={isPhone ? width - 40 : (isTablet ? 0.75 : 0.5) * width}
        >
          <VideoModal
            setOpenVideo={setOpenVideo}
            videoAttr={{ src: "https://www.youtube.com/watch?v=Oq30x5QpPgg" }}
          />
        </Modal>
      )}
    </View>
  );
}

export default AuthenticationScreen;
