import { getRootUrl } from "../utils/api";
import { eventsTracker } from "../utils/eventsFunctions";
// import * as Sentry from '@sentry/react-native';

function log(msg, color) {
  // if (__DEV__) {
  //   color = color || "black";
  //   let bgc = "White";
  //   switch (color) {
  //     case "success":
  //       color = "Green";
  //       bgc = "LimeGreen";
  //       break;
  //     case "info":
  //       color = "DodgerBlue";
  //       bgc = "Turquoise";
  //       break;
  //     case "error":
  //       color = "Red";
  //       bgc = "Black";
  //       break;
  //     case "start":
  //       color = "OliveDrab";
  //       bgc = "PaleGreen";
  //       break;
  //     case "warning":
  //       color = "Tomato";
  //       bgc = "Black";
  //       break;
  //     case "end":
  //       color = "Orchid";
  //       bgc = "MediumVioletRed";
  //       break;
  //     default:
  //       color = color;
  //   }
  //   if (typeof msg == "object") {
  //     console.log(msg);
  //   } else if (typeof color == "object") {
  //     console.log(
  //       "%c" + msg,
  //       "color: PowderBlue;font-weight:bold; background-color: RoyalBlue;"
  //     );
  //     console.log(color);
  //   } else {
  //     console.log(
  //       "%c" + msg,
  //       "color:" + color + ";font-weight:bold; background-color: " + bgc + ";"
  //     );
  //   }
  // }
}

export default log;

export async function logApi(response, color = "lime") {
  try {
    const method = response?.config?.method?.toUpperCase();
    const endpoint = await removeBaseUrl(response?.config?.url);
    const url = method + " " + endpoint;

    //add sentry to log full url and response in case there is network error
    // Sentry.addBreadcrumb({
    //   category: 'Log API Url',
    //   message: `url: ${url}`,
    //   level: Sentry.Severity.Info,
    // });

    // console.log('Response logged to sentry:', response);

    // if (__DEV__) {
    //   console.groupCollapsed(
    //     "%c" + url,
    //     "color:" + color + ";font-weight:bold;" + "transparent" + ";"
    //   );
    //   console.log("Response:", response);
    //   console.groupEnd();
    // }
  } catch (error) {
    console.log(error, "logApi error");
  }
}

const removeBaseUrl = async (url) => {
  const rootUrl = await getRootUrl();
  return String(url).replace(rootUrl, "");
};
