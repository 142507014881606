import React, { useEffect, useState } from "react";
import { View, useWindowDimensions, TouchableOpacity } from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { GrAdd } from "react-icons/gr";
import { IoIosAdd } from "react-icons/io";
import ConsignmentWallet from "./ConsignmentWallet";
import ConsignmentItemCard from "./ConsignmentItemCard";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { sortingDecider } from "../../tools/decider";
import { upperCase } from "lodash";
import TopMenuSelection from "../TopMenuSelection";

function ConsignmentList({
  navigate,
  datas = [],
  setOpenModal = () => {},
  setSelected = () => {},
  setOpenCashout = () => {},
}) {
  const [newest, setNewest] = useState(true);
  const [data, setData] = useState(datas);
  const [openFilter, setOpenFilter] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("active");
  const [filterState, setFilterState] = useState({
    title: "All Status",
    value: [
      "waiting_for_appraisal",
      "appraisal_negotiation",
      "waiting_for_live",
      "on_sale",
      "waiting_for_disbursement",
      "completed",
    ],
    disbursementState: ["created", "disburse_waiting_for_verification"],
  });

  const menuArr = [
    {
      title: "Active",
      value: "active",
    },
    {
      title: "History",
      value: "history",
    },
  ];

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const filterArr =
    selectedMenu === "active"
      ? [
          {
            title: "All Status",
            value: [
              "waiting_for_appraisal",
              "appraisal_negotiation",
              "waiting_for_live",
              "on_sale",
              "waiting_for_disbursement",
              "completed",
            ],
            disbursementState: ["created", "disburse_waiting_for_verification"],
          },
          {
            title: "Under appraisal process",
            value: ["appraisal_negotiation", "waiting_for_appraisal"],
          },
          {
            title: "Price confirmed",
            value: ["waiting_for_live"],
          },
          {
            title: "Active",
            value: ["on_sale"],
          },
          {
            title: "Sold",
            value: ["waiting_for_disbursement", "completed"],
            disbursementState: ["created", "disburse_waiting_for_verification"],
          },
        ]
      : [
          {
            title: "All Status",
            value: [
              "failed",
              "disburse_completed",
              "cancelled",
              "completed_without_deal",
              "completed",
            ],
            disbursementState: ["disburse_completed"],
          },
          { title: "Cancelled", value: ["cancelled"] },
          {
            title: "Rejected",
            value: ["failed"],
          },
          {
            title: "Sold",
            value: ["completed", "completed_without_deal"],
            disbursementState: ["disburse_completed"],
          },
        ];

  useEffect(() => {
    setData(
      datas.filter(({ state, disbursement_state }) =>
        state === "completed"
          ? filterArr[0]?.value?.includes(state) &&
            filterArr[0]?.disbursementState?.includes(disbursement_state)
          : filterArr[0]?.value?.includes(state)
      )
    );
    setFilterState(filterArr[0]);
  }, [datas, selectedMenu]);

  const handlePressMenu = (val) => {
    setSelectedMenu(val);
  };

  return datas?.length ? (
    <View
      style={{
        width: isPhone ? width - 32 : isTablet ? width - 300 : width - 450,
        // marginBottom: 0.6 * height,
        marginHorizontal: isPhone ? 0 : isTablet ? 50 : 125,
      }}
    >
      {Boolean(!isPhone) && (
        <LatoBold
          style={{
            fontSize: isPhone ? 20 : 24,
            lineHeight: isPhone ? 26 : 32,
            color: colors.grey33,
            marginBottom: 42,
            textAlign: "left",
          }}
        >
          Seller Dashboard
        </LatoBold>
      )}
      {/* <ConsignmentWallet isPhone={isPhone} /> */}
      <TopMenuSelection
        menu={menuArr}
        selected={selectedMenu}
        setSelected={handlePressMenu}
      />
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-end",
          marginTop: 32,
          marginBottom: 24,
          zIndex: 100,
        }}
      >
        <View>
          <Buttons
            label={upperCase(filterState?.title)}
            // icon={<IoIosAdd size={20} color={colors.whiteFF} />}
            // iconStyle="center"
            // isBlack
            width={"auto"}
            onPress={() => {
              setOpenFilter(!openFilter);
            }}
            containerStyle={{ marginHorizontal: 12, paddingHorizontal: 12 }}
          />
          {Boolean(openFilter) && (
            <View
              style={{
                position: "absolute",
                right: 12,
                top: 48,
                // height: 100,
                width: 200,
                borderWidth: 1,
                backgroundColor: colors.whiteFF,

                paddingHorizontal: 8,
                paddingVertical: 6,
                borderRadius: 8,
                borderColor: colors.grey90,
              }}
            >
              {filterArr.map((item, idx) => (
                <TouchableOpacity
                  key={idx}
                  onPress={() => {
                    setFilterState(item);
                    setData(
                      datas.filter(({ state, disbursement_state }) =>
                        state === "completed"
                          ? item?.value?.includes(state) &&
                            item?.disbursementState?.includes(
                              disbursement_state
                            )
                          : item?.value?.includes(state)
                      )
                    );
                    setOpenFilter(false);
                  }}
                  style={{
                    paddingVertical: 12,
                    backgroundColor:
                      filterState?.title === item?.title
                        ? colors.transparentGrey6C
                        : colors.whiteFF,
                    marginHorizontal: -8,
                    paddingHorizontal: 8,
                  }}
                >
                  <LatoRegular
                    style={{
                      fontSize: 12,
                      lineHeight: 16,
                      textTransform: "uppercase",
                    }}
                  >
                    {item?.title}
                  </LatoRegular>
                </TouchableOpacity>
              ))}
            </View>
          )}
        </View>
        <Buttons
          label={newest ? "NEWEST" : "OLDEST"}
          rightIcon={
            newest ? (
              <AiOutlineArrowDown size={14} color={colors.grey33} />
            ) : (
              <AiOutlineArrowUp size={14} color={colors.grey33} />
            )
          }
          iconStyle="center"
          width={"auto"}
          onPress={() => {
            setNewest(!newest);
            setData(sortingDecider(data, !newest ? "newest" : "oldest"));
          }}
          containerStyle={{ marginHorizontal: 12, paddingHorizontal: 12 }}
          textStyle={{ marginRight: 8 }}
        />
        <Buttons
          label={"Create a new listing"}
          icon={<IoIosAdd size={20} color={colors.whiteFF} />}
          iconStyle="center"
          isBlack
          width={250}
          onPress={() => {
            navigate("/sell-with-us/consignment");
          }}
        />
      </View>
      {data?.map((item, index) => (
        <ConsignmentItemCard
          item={item}
          key={index}
          setOpenModal={setOpenModal}
          setOpenCashout={setOpenCashout}
          setSelected={setSelected}
          index={index}
        />
      ))}
    </View>
  ) : (
    <View
      style={{
        justifyContent: "center",
        // marginTop: 100,
        alignItems: "center",
        // marginHorizontal: isPhone ? 16 : isTablet ? 48 : 100,
        width: isPhone ? width - 32 : isTablet ? width - 300 : width - 450,
        marginBottom: 0.6 * height,
      }}
    >
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 24,
          color: colors.grey6C,
          marginBottom: 24,
          textAlign: "center",
        }}
      >
        You currently have no active items at Belle&Kate Start Selling With Us
      </LatoRegular>
      <Buttons
        label={"Create a new listing"}
        icon={<IoIosAdd size={20} color={colors.whiteFF} />}
        iconStyle="center"
        isBlack
        width={250}
        onPress={() => {
          navigate("/account/consignment/method-selection");
        }}
      />
    </View>
  );
}

export default ConsignmentList;
