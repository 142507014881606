import React, { useState } from "react";
import {
  Animated,
  TouchableOpacity,
  View,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { FaChevronDown, FaChevronLeft, FaChevronUp } from "react-icons/fa";
import Hermes from "../../assets/images/hermes-cover.png";
import Chanel from "../../assets/images/chanel-cover.png";
import LV from "../../assets/images/lv-cover.png";
import Dior from "../../assets/images/dior-cover.png";
import { LatoBold, LatoRegular } from "../Text";
import { lowerCase } from "lodash";

function AdditionalBurgerMenu({
  opacity,
  openBurger = false,
  setOpenBurger = () => {},
  setOpenSubBurger = () => {},
  burgerArr = [],
  subBurgerIndex = 0,
  user = {},
  hover = false,
  setHover = () => {},
  promotion,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  const [showOption, setShowOption] = useState(true);
  const [showOptionIndex, setShowOptionIndex] = useState(0);

  const animatedHeight = opacity.interpolate({
    inputRange: [0, 1],
    outputRange: [0, openBurger ? height : 300],
  });

  const isNewest = burgerArr[subBurgerIndex].title === "New Arrivals";

  return (
    <Animated.View
      style={{
        borderTopColor: colors.greyE6,
        borderTopWidth: 1,
        position: "absolute",
        zIndex: 1,
        top: isTablet ? 0 : promotion?.promotional_text ? 176 : 152,
        width,
        height: isPhone ? height : isTablet ? "auto" : animatedHeight,
        backgroundColor: colors.whiteFF,
        shadowColor: colors.greyBB,
        shadowOffset: { height: 1, width: 0 },
        shadowOpacity: 0.15,
        shadowRadius: 2,
        opacity,
      }}
    >
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{
          height: isPhone ? height : isTablet ? "auto" : animatedHeight,
        }}
        scrollEventThrottle={16}
      >
        {Boolean(isTablet) && (
          <View>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginHorizontal: isPhone
                  ? 16
                  : width < 1350
                  ? 50
                  : width < 1500
                  ? 100
                  : 230,
                paddingBottom: 16,
                paddingTop: isPhone ? 24 : 32,
                borderBottomWidth: isTablet ? 1 : 0,
                borderBottomColor: colors.greyE6,
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  setOpenSubBurger(false);
                  setOpenBurger(true);
                  setShowOptionIndex(0);
                }}
              >
                <FaChevronLeft size={16} color={colors.grey90} />
              </TouchableOpacity>
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  textTransform: "uppercase",
                  marginLeft: 10,
                }}
              >
                {burgerArr[subBurgerIndex]?.title || ""}
              </LatoRegular>
            </View>
          </View>
        )}
        <View
          style={{
            flexDirection: isTablet ? "column" : "row",
            marginHorizontal: isPhone
              ? 16
              : width < 1350
              ? 50
              : width < 1500
              ? 100
              : 230,
            paddingBottom: 16,
            paddingTop: isPhone ? 24 : 32,
            borderBottomWidth: isTablet ? 1 : 0,
            borderBottomColor: colors.greyE6,
          }}
        >
          {burgerArr[subBurgerIndex]?.menu.map(({ title, option }, i) => (
            <View key={i} style={{ marginRight: isTablet ? 0 : 100 }}>
              {isTablet ? (
                burgerArr[subBurgerIndex]?.menu?.length > 1 ? (
                  <TouchableOpacity
                    onMouseEnter={() => {
                      if (hover !== title) {
                        setHover(title);
                      }
                    }}
                    onMouseLeave={() => {
                      if (hover) {
                        setHover("");
                      }
                    }}
                    onPress={() => {
                      setShowOption(!showOption);
                      if (showOptionIndex === i) {
                        setShowOptionIndex(null);
                      } else {
                        setShowOptionIndex(i);
                      }
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      flex: 1,
                      marginBottom: showOptionIndex === i ? 16 : 32,
                      marginTop: showOptionIndex === i || !showOption ? 16 : 0,
                      borderBottomColor: colors.grey33,
                      borderBottomWidth: 1,
                      paddingVertical: 8,
                    }}
                  >
                    <LatoBold
                      style={{
                        fontSize: 16,
                        lineHeight: 24,
                        textTransform: "uppercase",
                        color: hover === title ? colors.green5B : colors.grey33,
                      }}
                    >
                      {title}
                    </LatoBold>
                    {showOptionIndex === i ? (
                      <FaChevronUp
                        size={16}
                        color={hover === title ? colors.green5B : colors.grey90}
                      />
                    ) : (
                      <FaChevronDown
                        size={16}
                        color={hover === title ? colors.green5B : colors.grey90}
                      />
                    )}
                  </TouchableOpacity>
                ) : null
              ) : (
                <LatoBold
                  style={{
                    fontSize: 16,
                    lineHeight: 24,
                    textTransform: "uppercase",
                    color: colors.grey90,
                    paddingBottom: 16,
                  }}
                >
                  {title}
                </LatoBold>
              )}
              {Boolean(
                !isTablet ||
                  (isTablet &&
                    (showOptionIndex === i ||
                      burgerArr[subBurgerIndex]?.menu?.length === 1))
              ) &&
                option.map((item, j) => (
                  <TouchableOpacity
                    onMouseEnter={() => {
                      if (hover !== item) {
                        setHover(item);
                      }
                    }}
                    onMouseLeave={() => {
                      if (hover) {
                        setHover("");
                      }
                    }}
                    onPress={() => {
                      setOpenSubBurger(false);
                      if (title === "Services") {
                        navigate("/authentication");
                      }
                      if (title === "Work With Us") {
                        navigate("/work-with-us", {
                          state: {
                            section:
                              item === "Agent Program" ? "agents" : "clique",
                          },
                        });
                      }
                      if (title === "Belle&Kate") {
                        navigate(
                          item === "About Us"
                            ? "/about"
                            : item === "Customer Care"
                            ? "/customer-care"
                            : item === "VIP Room"
                            ? "/vip"
                            : "/"
                        );
                      }
                      if (title === "Sell With Us") {
                        navigate(
                          `/sell-with-us/${item
                            .replace(" ", "-")
                            .toLowerCase()}`
                        );
                      }
                      if (
                        title === "Designers" ||
                        title === "Brands" ||
                        title === "New Arrivals" ||
                        title === "Category"
                      ) {
                        navigate(
                          `/products/${
                            title === "New Arrivals" || title === "Category"
                              ? "category"
                              : "designer"
                          }/${item}`,
                          {
                            state: {
                              isNewest,
                              menu:
                                title === "New Arrivals" || title === "Category"
                                  ? [
                                      {
                                        name: item,
                                        section: "Category",
                                        id:
                                          title === "New Arrivals" ? j : j + 1,
                                      },
                                    ]
                                  : [
                                      {
                                        name: item,
                                        section: "Designer",
                                        id:
                                          item === "Chanel"
                                            ? 2
                                            : item === "Louis Vuitton"
                                            ? 4
                                            : item === "Dior"
                                            ? 3
                                            : 1,
                                      },
                                    ],
                              designer_page: {
                                title: item,
                                image:
                                  item === "Chanel"
                                    ? Chanel
                                    : item === "Louis Vuitton"
                                    ? LV
                                    : item === "Dior"
                                    ? Dior
                                    : Hermes,
                              },
                            },
                          }
                        );
                      }
                    }}
                    key={j}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      paddingVertical: isPhone ? 12.5 : 16,
                      paddingHorizontal:
                        burgerArr[subBurgerIndex]?.menu?.length > 1 && isTablet
                          ? 24
                          : 0,
                    }}
                  >
                    <LatoBold
                      style={{
                        color: hover === item ? colors.green5B : colors.black00,
                        fontSize: 16,
                        lineHeight: 24,
                        textTransform: "uppercase",
                        fontFamily:
                          burgerArr[subBurgerIndex]?.menu?.length === 1 &&
                          isTablet
                            ? "lato-bold"
                            : "lato-regular",
                      }}
                    >
                      {item}
                    </LatoBold>
                  </TouchableOpacity>
                ))}
            </View>
          ))}
        </View>
      </ScrollView>
    </Animated.View>
  );
}

export default AdditionalBurgerMenu;
