import React from "react";
import { TouchableOpacity, View, useWindowDimensions } from "react-native";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";

function PrivacyScreen({ email, setOpen = () => {} }) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();

  return (
    <View
      style={{
        width: isPhone ? width - 32 : isTablet ? width - 300 : width - 600,
      }}
    >
      <LatoBold style={{ fontSize: 24, lineHeight: 32 }}>
        Account & Privacy
      </LatoBold>
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.greyE6,
          paddingVertical: 16,
          marginBottom: 16,
        }}
      >
        <View style={{ marginVertical: 16 }}>
          <LatoBold style={{ fontSize: 16, lineHeight: 24 }}>
            Account Terms and Conditions
          </LatoBold>
          <LatoRegular
            style={{ fontSize: 14, lineHeight: 20, color: colors.grey6C }}
          >
            Last updated on Nov 20, 2022
          </LatoRegular>
          <Buttons
            onPress={() => navigate("/terms-conditions")}
            label="Read the Terms and Conditions"
            containerStyle={{ alignItems: "flex-start" }}
            textStyle={{ textAlign: "justify" }}
            noBorder
          />
        </View>
        <View style={{ marginVertical: 16 }}>
          <LatoBold style={{ fontSize: 16, lineHeight: 24 }}>
            Privacy Policy
          </LatoBold>
          <LatoRegular
            style={{ fontSize: 14, lineHeight: 20, color: colors.grey6C }}
          >
            Last updated on Nov 20, 2022
          </LatoRegular>
          <Buttons
            onPress={() => navigate("/privacy-policy")}
            label="Read Our Privacy Policy"
            containerStyle={{ alignItems: "flex-start" }}
            textStyle={{ textAlign: "justify" }}
            noBorder
          />
        </View>
      </View>
      <View
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.greyE6,
          paddingVertical: 16,
          marginBottom: 16,
        }}
      >
        <LatoBold style={{ fontSize: 16, lineHeight: 24, marginBottom: 16 }}>
          Close Account
        </LatoBold>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            marginBottom: 16,
          }}
        >
          Once your account closure request is submitted, it will be processed,
          and your account will be permanently deleted.
        </LatoBold>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            marginBottom: 16,
          }}
        >
          Please note that closing your account will remove all your data from
          our platform, and this action cannot be undone. If you have any
          concerns or need further assistance, please contact our support team.
        </LatoBold>
      </View>
      <TouchableOpacity
        onPress={() => setOpen(true)}
        style={{
          borderBottomWidth: 1,
          borderBottomColor: colors.greyE6,
          paddingVertical: 16,
          marginBottom: 16,
        }}
      >
        <LatoBold style={{ fontSize: 14, lineHeight: 20, color: colors.redAD }}>
          Close Account
        </LatoBold>
        <LatoBold
          style={{
            fontSize: 14,
            lineHeight: 20,
            marginBottom: 16,
            color: colors.grey6C,
          }}
        >
          {email || ""}
        </LatoBold>
      </TouchableOpacity>
    </View>
  );
}

export default PrivacyScreen;
