import React, { useContext, useEffect, useState } from "react";
import {
  Image,
  ScrollView,
  TouchableOpacity,
  View,
  useWindowDimensions,
} from "react-native-web";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { Formik } from "formik";
import { sentenceCase } from "change-case";
import { MyInput, SelectionDropDown } from "../components/Inputs";
import Buttons from "../components/Buttons";
import ToasterContext from "../contexts/ToastContext";
import { useNavigate } from "react-router";
import { useFilePicker } from "use-file-picker";
import { useDropzone } from "react-dropzone";
import DragDropFile from "../components/DragDropFile";
import { api } from "../utils/api";
import { requestProductFormatter } from "../tools/formatter";
import {
  asyncRemoveIsMultipart,
  asyncSetIsMultipart,
} from "../tools/asyncStorages";

function RequestFormScreen() {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const { showToast, showSuccessToast } = useContext(ToasterContext);
  const navigate = useNavigate();

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receipt, setReceipt] = useState({});
  const [imageURI, setImageURI] = useState("");

  const initialValues = {};

  const formArr = [
    {
      name: "designer",
      placeholder: "Choose designer",
      label: "Designer",
      required: true,
      initialID: initialValues?.designer,
      type: "selection",
      half: true,
    },
    {
      name: "condition",
      placeholder: "Choose item condition",
      label: "Condition",
      required: true,
      initialID: initialValues?.condition,
      type: "selection",
      half: true,
    },
    {
      name: "name",
      placeholder: "Name your item",
      label: "Item Name",
      required: true,
      initialValue: initialValues?.name,
      type: "text",
      half: true,
    },
    {
      name: "phone",
      placeholder: "Your Phone Number",
      label: "Phone",
      required: true,
      initialValue: initialValues?.phone,
      prefix: "+62",
      half: true,
    },
    {
      name: "stamp",
      placeholder: "i.e 2010",
      label: "Year (Optional)",
      initialValue: initialValues?.stamp,
      type: "text",
      half: true,
    },
    {
      name: "color",
      placeholder: "Item color",
      label: "Colour",
      required: true,
      initialID: initialValues?.color,
      type: "text",
      half: true,
    },
    {
      name: "notes",
      placeholder: "Add more information or your personal notes",
      label: "Notes",
      required: true,
      initialValue: initialValues?.additional,
      type: "long-text",
    },
  ];

  const { getRootProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  const [
    openFileSelector,
    { filesContent, loading: pickerLoading, plainFiles },
  ] = useFilePicker({
    accept: [".png", ".jpg", ".jpeg"],
    readAs: "DataURL",
    multiple: false,
  });

  const handleSelectedImage = () => {
    plainFiles.map((file) => {
      setReceipt({ content: URL.createObjectURL(file), ...file });

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        setImageURI(file);
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    handleSelectedImage();
  }, [filesContent]);

  const handleRequest = async (val) => {
    try {
      setLoading(true);
      await asyncSetIsMultipart(true);
      const formattedPayload = await requestProductFormatter(val, imageURI);

      await api.post("api/request_items", formattedPayload);

      showSuccessToast("We have received your request");

      navigate("/account/request", {
        state: { idx: 3, index: 0 },
      });
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      await asyncRemoveIsMultipart();
      setLoading(false);
    }
  };

  return (
    <View>
      <ScrollView
        showVerticalScrollIndicator={false}
        style={{ height }}
        scrollEventThrottle={16}
      >
        <Navbar />
        <View style={{ paddingHorizontal: gap, marginVertical: 100 }}>
          <LatoBold style={{ fontSize: 24, lineHeight: 32 }}>
            Request item
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: 18,
              lineHeight: 26,
              color: colors.grey6C,
              marginVertical: 24,
            }}
          >
            Fill in the details of the item you want to request.
          </LatoRegular>
          <Formik
            onSubmit={handleRequest}
            validateOnChange={hasSubmitted}
            validateOnBlur={hasSubmitted}
            initialValues={initialValues}
            validate={(values) => {
              !hasSubmitted && setHasSubmitted(true);
              const errors = {};

              for (const a of formArr) {
                const { name, required, label, type } = a;

                if (
                  (required && !values[name] && type !== "selection") ||
                  (required && !values[name]?.name && type === "selection")
                ) {
                  errors[name] = `${sentenceCase(label)} can't be blank`;
                }
              }
              return errors;
            }}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              resetForm,
            }) => (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                {formArr.map(
                  (
                    {
                      name,
                      placeholder,
                      required,
                      hide,
                      initialValue,
                      disabled,
                      type,
                      label,
                      initialID,
                      half,
                      prefix,
                    },
                    index
                  ) => {
                    const startHalfIndex = formArr.findIndex(({ half }) =>
                      Boolean(half)
                    );
                    return (
                      <View
                        style={{
                          width: half ? containerWidth / 2 - 32 : "100%",
                          marginRight:
                            half && (index + 1 - startHalfIndex) % 2 !== 0
                              ? 6
                              : 0,
                          marginBottom: 32,
                        }}
                        key={index}
                      >
                        {type === "selection" ? (
                          <SelectionDropDown
                            label={label}
                            placeholder={placeholder}
                            required={required}
                            values={values}
                            name={name}
                            setValue={setFieldValue}
                            initialID={initialID}
                            error={errors[name]}
                          />
                        ) : (
                          <MyInput
                            error={errors[name]}
                            handleBlur={handleBlur}
                            handleChange={handleChange}
                            name={name}
                            label={label}
                            placeholder={placeholder}
                            required={required}
                            hide={hide}
                            initialValue={initialValue}
                            customWidth={
                              half ? containerWidth / 2 - 32 : "100%"
                            }
                            disabled={disabled}
                            multiline={type === "long-text"}
                            bordered={type === "long-text"}
                            inline={type === "long-text"}
                            prefix={prefix}
                          />
                        )}
                      </View>
                    );
                  }
                )}
                <View>
                  <LatoRegular
                    style={{
                      fontSize: 16,
                      lineHeight: 24,
                      paddingBottom: 8,
                    }}
                  >
                    Upload Image
                  </LatoRegular>
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey6C,
                      paddingBottom: 16,
                    }}
                  >
                    Upload your desired item image, so we can find it quickly
                    for you.
                  </LatoRegular>
                </View>
                {Boolean(!receipt.content) ? (
                  <DragDropFile
                    setImage={setReceipt}
                    setImageURI={setImageURI}
                    width={containerWidth}
                  />
                ) : (
                  <TouchableOpacity
                    onPress={(event) => {
                      openFileSelector();
                    }}
                  >
                    <Image
                      source={receipt.content}
                      style={{
                        width: containerWidth,
                        height: 500,
                        marginBottom: 32,
                      }}
                      resizeMode="contain"
                    />
                  </TouchableOpacity>
                )}
                <LatoRegular
                  style={{
                    fontSize: 12,
                    lineHeight: 16,
                    color: colors.grey6C,
                    marginVertical: 16,
                    paddingBottom: 16,
                  }}
                >
                  Allowed file extensions: .JPG, .JPEG, .PNGMaximum size 10 MB
                  per file.
                </LatoRegular>
                <View
                  style={{
                    flexDirection: isPhone ? "column" : "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: containerWidth,
                  }}
                >
                  <Buttons
                    label={"Cancel"}
                    noBorder
                    onPress={() =>
                      navigate("/account/request", {
                        state: { idx: 3, index: 0 },
                      })
                    }
                    disabled={loading}
                    width={100}
                    containerStyle={{ alignItems: "flex-start" }}
                  />
                  <Buttons
                    label={"Request Item"}
                    loading={loading}
                    isBlack
                    onPress={handleSubmit}
                    disabled={
                      errors.designer ||
                      errors.name ||
                      errors.condition ||
                      errors.notes ||
                      errors.phone ||
                      errors.color ||
                      loading
                    }
                    width={isPhone ? width - 32 : 250}
                  />
                </View>
              </View>
            )}
          </Formik>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default RequestFormScreen;
