import React, { useContext, useState } from "react";
import {
  View,
  Image,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import Navbar from "../components/Navbar";
import LoginAsset from "../assets/images/register.png";
import { LatoBold, LatoRegular } from "../components/Text";
import colors from "../assets/colors/colors";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { api, useMutation } from "../utils/api";
import { getDeviceType } from "../tools/Interface";
import { MyInput } from "../components/Inputs";
import Line from "../components/Line";
import { Wrapper, WrapperInsider } from "../components/Wrapper";
import { sentenceCase } from "change-case";
import { regexChecker } from "../tools/regexChecker";
import { useAuth } from "../contexts/AuthContext";
import _ from "lodash";
import ToasterContext from "../contexts/ToastContext";
import Buttons from "../components/Buttons";
import Footer from "../components/Footer";

export default function ForgotPasswordScreen() {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { updateUser, setUserHook, authorize, user, refetch } = useAuth();
  const navigate = useNavigate();
  const { showSuccessToast, showToast } = useContext(ToasterContext);

  const formFieldArr = [
    {
      name: "email",
      placeholder: "Email Address",
      required: true,
    },
  ];

  const handleSubmit = async (value) => {
    try {
      setLoading(true);
      await api.post("/api/auth/password", { email: value?.email });
      navigate("/login");

      showSuccessToast("Email successfully sent");
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <View>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ height: "100vh" }}
      >
        {Boolean(user?.id) && (
          <Navigate to={"/"} replace={true} relative={"path"} />
        )}
        <Navbar />
        <View style={{ flexDirection: isPhone ? "column" : "row" }}>
          <Image
            source={LoginAsset}
            style={{
              height: isPhone ? "40vh" : "100vh",
              width: isPhone ? "100vw" : "50vw",
              marginTop: -88,
            }}
          />
          <WrapperInsider engineeredHeight={"85vh"} centered>
            <View
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: isPhone ? "100vw" : "50vw",
                paddingHorizontal: isTablet ? 40 : 190,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 24,
                  lineHeight: 32,
                  marginBottom: 88,
                  width: isPhone ? "80vw" : isTablet ? "40vw" : "32.5vw",
                  textAlign: "center",
                }}
              >
                Forgot Password
              </LatoRegular>
              <Formik
                onSubmit={handleSubmit}
                validateOnChange={hasSubmitted}
                validateOnBlur={hasSubmitted}
                initialValues={{
                  email: "",
                }}
                validate={(values) => {
                  !hasSubmitted && setHasSubmitted(true);
                  const errors = {};

                  for (const a of formFieldArr) {
                    const { name, required, placeholder } = a;

                    if (required && !values[name]) {
                      errors[name] = `${sentenceCase(
                        placeholder
                      )} can't be blank`;
                    } else if (!regexChecker(name, values[name])) {
                      errors[name] = `Invalid ${placeholder.toLowerCase()}`;
                    }
                  }
                  return errors;
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <View>
                    {formFieldArr.map(
                      ({ name, placeholder, required, hide }, index) => {
                        return (
                          <View key={index}>
                            <MyInput
                              error={errors[name]}
                              handleBlur={handleBlur}
                              handleChange={handleChange}
                              name={name}
                              placeholder={placeholder}
                              required={required}
                              hide={hide}
                              label={values[name] ? placeholder : ""}
                            />
                          </View>
                        );
                      }
                    )}
                    <TouchableOpacity
                      onPress={() => navigate("/login")}
                      style={{ alignSelf: "flex-end" }}
                    >
                      <LatoRegular
                        style={{
                          fontSize: 16,
                          lineHeight: 24,
                          color: colors.grey6C,
                          textDecorationLine: "underline",
                        }}
                      >
                        I want to login
                      </LatoRegular>
                    </TouchableOpacity>
                    <Buttons
                      label="Recover My Password"
                      width={"100%"}
                      isBlack
                      onPress={handleSubmit}
                      disabled={errors.email}
                      loading={loading}
                      borderRadius={6}
                      noBorder
                      containerStyle={{ marginVertical: 32 }}
                    />
                  </View>
                )}
              </Formik>
            </View>
          </WrapperInsider>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}
