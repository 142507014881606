import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  View,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  Linking,
} from "react-native";
import { getDeviceType } from "../../tools/Interface";
import { useNavigate } from "react-router";
import { api } from "../../utils/api";
import ImageWithLoader from "../ImageWithLoader";
import colors from "../../assets/colors/colors";

export default function BannerCarousel({ loading = false }) {
  const [count, setCount] = useState(0);
  const [images, setImages] = useState([]);

  const autoScrollRef = useRef();
  const navigate = useNavigate();

  const { width, height } = useWindowDimensions();
  const { isPhone } = getDeviceType({ width, height });

  const getBanners = async () => {
    try {
      const res = await api.get("/api/banners");
      const data = res.data.data;

      setImages(data);
    } catch (err) {
      console.log("err:", err.response);
    }
  };

  useEffect(() => {
    getBanners();
  }, [width]);

  const autoSlideCarousel = useCallback(() => {
    setTimeout(() => {
      const multiplier = count + 1;
      const slideX = width;
      const slide = multiplier * slideX;

      autoScrollRef?.current?.scrollTo({
        x: slide,
        y: 0,
        animated: true,
      });
      setCount(count === images.length - 2 ? -1 : count + 1);
    }, 5000);
  }, [count, images, width]);

  useEffect(() => {
    autoSlideCarousel();
  }, [count, autoSlideCarousel]);

  return (
    <View>
      <ScrollView
        ref={autoScrollRef}
        horizontal
        showsHorizontalScrollIndicator={false}
        onContentSizeChange={autoSlideCarousel}
      >
        {loading ? (
          <View
            style={{
              height: isPhone ? (3 / 2) * width : (9 / 16) * width - 120,
              width,
              backgroundColor: colors.superTransparentBlack00,
            }}
          />
        ) : (
          images.map((itm, index) => {
            return (
              <TouchableOpacity
                onPress={() => {
                  if (itm.url.includes("belleandkate.com")) {
                    const direction = itm.url.split("belleandkate.com")[1];

                    navigate(direction ? direction : "/");
                  } else {
                    Linking.openURL(
                      itm.url.startsWith("https") || itm.url.startsWith("http")
                        ? itm.url
                        : `https://${itm.url}`
                    );
                  }
                }}
                activeOpacity={1}
                style={{
                  flexDirection: "row",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  width,
                }}
                key={index}
              >
                <ImageWithLoader
                  source={{
                    uri: isPhone ? itm.vertical_image_url : itm.image_url,
                  }}
                  alt={`Product ${index}`}
                  style={{
                    height: isPhone ? (3 / 2) * width : (9 / 16) * width - 120,
                    width,
                  }}
                  defaultStyle={{
                    width: 120,
                    height: isPhone ? (3 / 2) * width : (9 / 16) * width - 120,
                  }}
                  resizeMode={"cover"}
                />
              </TouchableOpacity>
            );
          })
        )}
      </ScrollView>
    </View>
  );
}
