import React, { useState } from "react";
import {
  View,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
} from "react-native";
import colors from "../assets/colors/colors";
import { LatoBold, LatoRegular } from "./Text";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { getDeviceType } from "../tools/Interface";
import { useNavigate } from "react-router-dom";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import VerticalProgress from "./VerticalProgress";
import { AiOutlineInfoCircle } from "react-icons/ai";

export default function DropDownTab({
  item = {},
  noBorder = false,
  noMargin = false,
  right,
  additional,
  isBold = false,
  isLast = false,
  titleStyle = {},
  handleLogout = () => {},
  inline = false,
  opened = false,
}) {
  const navigate = useNavigate();

  const [shown, setShown] = useState(opened);
  const { title = "judul", datas = [], style = "paragraph", onPress } = item;

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const TitleText = isBold ? LatoBold : LatoRegular;
  const OpenIcon = isBold ? BiChevronDown : BsChevronDown;
  const CloseIcon = isBold ? BiChevronUp : BsChevronUp;

  return (
    <View
      style={{
        paddingVertical: 16,
        paddingHorizontal: noBorder || noMargin ? 0 : 20,
        borderTopWidth: noBorder ? 0 : 1,
        borderBottomWidth:
          (style === "sideMenuAccount" && !shown) || isLast ? 1 : 0,
        borderBottomColor: colors.greyE6,
        borderTopColor: colors.greyE6,
        width: "100%",
      }}
    >
      <View
        style={{
          flexDirection: right ? "row-reverse" : "row",
          alignItems: "center",
          justifyContent: right ? "space-between" : "flex-start",
          borderBottomWidth:
            (noBorder && shown) || item?.style === "history" ? 1 : 0,
          borderBottomColor: colors.greyE6,
          paddingBottom:
            (noBorder && shown) || item?.style === "history" ? 12 : 0,
          marginHorizontal:
            (isPhone && !noMargin && style !== "sideMenuAccount") || inline
              ? 16
              : 0,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          {additional && additional}
          <TouchableOpacity onPress={() => setShown(!shown)}>
            {shown ? (
              <CloseIcon
                size={18}
                color={style === "menu" ? colors.whiteFF : colors.black00}
              />
            ) : (
              <OpenIcon
                size={18}
                color={style === "menu" ? colors.whiteFF : colors.black00}
              />
            )}
          </TouchableOpacity>
        </View>
        {style === "sideMenuAccount" ? (
          <TitleText
            style={{
              fontSize: 20,
              lineHeight: 28,
              // textTransform: "uppercase",
              marginLeft: 10,
              color: style === "menu" ? colors.whiteFF : colors.black00,
              ...titleStyle,
            }}
          >
            {title}
          </TitleText>
        ) : (
          <TitleText
            style={{
              fontSize: 14,
              lineHeight: 20,
              // textTransform: "uppercase",
              marginLeft: 10,
              color: style === "menu" ? colors.whiteFF : colors.black00,
              ...titleStyle,
            }}
          >
            {title}
          </TitleText>
        )}
      </View>
      {Boolean(shown) && style === "history" ? (
        <View
          style={{
            // marginVertical: 24,
            borderBottomColor: colors.greyE6,
            borderBottomWidth: 1,
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{ height: datas?.length > 3 ? 200 : "auto" }}
          >
            <VerticalProgress datas={datas} />
          </ScrollView>
        </View>
      ) : (
        Boolean(shown) &&
        datas.map((item, index) => {
          return style === "menu" ? (
            <TouchableOpacity
              onPress={() => navigate(item?.route)}
              style={{ marginLeft: inline ? 45 : 28 }}
              key={index}
            >
              <LatoRegular
                style={{
                  color: colors.whiteFF,
                  fontSize: 14,
                  lineHeight: 20,
                  textDecorationLine: "none",
                  marginTop: 20,
                }}
              >
                {item?.title}
              </LatoRegular>
            </TouchableOpacity>
          ) : style === "sideMenuAccount" ? (
            <View
              key={index}
              style={{ borderBottomColor: colors.greyE6, borderBottomWidth: 1 }}
            >
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginBottom: 12,
                  paddingTop: 24,
                }}
              >
                {item?.title}
              </LatoBold>
              {item?.subtitle?.map(({ title, navigation }, idx) => (
                <TouchableOpacity
                  onPress={() => {
                    if (
                      index === datas.length - 1 &&
                      idx === item.subtitle.length - 1
                    ) {
                      handleLogout();
                    } else {
                      navigate(`/account/${navigation}`, {
                        state: { index: index, idx: idx },
                      });
                    }

                    setShown(false);
                  }}
                  style={{
                    // paddingTop: 24,
                    paddingBottom: 12,
                    marginHorizontal: -16,
                    paddingHorizontal: 16,
                  }}
                  key={idx}
                >
                  <LatoRegular
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color:
                        index === datas?.length - 1 &&
                        idx === item?.subtitle?.length - 1
                          ? colors.redB6
                          : colors.grey33,
                    }}
                  >
                    {title}
                  </LatoRegular>
                </TouchableOpacity>
              ))}
            </View>
          ) : style === "paragraph" ? (
            <View
              key={index}
              style={{
                marginTop: typeof item === "string" ? 20 : 8,
                paddingHorizontal: isPhone && !noMargin ? 16 : 0,
              }}
            >
              {typeof item === "string" ? (
                <LatoRegular
                  style={{
                    fontSize: 14,
                    lineHeight: 20,
                    color: colors.grey33,
                  }}
                >
                  {item.includes("<b>") ? item.split("<b>")[0] : item}
                  {Boolean(item.includes("<b>")) && (
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                      }}
                    >
                      {item.split("<b>")[1].split("</b>")[0]}
                    </LatoBold>
                  )}
                  {Boolean(item.includes("<b>")) &&
                    item.split("<b>")[1].split("</b>")[1]}
                </LatoRegular>
              ) : (
                item.map((itm, i) => (
                  <View
                    key={i}
                    style={{
                      marginTop: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      paddingHorizontal: isPhone && !noMargin ? 16 : 0,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: colors.grey6C,
                        borderRadius: "100%",
                        width: 5,
                        height: 5,
                      }}
                    />
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        marginLeft: 10,
                      }}
                    >
                      {itm}
                    </LatoRegular>
                  </View>
                ))
              )}
            </View>
          ) : style === "button" ? (
            <View
              key={index}
              style={{
                marginTop: index === 0 ? 20 : 8,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                paddingHorizontal: isPhone && !noMargin ? 16 : 0,
              }}
            >
              <View
                style={{
                  backgroundColor: colors.grey33,
                  borderRadius: "100%",
                  width: 5,
                  height: 5,
                }}
              />
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginLeft: 10,
                }}
              >
                {item}
              </LatoRegular>
            </View>
          ) : style === "condition" ? (
            <View
              key={index}
              style={{
                marginTop: 20,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: index === 0 ? colors.green5B : colors.grey33,
                  fontFamily: index === 0 ? "Lato-Bold" : "Lato-Regular",
                  textTransform: index === 0 ? "uppercase" : "none",
                  paddingHorizontal: isPhone && !noMargin ? 16 : 0,
                  marginRight: onPress && index === 0 ? 6 : 0,
                }}
              >
                {item}
              </LatoRegular>
              {Boolean(onPress && index === 0) && (
                <TouchableOpacity onPress={onPress}>
                  <AiOutlineInfoCircle size={16} />
                </TouchableOpacity>
              )}
            </View>
          ) : null;
        })
      )}
    </View>
  );
}
