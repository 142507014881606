import React, { useContext, useEffect, useRef, useState } from "react";
import {
  ScrollView,
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import Navbar from "../components/Navbar";
import colors from "../assets/colors/colors";
import { getDeviceType } from "../tools/Interface";
import { LatoBold, LatoRegular } from "../components/Text";
import Buttons from "../components/Buttons";
import { useNavigate } from "react-router";
import { IoAlertCircleSharp } from "react-icons/io5";
import IDCard from "../assets/images/id-card.png";
import DragDropFile from "../components/DragDropFile";
import Footer from "../components/Footer";
import { useDropzone } from "react-dropzone";
import {
  asyncRemoveIsMultipart,
  asyncSetIsMultipart,
} from "../tools/asyncStorages";
import { api } from "../utils/api";
import { RxCheckCircled } from "react-icons/rx";
import { useAuth } from "../contexts/AuthContext";
import { BsClockHistory } from "react-icons/bs";
import { useFilePicker } from "use-file-picker";
import ToasterContext from "../contexts/ToastContext";

function KYCScreen() {
  const navigate = useNavigate();
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const { user, updateUser, refetch } = useAuth();
  const { showToast } = useContext(ToasterContext);

  const [loading, setLoading] = useState(false);
  const [idCard, setIdCard] = useState({});
  const [imageURI, setImageURI] = useState("");

  const scrollRef = useRef();

  const { getRootProps, open } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
  });

  const [
    openFileSelector,
    { filesContent, loading: pickerLoading, plainFiles },
  ] = useFilePicker({
    accept: [".png", ".jpg", ".jpeg"],
    readAs: "DataURL",
    multiple: false,
  });

  const instructionsArr = [
    "Document must be in  PNG or JPEG format ",
    "Document is in good condition",
    "Must be the original document and not a copy",
    "All information and image must be visible and clear",
  ];

  const handleSelectedImage = () => {
    plainFiles.map((file) => {
      setIdCard({ content: URL.createObjectURL(file), ...file });

      const reader = new FileReader();

      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        setImageURI(file);
      };

      reader.readAsDataURL(file);
    });
  };

  useEffect(() => {
    handleSelectedImage();
  }, [filesContent]);

  const handleUploadIDCard = async () => {
    try {
      setLoading(true);
      await asyncSetIsMultipart(true);

      const formData = new FormData();

      formData.append("user[detail_attributes][id_card]", imageURI);

      await updateUser(formData);

      await api.post("/api/users/request_kyc_check");

      refetch();
      navigate("/account/seller-dashboard", {
        state: { idx: 0, index: 2 },
      });
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
      await asyncRemoveIsMultipart();
      setLoading(false);
    }
  };

  return (
    <View>
      <ScrollView ref={scrollRef} style={{ height: "100vh" }}>
        {/* <Wrapper> */}
        <Navbar backgroundColor={colors.whiteFF} />
        <View
          style={{
            justifyContent: "center",
            marginTop: 100,
            alignItems: "center",
            marginHorizontal: isPhone ? 16 : isTablet ? 48 : 100,
            // height: height - 275,
            marginBottom: 100,
          }}
        >
          <LatoBold
            style={{
              fontSize: 24,
              lineHeight: 30,
              color: colors.grey33,
              marginBottom: 24,
              marginTop: 12,
              textAlign: "left",
              width: isPhone ? "100%" : isTablet ? 0.65 * width : 0.5 * width,
            }}
          >
            Upload KTP/ID Card
          </LatoBold>
          <LatoRegular
            style={{
              fontSize: 16,
              lineHeight: 24,
              color: colors.grey6C,
              marginBottom: 32,
              textAlign: "left",
              width: isPhone ? "100%" : isTablet ? 0.65 * width : 0.5 * width,
            }}
          >
            This helps us ensure the safety and security of our platform for all
            users.
          </LatoRegular>
          <View
            style={{
              backgroundColor: colors.whiteF1,
              borderRadius: 4,
              padding: 14,
              marginBottom: 32,
              // alignSelf: "flex-start",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                marginBottom: 16,
                width: isPhone ? "100%" : isTablet ? 0.65 * width : 0.5 * width,
              }}
            >
              <IoAlertCircleSharp size={20} color={colors.grey90} />
              <LatoBold
                style={{
                  fontSize: 14,
                  lineHeight: 20,
                  color: colors.grey33,
                  marginLeft: 14,
                }}
              >
                Upload Guide
              </LatoBold>
            </View>
            <View style={{ flexDirection: isPhone ? "column" : "row" }}>
              <View
                style={{
                  backgroundColor: colors.whiteFF,
                  width: isPhone ? (5 / 8) * (width - 64) : 225,
                  height: isPhone ? (2 / 5) * (width - 64) : 125,
                  alignItems: "center",
                  justifyContent: "center",
                  alignSelf: isPhone ? "center" : "flex-start",
                  marginBottom: isPhone ? 24 : 0,
                }}
              >
                <Image
                  source={IDCard}
                  style={{
                    width: isPhone ? width - 100 : 175,
                    height: isPhone ? (1 / 3) * (width - 100) : 115,
                  }}
                  resizeMode="contain"
                />
              </View>
              <View>
                {instructionsArr.map((itm, idx) => (
                  <View
                    key={idx}
                    style={{
                      flexDirection: "row",
                      width: isPhone
                        ? "100%"
                        : isTablet
                        ? 0.65 * width - 239
                        : 0.5 * width - 239,
                    }}
                  >
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        marginLeft: 14,
                        marginBottom: 8,
                      }}
                    >
                      {`${idx + 1}.`}
                    </LatoRegular>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        marginLeft: 14,
                        marginBottom: 8,
                      }}
                    >
                      {itm}
                    </LatoRegular>
                  </View>
                ))}
              </View>
            </View>
          </View>
          <LatoBold
            style={{
              fontSize: 24,
              lineHeight: 30,
              color: colors.grey33,
              marginBottom: 24,
              marginTop: 12,
              textAlign: "left",
              width: isPhone ? "100%" : isTablet ? 0.65 * width : 0.5 * width,
            }}
          >
            Upload here
            <LatoBold style={{ color: colors.redAD }}>*</LatoBold>
          </LatoBold>
          <View
            style={{
              width: isPhone ? "100%" : isTablet ? 0.65 * width : 0.5 * width,
              marginBottom: 24,
            }}
          >
            {Boolean(!idCard.content) ? (
              <DragDropFile setImage={setIdCard} setImageURI={setImageURI} />
            ) : (
              <View>
                <TouchableOpacity
                  {...getRootProps()}
                  onPress={(event) => {
                    openFileSelector();
                  }}
                >
                  <Image
                    source={{ uri: idCard.content }}
                    style={{ width: "100%", height: 500, marginBottom: 32 }}
                    resizeMode="contain"
                  />
                </TouchableOpacity>
                <Buttons
                  isBlack
                  label="Continue"
                  loading={loading}
                  onPress={handleUploadIDCard}
                />
              </View>
            )}
          </View>
        </View>
        <Footer />
      </ScrollView>
    </View>
  );
}

export default KYCScreen;
