export const regexChecker = (value, sample) => {
  if (value === "email")
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(sample);
  if (value === "password")
    return /[A-Z]/.test(sample) && /[a-z]/.test(sample) && /\d/.test(sample);

  if (value.includes("number")) return /^[0-9]*$/.test(sample);

  return true;
};
