import React from "react";
import {
  View,
  Image,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { LatoBold, LatoRegular } from "../Text";
import colors from "../../assets/colors/colors";
import Buttons from "../Buttons";
import { IoIosAdd } from "react-icons/io";
import { MdDeleteOutline, MdOutlineAddPhotoAlternate } from "react-icons/md";
import { RiPencilLine } from "react-icons/ri";
import { getDeviceType } from "../../tools/Interface";
import { AiOutlineBulb } from "react-icons/ai";

function AssistedListingItems({
  items = [],
  setItems = () => {},
  setOpenModal = () => {},
  setOpenAddPhotosModal = () => {},
  setEditIndex = () => {},
  // method = 0,
  outService = false,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  const handleDeleteItem = (i) => {
    setItems(
      items.filter((item, index) => {
        if (index !== i) return item;
      })
    );
  };

  return (
    <View style={{ marginBottom: 48 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 4,
        }}
      >
        <LatoRegular
          style={{
            fontSize: 16,
            lineHeight: 24,
            color: colors.grey33,
            textAlign: "left",
          }}
        >
          Total Item(s)
        </LatoRegular>
        <View
          style={{
            backgroundColor: colors.grey33,
            borderRadius: "100%",
            width: 5,
            height: 5,
            marginHorizontal: 10,
          }}
        />
        <LatoRegular
          style={{
            fontSize: 16,
            lineHeight: 24,
            color: colors.grey33,
            textAlign: "left",
          }}
        >{`${items.length} items`}</LatoRegular>
      </View>
      <LatoRegular
        style={{
          fontSize: 14,
          lineHeight: 20,
          color: colors.grey90,
          textAlign: "left",
          marginBottom: 12,
        }}
      >
        You can add up to 10 item.
      </LatoRegular>
      <View
        style={{
          backgroundColor: colors.whiteF1,
          borderWidth: 1,
          borderColor: colors.greyBB,
          borderRadius: 4,
          flexDirection: "row",
          alignItems: "center",
          paddingVertical: 8,
          paddingHorizontal: 12,
          marginBottom: 32,
        }}
      >
        <AiOutlineBulb size={20} color={colors.grey6C} />
        <LatoRegular
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.grey6C,
            marginLeft: 12,
          }}
        >
          The minimum required for pickup is 5 items.
        </LatoRegular>
      </View>
      {Boolean(items.length) &&
        items.map((item, idx) => {
          return (
            <View
              key={idx}
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                borderBottomColor: colors.greyBB,
                borderBottomWidth: 1,
                width: containerWidth,
                marginBottom: 28,
                paddingHorizontal: 8,
                paddingVertical: 12,
              }}
            >
              <View
                style={{ flexDirection: "row", justifyContent: "flex-start" }}
              >
                {Boolean(item?.photos?.length) ? (
                  <View
                    style={{
                      // backgroundColor: colors.greyE6,
                      borderWidth: 1,
                      borderColor: colors.greyE6,
                      borderRadius: 4,
                      padding: 8,
                      marginRight: 16,
                    }}
                  >
                    <Image
                      source={URL.createObjectURL(
                        item?.photos[0]?.imageFile || item?.photos[0]
                      )}
                      style={{ width: 64, height: 64 }}
                      resizeMode="contain"
                    />
                  </View>
                ) : (
                  <View
                    style={{
                      backgroundColor: colors.greyE6,
                      borderRadius: 4,
                      padding: 34,
                      marginRight: 16,
                    }}
                  >
                    <MdOutlineAddPhotoAlternate size={24} />
                  </View>
                )}
                <View
                  style={{
                    justifyContent: "space-between",
                    width: isPhone ? 150 : 250,
                  }}
                >
                  <View>
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        textAlign: "left",
                        textTransform: "uppercase",
                        marginBottom: 8,
                      }}
                    >
                      {item?.designer?.name}
                    </LatoBold>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        textAlign: "left",
                      }}
                    >
                      {item?.name}
                    </LatoRegular>
                  </View>
                  <View
                    style={{
                      flexDirection: isPhone ? "column" : "row",
                      alignItems: isPhone ? "flex-start" : "center",
                    }}
                  >
                    {Boolean(item?.photos?.length) && (
                      <LatoRegular
                        style={{
                          fontSize: 14,
                          lineHeight: 20,
                          color: colors.grey33,
                          textAlign: "left",
                          // marginRight: 12,
                        }}
                      >
                        {`${item?.photos?.length} item photo(s). `}
                      </LatoRegular>
                    )}
                    {Boolean(
                      !item?.photos?.length || item?.photos?.length < 6
                    ) && (
                      <TouchableOpacity
                        onPress={() => {
                          setOpenAddPhotosModal(true);
                          setEditIndex(idx);
                        }}
                        style={{ flexDirection: "row", alignItems: "center" }}
                      >
                        <LatoBold
                          style={{
                            fontSize: 14,
                            lineHeight: 20,
                            color: colors.grey33,
                            // marginBottom: 8,
                            textDecorationLine: "underline",
                          }}
                        >
                          {Boolean(!item?.photos?.length) && (
                            <LatoBold
                              style={{
                                fontSize: 16,
                                lineHeight: 20,
                                color: colors.grey33,
                                // marginBottom: 8,
                                // textDecorationLine: "underline",
                              }}
                            >
                              +
                            </LatoBold>
                          )}{" "}
                          {item?.photos?.length
                            ? "add more photo(s)"
                            : " Add item photo(s)"}
                        </LatoBold>
                      </TouchableOpacity>
                    )}
                  </View>
                </View>
              </View>

              {Boolean(
                !isTablet || (isPhone && width >= 500 && width < 600)
              ) && (
                <View style={{ width: 100 }}>
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.grey33,
                      textAlign: "left",
                      textTransform: "uppercase",
                      marginBottom: 8,
                    }}
                  >
                    Condition
                  </LatoBold>
                  <LatoBold
                    style={{
                      fontSize: 14,
                      lineHeight: 20,
                      color: colors.green5B,
                      textAlign: "left",
                    }}
                  >
                    {item?.condition?.name}
                  </LatoBold>
                </View>
              )}
              <View style={{ flexDirection: "row" }}>
                {Boolean(!isPhone || (isPhone && width >= 600)) && (
                  <View
                    style={{ width: isTablet ? 275 : 350, marginRight: 16 }}
                  >
                    <LatoBold
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey33,
                        textAlign: "left",
                        textTransform: "uppercase",
                        marginBottom: 8,
                      }}
                    >
                      Seller Story
                    </LatoBold>
                    <LatoRegular
                      style={{
                        fontSize: 14,
                        lineHeight: 20,
                        color: colors.grey6C,
                        textAlign: "left",
                      }}
                      numberOfLines={3}
                    >
                      {item?.story}
                    </LatoRegular>
                  </View>
                )}
                <View>
                  <TouchableOpacity
                    onPress={() => {
                      handleDeleteItem(idx);
                    }}
                    style={{
                      borderColor: colors.greyE6,
                      borderWidth: 1,
                      borderRadius: 4,
                      padding: 4,
                      marginBottom: 16,
                    }}
                  >
                    <MdDeleteOutline size={20} color={colors.grey90} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      setEditIndex(idx);
                      setOpenModal(true);
                    }}
                    style={{
                      borderColor: colors.greyE6,
                      borderWidth: 1,
                      borderRadius: 4,
                      padding: 4,
                    }}
                  >
                    <RiPencilLine size={20} color={colors.grey90} />
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          );
        })}
      <View
        style={{
          flexDirection: isPhone ? "column" : "row",
          justifyContent: Boolean(
            // method === 0 ||
            items?.length < 5 ||
              items?.filter(({ photos }, idx) => !photos?.length)?.length
          )
            ? "space-between"
            : items?.length
            ? "flex-end"
            : "flex-start",
          alignItems: isPhone ? "flex-start" : "center",
          width: containerWidth,
        }}
      >
        {Boolean(
          items?.length &&
            (items?.length < 5 ||
              items?.filter(({ photos }, idx) => !photos?.length)?.length)
        ) && (
          <LatoRegular
            style={{
              fontSize: 14,
              lineHeight: 20,
              color: colors.redB6,
              textAlign: "left",
              marginBottom: isPhone ? 16 : 0,
            }}
            numberOfLines={3}
          >
            {
              // method === 0 &&
              items?.length < 5 &&
              !items.filter(({ photos }, idx) => !photos?.length)?.length
                ? "Add at least 5 items to proceed pickup method"
                : "Add at least one photo for each item."
            }
          </LatoRegular>
        )}
        {Boolean(items?.length < 10) && (
          <Buttons
            label="Add new Item"
            onPress={() => setOpenModal(true)}
            isBlack
            width={141}
            icon={<IoIosAdd size={20} color={colors.whiteFF} />}
            iconStyle="center"
            disabled={outService}
          />
        )}
      </View>
    </View>
  );
}

export default AssistedListingItems;
