import React, { useContext, useEffect, useState } from "react";
import { BsWhatsapp } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import {
  View,
  TouchableOpacity,
  StyleSheet,
  useWindowDimensions,
  ScrollView,
  Image,
  Animated,
} from "react-native";
import { Link, useNavigate } from "react-router-dom";
import colors from "../assets/colors/colors";
import { IMAGE_ROOT_URL } from "../constants/api";
import { useAuth } from "../contexts/AuthContext";
import ToasterContext from "../contexts/ToastContext";
import { formatCurrency } from "../tools/formatter";
import { getDeviceType } from "../tools/Interface";
import { api } from "../utils/api";
import { LatoBold, LatoRegular } from "./Text";
import { openWhatsapp } from "../tools/tools";
import { contactDecider } from "../tools/decider";
import Buttons from "./Buttons";
import CurrencyContext from "../contexts/CurrenctContext";

export default function WishList({
  setOpenCart,
  getData,
  animatedWidth = 450,
  opacity = 1,
}) {
  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [subTotal, setSubTotal] = useState(0);

  const { user } = useAuth();
  const { showToast, showSuccessToast } = useContext(ToasterContext);
  const { selected: currency } = useContext(CurrencyContext);

  const getItems = async () => {
    try {
      const res = await api.get("/api/wishlist_items");
      const data = res?.data?.data;

      setItems(data);
      setSubTotal(data?.calculator?.discounted_subtotal);
    } catch (err) {
      console.log("err:", err);
      // showToast(err);
    } finally {
    }
  };

  useEffect(() => {
    getItems();
  }, []);

  const handleRemove = async (id) => {
    try {
      await api.delete(`api/wishlist_items/${id}`);
      showSuccessToast("Item removed from wishlist");

      await getItems();
      await getData();
      // await getItem();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  const handleMove = async (id) => {
    try {
      await api.delete(`api/wishlist_items/${id}`);
      showSuccessToast("Item removed from wishlist");

      await api.post("api/carts", { product_id: id });
      showSuccessToast("Item added to cart");

      await getItems();
      await getData();
      // await getItem();
    } catch (err) {
      console.log("err:", err);
      showToast(err);
    } finally {
    }
  };

  return (
    <TouchableOpacity
      activeOpacity={1}
      onPress={() => {
        if (!isPhone) {
          setOpenCart(false);
        }
      }}
      style={{
        zIndex: 10,
        position: "sticky",
        top: 0,
        backgroundColor: colors.transparentBlack,
        height: height,
        width: width,
        marginBottom: -(height - 88),
      }}
    >
      <Animated.View
        style={{
          zIndex: 4,
          backgroundColor: colors.whiteFF,
          width: animatedWidth,
          alignSelf: "flex-end",
          height: height,
          paddingHorizontal: 24,
          paddingVertical: 44,
          opacity,
        }}
      >
        <TouchableOpacity activeOpacity={1} style={{ height: height - 48 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingBottom: 20,
              borderBottomColor: colors.greyE6,
              borderBottomWidth: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <LatoRegular>WISHLIST</LatoRegular>
              {Boolean(items.length && user?.id) && (
                <>
                  <View
                    style={{
                      backgroundColor: colors.grey33,
                      borderRadius: "100%",
                      width: 5,
                      height: 5,
                      marginHorizontal: 10,
                    }}
                  />
                  <LatoRegular>{`${items.length} items`}</LatoRegular>
                </>
              )}
            </View>
            <TouchableOpacity onPress={() => setOpenCart(false)}>
              <GrClose size={14} />
            </TouchableOpacity>
          </View>
          {Boolean(items?.length && user?.id) ? (
            <View>
              <ScrollView
                style={{ height: height - 80 }}
                showsVerticalScrollIndicator={false}
              >
                {items.map((item, idx) => {
                  const { product } = item;
                  const {
                    name,
                    selling_price,
                    default_price,
                    multi_currency_price,
                    id,
                    sku,
                    inventory,
                  } = product;
                  return (
                    <TouchableOpacity
                      key={idx}
                      onPress={() =>
                        navigate(`/products/${inventory?.id}`, {
                          state: { index: idx },
                        })
                      }
                      style={{
                        marginTop: 32,
                        paddingBottom: 32,
                        borderBottomColor: colors.greyE6,
                        borderBottomWidth: 1,
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Image
                          source={{
                            uri: `${product?.summary_index?.direct_photos[0]}`,
                          }}
                          alt={`${name}`}
                          style={{
                            height: 120,
                            width: 120,
                            marginRight: 16,
                          }}
                          defaultSource={require("../assets/images/spinning-loading.gif")}
                          resizeMode={"contain"}
                        />
                        <View
                          style={{
                            width: isPhone ? width - 200 : 250,
                            flexWrap: "wrap",
                          }}
                        >
                          <LatoBold
                            style={{
                              fontSize: 14,
                              lineHeight: 20,
                              color: colors.grey33,
                              marginBottom: 8,
                            }}
                          >
                            {product?.summary_index?.designer}
                          </LatoBold>
                          <LatoRegular
                            style={{
                              fontSize: 14,
                              lineHeight: 20,
                              color: colors.grey33,
                              marginBottom: 8,
                            }}
                            numberOfLines={2}
                          >
                            {name}
                          </LatoRegular>
                          {Boolean(
                            (multi_currency_price?.selling_price[currency] ||
                              selling_price) !==
                              (multi_currency_price?.default_price[currency] ||
                                default_price)
                          ) && (
                            <LatoBold
                              style={{
                                fontSize: 14,
                                lineHeight: 20,
                                color: colors.redAD,
                              }}
                            >
                              {`Save ${(
                                (((multi_currency_price?.default_price[
                                  currency
                                ] || default_price) -
                                  (multi_currency_price?.selling_price[
                                    currency
                                  ] || selling_price)) /
                                  (multi_currency_price?.default_price[
                                    currency
                                  ] || default_price)) *
                                100
                              ).toFixed(0)}%`}
                            </LatoBold>
                          )}
                          <View
                            style={{
                              flexDirection: "row",
                              flexWrap: "wrap",
                              marginBottom: 16,
                            }}
                          >
                            <LatoBold
                              style={{
                                fontSize: 14,
                                lineHeight: 20,
                                color:
                                  (multi_currency_price?.selling_price[
                                    currency
                                  ] || selling_price) !==
                                  (multi_currency_price?.default_price[
                                    currency
                                  ] || default_price)
                                    ? colors.grey6C
                                    : colors.grey33,
                                textDecorationLine:
                                  (multi_currency_price?.selling_price[
                                    currency
                                  ] || selling_price) !==
                                  (multi_currency_price?.default_price[
                                    currency
                                  ] || default_price)
                                    ? "line-through"
                                    : "none",
                              }}
                            >{`${currency} ${formatCurrency(
                              multi_currency_price?.default_price[currency] ||
                                default_price
                            )}`}</LatoBold>
                            <LatoRegular style={{ textDecorationLine: "none" }}>
                              {"  "}
                            </LatoRegular>
                            {Boolean(
                              (multi_currency_price?.selling_price[currency] ||
                                selling_price) !==
                                (multi_currency_price?.default_price[
                                  currency
                                ] || default_price)
                            ) && (
                              <LatoBold
                                style={{
                                  fontSize: 14,
                                  lineHeight: 20,
                                  color: colors.grey33,
                                }}
                              >
                                {`${currency} ${formatCurrency(
                                  multi_currency_price?.selling_price[
                                    currency
                                  ] || selling_price
                                )}`}
                              </LatoBold>
                            )}
                          </View>
                          <View
                            style={{
                              flexDirection:
                                (isPhone && width > 545) || !isPhone
                                  ? "row"
                                  : "column",
                              alignItems:
                                (isPhone && width > 545) || !isPhone
                                  ? "center"
                                  : "flex-start",
                            }}
                          >
                            <TouchableOpacity
                              onPress={() => {
                                handleRemove(id);
                              }}
                              style={{
                                marginRight:
                                  (isPhone && width > 545) || !isPhone ? 12 : 0,
                                borderBottomColor: colors.grey6C,
                                borderBottomWidth: 1,
                                marginBottom: 8,
                              }}
                            >
                              <LatoRegular
                                style={{
                                  fontSize: 14,
                                  lineHeight: 20,
                                  color: colors.grey6C,
                                }}
                              >
                                Remove from wishlist
                              </LatoRegular>
                            </TouchableOpacity>

                            <TouchableOpacity
                              onPress={() => {
                                const isAccessories =
                                  product?.summary_index?.category ===
                                  "Accessories";
                                if (inventory?.quantity) {
                                  if (
                                    product?.summary_index?.designer ===
                                      "Hermes" &&
                                    !isAccessories
                                  ) {
                                    let message = `Hi, I'd like to purchase the following products:\n ${product?.summary_index?.designer} ${name} ${product?.summary_index?.category}. SKU: ${sku} (https://www.belleandkate.com/products/${inventory?.id})`;

                                    const contact = contactDecider(
                                      isAccessories
                                        ? "HermesAccessories"
                                        : "HermesBags"
                                    );
                                    openWhatsapp(message, contact);
                                  } else {
                                    handleMove(id);
                                  }
                                } else {
                                  navigate(`/products/${inventory?.id}`, {
                                    state: { index: idx },
                                  });
                                }
                              }}
                              style={{
                                borderBottomColor: colors.grey6C,
                                borderBottomWidth: 1,
                                marginBottom: 8,
                              }}
                            >
                              <LatoRegular
                                style={{
                                  fontSize: 14,
                                  lineHeight: 20,
                                  color: colors.grey6C,
                                }}
                              >
                                {inventory?.quantity
                                  ? (product?.summary_index?.designer ===
                                      "Hermes" &&
                                      product?.summary_index?.category !==
                                        "Accessories") ||
                                    product?.sku?.includes("GT") ||
                                    product?.sku?.startsWith("BKS")
                                    ? "Contact Us"
                                    : "Add to cart"
                                  : "See detail"}
                              </LatoRegular>
                            </TouchableOpacity>
                          </View>
                        </View>
                      </View>
                    </TouchableOpacity>
                  );
                })}
              </ScrollView>
            </View>
          ) : (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                height: height - 300,
              }}
            >
              <LatoRegular
                style={{
                  fontSize: 20,
                  lineHeight: 28,
                  color: colors.grey33,
                  textAlign: "center",
                  marginBottom: 24,
                }}
              >
                {user?.id
                  ? "YOUR WISHLIST IS EMPTY"
                  : "Please create an account first."}
              </LatoRegular>
              {Boolean(!user?.id) && (
                <Buttons
                  label={"Create Account"}
                  onPress={() => {
                    setOpenCart(false);
                    // setCustomOpenCart(false);
                    navigate("/register");
                  }}
                  width={260}
                  containerStyle={{ marginBottom: 12 }}
                />
              )}
              <Buttons
                label={user?.id ? "Shop now" : "Login"}
                onPress={() => {
                  setOpenCart(false);
                  // setCustomOpenCart(false);
                  navigate(user?.id ? "/products" : "/login");
                }}
                isBlack
                width={262}
              />
            </View>
          )}
        </TouchableOpacity>
      </Animated.View>
    </TouchableOpacity>
  );
}
