import React, { useState } from "react";
import { BsTelephone } from "react-icons/bs";
import { MdOutlineMail } from "react-icons/md";
import { View, TouchableOpacity, useWindowDimensions } from "react-native";
import colors from "../../assets/colors/colors";
import { useAuth } from "../../contexts/AuthContext";
import { api } from "../../utils/api";
import { LatoBold, LatoRegular } from "../Text";
import { useLocation, useNavigate, useParams } from "react-router";
import { getDeviceType } from "../../tools/Interface";

export default function MyAddress({ user }) {
  const { segment } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const { address = {}, email } = user;
  const { recipient_name, complete_address, recipient_number } = address;

  return Boolean(address?.id) ? (
    <View style={{ marginBottom: 0.5 * height }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          width: isPhone ? width - 32 : "100%",
        }}
      >
        <LatoRegular style={{ fontSize: 20, lineHeight: 28, marginBottom: 8 }}>
          Address
        </LatoRegular>
        <TouchableOpacity
          onPress={() => {
            navigate(`/account/${segment}/edit`, {
              state: { idx: state?.idx, index: state?.index },
            });
          }}
        >
          <View
            style={{
              borderBottomColor: colors.grey33,
              borderBottomWidth: 1,
              paddingBottom: 2,
              paddingHorizontal: 4,
            }}
          >
            <LatoRegular style={{ fontSize: 16, lineHeight: 20 }}>
              Edit
            </LatoRegular>
          </View>
        </TouchableOpacity>
      </View>
      <LatoRegular
        style={{
          fontSize: 16,
          lineHeight: 20,
          marginBottom: 32,
          width: isPhone ? width - 32 : "100%",
        }}
      >
        The following address will be used on the checkout page by default.
      </LatoRegular>
      <LatoBold style={{ fontSize: 16, lineHeight: 20, marginBottom: 8 }}>
        {recipient_name}
      </LatoBold>
      <LatoRegular style={{ fontSize: 16, lineHeight: 20 }}>
        {complete_address}
      </LatoRegular>
      {Boolean(recipient_number) && (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 24,
          }}
        >
          <BsTelephone size={20} color={colors.grey33} />
          <LatoRegular style={{ fontSize: 16, lineHeight: 20, marginLeft: 10 }}>
            {`+62${recipient_number}`}
          </LatoRegular>
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 14,
        }}
      >
        <MdOutlineMail size={20} color={colors.grey33} />
        <LatoRegular style={{ fontSize: 16, lineHeight: 20, marginLeft: 10 }}>
          {email}
        </LatoRegular>
      </View>
    </View>
  ) : (
    <View style={{ alignItems: "center", marginTop: 24 }}>
      <LatoRegular style={{ fontSize: 20, lineHeight: 28, marginBottom: 8 }}>
        You have no address yet.
      </LatoRegular>
      <TouchableOpacity
        onPress={() => {
          navigate(`/account/${segment}/edit`, {
            state: { idx: state?.idx, index: state?.index },
          });
        }}
        style={{
          width: "20%",
          paddingVertical: 10,
          paddingHorizontal: 12,
          backgroundColor: colors.grey33,
          borderColor: colors.grey33,
          borderWidth: 1,
          borderRadius: 6,
          marginVertical: 24,
        }}
      >
        <LatoRegular
          style={{
            fontSize: 14,
            lineHeight: 20,
            color: colors.whiteFF,
            textAlign: "center",
            width: "100%",
          }}
        >
          Add Address
        </LatoRegular>
      </TouchableOpacity>
    </View>
  );
}
