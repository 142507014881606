import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomeScreen from "../screens/HomeScreen";
import LoginScreen from "../screens/LoginScreen";
import ProductCatalogScreen from "../screens/ProductCatalogScreen";
import ProductDetailScreen from "../screens/ProductDetailScreen";
import RegisterScreen from "../screens/RegisterScreen";
import ContactUsScreen from "../screens/ContactUsScreen";
import AboutUsScreen from "../screens/AboutUsScreen";
import FAQScreen from "../screens/FAQScreen";
import PrivacyPolicyScreen from "../screens/PrivacyPolicyScreen";
import TermsConditionsScreen from "../screens/TermsConditionsScreen";
import AccountScreen from "../screens/AccountScreen";
import CheckoutAddressScreen from "../screens/CheckoutAddressScreen";
import CheckoutShipmentScreen from "../screens/CheckoutShipmentScreen";
import CheckoutPaymentScreen from "../screens/CheckoutPaymentScreen";
import CheckoutSuccessScreen from "../screens/CheckoutSuccessScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import PhoneVerificationScreen from "../screens/PhoneVerificationScreen";
import KYCScreen from "../screens/KYCScreen";
import PhoneConsignmentScreen from "../screens/PhoneConsignmentScreen";
import { AuthProvider } from "../contexts/AuthContext";
import { ToasterProvider } from "../contexts/ToastContext";
import AppointmentScreen from "../screens/AppointmentScreen";
import SellWithUsScreen from "../screens/SellWithUsScreen";
import CustomerCareScreen from "../screens/CustomerCareScreen";
import AuthenticationScreen from "../screens/AuthenticationScreen";
import ClassRoomScreen from "../screens/ClassRoomScreen";
import ConsignmentBranchingScreen from "../screens/ConsignmentBranchingScreen";
import WorkWithUsScreen from "../screens/WorkWithUsScreen";
import EditorialScreen from "../screens/EditorialScreen";
import CrushScreen from "../screens/CrushScreen";
import VIPScreen from "../screens/VIPScreen";
import RequestFormScreen from "../screens/RequestFormScreen";
import { CurrencyProvider } from "../contexts/CurrenctContext";

const routes = [
  {
    path: "/products/designer/:id",
    component: <ProductCatalogScreen />,
  },
  {
    path: "/products/category/:id",
    component: <ProductCatalogScreen />,
  },
  {
    path: "/products/:id",
    component: <ProductDetailScreen />,
  },
  {
    path: "/deals/:id",
    component: <CheckoutSuccessScreen />,
  },
  {
    path: "/checkout/payment",
    component: <CheckoutPaymentScreen />,
  },
  {
    path: "/checkout/shipment",
    component: <CheckoutShipmentScreen />,
  },
  {
    path: "/checkout/address",
    component: <CheckoutAddressScreen />,
  },
  {
    path: "/products",
    component: <ProductCatalogScreen />,
  },
  {
    path: "/login",
    component: <LoginScreen />,
  },
  {
    path: "/forgot-password",
    component: <ForgotPasswordScreen />,
  },
  {
    path: "/register",
    component: <RegisterScreen />,
  },
  {
    path: "/book-appointment",
    component: <AppointmentScreen />,
  },
  {
    path: "/about",
    component: <AboutUsScreen />,
  },
  {
    path: "/contact-us",
    component: <ContactUsScreen />,
  },
  {
    path: "/faq",
    component: <FAQScreen />,
  },
  {
    path: "/privacy-policy",
    component: <PrivacyPolicyScreen />,
  },
  {
    path: "/terms-conditions",
    component: <TermsConditionsScreen />,
  },
  {
    path: "/account/:segment/:additional/:id",
    component: <AccountScreen />,
  },
  {
    path: "/account/:segment/:additional",
    component: <AccountScreen />,
  },
  {
    path: "/account/:segment",
    component: <AccountScreen />,
  },
  {
    path: "/account",
    component: <AccountScreen />,
  },
  {
    path: "/request-item",
    component: <RequestFormScreen />,
  },
  {
    path: "/verification/input-phone",
    component: <PhoneConsignmentScreen />,
  },
  {
    path: "/verification/input-otp",
    component: <PhoneVerificationScreen />,
  },
  {
    path: "/verification/upload-id",
    component: <KYCScreen />,
  },
  {
    path: "/sell-with-us/:category",
    component: <SellWithUsScreen />,
  },
  {
    path: "/customer-care/:id",
    component: <CustomerCareScreen />,
  },
  {
    path: "/customer-care",
    component: <CustomerCareScreen />,
  },
  {
    path: "/authentication",
    component: <AuthenticationScreen />,
  },
  {
    path: "/work-with-us",
    component: <WorkWithUsScreen />,
  },
  {
    path: "/vip",
    component: <VIPScreen />,
  },
  {
    path: "/editorial/:category/:id",
    component: <ClassRoomScreen />,
  },
  {
    path: "/editorial/crushes/:id",
    component: <CrushScreen />,
  },
  {
    path: "/editorial",
    component: <EditorialScreen />,
  },
  {
    path: "/",
    component: <HomeScreen />,
  },
];

export default function RootNavigation() {
  return (
    <BrowserRouter>
      <ToasterProvider>
        <AuthProvider>
          <CurrencyProvider>
            <Routes>
              {routes.map(({ component, path }, index) => {
                return <Route path={path} element={component} key={index} />;
              })}
            </Routes>
          </CurrencyProvider>
        </AuthProvider>
      </ToasterProvider>
    </BrowserRouter>
  );
}
