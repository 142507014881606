import React from "react";
import { Image, View, useWindowDimensions } from "react-native";
import colors from "../../assets/colors/colors";
import { getDeviceType } from "../../tools/Interface";
import { LatoBold, LatoRegular } from "../Text";
import Buttons from "../Buttons";
import { useNavigate } from "react-router";
import { useAuth } from "../../contexts/AuthContext";

function SellCover({
  menuArr = [],
  cat = "",
  setYPosition = () => {},
  aboutPosition,
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { detail = {} } = user || {};

  const { width, height } = useWindowDimensions();
  const { isPhone, isTablet } = getDeviceType({ width, height });

  const gap = isPhone ? 16 : width < 1350 ? 50 : width < 1500 ? 150 : 230;
  const containerWidth = width - gap * 2;

  return (
    <View
      style={{
        flexDirection: isTablet ? "column-reverse" : "row",
        marginBottom: isTablet ? 0 : 100,
        paddingBottom: isTablet ? 24 : 100,
        borderBottomColor: colors.greyE6,
        borderBottomWidth: isTablet ? 0 : 1,
      }}
    >
      {Boolean(isPhone || !isTablet) && (
        <Image
          source={menuArr.filter(({ category }) => cat === category)[0].cover}
          alt={cat}
          style={{
            alignSelf: "center",
            width: isTablet ? containerWidth : width / 2,
            height:
              ((isTablet ? containerWidth : width / 2) *
                (isTablet ? 281 : 720)) /
              (isTablet ? 358 : 960),
          }}
          defaultSource={require("../../assets/images/spinning-loading.gif")}
          resizeMode={"cover"}
        />
      )}
      <View
        style={{
          width: isTablet ? width : width / 2,
          backgroundColor: colors.green5B,
          paddingHorizontal: isPhone ? 16 : 80,
          paddingVertical: isPhone ? 100 : 80,
          justifyContent: "center",
          alignItems: "center",
          marginBottom: isPhone ? 48 : 0,
        }}
      >
        <LatoBold
          style={{
            fontSize: isPhone ? 24 : 32,
            lineHeight: isPhone ? 32 : 40,
            color: colors.whiteFF,
            marginBottom: 16,
            textTransform: "capitalize",
            textAlign: "center",
          }}
        >
          Sell your luxury items with us in just a few clicks
        </LatoBold>
        <LatoRegular
          style={{
            fontSize: isPhone ? 12 : 20,
            lineHeight: isPhone ? 16 : 28,
            textAlign: "center",
            color: colors.whiteFF,
            marginBottom: isPhone ? 24 : 60,
          }}
        >
          No hassle, no fuss, just self-upload or send us your pre-loved items
        </LatoRegular>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Buttons
            label={`${
              menuArr.filter(({ category }) => cat === category)[0].title ===
              "CONSIGNMENT"
                ? "CONSIGN"
                : menuArr.filter(({ category }) => cat === category)[0].title
            } WITH US`}
            width={isPhone ? 120 : 188}
            onPress={() => {
              navigate(
                user?.id
                  ? detail?.state === "verified"
                    ? `/account/${cat}/method-selection`
                    : "/account/seller-dashboard"
                  : "/login",
                {
                  state: { idx: 0, index: 2 },
                }
              );
            }}
            isBlack
            containerStyle={{ marginRight: isPhone ? 16 : 24 }}
            fontSize={isPhone ? 10 : 16}
            lineHeight={isPhone ? 12 : 24}
            backgroundColor={colors.black00}
          />
          <Buttons
            label={"LEARN MORE"}
            width={isPhone ? 85 : 145}
            onPress={() => {
              setYPosition(aboutPosition);
            }}
            backgroundColor={"transparent"}
            color={colors.whiteFF}
            fontSize={isPhone ? 10 : 16}
            lineHeight={isPhone ? 12 : 24}
          />
        </View>
      </View>
    </View>
  );
}

export default SellCover;
